/* eslint-disable */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
    actionUsuarioInicia,
    actionUsuarioLogeado,
    actionUsuarioError,
} from "src/actions/api/user";

import { 
  apiUserLogin, 
} from "src/api/user";

import {
    USUARIO_LOGIN
} from 'src/constants/api/user';


function* sagaUser({ type, params }) {
  try {        
    yield put(actionUsuarioInicia());
    const data = yield call(apiUserLogin, params);
    yield put(actionUsuarioLogeado(data));
  } catch (e) {
    yield put(actionUsuarioError(e));    
  }
}

export default  [
  takeEvery(USUARIO_LOGIN, sagaUser), 
]
