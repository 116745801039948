/* eslint-disable */
import {
  DETECTORS_INICIA,
  DETECTORS_LISTADO,
  DETECTORS_ERROR,
  DETECTORS_ACTUALIZADA,
  DETECTORS_CREADA,
  DETECTORS_HABILITADA_DESHABILITADA
} from 'src/constants/api/detectors';

import { LIMPIAR_ESTADO } from 'src/constants/ActionTypes';

const DEFAULT_STATE = {
  waiting: false,
  estado: '',
  items: [],
  actualizar: false,
  apiRespuesta: false,
  dataDetail: {}
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case DETECTORS_INICIA:
      return { ...state, waiting: true };
    case DETECTORS_LISTADO:
      return {
        ...state,
        waiting: false,
        items: action.data,
        actualizar: false,
        estado: 'LISTADO',
        apiRespuesta: true
      };

    case DETECTORS_ACTUALIZADA:
      return {
        ...state,
        waiting: false,
        actualizar: true,
        estado: 'ACTUALIZADO',
        apiRespuesta: true
      };

    case DETECTORS_CREADA:
      return {
        ...state,
        waiting: false,
        actualizar: true,
        estado: 'CREADO',
        apiRespuesta: true,
        data: action.data
      };

    case DETECTORS_HABILITADA_DESHABILITADA:
      return {
        ...state,
        waiting: false,
        actualizar: true,
        estado: 'HABILITADO_DESHABILITADO',
        apiRespuesta: true
      };

    case DETECTORS_ERROR:
      return {
        ...state,
        waiting: false,
        error: action.error
          ? `${action.error.detail || action.error.detail}`
          : 'API Error.',
        estado: 'ERROR',
        actualizar: true,
        apiRespuesta: true
      };
    case LIMPIAR_ESTADO:
      return {
        ...state,
        error: '',
        actualizar: false,
        estado: '',
        apiRespuesta: false
      };
    default:
      return state;
  }
};
