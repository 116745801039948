/*eslint-disable */
import { Fragment, useEffect, useRef } from 'react';
import { Rect, Transformer } from 'react-konva';

export default function Gate({
  shapeProps,
  isSelected,
  onSelect,
  onChange,
  rotation
}) {
  // console.log(shapeProps);
  const shapeRef = useRef();
  const trRef = useRef();

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <Fragment>
      <Rect
        rotation={rotation}
        fill="white"
        stroke="black"
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        draggable
        onDragEnd={(e) => {
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          const rotation = node.rotation();
          const width = Math.max(5, node.width() * scaleX);
          const height = Math.max(5, node.height() * scaleY);
          const [x, y] = [node.x(), node.y()];

          const angleRad = (rotation * Math.PI) / 180;
          const diagonal = Math.sqrt(width ** 2 + height ** 2);

          const x1 =
            x + width * Math.cos(angleRad) - height * Math.sin(angleRad);
          const y1 =
            y + height * Math.cos(angleRad) + width * Math.sin(angleRad);

          node.scaleX(1);
          node.scaleY(1);
          onChange({
            ...shapeProps,
            x,
            y,
            rotation,
            x1,
            y1,
            width,
            height,
            diagonal
          });
        }}
        onTransformEnd={(e) => {
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          const rotation = node.rotation();
          const width = Math.max(5, node.width() * scaleX);
          const height = Math.max(5, node.height() * scaleY);
          const [x, y] = [node.x(), node.y()];

          const angleRad = (rotation * Math.PI) / 180;
          const diagonal = Math.sqrt(width ** 2 + height ** 2);

          const x1 =
            x + width * Math.cos(angleRad) - height * Math.sin(angleRad);
          const y1 =
            y + height * Math.cos(angleRad) + width * Math.sin(angleRad);

          node.scaleX(1);
          node.scaleY(1);
          onChange({
            ...shapeProps,
            x,
            y,
            rotation,
            x1,
            y1,
            width,
            height,
            diagonal
          });
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          enabledAnchors={[
            'top-left',
            'top-center',
            'top-right',
            'middle-right',
            'middle-left',
            'bottom-left',
            'bottom-center',
            'bottom-right'
          ]}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </Fragment>
  );
}
