/* eslint-disable */
import {
  COMPANIES_DETALLE_INICIA,

  COMPANIES_DETALLE,
  COMPANIES_DETALLADO,
  COMPANIES_DETALLE_ERROR,
} from 'src/constants/api/companies';

export const actionCompaniesDetalleInicia = () => ({ type: COMPANIES_DETALLE_INICIA, });

//Detalle
export const actionCompaniesDetalle = (params) => ({ type: COMPANIES_DETALLE, params });
export const actionCompaniesDetallado = (data) => ({ type: COMPANIES_DETALLADO, data });

export const actionCompaniesDetalleError = (error) => ({ type: COMPANIES_DETALLE_ERROR, error });
