/* eslint-disable */
import { URL_API_GATES_VIDEO } from './endpoints'

import {
  DEFAULT_HEADERS,
  apiPeticionExitosa,
  apiErrorHandler,
  userAuthToken,
  
} from './commons';

export const apiGatesVideoList = async params => {
  var query = await `/Video?video=${params.id}`
  return await fetch(
    `${URL_API_GATES_VIDEO}${query}`,
    Object.assign({ method: "GET" },
      {
        headers: new Headers({
          ...DEFAULT_HEADERS,
          Authorization: userAuthToken(),
        })
      }
    )
  ).then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}


export const apiGatesVideoCrear = async datos => {
  return await fetch(
    URL_API_GATES_VIDEO,
    //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
    {
      method: "POST", body: JSON.stringify(datos), headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken(),
      })
    }
  ).then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}



export const apiGatesVideoActualizar = async datos => {
  var query = datos.id ? `/${datos.id}` : '';
  return await fetch(
    `${URL_API_GATES_VIDEO}${query}`,
    //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
    {
      method: "PUT", body: JSON.stringify(datos), headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken(),
      })
    }
  ).then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}

export const apiGatesVideoDetail = async params => {
  var query = params.id ? `/${params.id}` : '';
  return await fetch(
    `${URL_API_GATES_VIDEO}${query}`,
    Object.assign({ method: "GET" },
      {
        headers: new Headers({
          ...DEFAULT_HEADERS,
          Authorization: userAuthToken(),
        })
      }
    )
  ).then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}

export const apiGatesVideoHabilitarDeshabilitar = async datos => {
  var query = `/${datos.id}`;
  return await fetch(
    `${URL_API_GATES_VIDEO}${query}`,
    {
      method: "DELETE", body: "", headers: new Headers({
        ...DEFAULT_HEADERS,
        "Content-Type": "text/plain",
        Authorization: userAuthToken(),
      })
    }
  )
  .then(apiPeticionExitosa)
  .catch(apiErrorHandler);
}

