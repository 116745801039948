/* eslint-disable */
import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Box,
    Card,
    Button,
    createMuiTheme,
} from '@material-ui/core';
import {
    DataGrid,
    esES,
    CellParams,
    GridApi
} from '@material-ui/data-grid';
import { localeTextDataGrid } from 'src/constants/ActionTypes'
import SweetAlert from 'react-bootstrap-sweetalert';

//Kanban
import Board from '@lourenci/react-kanban'
import '@lourenci/react-kanban/dist/styles.css'

//opciones
import { Edit, Reorder, ViewWeek } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import RequestCanvaCard from '../RequestCanvaCard';


const theme = createMuiTheme(
    {
        palette: {
            primary: { main: '#1976d2' },
        },
    },
    esES,
);


class ContactTableResults extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedCustomerIds: [],
            limit: 10,
            page: 0,
            viewList: true,
            contactEnableDisable: [],
            showConfirmEnableDisable: false
        }

        this.columns = [
            { field: 'id', hide: true, filterable: false },
            {
                field: "",
                headerName: "Opciones",
                sortable: false,
                filterable: false,
                width: 120,
                headerAlign: 'center',
                align: 'center',
                disableClickEventBubbling: true,
                renderCell: (params: CellParams) => {
                    const onClick = () => {
                        const api: GridApi = params.api;
                        const fields = api
                            .getAllColumns()
                            .map((c) => c.field)
                            .filter((c) => c !== "__check__" && !!c);
                        const thisRow = {};

                        fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                        });
                        this.props.OnEdit(thisRow.id);
                        //return alert(JSON.stringify(thisRow, null, 4));
                    };

                    return <div>
                        <IconButton aria-label="Editar" onClick={onClick}>
                            <Edit fontSize="small" />
                        </IconButton>
                    </div>;
                }
            },
            { field: 'name', headerName: 'Nombre', width: 200, editable: false },
            { field: 'administrativeCharge', headerName: 'Cargo', width: 200 },
            { field: 'email', headerName: 'Correo', width: 250 },
            { field: 'phone', headerName: 'Teléfono', width: 250 },
            { field: 'nit', headerName: 'NIT', width: 200 },
            { field: 'company', headerName: 'Empresa', width: 200 },
            { field: 'businessGroup', headerName: 'Grupo', width: 200 },
            {
                field: 'insertDate', headerName: 'Fecha Creación', width: 200,
                valueFormatter: (params: GridValueFormatterParams) => {
                    // first converts to JS Date, then to locale option through date-fns
                    return moment(params.value).format("DD/MM/YYYY HH:mm");
                },
                // valueGetter for filtering
                valueGetter: (params: GridValueGetterParams) => {
                    return moment(params.value).format("MM/DD/YYYY HH:mm");
                }
            },
            // { field: 'requestState', headerName: 'Última acción realizada', width: 250 },
            // { field: 'executiveName', headerName: 'Ejecutivo', width: 150 }
            // Contrato 
            // Grupo 
            // Razón social: no se puede editar 
            // Nit: no se puede editar 
            // Fecha de creación del contacto 
        ];
    }

    handleEnableDisable = () => {
        this.props.onEnableDisable({
            idRegistry: this.state.contactEnableDisable,
            stateEnableDisable: 0
        })
        this.setState({ showConfirmEnableDisable: false, contactEnableDisable: [] })

    }

    render() {
        return (
            <div
                onKeyDownCapture={(e) => {
                    if (e.key === "Backspace" || e.key === "Delete") {
                        e.stopPropagation();
                    }
                }}
            >
                <DataGrid
                    columns={this.columns}
                    rows={this.props.Customers}
                    rowHeight={25}
                    autoHeight={true}
                    checkboxSelection
                    disableSelectionOnClick={true}
                    disableColumnReorder={true}
                    disableColumnSelector={true}
                    disableColumnSelector={true}
                    localeText={localeTextDataGrid}
                    isCellEditable={false}
                    editMode={false}
                    rowsPerPageOptions={[10, 20, 40]}
                    pageSize={10}
                    onSelectionModelChange={newSelection => this.setState({ contactEnableDisable: newSelection.selectionModel })}
                />
                {
                    this.state.contactEnableDisable.length > 0 ?
                        <div style={{ width: '100%', textAlign: 'left', margin: "0.5em 0em 0.5em 0em" }}>
                            <Button color="primary" style={{ marginLeft: "1em" }} variant="contained"
                                onClick={() => this.setState({ showConfirmEnableDisable: true })}>
                                Deshabilitar
                            </Button>
                        </div>
                        : null
                }
                <SweetAlert
                    show={this.state.showConfirmEnableDisable}
                    success
                    confirmBtnText="Aceptar"
                    confirmBtnBsStyle="light"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="light"
                    cancelBtnStyle={{ fontSize: 14 }}
                    style={{ color: "#000", fontSize: 14 }}
                    confirmBtnStyle={{ fontSize: 14 }}
                    title={"Deshabilitar contactos"}
                    onConfirm={() => this.handleEnableDisable()}
                    onCancel={() => this.setState({ showConfirmEnableDisable: false })}
                    showCancel={true}
                    showConfirm={true}
                >
                    <span>{"¿Estás seguro que desea deshabilitar estos contactos?"}</span>
                </SweetAlert>
            </div>
        )
    }
}



export default ContactTableResults;