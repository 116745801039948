/* eslint-disable */
import {        
    APROBAR_USUARIO,
    RESTABLECER_CLAVE_USUARIO,    
    ERROR_API,        
    LIMPIAR_ESTADO
} from 'src/constants/ActionTypes';

// ----------------------------------------------------------------------------
export const actionErrorApi = data => ({ type: ERROR_API, data });
export const actionLimpiarEstado = () => ({ type: LIMPIAR_ESTADO, });
// ----------------------------------------------------------------------------

export const actionAprobarUsuario = data => ({ type: APROBAR_USUARIO, data });
export const actionRestablecerClaveUsuario = data => ({ type: RESTABLECER_CLAVE_USUARIO, data });















