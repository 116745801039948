/* eslint-disable */
import React, { Component } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SweetAlert from 'react-bootstrap-sweetalert'
import UploadVideo from 'src/components/CreateVideoForms/UploadVideo';
import GeneralData from 'src/components/CreateCameraForms/GeneralData';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import Driveways from 'src/components/CreateVideoForms/Driveways';

import Maneuvers from 'src/components/CreateVideoForms/Maneuvers';
import AnalyzeVideo from 'src/components/CreateVideoForms/AnalyzeVideo';


import LocationOnIcon from '@mui/icons-material/LocationOn';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import EventNoteIcon from '@mui/icons-material/EventNote';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';

const styles = {
    root: {
        width: '100%',
    },
    button: {
        marginRight: 1,
    },
    backButton: {
        marginRight: 1,
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: 1,
        marginBottom: 1,
        marginRight: 10,
        marginLeft: 10
    },
    icon: {
        backgroundColor: "#0099ff",
        color: "#fff",
        borderRadius: 20,
        fontSize: 40,
        paddingTop: 5,
        paddingBottom: 5
    }
};
class StepperComponentVideo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: 0,
            valueGeneral: 0,
            activeStep: 0,
            completed: {},
            skipped: 0,
            steps: [
                "Cargar video",
                "Crear compuertas",
                "Agregar maniobras",
                "Analizar video"],
            dataStep: {},
            showAlert: false,
            step: 0,
            step1Error: null,
            step2Error: null,
            step3Error: null,
            objStep1: {},
            objStep2: {},
            objStep3: {},
        }
    }

    componentDidMount() {
        this.props.ShowSaveButton();
    }

    componentDidUpdate() {

    }

    totalSteps = () => {
        return this.state.steps.length;
    };

    completedSteps = () => {
        const { completed } = this.state;
        return Object.keys(completed).length;
    };

    isLastStep = () => {
        const { activeStep } = this.state;
        return activeStep === this.totalSteps() - 1;
    };

    allStepsCompleted = () => {
        return this.completedSteps() === this.totalSteps();
    };

    handleNext = (obj) => {
        const { completed, steps, activeStep } = this.state;
        const newActiveStep =
            this.isLastStep() && !this.allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        this.setState({
            activeStep: newActiveStep
        });

        if (obj.step === 1) {
            this.setState({
                objStep1: obj.fields,
                step1Error: false
            });
        }


        if (obj.step === 2) {
            this.setState({
                objStep2: obj.fields,
                step2Error: false
            });
        }

        if (obj.step === 3) {
            this.setState({
                objStep2: obj.fields,
                step2Error: false
            });
        }
    };

    handleCreateCamera = async (obj) => {
        if (obj.step === 3) {
            await this.setState({
                objStep3: obj.fields,
                step3Error: false
            }, () => {
                this.props.CreateCamera({
                    step1Error: this.state.step1Error,
                    step2Error: this.state.step2Error,
                    step3Error: this.state.step3Error,
                    objStep1: this.state.objStep1,
                    objStep2: this.state.objStep2,
                    objStep3: this.state.objStep3,
                })
            });
        }
    }

    handleBack = () => {
        this.setState({
            activeStep: this.state.activeStep - 1
        });
    };

    handleStep = (step) => {
        this.setState({
            activeStep: step
        });
    };

    handleComplete = () => {
        const { completed, steps, activeStep } = this.state;

        const newCompleted = completed;
        newCompleted[activeStep] = true;
        this.setState({
            completed: newCompleted
        }, () => {
            this.handleNext();
        })
    };

    handleReset = () => {
        this.setState({
            activeStep: 0,
            completed: {}
        })
    };
    finishForm = () => {
        //console.log("Finish form");
    }


    getStepContent = (step: number) => {
        const {
            step1Error,
            step2Error,
            step3Error,
            objStep1,
            objStep2,
            objStep3,
        } = this.state;

        switch (step) {
            case 0:
                return <UploadVideo
                    {...this.props}
                    descriptionText={""}
                    objStep1={objStep1}
                    NextStep={(obj) => this.handleNext(obj)}
                    ref={instance => { this.child = instance; }}
                />;
            case 1:
                return <Driveways
                    {...this.props}
                    descriptionText={""}
                    objStep1={this.state.objStep1}
                    objStep2={this.state.objStep2}
                    ref={instance => { this.child = instance; }}
                    BackStep={() => this.handleBack()}
                    NextStep={(obj) => this.handleNext(obj)}
                    CreateCamera={(obj) => this.handleCreateCamera(obj)}
                    deleteById={(id) => this.props.deleteById(id)}
                />;
            case 2:
                return <Maneuvers
                    {...this.props}
                    descriptionText={""}
                    ref={instance => { this.child = instance; }}
                    objStep1={this.state.objStep1}
                    objStep2={this.state.objStep2}
                    BackStep={() => this.handleBack()}
                    NextStep={(obj) => this.handleNext(obj)}
                    CreateCamera={(obj) => this.handleCreateCamera(obj)}
                    deleteById={(id) => this.props.deleteById(id)}
                />;
                case 3:
                    return <AnalyzeVideo
                        {...this.props}
                        descriptionText={""}
                        objStep1={objStep1}
                        NextStep={(obj) => this.handleNext(obj)}
                        ref={instance => { this.child = instance; }}
                    />;

            default:
                return 'Unknown step';
        }
    }

    saveRequest() {
        //alert('clicked');
        this.child.saveRequest();
    }

    returnIcon = (index) => {

        if (index === 0) {
            return (<LocationOnIcon
                style={styles.icon}
            />);
        } else if (index === 1) {
            return (<EventNoteIcon
                style={styles.icon}
            />);
        } else if (index === 2) {
            return (<EditRoadIcon
                style={styles.icon}
            />);
        } else {
            return (<VideoSettingsIcon
                style={styles.icon}
            />);
        }
    }
    
    render() {
        const { steps, activeStep, completed, } = this.state;
        return (
            <Box display="flex" flexDirection="row" width={"100%"} overflow={"hidden"} >
                <div style={{ width: "100%" }} >
                    <div className={styles.root}>
                        <PerfectScrollbar>
                            <div class="d-flex justify-content-center">
                                <Stepper
                                    variant="progress"
                                    steps={4}
                                    alternativeLabel
                                    nonLinear={false}
                                    activeStep={activeStep} style={{
                                        border: "none",
                                        outline: "none",
                                        width: "90%",
                                        alignSelf: "center",
                                        marginTop: 20
                                    }}
                                >
                                    {steps.map((label, index) => (
                                        <Step key={label} style={{
                                            // border: "none",
                                            outline: "none",
                                        }}
                                        >
                                            <StepButton
                                                icon={this.returnIcon(index)}
                                                onClick={() => this.handleStep(index)} completed={completed[index]}
                                                style={{
                                                    border: "none ",
                                                    outline: "none",
                                                    marginTop: -5
                                                }}>
                                                {label}
                                            </StepButton>
                                        </Step>
                                    ))}
                                </Stepper>
                            </div>
                        </PerfectScrollbar>
                        <div>
                            {this.allStepsCompleted() ? (
                                <div>
                                    <Typography className={styles.instructions}>
                                        All steps completed - you&apos;re finished
                                    </Typography>
                                    <Button onClick={handleReset}>Reset</Button>
                                </div>
                            ) : (
                                <div>
                                    <Typography className={styles.instructions}>{this.getStepContent(activeStep)}</Typography>
                                    {/* <div>
                                        <Button disabled={activeStep === 0} onClick={() => this.handleBack()} className={styles.button}>
                                            Back
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => this.handleNext()}
                                            className={styles.button}
                                        >
                                            Next
                                        </Button>
                                        {activeStep !== steps.length &&
                                            (completed[activeStep] ? (
                                                <Typography variant="caption" className={styles.completed}>
                                                    Step {activeStep + 1} already completed
                                                </Typography>
                                            ) : (
                                                <Button variant="contained" color="primary" onClick={() => this.handleComplete()}>
                                                    {this.completedSteps() === this.totalSteps() - 1 ? 'Finish' : 'Complete Step'}
                                                </Button>
                                            ))}
                                    </div> */}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <SweetAlert
                    show={this.state.showAlert}
                    error
                    confirmBtnText="Aceptar"
                    confirmBtnBsStyle="light"
                    style={{ color: "#000", fontSize: 14 }}
                    title={"Se han realizado cambios"}
                    onConfirm={() => this.setState({
                        showAlert: !this.state.showAlert,
                    })}
                    showCancel
                    cancelBtnText={"Cancelar"}
                    cancelBtnBsStyle="light"

                    cancelBtnStyle={{ fontSize: 14 }}
                    confirmBtnStyle={{ fontSize: 14 }}

                    onCancel={() => this.setState({
                        showAlert: !this.state.showAlert,
                    })}
                >
                    <span>{"¿Desea guardar los cambios?"}</span>
                </SweetAlert>

            </Box>
        )
    }
}



export default StepperComponentVideo;