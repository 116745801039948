/* eslint-disable */
export const DETECTORS_INICIA = 'DETECTORS_INICIA';
export const DETECTORS_LISTA = 'DETECTORS_LISTA';
export const DETECTORS_LISTADO = 'DETECTORS_LISTADO';
export const DETECTORS_ERROR = 'DETECTORS_ERROR';

export const DETECTORS_ACTUALIZAR = 'DETECTORS_ACTUALIZAR';
export const DETECTORS_ACTUALIZADA = 'DETECTORS_ACTUALIZADA';
export const DETECTORS_CREAR = 'DETECTORS_CREAR';
export const DETECTORS_CREADA = 'DETECTORS_CREADA';

export const DETECTOR_DETALLE_INICIA = 'DETECTOR_DETALLE_INICIA';
export const DETECTOR_DETALLE = 'DETECTORS_DETALLE';
export const DETECTOR_DETALLADO = 'DETECTOR_DETALLADO';
export const DETECTOR_DETALLE_ERROR = 'DETECTOR_DETALLE_ERROR';

export const DETECTORS_HABILITAR_DESHABILITAR =
  'DETECTORS_HABILITAR_DESHABILITAR';
export const DETECTORS_HABILITADA_DESHABILITADA =
  'DETECTORS_HABILITADA_DESHABILITADA';
