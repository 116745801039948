/* eslint-disable */
import React, { Component } from 'react';

import { Box, Container } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import './styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faFileExcel, faFileWord, faFileZipper } from '@fortawesome/free-regular-svg-icons';

import ReactFormInputValidation from "react-form-input-validation";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";

import 'react-circular-progressbar/dist/styles.css';

import { URL_API_FILES_LOCATION } from 'src/api/endpoints';

import 'react-toastify/dist/ReactToastify.css';
import VideocamIcon from '@mui/icons-material/Videocam';

class AnalyzeVideo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: {
                idRegistry: "",

                name: (this.props.Update && this.props.dataDetail) ? this.props.dataDetail.name : this.props.objStep1.name ? this.props.objStep1.name : "",
                fileType: "",
            },
            errors: {},
            files: [{
                size: 1
            }],
            showErrorFiles: false,
            showErrorVideo: false,
            key: Math.random(),
            image: "",
            uploadVideo: true,
            objToSend: {},
            percent: 0,
            intervalId: null,
            intervalIdTrajectory: null,

        };
        this.form = new ReactFormInputValidation(this, { locale: 'es' });
        this.form.onformsubmit = async (fields) => { }
    }

    componentDidUpdate(preprops) {
        if (this.props.dataDetail !== preprops.dataDetail) {
            this.setDataFields();
        }

        if (this.props.dataVideoUploaded) {
            switch (this.props.estadoVideo) {
                case "DETALLADO":
                    this.setAnalyzePercent()
                    if (this.props.dataVideoDetailed.load_analyzing >= 100) {
                        clearInterval(this.state.intervalIdTrajectory);
                    }

                    break;
            }
        }

        // if (this.props.apiRespuestaVideo) {
        //     switch (this.props.estadoVideo) {
        //         case "CREADO":
        //             this.getDetailVideo()
        //             break;
        //         default:
        //             break;
        //     }
        //     this.props.actionLimpiarEstado()
        // }
    }

    async componentDidMount() {
        this.form.useRules({
            name: "required",
        });
        this.getDetailVideo()
        // const intervalId = setInterval(() => this.updateUnread(), 2000);
        // this.setState({ intervalId })
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalIdTrajectory);
    }


    setDataFields = () => {
        this.setState({
            fields: {
                name: (this.props.Update && this.props.dataDetail) ? this.props.dataDetail.name : this.props.objStep1.name ? this.props.objStep1.name : "",
            },
            files: this.props.objStep1.files ? this.props.objStep1.files : []
        });
    }

    nextStep = () => {
        this.props.NextStep({
            step: 1,
            completed: true,
            fields: this.state.objToSend,
        });
    }

    getDetailVideo = () => {
        var intervalIdTrajectory = setInterval(() => this.updateAnalyzing(), 2000);
        this.setState({ intervalIdTrajectory: intervalIdTrajectory });
    }

    updateAnalyzing = () => {
        this.props.actionVideosDetalle({
            id: this.props.dataVideoCreated.public_key
        });
    }

    setAnalyzePercent = () => {
        this.setState({
            load_analyzing: this.props.dataVideoDetailed.load_analyzing,
            percent: this.props.dataVideoDetailed.load_analyzing,
        })
    }

    openFile = (file) => {
        window.open(URL_API_FILES_LOCATION+file, '_blank').focus();
    }

    render() {
        return (
            // <Dialog
            //     fullWidth={true}
            //     maxWidth={"md"}
            //     open={this.props.open}
            //     onClose={() => this.props.CloseDialog()}
            //     aria-labelledby="form-dialog-title">
            //     <DialogTitle id="form-dialog-title">{this.props.Update ? "Actualizar" : "Crear"} Contacto</DialogTitle>
            //     <DialogContent>
            <>

                <Box
                    sx={{
                        backgroundColor: '#fff',
                        minHeight: '100%',
                        py: 3,
                        marginTop: 0
                    }}
                >
                    <Container maxWidth={false}>

                        <Box display="flex" flexDirection="row" width={"100%"} overflow={"hidden"}>
                            <form onSubmit={this.form.handleSubmit} style={{ width: "100%", }}>
                                <Box display="flex"
                                    flexDirection="column"
                                    width={"100%"}
                                    marginTop={2}
                                    overflow={"hidden"}>

                                    <div className="form-row" style={{ width: "100%", marginTop: 10, marginBottom: 20, alignContent: "center", alignItems: "center", justifyContent: "center" }}>
                                        <img src={"/static/images/avatars/pollution.png"} width={"30%"} height={undefined} />
                                    </div>

                                    <div class="d-flex justify-content-center" style={{ flexDirection: "column", marginTop: 20 }}>
                                        <div class="d-flex justify-content-center" style={{
                                            backgroundColor: "#fff",
                                            width: "80%",
                                            alignSelf: "center",
                                            alignItems: "center",
                                            flexDirection: "row"
                                        }}>
                                            <VideocamIcon
                                                style={{
                                                    backgroundColor: "#0099ff",
                                                    fontSize: 40,
                                                    color: "#fff",
                                                    borderRadius: 30,
                                                    padding: 10,
                                                    marginRight: 20
                                                }} />
                                            <Box display="flex"
                                                flexDirection="column"
                                                width={"90%"}>
                                                <div>
                                                    {this.props.objStep1.name
                                                        ? this.props.objStep1.name
                                                        : ""
                                                    }
                                                </div>
                                                <ProgressBar
                                                    filledBackground="#0099ff"
                                                    unfilledBackground={"#e1ded8"}
                                                    percent={this.state.percent}
                                                    width={"100%"}
                                                    height={15}
                                                    text={`${this.state.percent}%`}
                                                />
                                            </Box>
                                        </div>
                                    </div>

                                    <div class="d-flex justify-content-center"
                                        style={{ fontSize: 14, color: "#4c4c4c", textAlign: "center", marginTop: 20 }}>
                                        {this.state.percent < 100
                                            ? <div class="d-flex justify-content-center">
                                                Estamos analizando el video.<br></br>
                                                Pronto tendrás información valiosa para tomar decisiones clave en tu operación.
                                            </div>
                                            :
                                            <div class="d-flex justify-content-center">
                                                El video ha sido analizado.<br></br>
                                                Descarga la información en los siguientes formatos.
                                            </div>
                                        }

                                    </div>

                                    <div
                                    class="d-flex justify-content-center" 
                                    style={{ flexDirection: "row", alignItems: "center" }}>
                                        <IconButton
                                            aria-label="Editar"
                                            disabled={this.props.dataVideoDetailed.word !== "" ? false : true}
                                            onClick={() => this.openFile(this.props.dataVideoDetailed.word )}
                                            style={{
                                                border: "none", outline: "none",
                                                color: this.props.dataVideoDetailed.word !== "" ? "#005c99" : "#cecece",
                                                fontSize: 40
                                            }}>
                                            <FontAwesomeIcon icon={faFileWord} />
                                        </IconButton>

                                        <IconButton
                                            aria-label="Editar"
                                            disabled={this.props.dataVideoDetailed.excel !== "" ? false : true}
                                            onClick={() => this.openFile(this.props.dataVideoDetailed.excel)}
                                            style={{
                                                border: "none", outline: "none",
                                                color: this.props.dataVideoDetailed.excel !== "" ? "#00b231" : "#cecece",
                                                fontSize: 40
                                            }}>
                                            <FontAwesomeIcon icon={faFileExcel} />
                                        </IconButton>

                                        <IconButton
                                            aria-label="Editar"
                                            disabled={this.props.dataVideoDetailed.gzip !== "" ? false : true}
                                            onClick={() => this.openFile(this.props.dataVideoDetailed.gzip)}
                                            style={{
                                                border: "none", outline: "none",
                                                color: this.props.dataVideoDetailed.gzip !== "" ? "#0099ff" : "#cecece",
                                                fontSize: 40
                                            }}>
                                            <FontAwesomeIcon
                                                enableBackground={false}
                                                icon={faFileZipper} />
                                        </IconButton>
                                    </div>
                                </Box>
                                <Box
                                    style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                        width: "100%",
                                        marginTop: 30,
                                        justifyContent: "center"
                                    }}>

                                    <Button
                                        variant='contained'
                                        endIcon={<ArrowForwardIcon />}
                                        color="primary"
                                        disabled={this.props.dataVideoDetailed !== undefined
                                            ? this.props.dataVideoDetailed.load_analyzing >= 100
                                                ? false
                                                : true
                                            : true}
                                        //type="submit"
                                        onClick={() => this.props.executeClose()}
                                        style={{ outline: "none" }}>
                                        Finalizar
                                    </Button>

                                </Box>
                            </form>
                        </Box>
                    </Container>
                </Box>

            </>

        )
    }
}



export default AnalyzeVideo;