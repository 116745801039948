/* eslint-disable */
import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  ThemeProvider,
  createMuiTheme,
} from '@material-ui/core';
import {
  esES
} from '@material-ui/data-grid';

//Kanban
import '@lourenci/react-kanban/dist/styles.css'

//opciones
import DrawGatesTableResults from './DrawGatesTableResults';

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  esES,
);


class DrawGatesListResults extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedCustomerIds: [],
      limit: 10,
      page: 0,
    }
  }

  handleSelectAll = (event) => {
    const { customers } = this.props;
    let newSelectedCustomerIds;
    if (event.target.checked) {
      newSelectedCustomerIds = customers.map((customer) => customer.idRegistry);
    } else {
      newSelectedCustomerIds = [];
    }
    this.setState({
      selectedCustomerIds: newSelectedCustomerIds
    })
  }

  handleSelectOne = (event, id) => {
    const { selectedCustomerIds } = this.state;
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    this.setState({
      selectedCustomerIds: newSelectedCustomerIds
    })
  }

  handleLimitChange = (event) => {
    this.setState({
      limit: event.target.value
    })
  }

  handlePageChange = (event, newPage) => {
    this.setState({
      page: newPage
    })
  };

  showList = (selectedCustomerIds, limit, page, Gates) => {
    return (
      <DrawGatesTableResults
        Gates={Gates}
        OnEdit={(id) => this.onEdit(id)}
        onEnableDisable={(data) => this.props.onEnableDisable(data)}
      />
    );
  }

  onEdit = (id) => {
    //console.log("id: ", id.toString())
    this.props.OpenEdit(id.toString());
  }


  render() {
    const { Gates } = this.props;
    const { selectedCustomerIds, limit, page } = this.state;
    return (
      <Card>
        <PerfectScrollbar>
          <Box display="flex" flexDirection="column" >
            <ThemeProvider theme={theme} overflow={"hidden"}>
              {this.showList(selectedCustomerIds, limit, page, Gates)}
            </ThemeProvider>
          </Box>
        </PerfectScrollbar>
      </Card>
    );
  }
}

export default DrawGatesListResults;
