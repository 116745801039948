/* eslint-disable */
import React from 'react';
import Select from 'react-select';

export default class DropDownWithSearchFormTemplateMulti extends React.Component {

    render() {
        const { field, label, classCol, error, className, value, options, disabled } = this.props;
        const colourStyles = {
            control: base => ({
                ...base,
                //maxHeight: "32px",
                marginTop: "-4px",
                position: 'relative'

            }),
            menuPortal: base => ({
                ...base,
                zIndex: 9999999999999999

            }),

            container: provided => ({ ...provided, width: "100%", }),

            placeholder: styles => ({ ...styles }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '30px',
            }),
        };
        return (
            <div className={className}>
                <label className="e-label-top" style={{
                    fontSize: "13px",
                    marginTop: "-200px",
                    color: "#0000008a",
                    fontWeight: "normal"
                }}>{label}</label>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={value}
                    isSearchable={true}
                    name="color"
                    options={options}
                    styles={colourStyles}
                    onChange={(args) => this.props.select(args)}
                    menuPortalTarget={document.body}
                    menuPlacement={"bottom"}
                    menuShouldScrollIntoView={true}
                    menuPosition={'fixed'}
                    isDisabled={disabled}
                    key={value}
                    isMulti={true}
                />
                {error
                    ?
                    <label className="error" style={{ color: "red", fontSize: 12, fontWeight: "normal" }}>
                        {error ? error : ""}
                    </label>
                    : null}
            </div>

        )
    }

}