/* eslint-disable */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
    actionRoadWaysDetalleInicia,
    actionRoadWaysDetallado,
    actionRoadWaysError,
} from "src/actions/api/roadWays";

import { 
    apiRoadWaysDetail,    
} from "src/api/roadWays";

import {
    ROADWAYS_DETALLE
} from 'src/constants/api/roadWays';

function* sagaRoadWaysDetail({ type, params }) {
  try {        
    yield put(actionRoadWaysDetalleInicia());
    const data = yield call(apiRoadWaysDetail, params);
    yield put(actionRoadWaysDetallado(data));
  } catch (e) {
    yield put(actionRoadWaysError(e));    
  }
}

export default  [
  takeEvery(ROADWAYS_DETALLE, sagaRoadWaysDetail), 
]
