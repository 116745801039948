/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

import { actionUsuarioLogin } from "src/actions/api/user";

import {
  actionCompaniesLista,
} from "src/actions/api/companies";

import { actionLimpiarEstado } from "src/actions";

import {
  actionDetectorsLista,
  actionDetectorsActualizar,
  actionDetectorsCrear,
  actionDetectorsDetalle,
  actionDetectorsHabilitarDeshabilitar
} from "src/actions/api/detectors";


import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import DetectorListResults from 'src/components/detector/DetectorListResults';
import DetectorListToolbar from 'src/components/detector/DetectorListToolbar';
import Loading from 'src/components/Loading';
import FrameCreateDetector from 'src/components/FrameCreateDetector';
import moment from 'moment';

import SweetAlert from 'react-bootstrap-sweetalert';

import { ToastContainer, toast } from 'react-toastify';


import { MsalContext } from "@azure/msal-react";

class DetectorsList extends Component {
  static contextType = MsalContext;
  constructor(props) {
    super(props)
    this.state = {
      openDialogEdit: false,
      idToEdit: "",
      textFilter: "",
      dataDetectorsCopy: [],
      dataDetectors: [],
      showCreate: false,
      update: false,
      showDelete: false,
      showMessageNotCreateUser: false
    }
  }

  async componentDidMount() {
    this.callList();
    //this.getUserEmail();

    this.props.actionCompaniesLista({ active: true });

  }

  componentDidUpdate(preprops) {
    if (this.props.apiRespuesta) {
      this.props.actionLimpiarEstado()
    }
    if (this.props.apiRespuesta) {
      switch (this.props.estado) {
        case "LISTADO":
          this.setState({ dataDetectorsCopy: this.props.dataDetectors, dataDetectors: this.props.dataDetectors });
          break;
        case "ACTUALIZADO":
          toast.success("El detector se ha actualizado exitosamente", {
            backgroundColor: "#1ABD24",
            color: "#ffffff",
          });
          this.executeClose();
          break;
        case "CREADO":
          toast.success("El detector se ha creado exitosamente", {
            backgroundColor: "#1ABD24",
            color: "#ffffff",
          });
          this.executeClose();
          break;

        case "HABILITADO_DESHABILITADO":
          toast.success("Detector eliminado exitosamente", {
            backgroundColor: "#1ABD24",
            color: "#ffffff",
          });
          this.executeClose();
          break;
        case "ERROR":
          toast.error(this.props.error, {
            backgroundColor: "#E80D0D",
            color: "#ffffff",
          });
          //this.callList();
          break;
        default:
          break;
      }
      this.props.actionLimpiarEstado()
    }

    if (this.props.apiRespuestaDataUser) {
      switch (this.props.estadoDataUser) {
        case "DETALLE":
          localStorage.setItem("setUserDataProp", JSON.stringify({
            ...this.props.dataUserLog,
            user: this.props.dataUserLog.name,
            profile: 1
          }))
          // document.addEventListener("visibilitychange", () => {
          //   if (document.visibilityState === 'visible') {
          //     this.getNotificationById()
          //   }
          // });
          break;
        case "ERROR":
          if (this.props.error === "El usuario no existe.") {
            window.location.href = '/401';
          }
          break;
        default:
          break;
      }
    }
  }

  getUserEmail = async () => {
    const msalAccounts = this.context.accounts;
    const getTokenLocalStorage = await localStorage.getItem('fcmToken');
    this.props.actionUsuarioLogin({
      email: msalAccounts[0].username,
      //Token: getTokenLocalStorage
    });

  }

  openEdit = (id) => {
    this.setState({
      showCreate: true,
      idToEdit: id,
      update: true
    });
    this.props.actionDetectorsDetalle({ id: id });

  }

  // closeDialog = () => {
  //   this.setState({
  //     openDialogEdit: false
  //   });
  // }

  executeClose = async () => {
    this.setState({
      showCreate: false,
      idToEdit: "",
      textFilter: "",
      update: false
    }, () => {
      this.callList();
    });
  }

  handleChange = async e => {
    const textData = e.toUpperCase();
    const newData = await this.state.dataDetectors.filter(item => {
      return item.name.toUpperCase().indexOf(textData) > -1 
      || item.ip_address.toString().toUpperCase().indexOf(textData) > -1 
      || item.description.toUpperCase().indexOf(textData) > -1;
    });
    this.setState({ dataDetectorsCopy: newData, textFilter: e });
  };

  // exportToExcel = async () => {
  //   const filterData = this.state.dataDetectors.map((item) =>
  //   ({
  //     item.name, email, profile, department
  //   }
  //   ));
  //   const filterData = this.state.dataDetectors.map(item => {
  //     return {
  //       //name: item.name,
  //       email: item.email,
  //       //profile: item.profile,
  //       //department: item.department.slice(0, -1)
  //     }
  //   })




    //   const Heading = [["Correo"]];
    //   const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    //   const ws = XLSX.utils.json_to_sheet(filterData, { origin: 'A2', skipHeader: true });
    //   XLSX.utils.sheet_add_aoa(ws, Heading);
    //   const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    //   const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //   const data = new Blob([excelBuffer], { type: fileType });
    //   FileSaver.saveAs(data, "Detectores.xlsx");
    // }

    handleEnableDisable = async (data) => {
      await this.props.actionDetectorsHabilitarDeshabilitar(data)
    }

    showCreate = () => {
      this.setState({
        showCreate: !this.state.showCreate,
        update: false,
      });
    }

    deleteItem = () => {
      this.setState({
        showDelete: true
      })
    }

    confirmDelete = async () => {
      //console.log("id to delete: ", this.state.idToEdit);
      await this.props.actionDetectorsHabilitarDeshabilitar({ id: this.state.idToEdit })
      this.setState({
        showDelete: false
      })
    }

    callList = async () => {
      //console.log("enviar: ", this.props.dataUserLog)
      const user = await localStorage.getItem('setUserDataProp')
      const userParse = JSON.parse(user)
      //console.log("usuario:", userParse);
      if (user) {
        this.props.actionDetectorsLista();
      }

      // this.props.actionDetectorsLista({
      //   active: true,
      //   client: this.props.dataUserLog.client_id,
      //   is_admin: this.props.dataUserLog.is_admin
      // });
    }
    render() {
      return (
        <>
          <Helmet>
            <title>Detectores</title>
          </Helmet>
          <Box
            sx={{
              backgroundColor: 'background.default',
              minHeight: '100%',
              py: 3
            }}
          >
            {this.state.showCreate ?

              <Container maxWidth={false}>
                <FrameCreateDetector
                  {...this.props}
                  dataDetail={this.props.dataDetectorDetail}
                  CloseDialog={() => this.showCreate()}
                  Update={this.state.update}
                  DeleteItem={() => this.deleteItem()}
                />
              </Container>


              :
              <Container maxWidth={false}>
                <DetectorListToolbar {...this.props}
                  filter={this.state.textFilter}
                  actionShowFrameCreate={() => { this.showCreate()
                  }
                  }
                  exportToExcel={() => this.exportToExcel()}
                  handleChange={(e) => this.handleChange(e)} />
                <Box sx={{ pt: 3 }}>
                  {this.props.dataDetectors ?
                    <DetectorListResults
                      busy={this.props.busy}
                      estado={this.props.estado}
                      OpenEdit={(id) => this.openEdit(id)}
                      onEnableDisable={this.handleEnableDisable}
                      Customers={this.state.dataDetectorsCopy
                        ? this.state.dataDetectorsCopy.map(item => {
                          return {
                            ...item,
                            insertDate: moment(item.created_at).format("DD/MM/YYYY HH:mm"),
                            updatedDate: moment(item.updated_at).format("DD/MM/YYYY HH:mm"),
                            id: item.public_key,
                            isActive: item.is_active ? "Si" : "No",
                            isOnline: item.is_online ? "Si" : "No",
                            vpnActive: item.vpn_active ? "Si" : "No",
                          }
                        })
                        : []} />
                    : null} 
                </Box>
              </Container>
            }

          </Box>
          <SweetAlert
            danger
            show={this.state.showDelete}
            showCancel
            confirmBtnText="Aceptar"
            confirmBtnBsStyle="light"
            cancelBtnText="Cancelar"
            cancelBtnBsStyle="light"
            cancelBtnStyle={{ fontSize: 14 }}
            style={{ color: "#000", fontSize: 14 }}
            confirmBtnStyle={{ fontSize: 14 }}

            onConfirm={() => this.confirmDelete()}
            onCancel={() => this.setState({ showDelete: false })}
          >
            <span>{"¿Está seguro de eliminar este Detector?"}</span>
          </SweetAlert>



          <SweetAlert
            danger
            show={this.state.showMessageNotCreateUser}
            confirmBtnText="Aceptar"
            confirmBtnBsStyle="light"
            // cancelBtnText="Cancelar"
            // cancelBtnBsStyle="light"
            // cancelBtnStyle={{ fontSize: 14 }}
            style={{ color: "#000", fontSize: 14 }}
            confirmBtnStyle={{ fontSize: 14 }}
            onConfirm={() => this.setState({ showMessageNotCreateUser: false })}
          >
            <span>{"Se ha superado el límite de Detectores de esta empresa"}</span>
          </SweetAlert>

          <Loading busy={this.props.busy} />
          <ToastContainer position={"top-right"} delay={5000} />
        </>
      )
    }
  }

  const mapStateToProps = ({ detectors, user, detectorDetail }) => ({
    dataDetectors: detectors.items,
    estado: detectors.estado,
    apiRespuesta: detectors.apiRespuesta,

    busy: detectors.waiting || user.waiting || detectorDetail.waiting,
    error: detectors.error || user.error || detectorDetail.error,

    dataDetectorDetail: detectorDetail.items,

    dataUserLog: user.items,
    estadoDataUser: user.estado,
    apiRespuestaDataUser: user.apiRespuesta,

  });

  const mapDispatchToProps = dispatch => bindActionCreators({
    actionCompaniesLista,
    actionUsuarioLogin,

    actionDetectorsLista,
    actionDetectorsDetalle,
    actionDetectorsActualizar,
    actionDetectorsCrear,
    actionDetectorsHabilitarDeshabilitar,

    actionLimpiarEstado,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DetectorsList);
