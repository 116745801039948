/* eslint-disable */

import React from "react";
import { useDropzone } from "react-dropzone";
import Button from '@material-ui/core/Button';

function DropzoneAreaComponent({ onDrop, accept, open }) {
    const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
        useDropzone({
            accept,
            onDrop,
        });

    const files = acceptedFiles.map((file) => (
        <li key={file.path}>
            <br/>
            {file.path} - {(file.size * 0.000000000931).toFixed(2)} Gb
        </li>
    ));

    return (
        <div>
            <div {...getRootProps({ className: "dropzone" })}>
                <input className="input-zone" {...getInputProps()} />
                <div className="text-center">
                    {isDragActive ? (
                        <p className="dropzone-content">
                            Arrastra el video
                        </p>
                    ) : (
                        <p className="dropzone-content">
                            Arrastra el video o haz click para cargar el archivo.
                        </p>
                    )}
                    <Button
                        variant='contained'
                        color="primary"
                        onClick={open}
                        style={{ outline: "none" }}>
                        Seleccionar Video
                    </Button>
                </div>
            </div>
            <aside>
                <ul>{files}</ul>
            </aside>
        </div>
    );
}

export default DropzoneAreaComponent;