/* eslint-disable */
import { store } from '../store';
import {
  faArrowDown,
  faArrowUp,
  faArrowRight,
  faArrowLeft,
  faArrowTurnDown,
  faArrowTurnUp,
  faArrowsUpDown,
  faArrowsLeftRight,
  faArrowRotateLeft,
  faArrowRotateRight
} from '@fortawesome/free-solid-svg-icons';

export const userAuthToken = async () => {
  const userTkn = await localStorage.getItem('userTkn');
  return userTkn;
};

const SubscriptionKey = '';

export const DEFAULT_HEADERS = {
  'Ocp-Apim-Subscription-Key': SubscriptionKey,
  'Content-Type': 'application/json'
};

export const DEFAULT_HEADERS_UPLOAD = {
  'Content-Length': '<calculated when request is sent>',
  Host: '<calculated when request is sent>',
  Accept: '*/*',
  'Accept-Encoding': 'gzip, deflate, br',
  Connection: 'keep-alive'
};

export const DEFAULT_HEADERS_WITHOUT_CONTENT = {
  'Ocp-Apim-Subscription-Key': SubscriptionKey
};

export const apiPeticionExitosaExcel = async (response) => {
  if (response.ok) return response;
  else if (response.status === 400) throw await response.json();
  else throw response;
};

export const apiPeticionExitosa = async (response) => {
  if (response.ok && response.status != 204) return response.json();
  else if (response.status === 204) return response;
  else if (response.status === 400) throw await response.json();
  else throw response;
};

export const apiPeticionExitosaText = async (response) => {
  if (response.status === 200) return response.data;
  else if (response.status === 400) throw await response.json();
  else throw response;
};

export const apiErrorHandler = (response) => {
  if (response.detail) throw response;
  else
    throw {
      codigo: response.status,
      detail: `Error al solicitar el servicio API.`
    };
};

export const adminList = [
  '97b490e3-14b0-49a9-a197-81e2e6d2f81b', //Administrador
  'c74e70b8-6a93-4357-9d03-c27fc8cc33fa' //Operario
];

export const directionsList = [
  { value: 'N', label: 'Norte' },
  { value: 'S', label: 'Sur' },
  { value: 'E', label: 'Este' },
  { value: 'O', label: 'Oeste' },
  { value: 'NO', label: 'Noroeste' },
  { value: 'SO', label: 'Suroeste' },
  { value: 'NE', label: 'Noreste' },
  { value: 'SE', label: 'Sureste' }
];

export const entityList = [
  { value: 'V', label: 'Vehículo' },
  { value: 'NV', label: 'No es Vehículo' }
];

export const colorsList = [
  { value: '#FF0000', label: '#FF0000' },
  { value: '#00FF00', label: '#00FF00' },
  { value: '#0000FF', label: '#0000FF' },
  { value: '#800080', label: '#800080' },
  { value: '#2196F3', label: '#2196F3' },
  { value: '#EEFF41', label: '#EEFF41' },
  { value: '#F57F17', label: '#F57F17' },
  { value: '#BF360C', label: '#BF360C' },
  { value: '#17A589', label: '#17A589' },
  { value: '#E65100', label: '#E65100' }
];

export const maneuversList = [
  { value: '1', label: '1', icon: faArrowDown, rotate: 0 },
  { value: '2', label: '2', icon: faArrowUp, rotate: 0 },
  { value: '3', label: '3', icon: faArrowRight, rotate: 0 },
  { value: '4', label: '4', icon: faArrowLeft, rotate: 0 },
  { value: '5', label: '5', icon: faArrowTurnUp, rotate: 90 },
  { value: '6', label: '6', icon: faArrowTurnUp, rotate: 270 },
  { value: '7', label: '7', icon: faArrowTurnUp, rotate: 360 },
  { value: '8', label: '8', icon: faArrowTurnUp, rotate: 180 },

  { value: '21', label: '21', icon: faArrowsLeftRight, rotate: 0 },
  { value: '22', label: '22', icon: faArrowsLeftRight, rotate: 0 },
  { value: '23', label: '23', icon: faArrowsUpDown, rotate: 0 },
  { value: '24', label: '24', icon: faArrowsUpDown, rotate: 0 },

  { value: '31', label: '31', icon: faArrowsLeftRight, rotate: 0 },
  { value: '32', label: '32', icon: faArrowsLeftRight, rotate: 0 },
  { value: '33', label: '33', icon: faArrowsUpDown, rotate: 0 },
  { value: '34', label: '34', icon: faArrowsUpDown, rotate: 0 },

  { value: '91', label: '91', icon: faArrowTurnDown, rotate: 90 },
  { value: '92', label: '92', icon: faArrowTurnDown, rotate: 270 },
  { value: '93', label: '93', icon: faArrowTurnDown, rotate: 360 },
  { value: '94', label: '94', icon: faArrowTurnDown, rotate: 180 },

  { value: '101', label: '101', icon: faArrowRotateLeft, rotate: 90 },
  { value: '102', label: '102', icon: faArrowRotateLeft, rotate: 270 },
  { value: '103', label: '103', icon: faArrowRotateLeft, rotate: 0 },
  { value: '104', label: '104', icon: faArrowRotateLeft, rotate: 180 }
];
export const acceptedFilesValidation = ['video/mp4', 'video/mp4'];

// compressed 'application/x-rar-compressed', 'application/x-7z-compressed', 'application/zip',
