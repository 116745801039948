/* eslint-disable */
import React, { useContext, useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Helmet } from 'react-helmet';
import { MsalContext } from '@azure/msal-react';
import Loading from 'src/components/Loading';

import { actionAnalyticSettingsList } from 'src/actions/api/analyticSettings';
import { actionUsuarioLogin } from 'src/actions/api/user';
import { actionLimpiarEstado } from 'src/actions';
import { POWERBI_URL } from 'src/api/endpoints';

const Dashboard = (props) => {
  // Obtiene la cuenta actual de Microsoft Authentication Library (MSAL)
  const { accounts } = useContext(MsalContext);

  // Estados locales
  const [urlIframe, setUrlIframe] = useState(null); // URL del iframe de Power BI
  const [powerBIFetched, setPowerBIFetched] = useState(false); // Indicador de si se ha obtenido la URL de Power BI

  // Función asincrónica para obtener la URL de Power BI
  const fetchPowerBIURL = async () => {
    if (!powerBIFetched) {
      try {
        const publicKeyUser = props.dataUser.public_key;
        const response = await fetch(`${POWERBI_URL}${publicKeyUser}`);
        if (response.ok) {
          const data = await response.json();
          setUrlIframe(data);
          setPowerBIFetched(true);
        } else {
          console.error('Error al obtener la URL de POWERBI');
        }
      } catch (error) {
        console.error('Error al obtener la URL de POWERBI', error);
      }
    }
  };

  // Función asincrónica para obtener el correo electrónico del usuario
  const getUserEmail = async () => {
    const getTokenLocalStorage = localStorage.getItem('fcmToken');
    props.actionUsuarioLogin({
      email: accounts[0].username
    });
  };

  // Efecto que se ejecuta al montar el componente
  useEffect(() => {
    getUserEmail();
    props.actionAnalyticSettingsList({ estado: 'All' });
  }, []);

  // Efecto que se ejecuta cuando props.apiRespuesta o props.estado cambian
  useEffect(() => {
    if (props.apiRespuesta) {
      switch (props.estado) {
        case 'DETALLE':
          localStorage.setItem('userTkn', props.dataUser.token);
          break;
        default:
          break;
      }
    }
    fetchPowerBIURL(); // Obtiene la URL de Power BI
  }, [props.apiRespuesta, props.estado]);

  return (
    <>
      <Helmet>
        <title>Dashboard | D33P</title>
      </Helmet>
      <div style={{ width: '100%', height: '100%', flex: 1 }}>
        <iframe
          width="100%"
          height="90%"
          style={{ marginTop: 10 }}
          src={urlIframe}
          allowFullScreen={true}
        ></iframe>
      </div>
      <Loading busy={props.busy} />
    </>
  );
};

// Mapea el estado de Redux a las propiedades del componente
const mapStateToProps = ({ user, analyticSettings }) => ({
  dataAnalyticSettings: analyticSettings.items,
  estadoAnalyticSettings: analyticSettings.estado,
  apiRespuestaAnalyticSettings: analyticSettings.apiRespuesta,
  busyAnalyticSettings: analyticSettings.waiting,
  errorAnalyticSettings: analyticSettings.error,
  dataUser: user.items,
  estado: user.estado,
  apiRespuesta: user.apiRespuesta,
  busy: user.waiting,
  error: user.error
});

// Mapea las acciones de Redux a las propiedades del componente
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      actionAnalyticSettingsList,
      actionUsuarioLogin,
      actionLimpiarEstado
    },
    dispatch
  );

// Conecta el componente a Redux
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
