/* eslint-disable */
export const GATES_INICIA = 'GATES_INICIA';
export const GATES_LISTA = 'GATES_LISTA';
export const GATES_LISTADO = 'GATES_LISTADO';
export const GATES_ERROR = 'GATES_ERROR';

export const GATES_ACTUALIZAR = "GATES_ACTUALIZAR";
export const GATES_ACTUALIZADA = "GATES_ACTUALIZADA";
export const GATES_CREAR = "GATES_CREAR";
export const GATES_CREADA =  "GATES_CREADA";

export const GATES_CLEAR = "GATES_CLEAR";
export const GATES_CLEARED =  "GATES_CLEARED";

export const GATES_DETALLE_INICIA = "GATES_DETALLE_INICIA";
export const GATES_DETALLE = "GATES_DETALLE";
export const GATES_DETALLADO = "GATES_DETALLADO";
export const GATES_DETALLE_ERROR = "GATES_DETALLE_ERROR";

export const GATES_HABILITAR_DESHABILITAR = "GATES_HABILITAR_DESHABILITAR";
export const GATES_HABILITADA_DESHABILITADA = "GATES_HABILITADA_DESHABILITADA";

//Gates video

export const GATES_VIDEO_INICIA = "GATES_VIDEO_INICIA"
export const GATES_VIDEO_LISTA = "GATES_VIDEO_LISTA"    
export const GATES_VIDEO_LISTADO = "GATES_VIDEO_LISTADO" 

export const GATES_VIDEO_CREAR = "GATES_VIDEO_CREAR"
export const GATES_VIDEO_CREADA = "GATES_VIDEO_CREADA"

export const GATES_VIDEO_CLEAR = "GATES_VIDEO_CLEAR"
export const GATES_VIDEO_CLEARED = "GATES_VIDEO_CLEARED"

export const GATES_VIDEO_HABILITAR_DESHABILITAR = "GATES_VIDEO_HABILITAR_DESHABILITAR"
export const GATES_VIDEO_HABILITADA_DESHABILITADA = "GATES_VIDEO_HABILITADA_DESHABILITADA"
export const GATES_VIDEO_ERROR = "GATES_VIDEO_ERROR"