/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { MsalContext } from '@azure/msal-react';

import { actionUsuarioLogin } from 'src/actions/api/user';
import {
  actionCompaniesLista,
  actionCompaniesActualizar,
  actionCompaniesCrear,
  actionCompaniesHabilitarDeshabilitar
} from 'src/actions/api/companies';

import { actionDetectorsLista } from 'src/actions/api/detectors';

import { actionPermissionsLista } from 'src/actions/api/permissions';

import { actionCompaniesDetalle } from 'src/actions/api/companiesDetail';

import {
  actionCamerasLista,
  actionCamerasActualizar,
  actionCamerasCrear,
  actionCamerasHabilitarDeshabilitar,
  actionCamerasDeshabilitar,
  actionCamerasSetCapacityPosition,
  actionCamerasClear,
  actionCamerasDelete,

  //--> Connect camera
  actionCamerasCheckConnectionObtener,
  actionCamerasPtzObtener,
  actionCamerasImageObtener
} from 'src/actions/api/cameras';

import { actionCamerasDetalle } from 'src/actions/api/camerasDetail';

//Create roadways

import {
  actionRoadWaysLista,
  actionRoadWaysActualizar,
  actionRoadWaysCrear,
  actionRoadWaysScaleFactorCrear,
  actionRoadWaysHabilitarDeshabilitar,
  actionRoadWaysClear
} from 'src/actions/api/roadWays';

import { actionRoadWaysDetalle } from 'src/actions/api/roadWaysDetail';

import {
  actionGatesLista,
  actionGatesActualizar,
  actionGatesCrear,
  actionGatesHabilitarDeshabilitar
} from 'src/actions/api/gates';

import { actionScaleFactorCrear } from 'src/actions/api/scaleFactor';

import { actionLimpiarEstado } from 'src/actions';

import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import CameraListResults from 'src/components/camera/CameraListResults';
import CameraListToolbar from 'src/components/camera/CameraListToolbar';
import CameraListMosaico from 'src/components/camera/CameraListMosaico';
import Loading from 'src/components/Loading';
import { ToastContainer, toast } from 'react-toastify';
import FrameCreateCamera from 'src/components/FrameCreateCamera';
import SweetAlert from 'react-bootstrap-sweetalert';

class CameraList extends Component {
  static contextType = MsalContext;
  constructor(props) {
    super(props);
    this.state = {
      showDeleteCamera: false,
      idToDelete: null,
      openDialogEdit: false,
      idToEdit: '',
      textFilter: '',
      dataCamerasCopy: [],
      dataCameras: [],
      showCreate: false,
      update: false,
      showDelete: false,
      currentList: 1,
      objToCreateCamera: {},
      objCam: {},
      showMessageNotCreate: false,
      companieId: '',
      companieName: ''
    };
    this.setContent = this.setContent.bind(this);
  }

  componentDidMount() {
    this.getUserEmail();
    this.props.actionCamerasClear();
    this.props.actionCompaniesLista({ active: true });
    this.props.actionPermissionsLista();
    this.getUserEmail();
    this.props.actionDetectorsLista();
    setTimeout(() => {
      // this.props.actionCamerasLista({ public_key: this.props.dataUserLog.client_id });
      // this.setState({ dataCamerasCopy: this.props.dataCameras });
      this.callList();
    }, 1000);
  }

  componentDidUpdate(preprops) {
    if (this.props.apiRespuesta) {
      switch (this.props.estado) {
        case 'LISTADO':
          this.setState({
            dataCamerasCopy: this.props.dataCameras,
            dataCameras: this.props.dataCameras
          });
          break;
        case 'ACTUALIZADO':
          toast.success('La Cámara se ha actualizado exitosamente', {
            backgroundColor: '#1ABD24',
            color: '#ffffff'
          });
          //this.executeClose();
          //this.callList();
          break;
        case 'CREADO':
          toast.success('La Cámara se ha creado exitosamente', {
            backgroundColor: '#1ABD24',
            color: '#ffffff'
          });
          this.getDetailCamera();
          //this.executeClose();
          break;
        case 'ELIMINADO':
          toast.success('Cámara eliminada exitosamente', {
            backgroundColor: '#1ABD24',
            color: '#ffffff'
          });
          this.callList();
          break;
        case 'HABILITADO':
          toast.success('Cámara habilitada exitosamente', {
            backgroundColor: '#1ABD24',
            color: '#ffffff'
          });
          this.callList();
          break;
        case 'DESHABILITADO':
          toast.success('Cámara deshabilitada exitosamente', {
            backgroundColor: '#1ABD24',
            color: '#ffffff'
          });
          this.callList();
          break;

        case 'SETED':
          toast.success('Cámara movida a pos. aforo exitosamente', {
            backgroundColor: '#1ABD24',
            color: '#ffffff'
          });
          this.callList();
          break;

        case 'CAMERA_CHECK_CONNECTION':
          this.CameraCheckConnection();
          break;

        case 'CAMERA_PTZ':
          this.CameraPtz();
          break;

        case 'CAMERA_IMAGE':
          this.CameraImage();
          break;

        case 'ERROR':
          toast.error(this.props.error, {
            backgroundColor: '#E80D0D',
            color: '#ffffff'
          });
          break;
        default:
          break;
      }
      this.props.actionLimpiarEstado();
    }

    //Añadir calzada -- add roadways
    if (this.props.apiRespuestaRoadWays) {
      switch (this.props.estadoRoadWays) {
        case 'ACTUALIZADO':
          toast.success('La Calzada se ha actualizado exitosamente', {
            backgroundColor: '#1ABD24',
            color: '#ffffff'
          });
          this.getRoadWaysList();
          //this.executeClose();
          break;
        case 'CREADO':
          toast.success('La Calzada se ha creado exitosamente', {
            backgroundColor: '#1ABD24',
            color: '#ffffff'
          });
          this.getRoadWaysList();
          this.getDetailRoadWay();
          //this.executeClose();
          break;
        case 'SCALEFACTOR_CREADO':
          toast.success('El factor de forma se ha añadido exitosamente', {
            backgroundColor: '#1ABD24',
            color: '#ffffff'
          });
          this.getRoadWaysList();
          //this.getDetailRoadWay();
          //this.executeClose();
          break;

        case 'HABILITADO_DESHABILITADO':
          toast.success('Calzada eliminada exitosamente', {
            backgroundColor: '#1ABD24',
            color: '#ffffff'
          });
          this.getRoadWaysList();
          //this.getDetailRoadWay();
          this.setState({
            idToEdit: ''
          });
          break;
        case 'ERROR':
          //console.log("Front error: ", this.props.error);
          toast.error(this.props.error, {
            backgroundColor: '#E80D0D',
            color: '#ffffff'
          });
          break;
        default:
          break;
      }
      this.props.actionLimpiarEstado();
    }

    //Añadir Compuertas -- add gates
    if (this.props.apiRespuestaGates) {
      switch (this.props.estadoGates) {
        case 'ACTUALIZADO':
          toast.success('La Compuerta se ha actualizado exitosamente', {
            backgroundColor: '#1ABD24',
            color: '#ffffff'
          });
          //this.executeClose();
          break;
        case 'CREADO':
          toast.success('La Compuerta se ha añadido exitosamente', {
            backgroundColor: '#1ABD24',
            color: '#ffffff'
          });
          this.getRoadWaysList();
          //this.executeClose();
          break;
        case 'HABILITADO_DESHABILITADO':
          toast.success('Compuerta eliminada exitosamente', {
            backgroundColor: '#1ABD24',
            color: '#ffffff'
          });
          this.getRoadWaysList();
          this.setState({
            idToEdit: '',
            showCreate: false,
            update: false
          });
          break;
        case 'ERROR':
          //console.log("Front error: ", this.props.error);
          toast.error(this.props.error, {
            backgroundColor: '#E80D0D',
            color: '#ffffff'
          });
          break;
        default:
          break;
      }
      this.props.actionLimpiarEstado();
    }

    //Añadir factor de forma -- add FormFactor
    if (this.props.apiRespuestaScaleFactor) {
      switch (this.props.estadoScaleFactor) {
        case 'CREADO':
          toast.success('el Factor de Forma se ha añadido exitosamente', {
            backgroundColor: '#1ABD24',
            color: '#ffffff'
          });
          this.updateCamera();
          //this.executeClose();
          break;
        case 'ERROR':
          //console.log("Front error: ", this.props.error);
          toast.error(this.props.error, {
            backgroundColor: '#E80D0D',
            color: '#ffffff'
          });
          break;
        default:
          break;
      }
      this.props.actionLimpiarEstado();
    }
  }

  getUserEmail = async () => {
    const msalAccounts = this.context.accounts;
    //const getTokenLocalStorage = await localStorage.getItem('fcmToken');
    this.props.actionUsuarioLogin({
      email: msalAccounts[0].username
      //Token: getTokenLocalStorage
    });
  };

  openEdit = (id) => {
    this.props.actionCamerasDetalle({ id: id });
    setTimeout(() => {
      this.setState({
        showCreate: true,
        idToEdit: id,
        update: true
      });
    }, 1000);
  };

  closeDialog = (companie) => {
    this.setState({
      showCreate: false,
      idToEdit: '',
      update: false
    });
    //this.props.actionLimpiarEstado()
    this.props.actionCamerasClear();
    this.props.actionCamerasLista({
      public_key: this.props.dataUserLog.client_id
    });
    this.props.actionRoadWaysClear();
    this.callList();
    //this.getByCompanie(companie)
  };

  executeClose = async () => {
    this.setState(
      {
        showCreate: false,
        idToEdit: '',
        textFilter: '',
        update: false
      },
      () => {
        this.props.actionCompaniesLista({ active: true });
      }
    );
  };

  handleChange = async (e) => {
    const textData = e.toString().toUpperCase();
    //console.log("textData: ", textData);
    //console.log("E: ", e)
    const newData = await this.state.dataCameras.filter((item) => {
      return (
        item.name.toUpperCase().indexOf(textData) > -1 ||
        item.initials.toUpperCase().indexOf(textData) > -1 ||
        item.route.toUpperCase().indexOf(textData) > -1 ||
        item.kilometer.toString().toUpperCase().indexOf(textData) > -1
      );
    });
    this.setState({ dataCamerasCopy: newData, textFilter: e });
  };

  exportToExcel = async () => {
    const filterData = this.state.dataCameras.map((item) => {
      return {
        nit: item.nit,
        name: item.name,
        email: item.email,
        phone: item.phone,
        limit_user: item.limit_user,
        detectors: item.detectors,
        permisos: item.permissions.map((pItem) => {
          ////console.log("pItem", pItem.permission.name);
          return pItem.permission.name;
        })
      };
    });

    const Heading = [
      [
        'NIT',
        'Nombre',
        'Correo electrónico',
        'Teléfono',
        'Número de usuarios',
        'Número detectores',
        'Permisos'
      ]
    ];
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const ws = XLSX.utils.json_to_sheet(filterData, {
      origin: 'A2',
      skipHeader: true
    });
    XLSX.utils.sheet_add_aoa(ws, Heading);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, 'Empresa.xlsx');
  };

  // handleEnableDisable = async (data) => {
  //   await this.props.actionCompaniesHabilitarDeshabilitar(data)
  // }

  showCreate = (companie) => {
    this.setState(
      {
        showCreate: !this.state.showCreate,
        update: false
      },
      () => {
        this.callList();
      }
    );
  };

  deleteItem = () => {
    this.setState({
      showDelete: true
    });
  };

  confirmDelete = async () => {
    //console.log("id to delete: ", this.state.idToEdit);
    await this.props.actionCompaniesHabilitarDeshabilitar({
      id: this.state.idToEdit
    });
    this.setState({
      showDelete: false
    });
  };

  setContent = (value) => {
    this.setState({ currentList: value });
  };

  createCameraByApi = async (objToSend) => {
    const { objStep1, objStep2, objStep3 } = await objToSend;
    this.setState(
      {
        objToCreateCamera: objToSend
      },
      () => {
        if (this.state.update || this.props.dataCameraCreated.public_key) {
          this.getDetailCamera();
        } else {
          const objFields = {
            created_user: objStep1.created_user,
            name: objStep1.name,
            initials: '',
            route: objStep1.route,
            street: objStep1.street,
            kilometer: objStep1.kilometer,
            description: objStep2.description,
            username: objStep2.objCam.username,
            password: objStep2.objCam.password,
            ip_address: objStep2.objCam.ip_address,
            is_active: true,
            is_online: true,
            is_preset: true,
            client_id: objStep1.client_id,
            lat: objStep1.lat,
            lng: objStep1.lng,
            pan: objStep2.pan,
            tilt: objStep2.tilt,
            zoom: objStep2.zoom,
            path: objStep2.path
          };
          this.props.actionCamerasCrear({
            ...objFields,
            ...(objStep1.detector_id && { detector_id: objStep1.detector_id })
          });
        }
      }
    );
  };

  getDetailCamera = async () => {
    const { objToCreateCamera } = this.state;
    //Create RoadWays
    //camera_id: this.props.dataCameraCreated.public_key,
    this.props.actionRoadWaysCrear({
      camera_id: this.props.dataCameraCreated.public_key,
      name: objToCreateCamera.objStep3.name,
      description: '',
      direction: objToCreateCamera.objStep3.direction
    });
    //this.props.actionCamerasDetalle({id: this.props.dataCameraCreated.public_key})
  };

  getDetailRoadWay = async () => {
    const { objToCreateCamera } = this.state;

    if (objToCreateCamera.objStep3.gatesList.length > 0) {
      objToCreateCamera.objStep3.gatesList.forEach((element) => {
        this.props.actionGatesCrear({
          roadway_id: this.props.dataRoadWaysCreated.public_key,
          name: element.name,
          x: element.x,
          y: element.y,
          x1: element.x1,
          y1: element.y1,
          rotation: element.rotation,
          width: element.width,
          height: element.height,
          fill: element.fill
        });
      });
    }
    //actionRoadWaysScaleFactorCrear
    if (Object.keys(objToCreateCamera.objStep3.formFactorObj).length !== 0) {
      if (objToCreateCamera.objStep3.formFactorObj.formFactor.length > 0) {
        var objToCreateScaleFactor = {
          l_m: objToCreateCamera.objStep3.formFactorObj.l_m,
          w_m: objToCreateCamera.objStep3.formFactorObj.w_m,
          id: this.props.dataRoadWaysCreated.public_key,
          lines: {
            line1: {
              x1: objToCreateCamera.objStep3.formFactorObj.formFactor[0].x,

              y1: objToCreateCamera.objStep3.formFactorObj.formFactor[0].y,

              x2: objToCreateCamera.objStep3.formFactorObj.formFactor[0].x1,

              y2: objToCreateCamera.objStep3.formFactorObj.formFactor[0].y1
            },
            line2: {
              x3: objToCreateCamera.objStep3.formFactorObj.formFactor[1].x,

              y3: objToCreateCamera.objStep3.formFactorObj.formFactor[1].y,

              x4: objToCreateCamera.objStep3.formFactorObj.formFactor[1].x1,

              y4: objToCreateCamera.objStep3.formFactorObj.formFactor[1].y1
            }
          }
        };
        this.props.actionRoadWaysScaleFactorCrear(objToCreateScaleFactor);
      }
    }

    this.getRoadWaysList();
  };

  getRoadWaysList = () => {
    this.props.actionRoadWaysClear();
    this.props.actionRoadWaysLista({
      id: this.props.dataCameraCreated.public_key
    });
  };

  deleteById = (id) => {
    this.props.actionRoadWaysHabilitarDeshabilitar({ id: id });
  };

  updateCamera = () => {
    //console.log("Update Camera");
    //console.log(this.props.dataScaleFactorCreated)
  };

  checkConnectionCamera = (objCam) => {
    //console.log("check Connection", objCam);
    this.setState({
      objCam: objCam
    });
    this.props.actionCamerasCheckConnectionObtener(objCam);
  };

  CameraCheckConnection = async () => {
    //console.log("CameraCheckConnection: ", this.props.dataCameraCheckConnection)

    if (
      this.props.dataCameraCheckConnection.message.includes('Camera online!')
    ) {
      this.props.actionCamerasPtzObtener(this.state.objCam);
    }
  };

  CameraPtz = async () => {
    //console.log("CameraPtz", this.props.dataCameraPtz)
    this.props.actionCamerasImageObtener(this.state.objCam);
  };

  showDeleteCamera = (id) => {
    this.setState({ showDeleteCamera: true, idToDelete: id });
  };

  CameraImage = async () => {
    console.log('CameraImage', this.props.dataCameraImage);
  };

  editCamera = (public_key) => {};

  getByCompanie = (companie) => {
    if (this.props.dataUserLog.is_admin === true) {
      console.log('entre', companie);
      this.setState(
        {
          companieId: companie.value ? companie.value : '',
          companieName: companie.label ? companie.label : ''
        },
        () => {
          if (this.state.companieId !== '') {
            this.props.actionCamerasLista({
              public_key: this.state.companieId
            });
            this.setState({ dataCamerasCopy: this.props.dataCameras });
            //this.props.actionVideosLista({ id: this.state.companieId });
          } else {
            console.log('Get all Cameras');
            this.props.actionCamerasLista({});
            this.setState({ dataCamerasCopy: this.props.dataCameras });
            //this.props.actionVideosLista({ id: ""});
            //this.props.actionVideosLista({ id: this.state.companieId });
          }

          this.props.actionCompaniesLista({ active: true });
          this.props.actionPermissionsLista();
          this.props.actionDetectorsLista();
        }
      );
    }
  };

  callList = async () => {
    this.props.actionRoadWaysClear();
    //this.props.actionCamerasClear();
    this.props.actionCompaniesLista({ active: true });
    console.log(
      'this.props.dataUserLog.is_admin: ',
      this.props.dataUserLog.is_admin
    );

    console.log('this.state.companieId: ', this.state.companieId);

    if (this.props.dataUserLog.is_admin === true) {
      if (this.state.companieId !== '') {
        this.props.actionCamerasLista({ public_key: this.state.companieId });
        this.setState({ dataCamerasCopy: this.props.dataCameras });
        //this.props.actionVideosLista({ id: this.state.companieId });
      } else {
        console.log('Get all Cameras');
        this.props.actionCamerasLista({});
        this.setState({ dataCamerasCopy: this.props.dataCameras });
        //this.props.actionVideosLista({ id: ""});
        //this.props.actionVideosLista({ id: this.state.companieId });
      }

      this.props.actionCompaniesLista({ active: true });
      this.props.actionPermissionsLista();
      this.props.actionDetectorsLista();
    } else {
      this.props.actionCamerasLista({
        public_key: this.props.dataUserLog.client_id
      });
    }

    // this.props.actionExecutivesLista({
    //   active: true,
    //   client: this.props.dataUserLog.client_id,
    //   is_admin: this.props.dataUserLog.is_admin
    // });
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Cámaras Fijas</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          {this.state.showCreate ? (
            <Container maxWidth={false}>
              <FrameCreateCamera
                {...this.props}
                getRoadWaysList={() => this.getRoadWaysList()}
                dataDetail={
                  this.state.update
                    ? this.props.dataCamerasDetail
                    : this.props.dataCameraCreated
                }
                CloseDialog={(companie) => this.showCreate(companie)}
                DeleteItem={() => this.deleteItem()}
                Update={this.state.update}
                Finish={(companie) => this.closeDialog(companie)}
                CreateCameraByApi={(objToSend) =>
                  this.createCameraByApi(objToSend)
                }
                deleteById={(id) => this.deleteById(id)}
                ConnectCameraApiMain={(objCam) =>
                  this.checkConnectionCamera(objCam)
                }
                companieId={this.state.companieId}
                companieName={this.state.companieName}
                filterByCompanie={(companie) => this.getByCompanie(companie)}
              />
            </Container>
          ) : (
            <Container maxWidth={false}>
              <CameraListToolbar
                {...this.props}
                filter={this.state.textFilter}
                actionShowFrameCreate={() => {
                  if (
                    this.state.companieId !== '' ||
                    this.props.dataUserLog.is_admin !== true
                  ) {
                    this.showCreate();
                  } else {
                    this.setState({
                      showMessageNotCreate: true
                    });
                  }
                }}
                exportToExcel={() => this.exportToExcel()}
                handleChange={(e) => this.handleChange(e)}
                setContent={this.setContent}
                currentList={this.state.currentList}
                filterByCompanie={(companie) => this.getByCompanie(companie)}
                companieId={this.state.companieId}
                companieName={this.state.companieName}
              />
              <Box sx={{ pt: 3 }}>
                {this.props.dataCameras ? (
                  this.state.currentList == 2 ? (
                    <CameraListResults
                      {...this.props}
                      busy={this.props.busy}
                      estado={this.props.estado}
                      OpenEdit={(id) => this.openEdit(id)}
                      onEnableDisable={this.handleEnableDisable}
                      showDeleteCamera={(idCamera) =>
                        this.showDeleteCamera(idCamera)
                      }
                      Cameras={
                        this.props.busy === true
                          ? []
                          : this.state.dataCamerasCopy.length > 0
                          ? this.state.dataCamerasCopy.map((item) => {
                              return {
                                id: item.public_key,
                                name: item.name,
                                state:
                                  item.analytics_id > 0
                                    ? 'Aforando'
                                    : item.is_online
                                    ? 'No aforando'
                                    : 'Desconectada',
                                route: item.route,
                                kilometer: item.kilometer,
                                is_online: item.is_online
                              };
                            })
                          : []
                      }
                    />
                  ) : (
                    <CameraListMosaico
                      showDeleteCamera={(idCamera) =>
                        this.showDeleteCamera(idCamera)
                      }
                      OpenEdit={(id) => this.openEdit(id)}
                      Cameras={
                        this.state.dataCamerasCopy
                          ? this.state.dataCamerasCopy
                          : []
                      }
                      {...this.props}
                    />
                  )
                ) : null}
              </Box>
            </Container>
          )}
        </Box>
        <SweetAlert
          danger
          show={this.state.showDelete}
          showCancel
          confirmBtnText="Aceptar"
          confirmBtnBsStyle="light"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="light"
          cancelBtnStyle={{ fontSize: 14 }}
          style={{ color: '#000', fontSize: 14 }}
          confirmBtnStyle={{ fontSize: 14 }}
          onConfirm={() => this.confirmDelete()}
          onCancel={() => this.setState({ showDelete: false })}
        >
          <span>{'¿Está seguro de eliminar esta Empresa?'}</span>
        </SweetAlert>
        <SweetAlert
          warning
          show={this.state.showDeleteCamera}
          showCancel
          confirmBtnText="Aceptar"
          confirmBtnBsStyle="light"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="light"
          cancelBtnStyle={{ fontSize: 14 }}
          style={{ color: '#000', fontSize: 14 }}
          confirmBtnStyle={{ fontSize: 14 }}
          onConfirm={() => {
            this.props.actionCamerasDelete({ id: this.state.idToDelete });
            this.setState({ showDeleteCamera: false });
          }}
          onCancel={() => this.setState({ showDeleteCamera: false })}
        >
          <span>{'¿Está seguro de eliminar esta Camara?'}</span>
        </SweetAlert>
        <SweetAlert
          danger
          show={this.state.showMessageNotCreate}
          confirmBtnText="Aceptar"
          confirmBtnBsStyle="light"
          // cancelBtnText="Cancelar"
          // cancelBtnBsStyle="light"
          // cancelBtnStyle={{ fontSize: 14 }}
          style={{ color: '#000', fontSize: 14 }}
          confirmBtnStyle={{ fontSize: 14 }}
          onConfirm={() => this.setState({ showMessageNotCreate: false })}
        >
          <span>
            {
              'Debes seleccionar una empresa en el filtro para poder agregar una Cámara fija'
            }
          </span>
        </SweetAlert>

        <Loading busy={this.props.busy} />
        <ToastContainer position={'top-right'} delay={5000} />
      </>
    );
  }
}

const mapStateToProps = ({
  detectors,
  companies,
  companiesDetail,
  permissions,
  user,
  cameras,
  camerasDetail,
  roadWays,
  roadWaysDetail,
  gates,
  scaleFactor
}) => ({
  dataCameras: cameras.items,
  estado: cameras.estado,
  apiRespuesta: cameras.apiRespuesta,
  dataCameraCheckConnection: cameras.cameraChkConnection
    ? cameras.cameraChkConnection
    : {},
  dataCameraPtz: cameras.ptz ? cameras.ptz : {},
  dataCameraImage: cameras.image ? cameras.image : {},
  detectors: detectors,
  busy:
    companies.waiting ||
    companiesDetail.waiting ||
    permissions.waiting ||
    cameras.waiting ||
    camerasDetail.waiting ||
    roadWays.waiting ||
    roadWaysDetail.waiting ||
    gates.waiting ||
    scaleFactor.waiting,

  error:
    companies.error ||
    companiesDetail.error ||
    permissions.error ||
    cameras.error ||
    camerasDetail.error ||
    roadWays.error ||
    roadWaysDetail.error ||
    gates.error ||
    scaleFactor.error,

  dataRoadWaysCreated: roadWays.data ? roadWays.data : {},
  estadoRoadWays: roadWays.estado,
  apiRespuestaRoadWays: roadWays.apiRespuesta,

  dataGatesCreated: gates.data ? gates.data : {},
  estadoGates: gates.estado,
  apiRespuestaGates: gates.apiRespuesta,
  dataPermissionsList: permissions.items,
  dataUserLog: user.items,

  dataCameras: cameras.items,
  dataCamerasDetail: camerasDetail.items,
  dataCameraCreated: cameras.data ? cameras.data : camerasDetail.items,

  dataRoadWaysList: roadWays.items,

  dataScaleFactorCreated: scaleFactor.data ? scaleFactor.data : {},
  estadoScaleFactor: scaleFactor.estado,
  apiRespuestaScaleFactor: scaleFactor.apiRespuesta,

  dataCompanies: companies.items
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      actionCompaniesLista,
      actionPermissionsLista,

      actionLimpiarEstado,
      actionCompaniesDetalle,
      actionCompaniesActualizar,
      actionCompaniesCrear,
      actionCompaniesHabilitarDeshabilitar,

      actionCamerasLista,
      actionCamerasActualizar,
      actionCamerasCrear,
      actionCamerasDelete,
      actionCamerasHabilitarDeshabilitar,
      actionCamerasDeshabilitar,
      actionCamerasSetCapacityPosition,

      actionCamerasCheckConnectionObtener,
      actionCamerasPtzObtener,
      actionCamerasImageObtener,

      actionCamerasClear,

      actionCamerasDetalle,

      actionRoadWaysLista,
      actionRoadWaysActualizar,
      actionRoadWaysCrear,
      actionRoadWaysScaleFactorCrear,
      actionRoadWaysHabilitarDeshabilitar,
      actionRoadWaysClear,
      actionRoadWaysDetalle,

      actionGatesLista,
      actionGatesActualizar,
      actionGatesCrear,
      actionGatesHabilitarDeshabilitar,
      actionUsuarioLogin,
      actionScaleFactorCrear,
      actionDetectorsLista
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CameraList);
