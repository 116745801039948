/* eslint-disable */
import { ERROR_API } from "../constants/ActionTypes";

export default (state = {error:null, estado:''}, { type, data }) => {  
  switch (type) {
    case ERROR_API:
      return {...state, error: data, estado:'ERROR'};
    default:
      return state;
      
  }
};

