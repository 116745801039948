/* eslint-disable */
import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import './IconOptionStyle.css';
import { Box, Card, Button, createMuiTheme } from '@material-ui/core';
import { DataGrid, esES, CellParams, GridApi } from '@material-ui/data-grid';
import {
  FETCH_ALL_CHAT_USER_SUCCESS,
  localeTextDataGrid
} from 'src/constants/ActionTypes';
import SweetAlert from 'react-bootstrap-sweetalert';

//Kanban
import Board from '@lourenci/react-kanban';
import '@lourenci/react-kanban/dist/styles.css';

//opciones
import { Edit, Reorder, ViewWeek } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import RequestCanvaCard from '../RequestCanvaCard';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#1976d2' }
    }
  },
  esES
);

class CameraTableResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCustomerIds: [],
      limit: 10,
      page: 0,
      viewList: true,
      companyEnableDisable: [],
      showConfirmEnableDisable: false,
      modalVisibility: 'modalInvisible',
      cameraSelected: null,
      marginTop: 0,
      showMessageNotCreateUser: false,
      isOnline: false
    };
    /*
        R5 Pelequendomo 121.7
        Pelequendomo
        */
    this.columns = [
      { field: 'id', hide: true, filterable: false },
      {
        field: '',
        headerName: 'Acciones',
        sortable: false,
        filterable: false,
        width: 120,
        headerAlign: 'center',
        align: 'center',
        disableClickEventBubbling: true,
        renderCell: (params: CellParams) => {
          const onEdit = () => {
            const api: GridApi = params.api;
            let position = 0;
            const fields = api
              .getAllColumns()
              .map((c) => {
                return c.field;
              })
              .filter((c) => c !== '__check__' && !!c);
            const thisRow = {};
            fields.forEach((f) => {
              thisRow[f] = params.getValue(f);
            });
            this.props.OpenEdit(thisRow.id);
          };

          const onDelete = () => {
            const api: GridApi = params.api;
            let position = 0;
            const fields = api
              .getAllColumns()
              .map((c) => {
                return c.field;
              })
              .filter((c) => c !== '__check__' && !!c);
            const thisRow = {};
            fields.forEach((f) => {
              thisRow[f] = params.getValue(f);
            });
            this.props.showDeleteCamera(thisRow.id);
          };

          const onSetPosition = () => {
            const api: GridApi = params.api;
            let position = 0;
            const fields = api
              .getAllColumns()
              .map((c) => {
                return c.field;
              })
              .filter((c) => c !== '__check__' && !!c);
            const thisRow = {};
            fields.forEach((f) => {
              thisRow[f] = params.getValue(f);
            });
            position = this.props.Cameras.findIndex((e) => e.id == thisRow.id);
            !this.props.Cameras[position].is_online
              ? this.setState({
                  showMessageNotCreateUser: !this.state.showMessageNotCreateUser
                })
              : this.props.actionCamerasSetCapacityPosition({ id: thisRow.id });
          };

          return (
            <div style={{ position: 'relative', zIndex: 1 }}>
              <a>
                <EditIcon
                  onClick={() => onEdit()}
                  style={{ color: '#4CA7E1' }}
                />
              </a>
              <a>
                <ControlCameraIcon
                  onClick={() => onSetPosition()}
                  style={{ color: '#4CA7E1' }}
                />
              </a>
              <a>
                <DeleteIcon
                  onClick={() => onDelete()}
                  style={{ color: 'red' }}
                />
              </a>
            </div>
          );
        }
      },
      { field: 'name', headerName: 'Nombre', width: 200 },
      { field: 'state', headerName: 'Estado', width: 250 },
      { field: 'event', headerName: 'Eventos', width: 150 },
      { field: 'route', headerName: 'ruta', width: 150 },
      { field: 'kilometer', headerName: 'Km', width: 150 }
    ];
  }

  handleEnableDisable = () => {
    this.props.onEnableDisable({
      idRegistry: this.state.companyEnableDisable,
      stateEnableDisable: 0
    });
    this.setState({
      showConfirmEnableDisable: false,
      companyEnableDisable: []
    });
  };

  render() {
    return (
      <div
        onKeyDownCapture={(e) => {
          if (e.key === 'Backspace' || e.key === 'Delete') {
            e.stopPropagation();
          }
        }}
      >
        <DataGrid
          columns={this.columns}
          rows={this.props.Cameras}
          rowHeight={25}
          autoHeight={true}
          //checkboxSelection
          disableSelectionOnClick={true}
          disableColumnReorder={true}
          disableColumnSelector={true}
          localeText={localeTextDataGrid}
          isCellEditable={false}
          rowsPerPageOptions={[10, 20, 40]}
          pageSize={10}
          onSelectionModelChange={(newSelection) =>
            this.setState({ companyEnableDisable: newSelection.selectionModel })
          }
          style={{ position: 'relative', zIndex: 1 }}
        />
        <SweetAlert
          danger
          title={'La cámara no está online'}
          show={this.state.showMessageNotCreateUser}
          confirmBtnText="Aceptar"
          confirmBtnBsStyle="light"
          // cancelBtnText="Cancelar"
          // cancelBtnBsStyle="light"
          // cancelBtnStyle={{ fontSize: 14 }}
          style={{ color: '#000', fontSize: 14 }}
          confirmBtnStyle={{ fontSize: 14 }}
          onConfirm={() => this.setState({ showMessageNotCreateUser: false })}
        >
          <span>{'La camara no está online'}</span>
        </SweetAlert>
        {this.state.companyEnableDisable.length > 0 ? (
          <div
            style={{
              width: '100%',
              textAlign: 'left',
              margin: '0.5em 0em 0.5em 0em'
            }}
          >
            <Button
              color="primary"
              style={{ marginLeft: '1em' }}
              variant="contained"
              onClick={() => this.setState({ showConfirmEnableDisable: true })}
            >
              Deshabilitar
            </Button>
          </div>
        ) : null}
        <SweetAlert
          show={this.state.showConfirmEnableDisable}
          success
          confirmBtnText="Aceptar"
          confirmBtnBsStyle="light"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="light"
          cancelBtnStyle={{ fontSize: 14 }}
          style={{ color: '#000', fontSize: 14 }}
          confirmBtnStyle={{ fontSize: 14 }}
          title={'Deshabilitar cámara'}
          onConfirm={() => this.handleEnableDisable()}
          onCancel={() => this.setState({ showConfirmEnableDisable: false })}
          showCancel={true}
          showConfirm={true}
        >
          <span>{'¿Estás seguro que desea deshabilitar esta cámara?'}</span>
        </SweetAlert>
      </div>
    );
  }
}

export default CameraTableResults;
