/* eslint-disable */
import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Box,
    Card,
    Button,
    createMuiTheme,
} from '@material-ui/core';
import {
    DataGrid,
    esES,
    CellParams,
    GridApi
} from '@material-ui/data-grid';
import { FETCH_ALL_CHAT_USER_SUCCESS, localeTextDataGrid } from 'src/constants/ActionTypes'
import SweetAlert from 'react-bootstrap-sweetalert';

//Kanban
import Board from '@lourenci/react-kanban'
import '@lourenci/react-kanban/dist/styles.css'

//opciones
import { Edit, Reorder, ViewWeek } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import RequestCanvaCard from '../RequestCanvaCard';


const theme = createMuiTheme(
    {
        palette: {
            primary: { main: '#1976d2' },
        },
    },
    esES,
);


class CompanyTableResults extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedCustomerIds: [],
            limit: 10,
            page: 0,
            viewList: true,
            companyEnableDisable: [],
            showConfirmEnableDisable: false
        }

        this.columns = [
            { field: 'id', hide: true, filterable: false },
            {
                field: "",
                headerName: "Opciones",
                sortable: false,
                filterable: false,
                width: 120,
                headerAlign: 'center',
                align: 'center',
                disableClickEventBubbling: true,
                renderCell: (params: CellParams) => {
                    const onClick = () => {
                        const api: GridApi = params.api;
                        const fields = api
                            .getAllColumns()
                            .map((c) => c.field)
                            .filter((c) => c !== "__check__" && !!c);
                        const thisRow = {};

                        fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                        });
                        this.props.OnEdit(thisRow.id);
                        //return alert(JSON.stringify(thisRow, null, 4));
                    };

                    return <div>
                        <IconButton aria-label="Editar" onClick={onClick} style={{ outline: 'none' }}>
                            <Edit fontSize="small" />
                        </IconButton>
                    </div>;
                }
            },
            { field: 'nit', headerName: 'NIT', width: 200 },
            { field: 'name', headerName: 'Nombre', width: 200 },
            { field: 'email', headerName: 'Correo electrónico', width: 250 },
            { field: 'phone', headerName: 'Teléfono', width: 150 },
            { field: 'detectors', headerName: 'Detectores', width: 150 },
            { field: 'limit_user', headerName: 'Usuarios', width: 150 },
            {
                field: 'permissions', headerName: 'Permisos', width: 200,
                renderCell: (params: CellParams) => {
                    const permisos = () => {
                        var listPermissions = [];
                        const api: GridApi = params.api;
                        const fields = api
                            .getAllColumns()
                            .map((c) => c.field)
                            .filter((c) => c !== "__check__" && !!c);
                        const thisRow = {};

                        fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                        });

                        thisRow.permissions.forEach(element => {
                            listPermissions.push(element.permission.name);
                            //return element.permission.name
                        });

                        return listPermissions.toString();
                        //this.props.OnEdit(thisRow.id);
                        //return alert(JSON.stringify(thisRow, null, 4));
                    };

                    return <div>
                        <Box>
                            {permisos}
                        </Box>
                    </div>;
                },
            },

            // {
            //     field: 'insertDate', headerName: 'Fecha Creado', width: 200, type: "date",
            //     valueFormatter: (params: GridValueFormatterParams) => {
            //         // first converts to JS Date, then to locale option through date-fns
            //         return moment(params.value).format("DD/MM/YYYY HH:mm");
            //     },
            //     // valueGetter for filtering
            //     valueGetter: (params: GridValueGetterParams) => {
            //         return moment(params.value).format("MM/DD/YYYY HH:mm");
            //     }
            // },
            // { field: 'requestState', headerName: 'Última acción realizada', width: 250 },
            // { field: 'executiveName', headerName: 'Ejecutivo', width: 150 }
            // Contrato 
            // Grupo 
            // Razón social: no se puede editar 
            // Nit: no se puede editar 
            // Fecha de creación del contacto 
        ];
    }

    handleEnableDisable = () => {
        this.props.onEnableDisable({
            idRegistry: this.state.companyEnableDisable,
            stateEnableDisable: 0
        })
        this.setState({ showConfirmEnableDisable: false, companyEnableDisable: [] })
    }

    render() {
        return (
            <div
                onKeyDownCapture={(e) => {
                    if (e.key === "Backspace" || e.key === "Delete") {
                        e.stopPropagation();
                    }
                }}
            >
                <DataGrid
                    columns={this.columns}
                    rows={this.props.Companys}
                    rowHeight={25}
                    autoHeight={true}
                    //checkboxSelection
                    disableSelectionOnClick={true}
                    disableColumnReorder={true}
                    disableColumnSelector={true}
                    disableColumnSelector={true}
                    localeText={localeTextDataGrid}
                    isCellEditable={false}
                    rowsPerPageOptions={[10, 20, 40]}
                    pageSize={10}
                    onSelectionModelChange={newSelection => this.setState({ companyEnableDisable: newSelection.selectionModel })}
                />
                {
                    this.state.companyEnableDisable.length > 0 ?
                        <div style={{ width: '100%', textAlign: 'left', margin: "0.5em 0em 0.5em 0em" }}>
                            <Button color="primary" style={{ marginLeft: "1em" }} variant="contained"
                                onClick={() => this.setState({ showConfirmEnableDisable: true })}>
                                Deshabilitar
                            </Button>
                        </div>
                        : null
                }
                <SweetAlert
                    show={this.state.showConfirmEnableDisable}
                    success
                    confirmBtnText="Aceptar"
                    confirmBtnBsStyle="light"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="light"
                    cancelBtnStyle={{ fontSize: 14 }}
                    style={{ color: "#000", fontSize: 14 }}
                    confirmBtnStyle={{ fontSize: 14 }}
                    title={"Deshabilitar Empresa"}
                    onConfirm={() => this.handleEnableDisable()}
                    onCancel={() => this.setState({ showConfirmEnableDisable: false })}
                    showCancel={true}
                    showConfirm={true}
                >
                    <span>{"¿Estás seguro que desea deshabilitar estos Empresa?"}</span>
                </SweetAlert>
            </div>
        )
    }
}



export default CompanyTableResults;
