/* eslint-disable */
import React, { Component } from 'react';
import { Box } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SweetAlert from 'react-bootstrap-sweetalert';

import Localization from 'src/components/CreateCameraForms/Localization';
import GeneralData from 'src/components/CreateCameraForms/GeneralData';
import Driveways from 'src/components/CreateCameraForms/Driveways';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import EventNoteIcon from '@mui/icons-material/EventNote';
const styles = {
  root: {
    width: '100%'
  },
  button: {
    marginRight: 1
  },
  backButton: {
    marginRight: 1
  },
  completed: {
    display: 'inline-block'
  },
  instructions: {
    marginTop: 1,
    marginBottom: 1,
    marginRight: 10,
    marginLeft: 10
  },
  icon: {
    backgroundColor: '#0099ff',
    color: '#fff',
    borderRadius: 20,
    fontSize: 40,
    paddingTop: 5,
    paddingBottom: 5
  }
};
class StepperComponentCamera extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      valueGeneral: 0,
      activeStep: 0,
      completed: {},
      skipped: 0,
      steps: ['Localización', 'Datos Generales', 'Agregar calzadas'],
      dataStep: {},
      showAlert: false,
      step: 0,
      step1Error: null,
      step2Error: null,
      step3Error: null,
      objStep1: {},
      objStep2: {},
      objStep3: {}
    };
  }

  componentDidMount() {
    this.props.ShowSaveButton();
  }

  totalSteps = () => {
    return this.state.steps.length;
  };

  completedSteps = () => {
    const { completed } = this.state;
    return Object.keys(completed).length;
  };

  isLastStep = () => {
    const { activeStep } = this.state;
    return activeStep === this.totalSteps() - 1;
  };

  allStepsCompleted = () => {
    return this.completedSteps() === this.totalSteps();
  };

  handleNext = (obj) => {
    console.log('handleNext', obj);
    const { completed, steps, activeStep } = this.state;
    let newActiveStep =
      this.isLastStep() && !this.allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;

    if (activeStep === 0 && this.props.Update) {
      this.props.actionRoadWaysLista({ id: this.props.dataDetail.public_key });
      newActiveStep = 1;

      this.props.dataDetail.name = obj.fields.name;
      this.props.dataDetail.route = obj.fields.route;
      this.props.dataDetail.street = obj.fields.street;
      this.props.dataDetail.kilometer = obj.fields.kilometer;
      this.props.dataDetail.lat = obj.fields.lat;
      this.props.dataDetail.lng = obj.fields.lng;
      obj.fields.detector_id
        ? (this.props.dataDetail.detector_id = obj.fields.detector_id)
        : delete this.props.dataDetail.detector_id;
      this.props.actionCamerasActualizar(this.props.dataDetail);
    }

    this.setState({
      activeStep: newActiveStep
    });

    if (obj.step === 1) {
      this.setState({
        objStep1: obj.fields,
        step1Error: false
      });
    }

    if (obj.step === 2) {
      this.setState(
        {
          objStep2: obj.fields,
          step2Error: false
        },
        () => {
          this.props.CreateCamera({
            step1Error: this.state.step1Error,
            step2Error: this.state.step2Error,
            step3Error: this.state.step3Error,
            objStep1: this.state.objStep1,
            objStep2: this.state.objStep2,
            objStep3: this.state.objStep3
          });
        }
      );
    }

    // Lógica para enviar datos a la API en cada paso
    if (obj.step === 3) {
      this.setState(
        {
          objStep3: obj.fields,
          step3Error: false
        },
        () => {
          this.props.CreateCamera({
            step1Error: this.state.step1Error,
            step2Error: this.state.step2Error,
            step3Error: this.state.step3Error,
            objStep1: this.state.objStep1,
            objStep2: this.state.objStep2,
            objStep3: this.state.objStep3
          });
        }
      );
    }
  };

  handleBack = () => {
    this.setState({
      activeStep: this.props.Update
        ? this.state.activeStep - 2
        : this.state.activeStep - 1
    });
  };

  handleStep = (step) => {
    this.setState({
      activeStep:
        this.props.Update && this.state.activeStep === 2 && step === 1
          ? 2
          : step
    });
  };

  handleComplete = () => {
    const { completed, steps, activeStep } = this.state;

    const newCompleted = completed;
    newCompleted[activeStep] = true;
    this.setState(
      {
        completed: newCompleted
      },
      () => {
        this.handleNext();
      }
    );
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      completed: {}
    });
  };
  finishForm = () => {
    //console.log("Finish form");
  };

  getStepContent = (step) => {
    const { step1Error, step2Error, step3Error, objStep1, objStep2, objStep3 } =
      this.state;
    switch (step) {
      case 0:
        return (
          <Localization
            {...this.props}
            descriptionText={''}
            objStep1={objStep1}
            NextStep={(obj) => this.handleNext(obj)}
            ref={(instance) => {
              this.child = instance;
            }}
          />
        );
      case 1:
        return (
          <GeneralData
            {...this.props}
            descriptionText={''}
            objStep2={objStep2}
            BackStep={() => this.handleBack()}
            NextStep={(obj) => this.handleNext(obj)}
            ref={(instance) => {
              this.child = instance;
            }}
            ConnectCameraApi={(objCam) => this.props.ConnectCameraApi(objCam)}
          />
        );
      case 2:
        return (
          <Driveways
            {...this.props}
            descriptionText={''}
            ref={(instance) => {
              this.child = instance;
            }}
            BackStep={() => this.handleBack()}
            Finish={() => this.props.Finish()}
            CreateCamera={(obj) => this.handleCreateCamera(obj)}
            deleteById={(id) => this.props.deleteById(id)}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  saveRequest() {
    console.log('guardado');
    this.child.saveRequest();
  }

  returnIcon = (index) => {
    if (index === 0) {
      return <LocationOnIcon style={styles.icon} />;
    } else if (index === 1) {
      return <EventNoteIcon style={styles.icon} />;
    } else {
      return <EditRoadIcon style={styles.icon} />;
    }
  };

  render() {
    const { steps, activeStep, completed } = this.state;
    return (
      <Box
        display="flex"
        flexDirection="row"
        width={'100%'}
        overflow={'hidden'}
      >
        <div style={{ width: '100%' }}>
          <div className={styles.root}>
            <PerfectScrollbar>
              <div className="d-flex justify-content-center">
                <Stepper
                  variant="progress"
                  steps={3}
                  alternativeLabel
                  nonLinear={false}
                  activeStep={activeStep}
                  style={{
                    border: 'none',
                    outline: 'none',
                    width: '90%',
                    alignSelf: 'center',
                    marginTop: 20
                  }}
                >
                  {steps.map((label, index) => (
                    <Step
                      key={label}
                      style={{
                        outline: 'none'
                      }}
                    >
                      <StepButton
                        icon={this.returnIcon(index)}
                        onClick={() => this.handleStep(index)}
                        completed={completed[index]}
                        style={{
                          outline: 'none',
                          marginTop: -5
                        }}
                      >
                        {label}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
              </div>
            </PerfectScrollbar>
            <div>
              {this.allStepsCompleted() ? (
                <div>
                  <Typography className={styles.instructions}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Button onClick={handleReset}>Reset</Button>
                </div>
              ) : (
                <div>
                  <Typography className={styles.instructions}>
                    {this.getStepContent(activeStep)}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </div>

        <SweetAlert
          show={this.state.showAlert}
          error
          confirmBtnText="Aceptar"
          confirmBtnBsStyle="light"
          style={{ color: '#000', fontSize: 14 }}
          title={'Se han realizado cambios'}
          onConfirm={() =>
            this.setState({
              showAlert: !this.state.showAlert
            })
          }
          showCancel
          cancelBtnText={'Cancelar'}
          cancelBtnBsStyle="light"
          cancelBtnStyle={{ fontSize: 14 }}
          confirmBtnStyle={{ fontSize: 14 }}
          onCancel={() =>
            this.setState({
              showAlert: !this.state.showAlert
            })
          }
        >
          <span>{'¿Desea guardar los cambios?'}</span>
        </SweetAlert>
      </Box>
    );
  }
}

export default StepperComponentCamera;
