/* eslint-disable */
import {
  VIDEO_INICIA,
  VIDEO_LISTADO,
  VIDEO_ERROR,
  VIDEO_ACTUALIZADA,
  VIDEO_CREADA,
  VIDEO_HABILITADA_DESHABILITADA,
  VIDEO_CLEARED,
  VIDEO_UPLOADED,
  VIDEO_INICIAUP,
  VIDEO_DETALLADO,

} from 'src/constants/api/videos';

import {
  LIMPIAR_ESTADO
} from 'src/constants/ActionTypes';


const DEFAULT_STATE = {
  waiting: false,
  estado: '',
  items: [],
  actualizar: false,
  apiRespuesta: false,
  dataDetail: {},
  dataUploaded: {},
  dataCreated: {},
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case VIDEO_INICIA:
      return { ...state, waiting: true };

    case VIDEO_INICIAUP:
      return { ...state, waiting: false };

    case VIDEO_LISTADO:
      return { ...state, waiting: false, items: action.data, actualizar: false, estado: 'LISTADO', apiRespuesta: true };

    case VIDEO_ACTUALIZADA:
      return { ...state, waiting: false, actualizar: true, estado: 'ACTUALIZADO', apiRespuesta: true };

    case VIDEO_DETALLADO:
      return {
        ...state, waiting: false, actualizar: true, estado: 'DETALLADO',
        apiRespuesta: true, dataDetail: action.params
      };

    case VIDEO_CREADA:
      return {
        ...state, waiting: false, actualizar: true, estado: 'CREADO',
        apiRespuesta: true, dataCreated: action.params
      };

    case VIDEO_UPLOADED:
      return {
        ...state, waiting: false, actualizar: true, estado: 'SUBIDO',
        apiRespuesta: true, dataUploaded: action.params
      };

    case VIDEO_HABILITADA_DESHABILITADA:
      return { ...state, waiting: false, actualizar: true, estado: 'HABILITADO_DESHABILITADO', apiRespuesta: true };

    case VIDEO_ERROR:
      return {
        ...state,
        waiting: false,
        error: action.error ? (action.error.detail
          ? action.error.detail

          : "Error al solicitar el Api"
        ) : 'API Error.',
        estado: 'ERROR',
        actualizar: true,
        apiRespuesta: true
      };

    //--->Connect camera


    case VIDEO_CLEARED:
      return { ...DEFAULT_STATE, error: '', waiting:false, actualizar: false, estado: '', apiRespuesta: false };
    case LIMPIAR_ESTADO:
      return { ...state, error: '', waiting:false, actualizar: false, estado: '', apiRespuesta: false };
    default:
      return state;
  }
};
