/* eslint-disable */
import { URL_API_COMPANIES } from './endpoints'

import {
  DEFAULT_HEADERS,
  apiPeticionExitosa,
  apiErrorHandler,
  userAuthToken,
  
} from './commons';


export const apiCompaniesDetail = async params => {
  var query = params.id ? `/${params.id}` : '';
  return await fetch(
    `${URL_API_COMPANIES}${query}`,
    Object.assign({ method: "GET" },
      {
        headers: new Headers({
          ...DEFAULT_HEADERS,
          Authorization: userAuthToken(),
        })
      }
    )
  ).then(apiPeticionExitosa)
  .catch(apiErrorHandler);
}
