/* eslint-disable */
import React from 'react';


export default class DropDownFormTemplate extends React.Component {

    render() {
        const { field, label, classCol, error, label2 } = this.props;
        return (
            <div className={`form-group ${classCol}`}>
                {label && <label className="e-label-top" style={{
                    fontSize: "13px",
                    marginTop: -4,
                    color: "#0000008a",
                    fontWeight: "normal"
                }}>{label}</label>}
                <div className="e-float-input e-control-wrapper" style={{ marginTop: -4 }}>
                    {field}
                    <span className="e-float-line" />
                </div>
                {label2 && <label className="e-label-top" style={{
                    fontSize: "13px",
                    marginTop: -4,
                    color: "#0000008a",
                    fontWeight: "normal"
                }}>{label2}</label>}
                {error
                    ?
                    <label className="error" style={{ color: "red", fontSize: 12, fontWeight: "normal" }}>
                        {error ? error : ""}
                    </label>
                    : null}
            </div>
        )
    }

}