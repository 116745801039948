/* eslint-disable */
import React from "react";
import './index.css';
// Rendering individual images
const Image = ({ image }) => {
    return (
        <div className="file-item justify-content-center" >
            <img
                alt={`img - ${image.id}`}
                src={image.src}
                className="file-img"
            />
        </div>
    );
};

// ImageList Component//
const ImageGrid = ({ images }) => {
    // render each image by calling Image component
    const renderImage = (image, index) => {
        return <Image image={image} key={`${image.id}-image`} />;
    };
    // Return the list of files//
    return (
        <section className="file-list" style={{
            alignContent: "center",
            justifyContent: "center"
        }}>{
            images.length > 0 ?
            images.map(renderImage): null}</section>
    );
};

export default ImageGrid;