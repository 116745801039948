/* eslint-disable */
import {
  URL_API_EXECUTIVES,
  URL_API_CREATE_USER,
  URL_API_USERS
} from './endpoints';

import {
  DEFAULT_HEADERS,
  apiPeticionExitosa,
  apiErrorHandler,
  userAuthToken
} from './commons';

export const apiExecutivesList = async (params) => {
  var query = `/list?active=${params.active}`;
  var query2 = params.is_admin === false ? `&client=${params.client}` : '';
  return await fetch(
    `${URL_API_EXECUTIVES}${query}${query2}`,
    Object.assign(
      { method: 'GET' },
      {
        headers: new Headers({
          ...DEFAULT_HEADERS,
          Authorization: userAuthToken()
        })
      }
    )
  )
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
};

export const apiUserCrear = async (datos) => {
  var query = '/';
  return await fetch(
    `${URL_API_USERS}${query}`,
    //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
    {
      method: 'POST',
      body: JSON.stringify(datos),
      headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken()
      })
    }
  )
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
};

export const apiUserActualizar = async (datos) => {
  var query = datos.id ? `/${datos.id}` : '';
  return await fetch(
    `${URL_API_USERS}${query}`,
    //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
    {
      method: 'PUT',
      body: JSON.stringify(datos),
      headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken()
      })
    }
  )
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
};

export const apiExecutiveDetail = async (params) => {
  var query = params.id ? `/${params.id}` : '';
  return await fetch(
    `${URL_API_USERS}${query}`,
    Object.assign(
      { method: 'GET' },
      {
        headers: new Headers({
          ...DEFAULT_HEADERS,
          Authorization: userAuthToken()
        })
      }
    )
  )
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
};

export const apiExecutivesHabilitarDeshabilitar = async (datos) => {
  var query = `/${datos.id}`;
  return await fetch(`${URL_API_USERS}${query}`, {
    method: 'DELETE',
    body: '',
    headers: new Headers({
      ...DEFAULT_HEADERS,
      'Content-Type': 'text/plain',
      Authorization: userAuthToken()
    })
  })
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
};
