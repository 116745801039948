/* eslint-disable */
import {
    VIDEO_INICIA,
    VIDEO_INICIAUP,
    VIDEO_LISTA,    
    VIDEO_LISTADO, 

    VIDEO_ACTUALIZAR,
    VIDEO_ACTUALIZADA,
    VIDEO_CREAR,
    VIDEO_CREADA,

    VIDEO_CLEAR,
    VIDEO_CLEARED,

    VIDEO_UPLOAD,
    VIDEO_UPLOADED,

    VIDEO_DETALLE,
    VIDEO_DETALLADO,
    VIDEO_DETALLE_INICIA,
    VIDEO_HABILITAR_DESHABILITAR,
    VIDEO_HABILITADA_DESHABILITADA,
    VIDEO_ERROR,

} from 'src/constants/api/videos';

export const actionVideosInicia = () => ({ type: VIDEO_INICIA, });
export const actionVideosIniciaUp = () => ({ type: VIDEO_INICIAUP,})
export const actionVideosLista = params => ({ type: VIDEO_LISTA, params });
export const actionVideosListado = data => ({ type: VIDEO_LISTADO, data });

export const actionVideosActualizar = data => ({ type: VIDEO_ACTUALIZAR, data });
export const actionVideosActualizada = (params) => ({ type: VIDEO_ACTUALIZADA, params });

export const actionVideosCrear = data => ({ type: VIDEO_CREAR, data });
export const actionVideosCreada = (params) => ({ type: VIDEO_CREADA, params });

export const actionVideosUpload = data => ({ type: VIDEO_UPLOAD, data });
export const actionVideosUploaded = (params) => ({ type: VIDEO_UPLOADED, params });

export const actionVideosClear = data => ({ type: VIDEO_CLEAR, data });
export const actionVideosCleared = data => ({ type: VIDEO_CLEARED, data });


export const actionVideosDetalle = data => ({ type: VIDEO_DETALLE, data });
export const actionVideosDetallado = (params) => ({ type: VIDEO_DETALLADO, params });
export const actionVideosDetalleInicia = () => ({ type: VIDEO_DETALLE_INICIA, });

export const actionVideosHabilitarDeshabilitar = data => ({ type: VIDEO_HABILITAR_DESHABILITAR, data });
export const actionVideosHabilitadaDeshabilitada = (params) => ({ type: VIDEO_HABILITADA_DESHABILITADA, params });



export const actionVideosError = error => ({ type: VIDEO_ERROR, error });
