/* eslint-disable */
import {
  COMPANIES_DETALLE_INICIA,
  COMPANIES_DETALLADO,
  COMPANIES_DETALLE_ERROR,
} from 'src/constants/api/companies';

import {
  LIMPIAR_ESTADO
} from 'src/constants/ActionTypes';


const DEFAULT_STATE = {
  waiting: false,
  estado: '',
  items: {},
  actualizar: false,
  apiRespuesta: false,
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case COMPANIES_DETALLE_INICIA:
      return { ...state, waiting: true, estado: '' };

    case COMPANIES_DETALLADO:
      return { ...state, waiting: false, items: action.data, actualizar: false, estado: 'DETALLE', 
      apiRespuesta: true };
    case COMPANIES_DETALLE_ERROR:
      return {
        ...state,
        waiting: false,
        error: action.error ? (`${action.error.detail || action.error.detail}`) : 'API Error.',
        estado: 'ERROR',
        actualizar: true,
        apiRespuesta: true
      };
    case LIMPIAR_ESTADO:
      return { ...state, error: '', actualizar: false, estado: '', apiRespuesta: false, waiting: false};
    default:
      return state;
  }
};
