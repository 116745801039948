/* eslint-disable */
import {
    MANEUVERS_INICIA,
    MANEUVERS_LISTA,    
    MANEUVERS_LISTADO, 

    MANEUVERS_CREAR,
    MANEUVERS_CREADA,
    
    MANEUVERS_CLEAR,
    MANEUVERS_CLEARED,

    MANEUVERS_HABILITAR_DESHABILITAR,
    MANEUVERS_HABILITADA_DESHABILITADA,
    MANEUVERS_ERROR,
} from 'src/constants/api/maneuvers';

export const actionManeuversInicia = () => ({ type: MANEUVERS_INICIA, });
export const actionManeuversLista = params => ({ type: MANEUVERS_LISTA, params });
export const actionManeuversListado = data => ({ type: MANEUVERS_LISTADO, data });

export const actionManeuversCrear = data => ({ type: MANEUVERS_CREAR, data });
export const actionManeuversCreada = (params) => ({ type: MANEUVERS_CREADA, params });

export const actionManeuversClear = data => ({ type: MANEUVERS_CLEAR, data });
export const actionManeuversCleared = data => ({ type: MANEUVERS_CLEARED, data });

export const actionManeuversHabilitarDeshabilitar = data => ({ type: MANEUVERS_HABILITAR_DESHABILITAR, data });
export const actionManeuversHabilitadaDeshabilitada = (params) => ({ type: MANEUVERS_HABILITADA_DESHABILITADA, params });

export const actionManeuversError = error => ({ type: MANEUVERS_ERROR, error });
