/* eslint-disable */
import {
  CAMERAS_INICIA,
  CAMERAS_LISTA,
  CAMERAS_LISTADO,
  CAMERAS_ACTUALIZAR,
  CAMERAS_ACTUALIZADA,
  CAMERAS_CREAR,
  CAMERAS_CREADA,
  CAMERAS_DELETE,
  CAMERAS_DELETED,
  CAMERAS_CLEAR,
  CAMERAS_CLEARED,
  CAMERAS_SCALEFACTOR,
  CAMERAS_SCALEFACTORED,
  CAMERAS_DETALLE,
  CAMERAS_DETALLADO,
  CAMERAS_DETALLE_INICIA,
  CAMERAS_HABILITAR_DESHABILITAR,
  CAMERAS_HABILITADA_DESHABILITADA,
  CAMERAS_DESHABILITAR,
  CAMERAS_DESHABILITADA,
  CAMERAS_SET_CAPACITY_POSITION,
  CAMERAS_SETED_CAPACITY_POSITION,
  CAMERAS_ERROR,
  CAMERA_STOP,
  CAMERAS_CHECKCONNECTION_OBTENER,
  CAMERAS_CHECKCONNECTION_OBTENIDA,
  CAMERAS_PTZ_OBTENER,
  CAMERAS_PTZ_OBTENIDA,
  CAMERAS_IMAGE_OBTENER,
  CAMERAS_IMAGE_OBTENIDA
} from 'src/constants/api/cameras';

export const actionCamerasInicia = () => ({ type: CAMERAS_INICIA });
export const actionCamerasLista = (params) => ({ type: CAMERAS_LISTA, params });
export const actionCamerasListado = (data) => ({ type: CAMERAS_LISTADO, data });

export const actionCamerasActualizar = (data) => ({
  type: CAMERAS_ACTUALIZAR,
  data
});
export const actionCamerasActualizada = (params) => ({
  type: CAMERAS_ACTUALIZADA,
  params
});

export const actionCamerasCrear = (data) => ({ type: CAMERAS_CREAR, data });
export const actionCamerasCreada = (params) => ({
  type: CAMERAS_CREADA,
  params
});

export const actionCamerasDelete = (data) => ({ type: CAMERAS_DELETE, data });
export const actionCamerasDeleted = (params) => ({
  type: CAMERAS_DELETED,
  params
});

export const actionCamerasClear = (data) => ({ type: CAMERAS_CLEAR, data });
export const actionCamerasCleared = (data) => ({ type: CAMERAS_CLEARED, data });

export const actionCamerasScaleFactor = (data) => ({
  type: CAMERAS_SCALEFACTOR,
  data
});
export const actionCamerasScaleFactored = (params) => ({
  type: CAMERAS_SCALEFACTORED,
  params
});

export const actionCamerasDetalle = (params) => ({
  type: CAMERAS_DETALLE,
  params
});
export const actionCamerasDetallado = (data) => ({
  type: CAMERAS_DETALLADO,
  data
});
export const actionCamerasDetalleInicia = () => ({
  type: CAMERAS_DETALLE_INICIA
});

export const actionCamerasHabilitarDeshabilitar = (data) => ({
  type: CAMERAS_HABILITAR_DESHABILITAR,
  data
});
export const actionCamerasHabilitadaDeshabilitada = (params) => ({
  type: CAMERAS_HABILITADA_DESHABILITADA,
  params
});

export const actionCamerasDeshabilitar = (data) => ({
  type: CAMERAS_DESHABILITAR,
  data
});
export const actionCamerasDeshabilitada = (params) => ({
  type: CAMERAS_DESHABILITADA,
  params
});

export const actionCamerasSetCapacityPosition = (data) => ({
  type: CAMERAS_SET_CAPACITY_POSITION,
  data
});
export const actionCamerasSetedCapacityPosition = (params) => ({
  type: CAMERAS_SETED_CAPACITY_POSITION,
  params
});

//Connect camera

export const actionCamerasCheckConnectionObtener = (data) => ({
  type: CAMERAS_CHECKCONNECTION_OBTENER,
  data
});
export const actionCamerasCheckConnectionObtenida = (params) => ({
  type: CAMERAS_CHECKCONNECTION_OBTENIDA,
  params
});

export const actionCamerasPtzObtener = (data) => ({
  type: CAMERAS_PTZ_OBTENER,
  data
});
export const actionCamerasPtzObtenida = (params) => ({
  type: CAMERAS_PTZ_OBTENIDA,
  params
});

export const actionCamerasImageObtener = (data) => ({
  type: CAMERAS_IMAGE_OBTENER,
  data
});
export const actionCamerasImageObtenida = (params) => ({
  type: CAMERAS_IMAGE_OBTENIDA,
  params
});

//-------->

export const actionCamerasError = (error) => ({ type: CAMERAS_ERROR, error });
