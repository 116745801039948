/* eslint-disable */
import { Fragment, useEffect, useRef, useState } from 'react';
import { Stage, Layer, Rect, Transformer } from 'react-konva';

export default function Gate({
  shapeProps,
  isSelected,
  onSelect,
  onChange,
  showFromManeuvers
}) {
  console.log(shapeProps);
  const shapeRef = useRef();
  const trRef = useRef();

  useEffect(() => {
    if (showFromManeuvers !== true) {
      if (isSelected) {
        // we need to attach transformer manually
        trRef.current.nodes([shapeRef.current]);
        trRef.current.getLayer().batchDraw();
      }
    }
  }, [isSelected]);

  return (
    <Fragment>
      <Rect
        fill="white"
        stroke="black"
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        draggable
        onDragEnd={(e) => {
          const [x1, y1] = [
            e.target.x() + shapeProps.width,
            e.target.y() + shapeProps.height
          ];
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
            x1,
            y1
          });
        }}
        onTransformEnd={(e) => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          const rotation = node.rotation();
          const width = Math.max(5, node.width() * scaleX);
          const height = Math.max(5, node.height() * scaleY);
          const [x, y] = [node.x(), node.y()];

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          onChange({
            ...shapeProps,
            x,
            y,
            rotation,
            x1: x + width * Math.cos((rotation * Math.PI) / 180),
            // y1: y + width * Math.sin((rotation * Math.PI) / 180),
            y1:
              y +
              /**
               * Calculate [value]: y + if rotation is zero just add the width
               */
              (rotation === 0
                ? width
                : /**
                   * Otherwise add next calculation
                   */
                  width * Math.sin((rotation * Math.PI) / 180)),
            width,
            height
          });
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          enabledAnchors={[
            'top-left',
            'top-center',
            'top-right',
            'middle-right',
            'middle-left',
            'bottom-left',
            'bottom-center',
            'bottom-right'
          ]}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </Fragment>
  );
}
