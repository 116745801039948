/* eslint-disable */
import React, { Component } from 'react';
import {
    Button,
    createMuiTheme,
} from '@material-ui/core';
import {
    DataGrid,
    esES,
    CellParams,
    GridApi
} from '@material-ui/data-grid';
import { localeTextDataGrid } from 'src/constants/ActionTypes'
import CircleIcon from '@mui/icons-material/Circle';

//Kanban
import '@lourenci/react-kanban/dist/styles.css'

//opciones
import DeleteIcon from '@mui/icons-material/Delete';
import { Edit } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Box } from '@material-ui/core';

const theme = createMuiTheme(
    {
        palette: {
            primary: { main: '#1976d2' },
        },
    },
    esES,
);


class ManeuversTableResults extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedCustomerIds: [],
            limit: 10,
            page: 0,
            viewList: true,
            userEnableDisable: [],
            showConfirmEnableDisable: false
        }

        this.columns = [
            { field: 'id', hide: true, filterable: false },
            {
                field: "",
                headerName: "Opciones",
                sortable: false,
                filterable: false,
                width: 120,
                headerAlign: 'center',
                align: 'center',
                disableClickEventBubbling: true,
                renderCell: (params: CellParams) => {
                    const onClick = () => {
                        const api: GridApi = params.api;
                        const fields = api
                            .getAllColumns()
                            .map((c) => c.field)
                            .filter((c) => c !== "__check__" && !!c);
                        const thisRow = {};

                        fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                        });

                        ////console.log("Edit: ", thisRow);
                        this.props.OnEdit(thisRow.id);
                        //return alert(JSON.stringify(thisRow, null, 4));
                    };

                    return <div>
                        <IconButton aria-label="Editar" onClick={onClick}
                            style={{ border: "none", outline: "none" }}>
                            <DeleteIcon fontSize="small" style={{ border: "none", outline: "none" }} />
                        </IconButton>
                    </div>;
                }
            },
            //             {
            //     field: "color",
            //     headerName: "Color",
            //     sortable: false,
            //     filterable: false,
            //     width: 120,
            //     headerAlign: 'center',
            //     align: 'center',
            //     disableClickEventBubbling: true,
            //     renderCell: (params: CellParams) => {
            //         const onClick = () => {
            //             const api: GridApi = params.api;
            //             const fields = api
            //                 .getAllColumns()
            //                 .map((c) => c.field)
            //                 .filter((c) => c !== "__check__" && !!c);
            //             const thisRow = {};

            //             fields.forEach((f) => {
            //                 thisRow[f] = params.getValue(f);
            //             });
            //             return thisRow.fill;
            //             //this.props.OnEdit(thisRow.id);
            //             //return alert(JSON.stringify(thisRow, null, 4));
            //         };

            //         return <div>
            //             <IconButton aria-label="Editar"
            //                 style={{ border: "none", outline: "none" }}>
            //                 <CircleIcon fontSize="small" 
            //                 style={{ 
            //                     border: "none", 
            //                     outline: "none",
            //                     color: onClick()
            //                 }} />
            //             </IconButton>
            //         </div>;
            //     }
            // },
            { field: 'index', headerName: 'Maniobra', width: 150 },
            { field: 'Type', headerName: 'Ent.', width: 150 },
            { field: 'movement', headerName: 'Mov.', width: 150 },
            //{ field: 'geometris_id_entry', headerName: 'C. entrada', width: 150 },

            {
                field: "geometris_id_entry",
                headerName: "C. entrada",
                sortable: false,
                filterable: false,
                width: 120,
                headerAlign: 'center',
                align: 'center',
                disableClickEventBubbling: true,
                renderCell: (params: CellParams) => {
                    const onClick = () => {
                        const api: GridApi = params.api;
                        const fields = api
                            .getAllColumns()
                            .map((c) => c.field)
                            .filter((c) => c !== "__check__" && !!c);
                        const thisRow = {};

                        fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                        });

                        return thisRow;
                        //return alert(JSON.stringify(thisRow, null, 4));
                    };

                    return <div style={{flexDirection: "row", display: "flex", alignItems: "center"}}>
                        {onClick().geometris_id_entry.gate.index}
                        <CircleIcon fontSize="small" 
                            style={{ 
                                border: "none", 
                                outline: "none",
                                color: onClick().geometris_id_entry.gate.color
                            }} />
                    </div>;
                }
            },

            //{ field: 'geometris_id_exit', headerName: 'C. salida', width: 150 },
            {
                field: "geometris_id_exit",
                headerName: "C. salida",
                sortable: false,
                filterable: false,
                width: 120,
                headerAlign: 'center',
                align: 'center',
                disableClickEventBubbling: true,
                renderCell: (params: CellParams) => {
                    const onClick = () => {
                        const api: GridApi = params.api;
                        const fields = api
                            .getAllColumns()
                            .map((c) => c.field)
                            .filter((c) => c !== "__check__" && !!c);
                        const thisRow = {};

                        fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                        });

                        return thisRow;
                        //return alert(JSON.stringify(thisRow, null, 4));
                    };

                    return <div style={{flexDirection: "row", display: "flex", alignItems: "center"}}>
                        {onClick().geometris_id_exit.gate.index}
                        <CircleIcon fontSize="small" 
                            style={{ 
                                border: "none", 
                                outline: "none",
                                color: onClick().geometris_id_exit.gate.color
                            }} />
                    </div>;
                }
            },
            { field: 'origin', headerName: 'Origen', width: 150 },
            { field: 'destination', headerName: 'Destino', width: 150 },
            //{ field: 'fill', hide: true, filterable: false },
            //{ field: 'name', headerName: 'Nombre', width: 150 },
            //{ field: 'direction', headerName: 'Sentido', width: 150 },
            //{ field: 'gates', headerName: 'Compuertas', width: 150 },
            //{ field: 'scalefactor', headerName: 'F. de forma', width: 150 },
            //{ field: 'isAdmin', headerName: 'Administrador', width: 200 },
            //{ field: 'isActive', headerName: 'Activo', width: 200, height: 150 },
            //{ field: 'insertDate', headerName: 'Fecha de creación', width: 200 },
            //{ field: 'updatedDate', headerName: 'Fecha de actualización', width: 200 },
        ];
    }

    handleEnableDisable = () => {
        this.props.onEnableDisable({
            idRegistry: this.state.userEnableDisable,
            stateEnableDisable: 0
        })
        this.setState({ showConfirmEnableDisable: false, userEnableDisable: [] })
    }

    render() {
        return (
            <div
                onKeyDownCapture={(e) => {
                    if (e.key === "Backspace" || e.key === "Delete") {
                        e.stopPropagation();
                    }
                }}
            >
                <DataGrid
                    columns={this.columns}
                    rows={this.props.Gates}
                    rowHeight={25}
                    autoHeight={true}
                    //checkboxSelection
                    disableSelectionOnClick={true}
                    disableColumnReorder={true}
                    disableColumnSelector={true}
                    disableColumnSelector={true}
                    localeText={localeTextDataGrid}
                    isCellEditable={false}
                    editMode={false}
                    rowsPerPageOptions={[10, 20, 40]}
                    pageSize={10}
                    onSelectionModelChange={newSelection => this.setState({ userEnableDisable: newSelection.selectionModel })}
                />
                {
                    this.state.userEnableDisable.length > 0 ?
                        <div style={{ width: '100%', textAlign: 'left', margin: "0" }}>
                            <Button color="primary" style={{ marginLeft: "1em" }} variant="contained"
                                onClick={() => {
                                    // let enableDisable = true;
                                    // this.state.requestEnableDisable.map(selectedElement => { 
                                    //     if ((this.props.Requests.find(element => element.idRegistry === selectedElement)).stage !== 0) enableDisable = false })
                                    // if (!enableDisable) {
                                    //     toast.error('Sólo se pueden deshabilitar solicitudes en etapa 0.', {
                                    //         backgroundColor: "#E80D0D",
                                    //         color: "#ffffff",
                                    //     });
                                    // }
                                    // else {
                                    //     this.setState({ showConfirmEnableDisable: true })
                                    // }
                                    this.setState({ showConfirmEnableDisable: true })
                                }}>
                                Deshabilitar
                            </Button>
                        </div>
                        : null
                }

                <SweetAlert
                    show={this.state.showConfirmEnableDisable}
                    success
                    confirmBtnText="Aceptar"
                    confirmBtnBsStyle="light"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="light"
                    cancelBtnStyle={{ fontSize: 14 }}
                    style={{ color: "#000", fontSize: 14 }}
                    confirmBtnStyle={{ fontSize: 14 }}
                    title={"Deshabilitar Usuarios"}
                    onConfirm={() => this.handleEnableDisable()}
                    onCancel={() => this.setState({ showConfirmEnableDisable: false })}
                    showCancel={true}
                    showConfirm={true}
                >
                    <span>{"¿Estás seguro que desea deshabilitar estos usuarios?"}</span>
                </SweetAlert>
            </div>
        )
    }
}



export default ManeuversTableResults;