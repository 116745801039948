/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { actionUsuarioLogin } from 'src/actions/api/user';

import { actionCompaniesLista } from 'src/actions/api/companies';

import { actionLimpiarEstado } from 'src/actions';

import {
  actionExecutivesLista,
  actionExecutivesActualizar,
  actionExecutivesCrear,
  actionExecutivesDetalle,
  actionExecutivesHabilitarDeshabilitar
} from 'src/actions/api/executives';

import { actionRolesLista } from 'src/actions/api/roles';

import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import UserListResults from 'src/components/user/UserListResults';
import UserListToolbar from 'src/components/user/UserListToolbar';
import Loading from 'src/components/Loading';
import FrameCreateUser from 'src/components/FrameCreateUser';
import moment from 'moment';

import SweetAlert from 'react-bootstrap-sweetalert';

import { ToastContainer, toast } from 'react-toastify';

import { MsalContext } from '@azure/msal-react';

class UsersList extends Component {
  static contextType = MsalContext;
  constructor(props) {
    super(props);
    this.state = {
      openDialogEdit: false,
      idToEdit: '',
      textFilter: '',
      dataUsersCopy: [],
      dataUsers: [],
      showCreate: false,
      update: false,
      showDelete: false,
      showMessageNotCreateUser: false
    };
  }

  async componentDidMount() {
    this.callList();
    this.getUserEmail();

    this.props.actionCompaniesLista({ active: true });

    this.props.actionRolesLista();
  }

  componentDidUpdate(preprops) {
    if (this.props.apiRespuesta) {
      this.props.actionLimpiarEstado();
    }
    if (this.props.apiRespuesta) {
      switch (this.props.estado) {
        case 'LISTADO':
          this.setState({
            dataUsersCopy: this.props.dataExecutives,
            dataUsers: this.props.dataExecutives
          });
          break;
        case 'ACTUALIZADO':
          toast.success('El usuario se ha actualizado exitosamente', {
            backgroundColor: '#1ABD24',
            color: '#ffffff'
          });
          this.executeClose();
          break;
        case 'CREADO':
          toast.success('El usuario se ha creado exitosamente', {
            backgroundColor: '#1ABD24',
            color: '#ffffff'
          });
          this.executeClose();
          break;

        case 'HABILITADO_DESHABILITADO':
          toast.success('Usuario eliminado exitosamente', {
            backgroundColor: '#1ABD24',
            color: '#ffffff'
          });
          this.executeClose();
          break;
        case 'ERROR':
          toast.error(this.props.error, {
            backgroundColor: '#E80D0D',
            color: '#ffffff'
          });
          //this.callList();
          break;
        default:
          break;
      }
      this.props.actionLimpiarEstado();
    }

    if (this.props.apiRespuestaDataUser) {
      switch (this.props.estadoDataUser) {
        case 'DETALLE':
          localStorage.setItem(
            'setUserDataProp',
            JSON.stringify({
              ...this.props.dataUserLog,
              user: this.props.dataUserLog.name,
              profile: 1
            })
          );
          // document.addEventListener("visibilitychange", () => {
          //   if (document.visibilityState === 'visible') {
          //     this.getNotificationById()
          //   }
          // });
          break;
        case 'ERROR':
          if (this.props.error === 'El usuario no existe.') {
            window.location.href = '/401';
          }
          break;
        default:
          break;
      }
    }
  }

  getUserEmail = async () => {
    const msalAccounts = this.context.accounts;
    const getTokenLocalStorage = await localStorage.getItem('fcmToken');
    this.props.actionUsuarioLogin({
      email: msalAccounts[0].username
      //Token: getTokenLocalStorage
    });
  };

  openEdit = (id) => {
    this.setState({
      showCreate: true,
      idToEdit: id,
      update: true
    });
    this.props.actionExecutivesDetalle({ id: id });
  };

  // closeDialog = () => {
  //   this.setState({
  //     openDialogEdit: false
  //   });
  // }

  executeClose = async () => {
    this.setState(
      {
        showCreate: false,
        idToEdit: '',
        textFilter: '',
        update: false
      },
      () => {
        this.callList();
      }
    );
  };

  updateExecutive = () => {
    this.props.actionExecutivesDetalle({ id: this.state.idToEdit });
    this.callList();
  };

  handleChange = async (e) => {
    const textData = e.toUpperCase();
    const newData = await this.state.dataUsers.filter((item) => {
      return (
        item.name.toUpperCase().indexOf(textData) > -1 ||
        item.email.toUpperCase().indexOf(textData) > -1 ||
        item.phone.toUpperCase().indexOf(textData) > -1 ||
        item.client.toUpperCase().indexOf(textData) > -1 ||
        item.role.toUpperCase().indexOf(textData) > -1
      );
    });
    this.setState({ dataUsersCopy: newData, textFilter: e });
  };

  // exportToExcel = async () => {
  //   const filterData = this.state.dataUsers.map((item) =>
  //   ({
  //     item.name, email, profile, department
  //   }
  //   ));
  //   const filterData = this.state.dataUsers.map(item => {
  //     return {
  //       //name: item.name,
  //       email: item.email,
  //       //profile: item.profile,
  //       //department: item.department.slice(0, -1)
  //     }
  //   })

  //   const Heading = [["Correo"]];
  //   const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  //   const ws = XLSX.utils.json_to_sheet(filterData, { origin: 'A2', skipHeader: true });
  //   XLSX.utils.sheet_add_aoa(ws, Heading);
  //   const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  //   const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  //   const data = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(data, "Usuarios.xlsx");
  // }

  handleEnableDisable = async (data) => {
    await this.props.actionExecutivesHabilitarDeshabilitar(data);
  };

  showCreate = () => {
    this.setState({
      showCreate: !this.state.showCreate,
      update: false
    });
  };

  deleteItem = () => {
    this.setState({
      showDelete: true
    });
  };

  confirmDelete = async () => {
    //console.log("id to delete: ", this.state.idToEdit);
    await this.props.actionExecutivesHabilitarDeshabilitar({
      id: this.state.idToEdit
    });
    this.setState({
      showDelete: false
    });
  };

  callList = async () => {
    //console.log('id: ', this.props.dataUserLog.client_id);
    const user = await localStorage.getItem('setUserDataProp');
    const userParse = JSON.parse(user);
    //console.log("usuario:", userParse);
    if (user) {
      this.props.actionExecutivesLista({
        active: true,
        client: userParse.client_id,
        is_admin: userParse.is_admin
      });
    }

    // this.props.actionExecutivesLista({
    //   active: true,
    //   client: this.props.dataUserLog.client_id,
    //   is_admin: this.props.dataUserLog.is_admin
    // });
  };
  render() {
    return (
      <>
        <Helmet>
          <title>Usuarios</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          {this.state.showCreate ? (
            <Container maxWidth={false}>
              <FrameCreateUser
                {...this.props}
                dataDetail={this.props.dataUserDetail}
                CloseDialog={() => this.showCreate()}
                Update={this.state.update}
                DeleteItem={() => this.deleteItem()}
              />
            </Container>
          ) : (
            <Container maxWidth={false}>
              <UserListToolbar
                {...this.props}
                filter={this.state.textFilter}
                actionShowFrameCreate={() => {
                  if (
                    this.props.dataExecutives.length >=
                      this.props.dataUserLog.limit_user &&
                    this.props.dataUserLog.is_admin === false
                  ) {
                    this.setState({
                      showMessageNotCreateUser: true
                    });
                  } else {
                    this.showCreate();
                  }
                }}
                exportToExcel={() => this.exportToExcel()}
                handleChange={(e) => this.handleChange(e)}
              />
              <Box sx={{ pt: 3 }}>
                {this.props.dataExecutives ? (
                  <UserListResults
                    busy={this.props.busy}
                    estado={this.props.estado}
                    OpenEdit={(id) => this.openEdit(id)}
                    onEnableDisable={this.handleEnableDisable}
                    Customers={
                      this.state.dataUsersCopy
                        ? this.state.dataUsersCopy.map((item) => {
                            return {
                              ...item,
                              insertDate: moment(item.created_at).format(
                                'DD/MM/YYYY HH:mm'
                              ),
                              updatedDate: moment(item.updated_at).format(
                                'DD/MM/YYYY HH:mm'
                              ),
                              id: item.public_key,
                              confirmedEmail: item.confirmed_email
                                ? 'Si'
                                : 'No',
                              isActive: item.is_active ? 'Si' : 'No',
                              isAdmin: item.is_admin ? 'Si' : 'No'
                            };
                          })
                        : []
                    }
                  />
                ) : null}
              </Box>
            </Container>
          )}
        </Box>
        <SweetAlert
          danger
          show={this.state.showDelete}
          showCancel
          confirmBtnText="Aceptar"
          confirmBtnBsStyle="light"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="light"
          cancelBtnStyle={{ fontSize: 14 }}
          style={{ color: '#000', fontSize: 14 }}
          confirmBtnStyle={{ fontSize: 14 }}
          onConfirm={() => this.confirmDelete()}
          onCancel={() => this.setState({ showDelete: false })}
        >
          <span>{'¿Está seguro de eliminar este Usuario?'}</span>
        </SweetAlert>

        <SweetAlert
          danger
          show={this.state.showMessageNotCreateUser}
          confirmBtnText="Aceptar"
          confirmBtnBsStyle="light"
          // cancelBtnText="Cancelar"
          // cancelBtnBsStyle="light"
          // cancelBtnStyle={{ fontSize: 14 }}
          style={{ color: '#000', fontSize: 14 }}
          confirmBtnStyle={{ fontSize: 14 }}
          onConfirm={() => this.setState({ showMessageNotCreateUser: false })}
        >
          <span>{'Se ha superado el límite de usuarios de esta empresa'}</span>
        </SweetAlert>

        <Loading busy={this.props.busy} />
        <ToastContainer position={'top-right'} delay={5000} />
      </>
    );
  }
}

const mapStateToProps = ({
  executives,
  executiveDetail,
  companies,
  roles,
  user
}) => ({
  dataExecutives: executives.items,
  estado: executives.estado,
  apiRespuesta: executives.apiRespuesta,

  busy:
    executives.waiting ||
    executiveDetail.waiting ||
    companies.waiting ||
    roles.waiting ||
    user.waiting,
  error:
    executives.error ||
    executiveDetail.error ||
    companies.error ||
    roles.error ||
    user.error,

  dataUserDetail: executiveDetail.items,

  dataCompanies: companies.items,
  dataRoles: roles.items,
  dataUserLog: user.items,
  estadoDataUser: user.estado,
  apiRespuestaDataUser: user.apiRespuesta
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      actionCompaniesLista,

      actionRolesLista,
      actionUsuarioLogin,

      actionExecutivesLista,
      actionExecutivesActualizar,
      actionExecutivesCrear,
      actionExecutivesDetalle,

      actionLimpiarEstado,
      actionExecutivesHabilitarDeshabilitar
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
