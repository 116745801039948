/* eslint-disable */
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import Account from 'src/pages/Account';

import CompanyList from 'src/pages/CompanyList';
import ContactList from 'src/pages/ContactList';
import CameraList from 'src/pages/CameraList';

import VideoList from 'src/pages/VideoList';

import UsersList from 'src/pages/UsersList';
import DetectorsList from 'src/pages/DetectorsList';

import Dashboard from 'src/pages/Dashboard';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound.jsx';
import NotPermissions from 'src/pages/NotPermissions.jsx';

import MainLayout from 'src/components/MainLayout';

import Grafana from './pages/Grafana';

import Eventos from './pages/Eventos';

import Register from 'src/pages/Register.jsx';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'companies', element: <CompanyList /> },
      { path: 'cameras', element: <CameraList /> },
      { path: 'contacts', element: <ContactList /> },
      { path: 'users', element: <UsersList /> },
      { path: 'videos', element: <VideoList /> },
      { path: 'detectors', element: <DetectorsList /> },
      { path: 'grafana', element: <Grafana /> },
      { path: 'eventos', element: <Eventos /> },

      { path: 'dashboard', element: <Dashboard /> },

      { path: '*', element: <Navigate to="/404" /> },
      { path: '+', element: <Navigate to="/401" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },

      { path: '401', element: <NotPermissions /> },
      { path: '+', element: <Navigate to="/404" /> },

      { path: '/', element: <Navigate to="/app/dashboard" /> },

      { path: '404', element: <NotFound /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
