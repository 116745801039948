/* eslint-disable */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { deepOrange, deepPurple, blue } from '@material-ui/core/colors';

import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import CampaignIcon from '@mui/icons-material/Campaign';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PostAddOutlinedIcon from '@material-ui/icons/PostAddOutlined';
import VideoCameraFrontIcon from '@material-ui/icons/VideoCameraFront';
import MemoryIcon from '@mui/icons-material/Memory';

import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';

import NavItem from './NavItem';
import Username from './Username';

import { adminList } from 'src/api/commons';

const userAvatar = {
  avatar: '/static/images/avatars/newlogo.svg',
  jobTitle: 'Ejecutivo',
  name: 'Patricia Gomez'
};

const initialMenu = [
  {
    href: '/app/dashboard',
    icon: DashboardIcon,
    title: 'Analítica',
    key: 'Analitica'
  }
];

const drones = [
  {
    href: '/app/videos',
    icon: SlowMotionVideoIcon,
    title: 'Videos Pregrabados',
    key: 'videos'
  }
];

const camarasFijas = [
  {
    href: '/app/cameras',
    icon: VideoCameraFrontIcon,
    title: 'Cámaras Fijas',
    key: 'camaras'
  }
];

const usuarios = [
  {
    href: '/app/users',
    icon: PeopleAltOutlinedIcon,
    title: 'Usuarios',
    key: 'usuarios'
  }
];

const detectores = [
  {
    href: '/app/detectors',
    icon: MemoryIcon,
    title: 'Detectores',
    key: 'detectores'
  }
];

const empresas = [
  {
    href: '/app/companies',
    icon: BusinessOutlinedIcon,
    title: 'Empresas',
    key: 'empresas'
  }
];

const grafana = [
  {
    href: '/app/grafana',
    icon: PostAddOutlinedIcon,
    title: 'Grafana',
    key: 'grafana'
  }
];
const eventos = [
  {
    href: '/app/eventos',
    icon: CampaignIcon,
    title: 'Eventos',
    key: 'eventos'
  }
];

// const items = [
//   {
//     href: '/app/dashboard',
//     icon: DashboardIcon,
//     title: 'Dashboard'
//   },
//   // {
//   //   href: '/app/requests',
//   //   icon: MenuBookOutlinedIcon,
//   //   title: 'Solicitudes',
//   //   typeMenu: 'subMenu',
//   //   sub: [
//   //     {
//   //       href: '/app/requests',
//   //       icon: MenuBookOutlinedIcon,
//   //       title: 'General',
//   //     },

//   //     {
//   //       href: '/app/requestsSolic',
//   //       icon: MenuBookOutlinedIcon,
//   //       title: 'Solicitudes',
//   //     },

//   //     {
//   //       href: '/app/requestsProyect',
//   //       icon: MenuBookOutlinedIcon,
//   //       title: 'Proyectos',
//   //     },

//   //     {
//   //       href: '/app/requestsEstable',
//   //       icon: MenuBookOutlinedIcon,
//   //       title: 'Estables',
//   //     },
//   //   ]
//   // },
//   {
//     href: '/app/contacts',
//     icon: ContactsIcon,
//     title: 'Contactos'
//   },
//   // {
//   //   href: '/app/companies',
//   //   icon: BusinessOutlinedIcon,
//   //   title: 'Empresa'
//   // },
//   {
//     href: '/app/cameras',
//     icon: VideoCameraFrontIcon,
//     title: 'Cámaras'
//   },
//   // {
//   //   href: '/app/activities',
//   //   icon: TimelineOutlinedIcon,
//   //   title: 'Actividades'
//   // },
//   {
//     href: '/app/users',
//     icon: PeopleAltOutlinedIcon,
//     title: 'Usuarios'
//   },
//   //,
//   // {
//   //   href: '/app/AnalyticSettings',
//   //   icon: SettingsOutlinedIcon,
//   //   title: 'Configuración analítica'
//   // },
//   // {
//   //   href: '/app/reports',
//   //   icon: PostAddOutlinedIcon,
//   //   title: 'Reportes'
//   // }
// ];
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500]
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500]
  },
  blue: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500]
  }
}));

const DashboardSidebar = ({ onMobileClose, openMobile, dataUser }) => {
  const [user, setUser] = useState({ profile: '', initials: '' });
  const [menu, setMenu] = useState([...initialMenu]);
  const [userProfile, setUserProfile] = useState('');
  const location = useLocation();

  const classes = useStyles();

  const getUserData = async () => {
    setMenu(initialMenu);
    var userDataParse = await { ...dataUser };

    // var nameSync = await userDataParse.name.split(" ")[0] + " " + userDataParse.name.split(" ")[1];
    // var fullName = await nameSync.split(' ');
    // var initials = await fullName.shift().charAt(0) + fullName.pop().charAt(0);
    // //const userProfile = await userDataParse.profile;
    // //setUserProfile(userDataParse.role);
    // initials.toUpperCase();
    var menuByProfile = [...menu];

    userDataParse.is_admin === true //is admin
      ? await menuByProfile.push(
          ...empresas,
          ...usuarios,
          ...drones,
          ...camarasFijas,
          ...detectores,
          // ...grafana,
          // ...eventos
        )
      : userDataParse.role === adminList[0] //administrador
      ? await menuByProfile.push(...usuarios)
      : null;

    if (userDataParse.permissions) {
      userDataParse.permissions.includes('Videos pregrabados') &&
      !userDataParse.is_admin
        ? await menuByProfile.push(...drones)
        : null;

      userDataParse.permissions.includes('Cámaras fijas') &&
      !userDataParse.is_admin
        ? await menuByProfile.push(...camarasFijas)
        : null;
    }
    // userDataParse.profile === 1
    //   ? await menuByProfile.push(...supervisorMenu, ...adminMenu)
    //   : userDataParse.profile === 2
    //     ? await menuByProfile.push(...supervisorMenu)
    //     : null
    await setMenu([...menuByProfile]);
    setUserProfile(userDataParse.role);
    //setUser({ ...userDataParse, initials: initials })
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    setMenu(initialMenu);
    getUserData();
  }, []);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '60%',
        position: 'relative',
        zIndex: 2
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        {/* <Avatar
          sx={{
            cursor: 'pointer',
            width: 50,
            height: 50
          }}
          to=""
          src={userAvatar.avatar}
          className={classes.blue}/>
            //{user.initials}</Avatar> */}
        <img
          src={userAvatar.avatar}
          alt="Logo"
          style={{
            width: 170,
            height: 90,
            filter: 'invert(1)'
          }}
        />
        <Typography color="textPrimary" variant="h5">
          {dataUser.name}
          {/* <Username dataUser = {dataUser}/> */}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {/* {userProfile === adminList[0] ? 'Administrador' : 'Operario'} */}
          {dataUser.is_admin ? 'Administrador' : 'Operario'}
          {/* {userProfile === 1
            ? 'Administrador'
            : userProfile === 2
            ? 'Supervisor'
            : 'Operario'} */}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {menu.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              typeMenu={item.typeMenu}
              sub={item.sub}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default DashboardSidebar;
