/* eslint-disable */
import React, { Component } from 'react';

import { Box, Container } from '@material-ui/core';

import Button from '@material-ui/core/Button';

import styles from './styles';

import ReactFormInputValidation from 'react-form-input-validation';
import FieldFormTemplate from '../FieldFormTemplate';
import DropDownWithSearchFormTemplate from 'src/components/DropDownWithSearchFormTemplate';
import DropDownFormTemplate from 'src/components/DropDownFormTemplate';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

class Localization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        name:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.name
            : this.props.objStep1.name
            ? this.props.objStep1.name
            : '',

        route:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.route
            : this.props.objStep1.route
            ? this.props.objStep1.route
            : '',

        street:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.street
            : this.props.objStep1.street
            ? this.props.objStep1.street
            : '',

        kilometer:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.kilometer
            : this.props.objStep1.kilometer
            ? this.props.objStep1.kilometer
            : '',

        lat:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.lat
            : this.props.objStep1.lat
            ? this.props.objStep1.lat
            : '',

        lng:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.lng
            : this.props.objStep1.lng
            ? this.props.objStep1.lng
            : '',

        detector_id:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.detector_id
            : this.props.objStep1.detector_id
            ? this.props.objStep1.detector_id
            : null
      },
      errors: {}
    };
    this.form = new ReactFormInputValidation(this, { locale: 'es' });
    this.form.onformsubmit = async (fields) => {
      const objToSendLocalization = {
        ...this.state.fields,
        // created_user: this.props.dataUserLog.public_key,
        initials: '',
        client_id:
          this.props.Update === true
            ? this.props.dataUserLog.client_id
            : this.props.dataUserLog.is_admin === true
            ? this.props.companieId
            : this.props.dataUserLog.client_id
      };

      this.props.NextStep({
        step: 1,
        completed: true,
        fields: objToSendLocalization
      });
      if (this.props.Update === true) {
        // this.props.actionExecutivesActualizar({
        //     ...objToSend,
        //     id: this.props.dataDetail.public_key,
        //     //updatedBy: "A40AF516-4209-4C5A-8EF0-D28328AFA10F"
        // });
      } else {
        //this.props.actionExecutivesCrear({ ...objToSend });
      }
    };
  }

  componentDidUpdate(preprops) {
    if (this.props.dataDetail !== preprops.dataDetail) {
      this.setDataFields();
    }
  }

  async componentDidMount() {
    this.form.useRules({
      name: 'required',
      route: 'required',
      street: 'required',
      kilometer: 'required',
      lat: 'required|numeric',
      lng: 'required|numeric',
      detector_id: 'sometimes'
    });
  }

  setDataFields = () => {
    this.setState({
      fields: {
        name:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.name
            : this.props.objStep1.name
            ? this.props.objStep1.name
            : '',

        route:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.route
            : this.props.objStep1.route
            ? this.props.objStep1.route
            : '',

        street:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.street
            : this.props.objStep1.street
            ? this.props.objStep1.street
            : '',

        kilometer:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.kilometer
            : this.props.objStep1.kilometer
            ? this.props.objStep1.kilometer
            : '',

        lat:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.lat
            : this.props.objStep1.lat
            ? this.props.objStep1.lat
            : '',

        lng:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.lng
            : this.props.objStep1.lng
            ? this.props.objStep1.lng
            : '',

        detector_id:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.detector_id
            : this.props.objStep1.detector_id
            ? this.props.objStep1.detector_id
            : null
      }
    });
  };

  render() {
    let selected = null;

    if (this.state.fields) {
      selected = this.props.detectors.items.find(
        (element) => element.id == this.state.fields.detector_id
      );
    }

    const newDetectors = this.props.detectors.items.filter(
      (element) => element !== selected
    );
    if (selected) {
      newDetectors.unshift(selected);
    }

    const options = newDetectors.map((element) => {
      return <option value={element.public_key}>{element.name}</option>;
    });
    if (!this.state.fields.detector_id || !this.props.Update) {
      options.unshift(<option value={null}>Seleccionar detector</option>);
    }

    const select = this.props.dataUserLog.is_admin ? ( //quitar el ! cuando acabe pruebas
      <div className="d-flex justify-content-center">
        <FieldFormTemplate
          label="Detector "
          classCol="col-6 col-md-6"
          field={
            <select
              maxLength={65}
              type="text"
              name="detector_id"
              onBlur={this.form.handleBlurEvent}
              onChange={this.form.handleChangeEvent}
              value={this.state.fields.detector_id}
              data-attribute-name="Detector_id"
              style={styles.inputStyle}
              placeholder={'Id detector'}
            >
              {options}
            </select>
          }
          error={this.state.errors.detector_id}
        />
      </div>
    ) : (
      ''
    );
    return (
      // <Dialog
      //     fullWidth={true}
      //     maxWidth={"md"}
      //     open={this.props.open}
      //     onClose={() => this.props.CloseDialog()}
      //     aria-labelledby="form-dialog-title">
      //     <DialogTitle id="form-dialog-title">{this.props.Update ? "Actualizar" : "Crear"} Contacto</DialogTitle>
      //     <DialogContent>
      <>
        <Box
          sx={{
            backgroundColor: '#fff',
            minHeight: '100%',
            py: 3,
            marginTop: 0
          }}
        >
          <Container maxWidth={false}>
            <Box
              display="flex"
              flexDirection="row"
              width={'100%'}
              overflow={'hidden'}
            >
              <form onSubmit={this.form.onformsubmit} style={{ width: '100%' }}>
                <div className="d-flex justify-content-center">
                  <FieldFormTemplate
                    label="Nombre de la Cámara *"
                    classCol="col-6 col-md-6"
                    field={
                      <input
                        maxLength={65}
                        type="text"
                        name="name"
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        value={this.state.fields.name}
                        data-attribute-name="Nombre de la Cámara "
                        style={styles.inputStyle}
                        placeholder={'Nombre de la Cámara'}
                      />
                    }
                    error={this.state.errors.name}
                  />
                </div>

                <div className="d-flex justify-content-center">
                  <FieldFormTemplate
                    label="Ruta *"
                    classCol="col-6 col-md-6"
                    field={
                      <input
                        maxLength={65}
                        type="text"
                        name="route"
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        value={this.state.fields.route}
                        data-attribute-name="Ruta "
                        style={styles.inputStyle}
                        placeholder={'Nombre de la ruta'}
                      />
                    }
                    error={this.state.errors.route}
                  />
                </div>

                <div className="d-flex justify-content-center">
                  <FieldFormTemplate
                    label="Avenida / Calle / Intersección *"
                    classCol="col-6 col-md-6"
                    field={
                      <input
                        maxLength={65}
                        type="text"
                        name="street"
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        value={this.state.fields.street}
                        data-attribute-name="Ubicación de la cámara "
                        style={styles.inputStyle}
                        placeholder={'Ubicación de la cámara'}
                      />
                    }
                    error={this.state.errors.street}
                  />
                </div>

                <div className="d-flex justify-content-center">
                  <FieldFormTemplate
                    label="Kilómetro *"
                    classCol="col-6 col-md-6"
                    field={
                      <input
                        maxLength={65}
                        type="text"
                        name="kilometer"
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        value={this.state.fields.kilometer}
                        data-attribute-name="Kilómetro"
                        style={styles.inputStyle}
                        placeholder={'Kilómetro'}
                      />
                    }
                    error={this.state.errors.kilometer}
                  />
                </div>

                <div className="d-flex justify-content-center">
                  <FieldFormTemplate
                    label="Latitud *"
                    classCol="col-6 col-md-6"
                    field={
                      <input
                        maxLength={65}
                        type="number"
                        name="lat"
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        value={this.state.fields.lat}
                        data-attribute-name="Latitud"
                        style={styles.inputStyle}
                        placeholder={'Coordenadas de Latitud'}
                      />
                    }
                    error={this.state.errors.lat}
                  />
                </div>

                <div className="d-flex justify-content-center">
                  <FieldFormTemplate
                    label="Longitud *"
                    classCol="col-6 col-md-6"
                    field={
                      <input
                        maxLength={65}
                        type="number"
                        name="lng"
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        value={this.state.fields.lng}
                        data-attribute-name="Longitud"
                        style={styles.inputStyle}
                        placeholder={'Coordenadas de Longitud'}
                      />
                    }
                    error={this.state.errors.lng}
                  />
                </div>
                {select}

                <Box
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    endIcon={<ArrowForwardIcon />}
                    style={{
                      outline: 'none',
                      backgroundColor: '#0099ff',
                      marginLeft: '30px'
                    }}
                  >
                    Siguiente
                  </Button>
                </Box>
              </form>
            </Box>
          </Container>
        </Box>
      </>
    );
  }
}

export default Localization;
