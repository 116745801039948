/* eslint-disable */
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import { Bold, Search as SearchIcon } from 'react-feather';

import React, { Component } from 'react';

import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import DropDownWithSearchFormTemplate from 'src/components/DropDownWithSearchFormTemplate';

const title = "Videos Pregrabados";

class VideoListToolbar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      openDialogCreate: false,
      title: this.props.title,
      selectCompanies: {
        value: "",
        label: ""
      },
      dataCompanies: []
    }
  }

  componentDidMount () {
    this.setState({
      selectCompanies: {
        value: this.props.companieId ? this.props.companieId : "",
        label: this.props.companieName ? this.props.companieName : "",
      },
    })
  }

  openCreate = () => {
    // this.setState({
    //   openDialogCreate: true
    // });
    this.props.actionShowFrameCreate();
    //this.props.actionRequestsDetalle({ id: id });

  }

  closeDialogCreate = () => {
    this.setState({
      openDialogCreate: false
    });
  }

  selectDropdownSearch = (data) => {
    this.setState({
      selectCompanies: data,
      errorCompanies: ""
    }, () => {
      this.setCreate()
    });
  }

  setCreate = () => {
    this.props.filterByCompanie(this.state.selectCompanies);
  }

  componentDidUpdate(preprops){
    if (this.props.dataCompanies !== undefined){
      if((this.props.dataCompanies !== preprops.dataCompanies)){
        this.updateCompanies()
      }
    }
  }

  updateCompanies = async() => {
    const dataCompaniesArr = []; 
    dataCompaniesArr.push({
      name: "Ver todo",
      public_key: "",
    })
    this.props.dataCompanies.map((item) => {
      dataCompaniesArr.push(
        {
          ...item,
          label: item.name,
          value: item.public_key,
        }
      );
    });

    this.setState({
      dataCompanies: dataCompaniesArr
    })
  }

  render() {
    return (
      <Box {...this.props}>

        <Box
          sx={{
            display: 'flex',
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between"
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start'
            }}
          >
            <div style={{
              fontFamily: "sans-serif",
              fontSize: 18,
              fontWeight: "bold"
            }}>{title}</div>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: "row",
              alignItems: "center",
              justifyContent: 'flex-end'
            }}
          >
            {/* <Button sx={{ mx: 1 }} onClick={() => this.props.exportToExcel()}>
              Exportar
            </Button> */}
            <Button
              style={{ backgroundColor: '#0099ff' }}
              color="primary"
              variant="contained"
              onClick={this.openCreate}>
              Agregar {title}
            </Button>
          </Box>
        </Box>
        <Box sx={{ mt: 3, }} >
          <Card style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "center" }}>

            <CardContent style={{ width: "50%" }}>
              <Box sx={{ width: "100%" }}>
                <TextField
                  fullWidth
                  onChange={(e) => this.props.handleChange(e.target.value)}
                  value={this.props.filter}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                        >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  placeholder={`Buscar ${title}`}
                  variant="outlined"
                />
              </Box>
            </CardContent>

            {(this.props.dataCompanies && this.props.dataUserLog.is_admin === true) ?
              <CardContent style={{ width: "35%", marginRight: 80 }}>
                <Box
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    display: "flex",
                    width: "100%",
                  }}>

                  <DropDownWithSearchFormTemplate
                    label="Filtrar por empresa *"
                    className="col-12 col-md-12"
                    value={this.state.selectCompanies}
                    options={
                      this.state.dataCompanies.map((item) => {
                        return {
                          ...item,
                          label: item.name,
                          value: item.public_key,
                        }
                      })
                    }
                    name="companies"
                    error={this.state.errorCompanies}
                    select={(args) => this.selectDropdownSearch(args)}
                  />


                  <Button
                    style={{ backgroundColor: '#0099ff', marginTop: 22 }}
                    color="primary"
                    variant="contained"
                    onClick={() => this.setCreate()}>
                    <FilterAltOutlinedIcon />
                  </Button>

                </Box>
              </CardContent>
              : null}
          </Card>
        </Box>
      </Box>
    )
  }
};

export default VideoListToolbar;
