/* eslint-disable */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
    actionGatesVideoInicia,
    actionGatesVideoListado,
    actionGatesVideoError,

    actionGatesVideoCreada,
    actionGatesVideoHabilitadaDeshabilitada,
    actionGatesVideoClear
} from "src/actions/api/gatesVideo";

import { 
    apiGatesVideoList,    
    apiGatesVideoCrear,
    apiGatesVideoHabilitarDeshabilitar
} from "src/api/gatesVideo";

import { 
    GATES_VIDEO_LISTA,
    GATES_VIDEO_CREAR,
    GATES_VIDEO_HABILITAR_DESHABILITAR,
    GATES_VIDEO_CLEARED
} from 'src/constants/api/gates';

function* sagaGatesLista({ type, params }) {
  try {        
    yield put(actionGatesVideoInicia());
    const data = yield call(apiGatesVideoList, params);
    yield put(actionGatesVideoListado(data));
  } catch (e) {
    yield put(actionGatesVideoError(e));    
  }
}


function* sagaGatesCrear(action) {
  try {       
    yield put(actionGatesVideoInicia());    
    const resp = yield call(apiGatesVideoCrear, action.data);
    yield put(actionGatesVideoCreada(resp));
  } catch (e) {
    yield put(actionGatesVideoError(e));    
  }
}

function* sagaGatesHabilitarDeshabilitar(action) {
  try {
    yield put(actionGatesVideoInicia());
    const resp = yield call(apiGatesVideoHabilitarDeshabilitar, action.data);
    yield put(actionGatesVideoHabilitadaDeshabilitada(resp));
  } catch (e) {
    yield put(actionGatesVideoError(e));
  }
}

function* sagaGatesClear(action) {
  try {
    yield put(actionGatesVideoInicia());
    //const resp = yield call(null, {});
    yield put(actionGatesVideoClear({}));
  } catch (e) {
    yield put(actionGatesVideoError(e));
  }
}

export default  [
  takeEvery(GATES_VIDEO_LISTA, sagaGatesLista), 
  takeEvery(GATES_VIDEO_CREAR, sagaGatesCrear),
  takeEvery(GATES_VIDEO_HABILITAR_DESHABILITAR, sagaGatesHabilitarDeshabilitar),
  takeEvery(GATES_VIDEO_CLEARED, sagaGatesClear),
]
