/* eslint-disable */
import {
  DETECTORS_INICIA,
  DETECTORS_LISTA,
  DETECTORS_LISTADO,
  DETECTORS_ACTUALIZAR,
  DETECTORS_ACTUALIZADA,
  DETECTORS_CREAR,
  DETECTORS_CREADA,
  DETECTOR_DETALLE,
  DETECTOR_DETALLADO,
  DETECTOR_DETALLE_INICIA,
  DETECTORS_HABILITAR_DESHABILITAR,
  DETECTORS_HABILITADA_DESHABILITADA,
  DETECTORS_ERROR
} from 'src/constants/api/detectors';

export const actionDetectorsInicia = () => ({ type: DETECTORS_INICIA });
export const actionDetectorsLista = () => ({ type: DETECTORS_LISTA });
export const actionDetectorsListado = (data) => ({
  type: DETECTORS_LISTADO,
  data
});

export const actionDetectorsActualizar = (data) => ({
  type: DETECTORS_ACTUALIZAR,
  data
});
export const actionDetectorsActualizada = (params) => ({
  type: DETECTORS_ACTUALIZADA,
  params
});

export const actionDetectorsCrear = (data) => ({ type: DETECTORS_CREAR, data });
export const actionDetectorsCreada = (params) => ({
  type: DETECTORS_CREADA,
  params
});

export const actionDetectorsDetalle = (params) => ({
  type: DETECTOR_DETALLE,
  params
});
export const actionDetectorsDetallado = (data) => ({
  type: DETECTOR_DETALLADO,
  data
});
export const actionDetectorsDetalleInicia = () => ({
  type: DETECTOR_DETALLE_INICIA
});

export const actionDetectorsHabilitarDeshabilitar = (data) => ({
  type: DETECTORS_HABILITAR_DESHABILITAR,
  data
});
export const actionDetectorsHabilitadaDeshabilitada = (params) => ({
  type: DETECTORS_HABILITADA_DESHABILITADA,
  params
});

export const actionDetectorsError = (error) => ({
  type: DETECTORS_ERROR,
  error
});
