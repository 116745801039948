/* eslint-disable */
import { URL_API_PERMISSIONS } from './endpoints'

import {
  DEFAULT_HEADERS,
  apiPeticionExitosa,
  apiErrorHandler,
  userAuthToken,
  
} from './commons';

export const apiPermissionsList = async params => {
  //var query = params.estado ? `?estado=${[params.estado]}` : '';
  return await fetch(
    //`${URL_API_PERMISSIONS}${query}`,
    URL_API_PERMISSIONS,
    Object.assign({ method: "GET" },
      {
        headers: new Headers({
          ...DEFAULT_HEADERS,
          Authorization: userAuthToken(),
        })
      }
    )
  ).then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}
