/* eslint-disable */
import React from 'react';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';

import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import { MsalProvider, AuthenticatedTemplate } from '@azure/msal-react';

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
} else {
  msalInstance
    .handleRedirectPromise()
    .then((tokenResponse) => {
      if (!tokenResponse) {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length === 0) {
          //           // No user signed in
          msalInstance.loginRedirect();
        }
      } else {
        // Do something with the tokenResponse
      }
    })
    .catch(() => {
      // Handle error
      console.error('An error occurred. Please try again later.');
    });
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  } else {
    //debugger;
  }
});

ReactDOM.render(
  <BrowserRouter>
    <MsalProvider instance={msalInstance}>
      <AuthenticatedTemplate>
        <App />
      </AuthenticatedTemplate>
    </MsalProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.unregister();
