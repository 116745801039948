/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actionUsuarioLogin } from 'src/actions/api/user';

import { actionCompaniesLista } from 'src/actions/api/companies';

import {
  actionGatesVideoLista,
  actionGatesVideoCrear,
  actionGatesVideoHabilitarDeshabilitar,
  actionGatesVideoClear
} from 'src/actions/api/gatesVideo';

import {
  actionManeuversLista,
  actionManeuversCrear,
  actionManeuversHabilitarDeshabilitar,
  actionManeuversClear
} from 'src/actions/api/maneuvers';

import { actionLimpiarEstado } from 'src/actions';

import {
  actionExecutivesLista,
  actionExecutivesActualizar,
  actionExecutivesCrear,
  actionExecutivesDetalle,
  actionExecutivesHabilitarDeshabilitar
} from 'src/actions/api/executives';

import { actionRolesLista } from 'src/actions/api/roles';

import {
  actionVideosLista,
  actionVideosUpload,
  actionVideosHabilitarDeshabilitar,
  actionVideosClear,
  actionVideosCrear,
  actionVideosDetalle
} from 'src/actions/api/videos';

import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import VideoListResults from 'src/components/video/VideoListResults';
import VideoListToolbar from 'src/components/video/VideoListToolbar';
import Loading from 'src/components/Loading';
import FrameCreateVideo from 'src/components/FrameCreateVideo';
import moment from 'moment';

import SweetAlert from 'react-bootstrap-sweetalert';

import { ToastContainer, toast } from 'react-toastify';

import { MsalContext } from '@azure/msal-react';

class VideoList extends Component {
  static contextType = MsalContext;
  constructor(props) {
    super(props);
    this.state = {
      openDialogEdit: false,
      idToEdit: '',
      textFilter: '',
      dataUsersCopy: [],
      dataUsers: [],
      showCreate: false,
      update: false,
      showDelete: false,
      showMessageNotCreate: false,
      errorUploadVideo: '',
      companieId: '',
      companieName: ''
    };
  }

  async componentDidMount() {
    this.props.actionVideosClear();
    this.props.actionGatesVideoClear();
    this.props.actionManeuversClear();
    setTimeout(() => {
      this.callList();
    }, 1000);
    //this.getUserEmail();
    this.props.actionCompaniesLista({ active: true });
    this.props.actionRolesLista();

    //this.props.actionVideosLista({ id: this.props.dataUserLog.client_id });
  }

  componentDidUpdate(preprops) {
    if (this.props.apiRespuesta) {
      this.props.actionLimpiarEstado();
    }

    if (this.props.apiRespuestaVideo) {
      switch (this.props.estadoVideo) {
        case 'LISTADO':
          this.setState({
            dataUsersCopy: this.props.dataExecutives,
            dataUsers: this.props.dataExecutives
          });
          break;
        case 'HABILITADO_DESHABILITADO':
          toast.success('Video eliminado exitosamente', {
            backgroundColor: '#1ABD24',
            color: '#ffffff'
          });
          this.callList();
          break;
        case 'CREADO':
          toast.success('El video se ha creado exitosamente.', {
            backgroundColor: '#1ABD24',
            color: '#ffffff'
          });
          this.setState({
            errorUploadVideo: ''
          });
          this.getDetailVideo();
          break;

        case 'DETALLADO':
          this.setState({
            errorUploadVideo: ''
          });
          this.getSteps();
          break;

        case 'SUBIDO':
          this.setState({
            errorUploadVideo: ''
          });
          this.videoUpload();
          break;
        case 'ERROR':
          toast.error(this.props.error, {
            backgroundColor: '#E80D0D',
            color: '#ffffff'
          });
          this.setState({
            errorUploadVideo: this.props.error
          });
          break;
        default:
          break;
      }
      this.props.actionLimpiarEstado();
    }
    // dataVideoUploaded: videos.dataUploaded,
    // estadoVideo: videos.estado,
    // apiRespuestaVideo: videos.apiRespuesta,
    if (this.props.apiRespuestaGatesVideo) {
      switch (this.props.estadoGatesVideo) {
        case 'CREADO':
          toast.success('La compuerta se ha creado exitosamente', {
            backgroundColor: '#1ABD24',
            color: '#ffffff'
          });
          this.chargueGatesList();
          break;

        case 'HABILITADO_DESHABILITADO':
          toast.success('La compuerta se ha eliminado exitosamente', {
            backgroundColor: '#1ABD24',
            color: '#ffffff'
          });
          this.chargueGatesList();
          break;
        case 'ERROR':
          toast.error(this.props.error, {
            backgroundColor: '#E80D0D',
            color: '#ffffff'
          });
          //this.callList();
          break;
        default:
          break;
      }
      this.props.actionLimpiarEstado();
    }

    if (this.props.apiRespuestaManeuvers) {
      switch (this.props.estadoManeuvers) {
        case 'CREADO':
          toast.success('La maniobra se ha creado exitosamente', {
            backgroundColor: '#1ABD24',
            color: '#ffffff'
          });
          this.chargueManeuversList();
          break;

        case 'HABILITADO_DESHABILITADO':
          toast.success('La maniobra se ha eliminado exitosamente', {
            backgroundColor: '#1ABD24',
            color: '#ffffff'
          });
          this.chargueManeuversList();
          break;
        case 'ERROR':
          toast.error(this.props.error, {
            backgroundColor: '#E80D0D',
            color: '#ffffff'
          });
          //this.callList();
          break;
        default:
          break;
      }
      this.props.actionLimpiarEstado();
    }
  }

  getUserEmail = async () => {
    const msalAccounts = this.context.accounts;
    const getTokenLocalStorage = await localStorage.getItem('fcmToken');
    this.props.actionUsuarioLogin({
      email: msalAccounts[0].username
      //Token: getTokenLocalStorage
    });
  };

  openEdit = (id) => {
    this.setState({
      idToEdit: id,
      showDelete: true
    });
    //this.props.actionExecutivesDetalle({ id: id });
  };

  // closeDialog = () => {
  //   this.setState({
  //     openDialogEdit: false
  //   });
  // }

  executeClose = async () => {
    this.props.actionVideosClear();
    this.props.actionGatesVideoClear();
    this.props.actionManeuversClear();
    this.setState(
      {
        showCreate: false,
        idToEdit: '',
        textFilter: '',
        update: false
      },
      () => {
        this.callList();
      }
    );
  };

  updateExecutive = () => {
    this.props.actionExecutivesDetalle({ id: this.state.idToEdit });
    this.callList();
  };

  handleChange = async (e) => {
    const textData = e.toUpperCase();
    const newData = await this.state.dataUsers.filter((item) => {
      return (
        item.name.toUpperCase().indexOf(textData) > -1 ||
        item.path.toUpperCase().indexOf(textData) > -1 ||
        item.maneuver.toString().toUpperCase().indexOf(textData) > -1 ||
        item.word.toUpperCase().indexOf(textData) > -1 ||
        item.excel.toUpperCase().indexOf(textData) > -1 ||
        item.gzip.toUpperCase().indexOf(textData) > -1 ||
        item.geometric.toString().toUpperCase().indexOf(textData) > -1 ||
        item.created_at.toUpperCase().indexOf(textData) > -1
      );
    });
    this.setState({ dataUsersCopy: newData, textFilter: e });
  };

  // exportToExcel = async () => {
  //   const filterData = this.state.dataUsers.map((item) =>
  //   ({
  //     item.name, email, profile, department
  //   }
  //   ));
  //   const filterData = this.state.dataUsers.map(item => {
  //     return {
  //       //name: item.name,
  //       email: item.email,
  //       //profile: item.profile,
  //       //department: item.department.slice(0, -1)
  //     }
  //   })

  //   const Heading = [["Correo"]];
  //   const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  //   const ws = XLSX.utils.json_to_sheet(filterData, { origin: 'A2', skipHeader: true });
  //   XLSX.utils.sheet_add_aoa(ws, Heading);
  //   const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  //   const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  //   const data = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(data, "Usuarios.xlsx");
  // }

  handleEnableDisable = async (data) => {
    await this.props.actionExecutivesHabilitarDeshabilitar(data);
  };

  showCreate = () => {
    this.setState({
      showCreate: !this.state.showCreate,
      update: false
    });
  };

  deleteItem = () => {
    this.setState({
      showDelete: true
    });
  };

  confirmDelete = async () => {
    //console.log("id to delete: ", this.state.idToEdit);
    await this.props.actionVideosHabilitarDeshabilitar({
      id: this.state.idToEdit
    });
    this.setState({
      showDelete: false
    });
  };

  callList = async () => {
    this.props.actionCompaniesLista({ active: true });
    //console.log("enviar: ", this.props.dataUserLog)
    if (this.props.dataUserLog.is_admin === true) {
      if (this.state.companieId === '') {
        this.props.actionVideosLista({ id: '' });
      } else {
        this.props.actionVideosLista({ id: this.state.companieId });
      }
    } else {
      this.props.actionVideosLista({ id: this.props.dataUserLog.client_id });
    }

    // this.props.actionExecutivesLista({
    //   active: true,
    //   client: this.props.dataUserLog.client_id,
    //   is_admin: this.props.dataUserLog.is_admin
    // });
  };

  videoUpload = async () => {};

  getDetailVideo = async () => {
    this.props.actionVideosDetalle({
      id: this.props.dataVideoCreated.public_key
    });
  };

  getSteps = async () => {};

  chargueGatesList = () => {
    this.props.actionGatesVideoLista({
      id: this.props.dataVideoCreated.public_key
    });
  };

  chargueManeuversList = () => {
    this.props.actionManeuversLista({
      id: this.props.dataVideoCreated.public_key
    });
  };

  clearError = () => {
    this.setState({
      errorUploadVideo: ''
    });
  };

  getByCompanie = (companie) => {
    this.setState(
      {
        companieId: companie.value,
        companieName: companie.label
      },
      () => {
        if (this.state.companieId !== '') {
          this.props.actionVideosLista({ id: this.state.companieId });
        } else {
          // console.log("Get all videos")
          this.props.actionVideosLista({ id: '' });
          //this.props.actionVideosLista({ id: ""});
          //this.props.actionVideosLista({ id: this.state.companieId });
        }
      }
    );
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Videos Pregrabados</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          {this.state.showCreate ? (
            <Container maxWidth={false}>
              <FrameCreateVideo
                {...this.props}
                dataDetail={this.props.dataUserDetail}
                CloseDialog={() => this.executeClose()}
                Update={this.state.update}
                DeleteItem={() => this.deleteItem()}
                executeClose={() => this.executeClose()}
                errorUploadVideo={this.state.errorUploadVideo}
                ClearError={() => this.clearError()}
                companieId={this.state.companieId}
                companieName={this.state.companieName}
              />
            </Container>
          ) : (
            <Container maxWidth={false}>
              <VideoListToolbar
                {...this.props}
                filter={this.state.textFilter}
                actionShowFrameCreate={() => {
                  if (
                    this.state.companieId !== '' ||
                    this.props.dataUserLog.is_admin !== true
                  ) {
                    this.showCreate();
                  } else {
                    this.setState({
                      showMessageNotCreate: true
                    });
                  }
                }}
                exportToExcel={() => this.exportToExcel()}
                handleChange={(e) => this.handleChange(e)}
                filterByCompanie={(companie) => this.getByCompanie(companie)}
                companieId={this.state.companieId}
                companieName={this.state.companieName}
              />
              <Box sx={{ pt: 3 }}>
                {this.props.dataExecutives ? (
                  <VideoListResults
                    busy={this.props.busy}
                    estado={this.props.estado}
                    OpenEdit={(id) => this.openEdit(id)}
                    onEnableDisable={this.handleEnableDisable}
                    Customers={
                      this.state.dataUsersCopy
                        ? this.state.dataUsersCopy.map((item) => {
                            return {
                              ...item,
                              id: item.public_key,
                              created_at: moment(item.created_at).format(
                                'DD-MM-YYYY'
                              )
                            };
                          })
                        : []
                    }
                  />
                ) : null}
              </Box>
            </Container>
          )}
        </Box>
        <SweetAlert
          danger
          show={this.state.showDelete}
          showCancel
          confirmBtnText="Aceptar"
          confirmBtnBsStyle="light"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="light"
          cancelBtnStyle={{ fontSize: 14 }}
          style={{ color: '#000', fontSize: 14 }}
          confirmBtnStyle={{ fontSize: 14 }}
          onConfirm={() => this.confirmDelete()}
          onCancel={() => this.setState({ showDelete: false })}
        >
          <span>{'¿Está seguro de eliminar este video?'}</span>
        </SweetAlert>

        <SweetAlert
          danger
          show={this.state.showMessageNotCreate}
          confirmBtnText="Aceptar"
          confirmBtnBsStyle="light"
          // cancelBtnText="Cancelar"
          // cancelBtnBsStyle="light"
          // cancelBtnStyle={{ fontSize: 14 }}
          style={{ color: '#000', fontSize: 14 }}
          confirmBtnStyle={{ fontSize: 14 }}
          onConfirm={() => this.setState({ showMessageNotCreate: false })}
        >
          <span>
            {
              'Debes seleccionar una empresa en el filtro para poder agregar un video'
            }
          </span>
        </SweetAlert>

        <Loading busy={this.props.busy} />
        <ToastContainer position={'top-right'} delay={5000} />
      </>
    );
  }
}

const mapStateToProps = ({
  executives,
  executiveDetail,
  companies,
  roles,
  videos,
  user,
  gatesVideo,
  maneuvers
}) => ({
  dataExecutives: videos.items,
  estado: executives.estado,
  apiRespuesta: executives.apiRespuesta,

  dataVideoUploaded: videos.dataUploaded,
  dataVideoCreated: videos.dataCreated,
  dataVideoDetailed: videos.dataDetail,
  estadoVideo: videos.estado,
  apiRespuestaVideo: videos.apiRespuesta,

  estadoGatesVideo: gatesVideo.estado,
  apiRespuestaGatesVideo: gatesVideo.apiRespuesta,
  GatesVideoList: gatesVideo.items,

  estadoManeuvers: maneuvers.estado,
  apiRespuestaManeuvers: maneuvers.apiRespuesta,
  ManeuversList: maneuvers.items,

  busy:
    companies.waiting ||
    user.waiting ||
    videos.waiting ||
    gatesVideo.waiting ||
    maneuvers.waiting,

  error:
    companies.error ||
    roles.error ||
    user.error ||
    videos.error ||
    gatesVideo.error ||
    maneuvers.error,

  dataUserDetail: executiveDetail.items,

  dataCompanies: companies.items,
  dataRoles: roles.items,
  dataUserLog: user.items,
  estadoDataUser: user.estado,
  apiRespuestaDataUser: user.apiRespuesta
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      actionCompaniesLista,

      actionRolesLista,
      actionUsuarioLogin,

      actionExecutivesLista,
      actionExecutivesActualizar,
      actionExecutivesCrear,
      actionExecutivesDetalle,
      actionLimpiarEstado,
      actionExecutivesHabilitarDeshabilitar,

      //Videos
      actionVideosLista,
      actionVideosUpload,
      actionVideosClear,
      actionVideosCrear,
      actionVideosDetalle,
      actionVideosHabilitarDeshabilitar,
      //GatesVideo,
      actionGatesVideoLista,
      actionGatesVideoCrear,
      actionGatesVideoHabilitarDeshabilitar,
      //Maneuvers
      actionManeuversLista,
      actionManeuversCrear,
      actionManeuversHabilitarDeshabilitar,
      actionGatesVideoClear,
      actionManeuversClear
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(VideoList);
