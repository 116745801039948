/* eslint-disable */
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import GridViewIcon from '@mui/icons-material/GridView';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import React, { Component } from 'react';

import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import DropDownWithSearchFormTemplate from 'src/components/DropDownWithSearchFormTemplate';

const title = 'Cámaras fijas';

class CameraListToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialogCreate: false,
      title: this.props.title,
      selectCompanies: {
        value: '',
        label: ''
      },
      dataCompanies: []
    };
  }

  componentDidMount() {
    this.setState({
      selectCompanies: {
        value: this.props.companieId ? this.props.companieId : '',
        label: this.props.companieName ? this.props.companieName : ''
      }
    });
  }

  openCreate = () => {
    // this.setState({
    //   openDialogCreate: true
    // });
    this.props.actionShowFrameCreate();
    //this.props.actionRequestsDetalle({ id: id });
  };

  closeDialogCreate = () => {
    this.setState(
      {
        openDialogCreate: false
      },
      () => {
        this.setCreate();
      }
    );
  };

  changeWindowList = (e, value) => {
    e.preventDefault();
    this.props.setContent(value); //1 mosaico - 2 lista
  };

  selectDropdownSearch = (data) => {
    this.setState(
      {
        selectCompanies: data,
        errorCompanies: ''
      },
      () => {
        this.setCreate();
      }
    );
  };

  setCreate = () => {
    this.props.filterByCompanie(this.state.selectCompanies);
  };

  componentDidUpdate(preprops) {
    if (this.props.dataCompanies !== undefined) {
      if (this.props.dataCompanies !== preprops.dataCompanies) {
        this.updateCompanies();
      }
    }
  }

  updateCompanies = async () => {
    const dataCompaniesArr = [];
    dataCompaniesArr.push({
      name: 'Ver todo',
      public_key: ''
    });
    this.props.dataCompanies.map((item) => {
      dataCompaniesArr.push({
        ...item,
        label: item.name,
        value: item.public_key
      });
    });

    this.setState({
      dataCompanies: dataCompaniesArr
    });
  };

  render() {
    const currentStyle = {
      width: '30px',
      height: '30px',
      backgroundColor: '#005C99',
      color: 'white',
      position: 'absolute',
      marginLeft: this.props.currentList == 1 ? '0' : '30px',
      borderTopLeftRadius: this.props.currentList == 1 ? '4px' : '0px',
      borderBottomLeftRadius: this.props.currentList == 1 ? '4px' : '0px',
      borderTopRightRadius: this.props.currentList == 2 ? '4px' : '0px',
      borderBottomRightRadius: this.props.currentList == 2 ? '4px' : '0px'
    };

    const disableStyle = {
      width: '30px',
      height: '30px',
      backgroundColor: '#75C8FF',
      color: 'white',
      position: 'absolute',
      marginLeft: this.props.currentList == 1 ? '30px' : '0',
      borderTopRightRadius: this.props.currentList == 1 ? '4px' : '0px',
      borderBottomRightRadius: this.props.currentList == 1 ? '4px' : '0px',
      borderTopLeftRadius: this.props.currentList == 2 ? '4px' : '0px',
      borderBottomLeftRadius: this.props.currentList == 2 ? '4px' : '0px'
    };

    const boxStyle = {
      position: 'relative',
      backgroundColor: 'black'
    };

    return (
      <Box {...this.props}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start'
            }}
          >
            <div
              style={{
                fontFamily: 'sans-serif',
                fontSize: 18,
                fontWeight: 'bold'
              }}
            >
              {title}
            </div>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            {/* <Button sx={{ mx: 1 }} onClick={() => this.props.exportToExcel()}>
              Exportar
            </Button> */}
            <Button
              style={{ backgroundColor: '#0099ff' }}
              color="primary"
              variant="contained"
              onClick={this.openCreate}
            >
              Agregar {title}
            </Button>
          </Box>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Card
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center'
            }}
          >
            <CardContent style={{ width: '50%' }}>
              <Box sx={{ width: '100%' }}>
                <TextField
                  fullWidth
                  onChange={(e) => this.props.handleChange(e.target.value)}
                  value={this.props.filter}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  placeholder={`Buscar ${title}`}
                  variant="outlined"
                />
              </Box>
            </CardContent>
            <Box style={boxStyle}>
              <a
                href="#"
                style={
                  this.props.currentList == 1 ? currentStyle : disableStyle
                }
                onClick={(e) => this.changeWindowList(e, 1)}
              >
                <GridViewIcon sx={{ fontSize: 30 }} />
              </a>
              <a
                href="#"
                style={
                  this.props.currentList == 2 ? currentStyle : disableStyle
                }
                onClick={(e) => this.changeWindowList(e, 2)}
              >
                <FormatListBulletedIcon sx={{ fontSize: 30 }} />
              </a>
            </Box>
            {this.props.dataCompanies &&
            this.props.dataUserLog.is_admin === true ? (
              <CardContent style={{ width: '35%', marginRight: 80 }}>
                <Box
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    display: 'flex',
                    width: '100%'
                  }}
                >
                  <DropDownWithSearchFormTemplate
                    label="Filtrar por empresa *"
                    className="col-12 col-md-12"
                    value={this.state.selectCompanies}
                    options={this.state.dataCompanies.map((item) => {
                      return {
                        ...item,
                        label: item.name,
                        value: item.public_key
                      };
                    })}
                    name="companies"
                    error={this.state.errorCompanies}
                    select={(args) => this.selectDropdownSearch(args)}
                  />

                  <Button
                    style={{ backgroundColor: '#0099ff', marginTop: 22 }}
                    color="primary"
                    variant="contained"
                    onClick={() => this.setCreate()}
                  >
                    <FilterAltOutlinedIcon />
                  </Button>
                </Box>
              </CardContent>
            ) : (
              <CardContent style={{ width: '35%', marginRight: 80 }}>
                <Box
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    display: 'flex',
                    width: '100%'
                  }}
                ></Box>
              </CardContent>
            )}
          </Card>
        </Box>
      </Box>
    );
  }
}

export default CameraListToolbar;
