/* eslint-disable */
import {
    GATES_INICIA,
    GATES_LISTA,    
    GATES_LISTADO, 

    GATES_ACTUALIZAR,
    GATES_ACTUALIZADA,
    GATES_CREAR,
    GATES_CREADA,
    GATES_CLEAR,
    GATES_CLEARED,
    GATES_DETALLE,
    GATES_DETALLADO,
    GATES_DETALLE_INICIA,
    GATES_HABILITAR_DESHABILITAR,
    GATES_HABILITADA_DESHABILITADA,
    GATES_ERROR,
} from 'src/constants/api/gates';

export const actionGatesInicia = () => ({ type: GATES_INICIA, });
export const actionGatesLista = params => ({ type: GATES_LISTA, params });
export const actionGatesListado = data => ({ type: GATES_LISTADO, data });

export const actionGatesActualizar = data => ({ type: GATES_ACTUALIZAR, data });
export const actionGatesActualizada = (params) => ({ type: GATES_ACTUALIZADA, params });

export const actionGatesCrear = data => ({ type: GATES_CREAR, data });
export const actionGatesCreada = (params) => ({ type: GATES_CREADA, params });

export const actionGatesClear = data => ({ type: GATES_CLEAR, data });
export const actionGatesCleared = data => ({ type: GATES_CLEARED, data });

export const actionGatesDetalle = params => ({ type: GATES_DETALLE, params });
export const actionGatesDetallado = (data) => ({ type: GATES_DETALLADO, data });
export const actionGatesDetalleInicia = () => ({ type: GATES_DETALLE_INICIA, });

export const actionGatesHabilitarDeshabilitar = data => ({ type: GATES_HABILITAR_DESHABILITAR, data });
export const actionGatesHabilitadaDeshabilitada = (params) => ({ type: GATES_HABILITADA_DESHABILITADA, params });

export const actionGatesError = error => ({ type: GATES_ERROR, error });
