/* eslint-disable */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
    actionCompaniesDetalleInicia,
    actionCompaniesDetallado,
    actionCompaniesDetalleError,
} from "src/actions/api/companiesDetail";

import { 
  apiCompaniesDetail, 
} from "src/api/companiesDetail";

import {
    COMPANIES_LISTA,
    COMPANIES_DETALLE
} from 'src/constants/api/companies';


function* sagaCompaniesDetalle({ type, params }) {
  try {        
    yield put(actionCompaniesDetalleInicia());
    const data = yield call(apiCompaniesDetail, params);
    yield put(actionCompaniesDetallado(data));
  } catch (e) {
    yield put(actionCompaniesDetalleError(e));    
  }
}

export default  [
  takeEvery(COMPANIES_DETALLE, sagaCompaniesDetalle), 
]
