/* eslint-disable */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
    actionDetectorsDetalleInicia,
    actionDetectorsDetallado,
    actionDetectorsError,
} from "src/actions/api/detectors";

import { 
    apiDetectorDetail,    
} from "src/api/detectors";

import {
    DETECTOR_DETALLE
} from 'src/constants/api/detectors';

function* sagaDetectorDetail({ type, params }) {
  try {        
    yield put(actionDetectorsDetalleInicia());
    const data = yield call(apiDetectorDetail, params);
    yield put(actionDetectorsDetallado(data));
  } catch (e) {
    yield put(actionDetectorsError(e));    
  }
}

export default  [
  takeEvery(DETECTOR_DETALLE, sagaDetectorDetail), 
]
