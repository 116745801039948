/* eslint-disable */
import React, { Component } from 'react';

import { Box, Container } from '@material-ui/core';

import Button from '@material-ui/core/Button';

import styles from './styles'

import ReactFormInputValidation from "react-form-input-validation";
import FieldFormTemplate from '../FieldFormTemplate';
import DropDownWithSearchFormTemplate from 'src/components/DropDownWithSearchFormTemplate'
import Add from '@material-ui/icons/Add';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import SweetAlert from 'react-bootstrap-sweetalert';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { colorsList } from "src/api/commons";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import 'react-toastify/dist/ReactToastify.css';
import GatesListResults from './GatesListResults';
import DialogCreateGatesVideo from 'src/components/DialogCreateGatesVideo';
import DialogCreateFormFactor from '../DialogCreateFormFactor';
import { fontSize } from '@mui/system';
import { URL_API } from 'src/api/endpoints';
import GateForm from '../DialogCreateGatesVideo/GateForm';


class Driveways extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fields: {
        idRegistry: "",
        direction: "",
        name: (this.props.Update && this.props.dataDetail) ? this.props.dataDetail.name : "",
      },
      roadWay: [],
      gatesList: this.props.objStep2.gatesList ? this.props.objStep2.gatesList : [],
      gatesListCopy: [],
      formFactorList: [],
      formFactorObj: {},
      showConfirmEnableDisable: false,
      errors: {},
      idToDelete: "",
      openDialogCreateGates: false,
      openDialogCreateFormFactor: false,
      idToDeleteFormFactor: "",
      aspectRatioDimensions: {height: 0, width: 0},
      showConfirmEnableDisableFormFactor: false,
      colorSelect: colorsList[0].value,
      showError: false,
      color: {},
    };
    this.formGateList = new ReactFormInputValidation(this, { locale: 'es' });
    this.formGateList.onformsubmit = async (fields) => {
      if (fields.name && fields.direction) {
        //if (this.props.dataDetail.public_key) {
        //  //console.log("La camara existe:", this.props.dataDetail);
        //} else {
        ////console.log("La camara no existe", this.props.dataDetail);
        //}
      }
      // if (this.props.Update === true) {
      //     // this.props.actionExecutivesActualizar({
      //     //     ...objToSend,
      //     //     id: this.props.dataDetail.public_key,
      //     //     //updatedBy: "A40AF516-4209-4C5A-8EF0-D28328AFA10F"
      //     // });
      // } else {
        //this.props.actionExecutivesCrear({ ...objToSend });
      // }
    }
  }

  componentDidUpdate(preprops) {
    if (this.props.dataDetail !== preprops.dataDetail) {
      this.setDataFields();
    }
    if (this.props.GatesVideoList !== preprops.GatesVideoList) {
      this.setGatesbyProps()
    }
  }

  setGatesbyProps = () => {
    const newArray = []
    const {width, height} = this.state.aspectRatioDimensions;
    if (this.props.GatesVideoList.length > 0) {
      this.props.GatesVideoList.forEach(element => {
        newArray.push({
          id: element.public_key,
          name: element.name,
          roadway_id: element.name,
          x: element.x / width,
          y: element.y / height,
          x1: element.x1 / width,
          y1: element.y1 / height,
          rotation: element.rotation,
          width: element.width / width,
          height: element.height / height,
          fill: "transparent",
          stroke: element.fill
        })
      });
      this.setState({
        gatesList: newArray,
        gatesListCopy: [],
      })
    } else {
      this.setState({
        gatesList: [],
        gatesListCopy: [],
      });
    }
  }

  async componentDidMount() {
    this.setState({
      gatesList: [],
      gatesListCopy: []
    })
    this.props.actionGatesVideoLista({
      id: this.props.dataVideoCreated.public_key,
    });
    this.formGateList.useRules({
      name: "required",
      direction: "required"
    });
    const random = Math.floor(Math.random() * colorsList.length);
    this.setState({ colorSelect: colorsList[random].value, color: colorsList[random] })
    this.setState({
      gatesList: [],
      gatesListCopy: [],
    })
  }

  setDataFields = () => {
    this.setState({
      fields: {
        name: (this.props.Update && this.props.dataDetail) ? this.props.dataDetail.name : "",
        direction: ""
      },
    });
  }

  drawFormFactor = () => {
    this.setState({
      openDialogCreateFormFactor: true
    })
  }

  drawGates = () => {
    this.setState({
      openDialogCreateGates: true
    })
  }

  openEdit = (id) => {
    this.setState({
      showConfirmEnableDisable: true,
      idToDelete: id
    });
  }

  openEditFormFactor = (id) => {
    this.setState({
      openDialogCreateFormFactor: true,
      idToDeleteFormFactor: id
    });
  }


  handleEnableDisable = async (data) => {
    this.props.actionGatesVideoHabilitarDeshabilitar({ id: this.state.idToDelete });
    this.setState({
      showConfirmEnableDisable: false,
      idToDelete: "",
    });
  }

  handleEnableDisableFormFactor = async (data) => {
    this.setState({
      showConfirmEnableDisable: false,
      idToDelete: "",
    });
  }

  closeDialogCreateGates = () => {
    this.setState({
      openDialogCreateGates: false
    })
  }

  closeDialogCreateFormFactor = () => {
    this.setState({
      openDialogCreateFormFactor: false
    })
  }

  saveGates = (gatesList) => {
    this.setState({
      gatesList: gatesList,
      gatesListCopy: gatesList
    }, () => {
      this.closeDialogCreateGates();
    })
  }

  saveformFactor = (formFactorObj) => {
    if (Object.keys(formFactorObj).length !== 0) {
      this.setState({
        formFactorList: formFactorObj.formFactor,
        formFactorObj: formFactorObj
      }, () => {
        this.closeDialogCreateFormFactor();
      })
    } else {
      this.setState({
        formFactorList: [],
        formFactorObj: {}
      }, () => {
        this.closeDialogCreateFormFactor();
      })
    }
  }

  setGatesData = (gates) => {
    this.setState({
      gatesList: gates,
      gatesListCopy: gates
    })
  }

  handleAddGates_ = async () => {
    let newGate = new Object();
    const width = 100;
    const height = 30;
    const [x, y] = [100, 100];
    const [x1, y1] = [x + width, y + height];
    const gateNow = Date.now();
    newGate = {
      id: gateNow.toString(),
      name: `gate-${gateNow}`,
      roadway_id: gateNow.toString(),
      x,
      y,
      x1,
      y1,
      rotation: 0,
      width,
      height,
      fill: "transparent",
      stroke: this.state.colorSelect
    };
    this.setState({
      gatesList: [...this.state.gatesList, newGate],
      gatesListCopy: [...this.state.gatesList, newGate]
    });
    const random = Math.floor(Math.random() * colorsList.length);
    this.setState({ colorSelect: colorsList[random].value, color: colorsList[random] })
  };

  setNextStep = async () => {
    const objToSend = await {
      gatesList: this.state.gatesList,
      gatesListCopy: this.state.gatesList,
      created_user: this.props.dataUserLog.public_key,
      //id: Math.random().toString().slice(2, 11),
    }
    if (objToSend.gatesList.length < 2) {
        this.setState({
            showError: true
        })
    } else {
      this.props.NextStep({
        step: 2,
        completed: true,
        fields: objToSend,
      });
    }
  }
  handleAddGatesToApi = () => {
    const { height, width } = this.state.aspectRatioDimensions;
    this.setState({
      gatesListCopy: this.state.gatesList
    }, () => {
      this.state.gatesListCopy.forEach(element => {
        let key = "";
        this.props.GatesVideoList.forEach(elementProps => {
          if (element.name === elementProps.name) {
            key = elementProps.public_key
          }
        });
        if (key === "") {
          this.props.actionGatesVideoCrear({
            video_id: this.props.dataVideoCreated.public_key,
            //video_id: "5e7ece77-1f16-4104-b782-2f43cb043508",
            ...element,            
            x: width && element.x * width || element.x * 3,
            y: height && element.y * height || element.y * 3,
            x1: width && element.x1 * width || element.x1 * 3,
            y1: height && element.y1 * height || element.y1 * 3,
            rotation: element.rotation,
            width: element.width * width,
            height: element.height * height,
            fill: element.stroke
          })
        }
        this.setState({
          gatesList: [],
          gatesListCopy: []
        })
      });
        // this.state.gatesListCopy.forEach(element => {
        //     this.props.actionGatesVideoCrear({
        //         video_id: this.props.dataVideoCreated.public_key,
        //         //video_id: "5e7ece77-1f16-4104-b782-2f43cb043508",
        //         ...element,
        //         fill: element.stroke,
        //     })
        // })
        // this.setState({
        //     gatesListCopy: [],
        //     gatesList: []
        // })
    })
    // if (this.state.gatesListCopy.length > 0 && this.props.GatesVideoList.length > 0) {
    //     this.state.gatesListCopy.forEach(element => {
    //         this.props.GatesVideoList.forEach(elementProps => {
    //             if (elementProps.name !== element.name) {
    //                 this.props.actionGatesVideoCrear({
    //                     video_id: this.props.dataVideoCreated.public_key,
    //                     //video_id: "5e7ece77-1f16-4104-b782-2f43cb043508",
    //                     ...element,
    //                     fill: element.stroke,
    //                 })
    //             } else {
    //                 console.log("No se llama")
    //             }
    //         });
    //     });
    // } else {
    //     if (this.state.gatesListCopy.length > 0) {
    //         this.state.gatesListCopy.forEach(element => {
    //             this.props.actionGatesVideoCrear({
    //                 video_id: this.props.dataVideoCreated.public_key,
    //                 //video_id: "5e7ece77-1f16-4104-b782-2f43cb043508",
    //                 ...element,
    //                 fill: element.stroke,
    //             })
    //         })
    //     }
    // }
  }
  dropDownOptions = ()=> {
    return colorsList.map((item) => {
      return {
        ...item,
        label: item.label,
        value: item.value,
        stroke: item.label
      }
    })
  }
  render() {
    return (
    <>
      <Box
        sx={{minHeight: '100%', py: 3, marginTop: 5, padding: 0}}
      >
        <Container style={{ width: "100%", padding: 0 }}>
          <Box display="flex" flexDirection="row" width={"100%"} overflow={"hidden"}>
            <form onSubmit={this.formGateList.handleSubmit} style={{ width: "100%", }}>
              <div className='form-row' style={{ width: "100%", marginTop: 30, justifyContent: "center", height: "33px", alignItems: "center", alignContent: "center" }}>
                <DropDownWithSearchFormTemplate
                  label="Color *"
                  showIcon={true}
                  className="col-4 col-md-4"
                  value={this.state.color}
                  defaultValue={{ label: "Seleccionar una Compuerta Entrada", value: ""}}
                  options={this.dropDownOptions()}
                  name="color"
                  error={this.state.errors.color ? this.state.errors.color : ""}
                  select={(e) => {
                    this.setState({ colorSelect: e.value, color: e})
                  }}
                />
                <Button
                  endIcon={<Add />}
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={() =>
                    this.handleAddGates_()
                  }
                  style={{
                    outline: "none",
                    width: "30%",
                    textTransform: "none",
                    marginLeft: 50, marginTop: 20
                  }}
                >
                  Dibujar Compuerta
                </Button>
              </div>
              <Box display="flex" flexDirection="row" width={"100%"} overflow={"hidden"}>
                <Box
                  display="flex" flexDirection="column" justifyContent='center' width={"50%"} overflow={"hidden"}
                  marginTop={5} 
                >
                  <div className="form-row" style={{   marginTop: 10, marginBottom: 20 }}>
                    <GateForm
                      showFromParent={true}
                      gates={this.state.gatesList}
                      setGates={(gates) => this.setGatesData(gates)}
                      roadways={[{
                        id: "7020", name: "Testing-Front"
                      }]}
                      setAspectRatio={ e => this.setState({ aspectRatioDimensions: {width: e.width, height: e.height}}) }
                      setRoadways={(set) => console.log("setRoadways", set)}
                      cameraImg={this.props.objStep1.image ?
                        `${this.props.objStep1.image}`
                        : "/static/images/avatars/null_image.png"}
                    />
                    <Button
                      endIcon={<Add />}
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={() =>
                          this.handleAddGatesToApi()
                          //     this.setState({
                          //     openDialogCreateGates: true
                          // })
                      }
                      disabled={
                        (this.state.gatesListCopy.length > 0)
                          ? false
                          : true
                      }
                      style={{
                        outline: "none",
                        width: "100%",
                        textTransform: "none",
                        marginLeft: 5, marginTop: 20
                      }}>
                      Guardar Compuerta(s)
                    </Button>
                  </div>
                </Box>
                <Box display="flex" flexDirection="column" width={"50%"} height={"20%"} padding={2} marginTop={3}>
                  <div style={{
                    fontFamily: "sans-serif",
                    fontSize: 20,
                    fontWeight: "bold",
                    color: "#045c9c",
                    marginTop: 0
                  }}>{"Compuertas"}</div>
                  <GatesListResults
                    busy={this.props.busy}
                    estado={this.props.estado}
                    OpenEdit={(id) => this.openEdit(id)}
                    //onEnableDisable={this.handleEnableDisable}
                    Gates={
                      this.props.GatesVideoList
                      ? this.props.GatesVideoList.map(item => ({...item, id: item.public_key}))
                      : []
                    } 
                  />
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "flex-end"
                }}>
                <Button color="primary"
                  onClick={() => this.props.BackStep()}
                  variant="outlined"
                  startIcon={<KeyboardBackspaceIcon />}
                  style={{ outline: "none" }}>
                  Volver
                </Button>
                <Button color="primary"
                  onClick={() => this.setNextStep()}
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  style={{ outline: "none", backgroundColor: '#0099ff', marginLeft: '30px' }}>
                  Siguiente
                </Button>
              </Box>
            </form>
          </Box>
        </Container>
      </Box>
      <SweetAlert
        show={this.state.showConfirmEnableDisable}
        warning
        confirmBtnText="Aceptar"
        confirmBtnBsStyle="light"
        cancelBtnText="Cancelar"
        cancelBtnBsStyle="light"
        cancelBtnStyle={{ fontSize: 14 }}
        style={{ color: "#000", fontSize: 14 }}
        confirmBtnStyle={{ fontSize: 14 }}
        title={"Eliminar Compuerta"}
        onConfirm={() => this.handleEnableDisable()}
        onCancel={() => this.setState({ showConfirmEnableDisable: false, idToDelete: "" })}
        showCancel={true}
        showConfirm={true}
      >
        <span>{"¿Estás seguro que desea eliminar esta compuerta?"}</span>
      </SweetAlert>
      <SweetAlert
        show={this.state.showError}
        warning
        confirmBtnText="Aceptar"
        confirmBtnBsStyle="light"
        cancelBtnText="Cancelar"
        cancelBtnBsStyle="light"
        cancelBtnStyle={{ fontSize: 14 }}
        style={{ color: "#000", fontSize: 14 }}
        confirmBtnStyle={{ fontSize: 14 }}
        title={"Error"}
        onConfirm={() => this.setState({ showError: false })}
        onCancel={() => this.setState({ showError: false })}
        showCancel={false}
        showConfirm={true}
      >
        <span>{"Debes agregar un minimo de 2(dos) compuertas para continuar"}</span>
      </SweetAlert>
      {this.state.openDialogCreateGates ?
        <DialogCreateGatesVideo
          {...this.props}
          url={this.props.objStep1.image ?
            `${this.props.objStep1.image}`
            : "/static/images/avatars/null_image.png"}
          open={this.state.openDialogCreateGates}
          gatesList={this.state.gatesList}
          SaveGates={(gatesList) => this.saveGates(gatesList)}
          CloseDialog={() => this.closeDialogCreateGates()}
        />
        : null
      }
      {this.state.openDialogCreateFormFactor ?
        <DialogCreateFormFactor
          {...this.props}
          url={
            this.props.dataCameraImage.image ?
            `${URL_API}/${this.props.dataCameraImage.image}`
            : "/static/images/avatars/null_image.png"}
          formFactorList={this.state.formFactorList}
          SaveformFactor={(formFactorObj) => this.saveformFactor(formFactorObj)}
          open={this.state.openDialogCreateFormFactor}
          CloseDialog={() => this.closeDialogCreateFormFactor()}
        />
        : null
      }
    </>
    )
  }
}



export default Driveways;