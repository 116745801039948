/* eslint-disable */
import React, { Component } from 'react';

import { Box, Container, Tooltip } from '@material-ui/core';

import Button from '@material-ui/core/Button';

import styles from './styles';

import ReactFormInputValidation from 'react-form-input-validation';
import FieldFormTemplate from '../FieldFormTemplate';
import DropDownFormTemplate from 'src/components/DropDownFormTemplate';
import Add from '@material-ui/icons/Add';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import SweetAlert from 'react-bootstrap-sweetalert';
import InfoIcon from '@mui/icons-material/Info';

import {
  colorsList,
  maneuversList,
  directionsList,
  entityList
} from 'src/api/commons';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import 'react-toastify/dist/ReactToastify.css';
import ManeuversListResults from './ManeuversListResults';
import DialogCreateGatesVideo from 'src/components/DialogCreateGatesVideo';
import DialogShowManeuvers from 'src/components/DialogShowManeuvers';
import DialogCreateFormFactor from '../DialogCreateFormFactor';
import { fontSize } from '@mui/system';
import { URL_API } from 'src/api/endpoints';
import GateForm from '../DialogCreateGatesVideo/GateForm';
import DropDownWithSearchFormTemplate from 'src/components/DropDownWithSearchFormTemplate';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
class Maneuvers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aspectRatioDimensions: { height: 0, width: 0 },
      fields: {
        idRegistry: '',
        direction: '',
        name:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.name
            : '',
        movement: '',
        geometris_id_entry: '',
        geometris_id_exit: '',
        entity: '',
        origin: '',
        destination: ''
      },
      roadWay: [],
      gatesList: this.props.objStep2.gatesList
        ? this.props.objStep2.gatesList
        : [],
      formFactorList: [],
      formFactorObj: {},
      showConfirmEnableDisable: false,
      errors: {},
      idToDelete: '',
      openDialogCreateGates: false,

      openDialogCreateFormFactor: false,
      idToDeleteFormFactor: '',
      showConfirmEnableDisableFormFactor: false,
      colorSelect: colorsList[0].value,
      showError: false,
      movement: {},
      geometris_id_entry: {},
      geometris_id_exit: {},
      entity: {},
      origin: {},
      destination: {},
      showManeuvers: false,
      errorOriDest: false,
      errorGeometries: false,
      showErrorCreateManeuvers: false
    };
    this.formManeuvers = new ReactFormInputValidation(this, { locale: 'es' });
    this.formManeuvers.onformsubmit = async (fields) => {
      const objToSend = {
        video_id: this.props.dataVideoCreated.public_key,
        //video_id: "2f7c3eab-d0d3-47d6-8a7d-c6bd909d1675",
        geometris_id_entry: fields.geometris_id_entry,
        geometris_id_exit: fields.geometris_id_exit,
        Type: fields.entity,
        observation: 'Obsv',
        movement: parseInt(fields.movement),
        origin: fields.origin,
        destination: fields.destination
      };

      if (objToSend.geometris_id_entry === objToSend.geometris_id_exit) {
        this.setState({
          errorGeometries: true,
          showErrorCreateManeuvers: true
        });
      }

      if (objToSend.origin === objToSend.destination) {
        this.setState({
          errorOriDest: true,
          showErrorCreateManeuvers: true
        });
      }

      if (
        this.state.errorOriDest === false &&
        this.state.errorGeometries === false
      ) {
        this.props.actionManeuversCrear({
          ...objToSend
        });

        setTimeout(() => {
          this.setState({
            fields: {},
            movement: {},
            geometris_id_entry: {},
            geometris_id_exit: {},
            entity: {},
            origin: {},
            destination: {}
          });
        }, 50);
      }
    };
  }

  componentDidUpdate(preprops, prestate) {
    if (this.props.objStep2 !== preprops.objStep2) {
      this.setDataFields();
    }
    if (prestate.aspectRatioDimensions !== this.state.aspectRatioDimensions) {
      const newArray = [];
      const { width, height } = this.state.aspectRatioDimensions;
      this.props.GatesVideoList.forEach((element) => {
        newArray.push({
          ...element,
          id: element.public_key,
          name: element.name,
          roadway_id: element.name,
          x: element.x / width,
          y: element.y / height,
          x1: element.x1 / width,
          y1: element.y1 / height,
          rotation: element.rotation,
          width: element.width / width,
          height: element.height / height,
          fill: 'transparent',
          stroke: element.fill
        });
      });
      this.setState({
        gatesList: newArray
      });
    }
  }

  async componentDidMount() {
    this.formManeuvers.useRules({
      name: 'required',
      entity: 'required',
      direction: 'required',
      movement: 'required',
      geometris_id_entry: 'required|different:geometris_id_exit',
      geometris_id_exit: 'required|different:geometris_id_entry',
      origin: 'required',
      destination: 'required'
    });

    // this.setState({
    //     gatesList: this.props.objStep2.gatesList ? this.props.objStep2.gatesList : []
    // })
  }

  setDataFields = () => {
    this.setState(
      {
        fields: {
          name:
            this.props.Update && this.props.dataDetail
              ? this.props.dataDetail.name
              : '',
          direction: ''
        }
      },
      () => {
        const newArray = [];
        const { width, height } = this.state.aspectRatioDimensions;
        this.props.GatesVideoList.forEach((element) => {
          newArray.push({
            ...element,
            id: element.public_key,
            name: element.name,
            roadway_id: element.name,
            x: element.x / width,
            y: element.y / height,
            x1: element.x1 / width,
            y1: element.y1 / height,
            rotation: element.rotation,
            width: element.width / width,
            height: element.height / height,
            fill: 'transparent',
            stroke: element.fill
          });
        });
        this.setState({
          gatesList: newArray
        });
      }
    );
  };

  drawFormFactor = () => {
    //console.log("draw Form Factor");
    this.setState({
      openDialogCreateFormFactor: true
    });
  };

  drawGates = () => {
    //console.log("draw Gates");
    this.setState({
      openDialogCreateGates: true
    });
  };

  openEdit = (id) => {
    this.setState({
      showConfirmEnableDisable: true,
      idToDelete: id
    });
  };

  openEditFormFactor = (id) => {
    this.setState({
      openDialogCreateFormFactor: true,
      idToDeleteFormFactor: id
    });
  };

  handleEnableDisable = async (data) => {
    // let roadWayCopy = await [...this.state.roadWay];
    // let elementToDelete = await roadWayCopy.findIndex((e) => e.id === this.state.idToDelete);
    // await roadWayCopy.splice(elementToDelete, 1);
    // this.setState({
    //     showConfirmEnableDisable: false,
    //     idToDelete: "",
    //     roadWay: roadWayCopy
    // });
    //this.props.deleteById(this.state.idToDelete);
    this.props.actionManeuversHabilitarDeshabilitar({
      id: this.state.idToDelete
    });
    this.setState({
      showConfirmEnableDisable: false,
      idToDelete: ''
    });
  };

  handleEnableDisableFormFactor = async (data) => {
    this.setState({
      showConfirmEnableDisable: false,
      idToDelete: ''
    });
  };

  closeDialogCreateGates = () => {
    this.setState({
      openDialogCreateGates: false
    });
  };

  closeDialogCreateFormFactor = () => {
    this.setState({
      openDialogCreateFormFactor: false
    });
  };

  saveGates = (gatesList) => {
    this.setState(
      {
        gatesList: gatesList
      },
      () => {
        this.closeDialogCreateGates();
      }
    );
  };

  saveformFactor = (formFactorObj) => {
    if (Object.keys(formFactorObj).length !== 0) {
      this.setState(
        {
          formFactorList: formFactorObj.formFactor,
          formFactorObj: formFactorObj
        },
        () => {
          this.closeDialogCreateFormFactor();
        }
      );
    } else {
      this.setState(
        {
          formFactorList: [],
          formFactorObj: {}
        },
        () => {
          this.closeDialogCreateFormFactor();
        }
      );
    }
  };

  setGatesData = (gates) => {
    this.setState({
      gatesList: gates
    });
  };

  setNextStep = async () => {
    const objToSend = await {
      gatesList: this.state.gatesList,
      created_user: this.props.dataUserLog.public_key
      //id: Math.random().toString().slice(2, 11),
    };

    if (objToSend.gatesList.length < 2) {
      this.setState({
        showError: true
      });
    } else {
      this.props.NextStep({
        step: 2,
        completed: true,
        fields: objToSend
      });
    }
  };

  changueSelectMovement = (e) => {
    this.setState({
      fields: {
        ...this.state.fields,
        [e.name]: e.value
      },
      //...this.state.fields,
      [e.name]: e
    });
  };

  changueSelect = (e) => {
    this.setState({
      fields: {
        ...this.state.fields,
        [e.name]: e.value
      },
      //...this.state.fields,
      [e.name]: e
    });
  };

  getColorDriveways = (id) => {
    //this.state.gatesList
    const getItem = this.state.gatesList.findIndex((x) => x.id === id);
    const item = this.state.gatesList[getItem];
    return {
      color: item.stroke,
      id: item.id,
      index: getItem + 1
    };
  };

  getType = (type) => {
    const getItem = entityList.findIndex((x) => x.value === type);
    const item = entityList[getItem].label;
    return item;
  };

  getCardinal = (cardinal) => {
    const getItem = directionsList.findIndex((x) => x.value === cardinal);
    const item = directionsList[getItem].label;
    return item;
  };

  render() {
    const { fields } = this.state;
    return (
      // <Dialog
      //     fullWidth={true}
      //     maxWidth={"md"}
      //     open={this.props.open}
      //     onClose={() => this.props.CloseDialog()}
      //     aria-labelledby="form-dialog-title">
      //     <DialogTitle id="form-dialog-title">{this.props.Update ? "Actualizar" : "Crear"} Contacto</DialogTitle>
      //     <DialogContent>
      <>
        <Box
          sx={{
            minHeight: '100%',
            py: 3,
            marginTop: 5,
            padding: 0
          }}
        >
          <Container style={{ width: '100%', padding: 0 }}>
            <Box
              display="flex"
              flexDirection="row"
              width={'100%'}
              overflow={'hidden'}
            >
              <form
                onSubmit={this.formManeuvers.handleSubmit}
                style={{ width: '100%' }}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  width={'100%'}
                  overflow={'hidden'}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    width={'50%'}
                    overflow={'hidden'}
                    marginTop={5}
                  >
                    <div
                      style={{
                        fontFamily: 'sans-serif',
                        fontSize: 20,
                        fontWeight: 'bold',
                        color: '#045c9c',
                        marginTop: 0
                      }}
                    >
                      {'Maniobra'}
                    </div>

                    <div
                      className="form-row"
                      style={{ width: '95%', marginTop: 10 }}
                    >
                      {/* <FieldFormTemplate
                                                label="Tipo de entidad *"
                                                classCol="col-6 col-md-6"
                                                field={
                                                    <input
maxLength={65}
                                                        type="text"
                                                        name="name"
                                                        onBlur={this.formManeuvers.handleBlurEvent}
                                                        onChange={this.formManeuvers.handleChangeEvent}
                                                        value={this.state.fields.name}
                                                        data-attribute-name="Nombre "
                                                        style={styles.inputStyle}
                                                    //placeholder={"Nombre de la ruta"}
                                                    />
                                                }
                                                error={this.state.errors.name}
                                            /> */}

                      <DropDownWithSearchFormTemplate
                        label="Tipo de entidad *"
                        showIcon={true}
                        showFontAwesome={true}
                        className="col-6 col-md-6"
                        value={this.state.entity}
                        defaultValue={{
                          label: 'Seleccionar una entidad',
                          value: ''
                        }}
                        options={entityList.map((item, index) => {
                          return {
                            ...item,
                            label: item.label,
                            value: item.value
                          };
                        })}
                        name="entity"
                        error={
                          this.state.errors.entity
                            ? this.state.errors.entity
                            : ''
                        }
                        select={(e) => this.changueSelectMovement(e)}
                      />

                      <DropDownWithSearchFormTemplate
                        label="Movimiento *"
                        showIcon={true}
                        showFontAwesome={true}
                        className="col-5 col-md-5"
                        value={this.state.movement}
                        defaultValue={{
                          label: 'Seleccionar un Movimiento',
                          value: ''
                        }}
                        options={maneuversList.map((item, index) => {
                          return {
                            ...item,
                            label: item.label,
                            value: item.value
                          };
                        })}
                        name="movement"
                        error={
                          this.state.errors.movement
                            ? this.state.errors.movement
                            : ''
                        }
                        select={(e) => this.changueSelectMovement(e)}
                      />
                      <div className="col-1 col-md-1">
                        <Tooltip title="Conoce los movimientos de tránsito">
                          <InfoIcon
                            onClick={() =>
                              this.setState({
                                showManeuvers: true
                              })
                            }
                            style={{
                              marginRight: 10,
                              color: '#0099ff',
                              fontSize: 20,
                              marginTop: 32
                            }}
                          />
                        </Tooltip>
                      </div>
                    </div>

                    <div
                      className="form-row"
                      style={{ width: '95%', marginTop: 10 }}
                    >
                      <DropDownWithSearchFormTemplate
                        label="Compuerta Entrada *"
                        showIcon={true}
                        className="col-6 col-md-6"
                        value={this.state.geometris_id_entry}
                        defaultValue={{
                          label: 'Seleccionar una Compuerta Entrada',
                          value: ''
                        }}
                        options={this.state.gatesList.map((item, index) => {
                          return {
                            ...item,
                            label: index + 1,
                            value: item.id,
                            stroke: item.stroke
                          };
                        })}
                        name="geometris_id_entry"
                        error={
                          this.state.errors.geometris_id_entry
                            ? this.state.errors.geometris_id_entry
                            : ''
                        }
                        select={(e) => this.changueSelectMovement(e)}
                      />

                      <DropDownWithSearchFormTemplate
                        label="Compuerta Salida *"
                        showIcon={true}
                        className="col-6 col-md-6"
                        value={this.state.geometris_id_exit}
                        defaultValue={{
                          label: 'Seleccionar una Compuerta Salida',
                          value: ''
                        }}
                        options={this.state.gatesList.map((item, index) => {
                          return {
                            ...item,
                            label: index + 1,
                            value: item.id,
                            stroke: item.stroke
                          };
                        })}
                        name="geometris_id_exit"
                        error={
                          this.state.errors.geometris_id_exit
                            ? this.state.errors.geometris_id_exit
                            : ''
                        }
                        select={(e) => this.changueSelectMovement(e)}
                      />
                    </div>

                    <div
                      className="form-row"
                      style={{ width: '95%', marginTop: 10 }}
                    >
                      <DropDownWithSearchFormTemplate
                        label="Origen *"
                        showIcon={false}
                        className="col-6 col-md-6"
                        value={this.state.origin}
                        defaultValue={{
                          label: 'Seleccionar un Origen',
                          value: ''
                        }}
                        options={directionsList.map((item, index) => {
                          return {
                            ...item,
                            label: item.label,
                            value: item.value
                          };
                        })}
                        name="origin"
                        error={
                          this.state.errors.origin
                            ? this.state.errors.origin
                            : ''
                        }
                        select={(e) => this.changueSelectMovement(e)}
                      />

                      <DropDownWithSearchFormTemplate
                        label="Destino *"
                        showIcon={false}
                        className="col-6 col-md-6"
                        value={this.state.destination}
                        defaultValue={{
                          label: 'Seleccionar un Destino',
                          value: ''
                        }}
                        options={directionsList.map((item, index) => {
                          return {
                            ...item,
                            label: item.label,
                            value: item.value
                          };
                        })}
                        name="destination"
                        error={
                          this.state.errors.destination
                            ? this.state.errors.destination
                            : ''
                        }
                        select={(e) => this.changueSelectMovement(e)}
                      />
                    </div>

                    <div>
                      <input
                        maxLength={65}
                        type="text"
                        name="movement"
                        hidden={true}
                        onChange={this.formManeuvers.handleChangeEvent}
                        value={this.state.fields.movement}
                        data-attribute-name="Movimiento "
                        style={styles.inputStyle}
                      />
                      <input
                        maxLength={65}
                        type="text"
                        name="entity"
                        hidden={true}
                        onChange={this.formManeuvers.handleChangeEvent}
                        value={this.state.fields.entity}
                        data-attribute-name="Tipo entidad "
                        style={styles.inputStyle}
                      />

                      <input
                        maxLength={65}
                        type="text"
                        name="geometris_id_entry"
                        hidden={true}
                        onChange={this.formManeuvers.handleChangeEvent}
                        value={this.state.fields.geometris_id_entry}
                        data-attribute-name="Compuerta Entrada "
                        style={styles.inputStyle}
                      />

                      <input
                        maxLength={65}
                        type="text"
                        name="geometris_id_exit"
                        hidden={true}
                        onChange={this.formManeuvers.handleChangeEvent}
                        value={this.state.fields.geometris_id_exit}
                        data-attribute-name="Compuerta Salida "
                        style={styles.inputStyle}
                      />

                      <input
                        maxLength={65}
                        type="text"
                        name="origin"
                        hidden={true}
                        onChange={this.formManeuvers.handleChangeEvent}
                        value={this.state.fields.origin}
                        data-attribute-name="Origen "
                        style={styles.inputStyle}
                      />

                      <input
                        maxLength={65}
                        type="text"
                        name="destination"
                        hidden={true}
                        onChange={this.formManeuvers.handleChangeEvent}
                        value={this.state.fields.destination}
                        data-attribute-name="Destino "
                        style={styles.inputStyle}
                      />
                    </div>
                    <div
                      className="form-row"
                      style={{ width: '95%', marginTop: 10 }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          flex: 1,
                          flexDirection: 'row',
                          width: '100%',
                          marginLeft: 5
                        }}
                      >
                        <Button
                          endIcon={<Add />}
                          variant="contained"
                          color="primary"
                          type="submit"
                          // onClick={() => this.formManeuvers.onformsubmit()}
                          // disabled={
                          //     (fields.user === ""
                          //         || fields.password === ""
                          //         || fields.direction_ip === "")
                          //         ? true
                          //         : false
                          // }
                          style={{
                            outline: 'none',
                            width: '100%',
                            textTransform: 'none'
                          }}
                        >
                          Guardar Maniobra
                        </Button>
                      </Box>
                    </div>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    width={'50%'}
                    height={'20%'}
                    padding={2}
                    marginTop={3}
                  >
                    <div
                      className="form-row"
                      style={{ width: '95%', marginTop: 10, marginBottom: 20 }}
                    >
                      <GateForm
                        setAspectRatio={(e) =>
                          this.setState({
                            aspectRatioDimensions: {
                              width: e.width,
                              height: e.height
                            }
                          })
                        }
                        showFromManeuvers={true}
                        showFromParent={true}
                        gates={this.state.gatesList}
                        setGates={(gates) => this.setGatesData(gates)}
                        roadways={[
                          {
                            id: '7020',
                            name: 'Testing-Front'
                          }
                        ]}
                        setRoadways={(set) => console.log('setRoadways', set)}
                        cameraImg={
                          this.props.objStep1.image
                            ? `${this.props.objStep1.image}`
                            : '/static/images/avatars/null_image.png'
                        }
                      />
                    </div>
                    <ManeuversListResults
                      busy={this.props.busy}
                      estado={this.props.estado}
                      OpenEdit={(id) => this.openEdit(id)}
                      //onEnableDisable={this.handleEnableDisable}
                      Gates={
                        this.props.ManeuversList
                          ? this.props.ManeuversList.map((item, index) => {
                              return {
                                ...item,
                                id: item.public_key,
                                index: index,
                                geometris_id_entry: {
                                  id: item.geometris_id_entry,
                                  index: index,
                                  gate: this.getColorDriveways(
                                    item.geometris_id_entry
                                  )
                                },
                                geometris_id_exit: {
                                  id: item.geometris_id_exit,
                                  index: index,
                                  gate: this.getColorDriveways(
                                    item.geometris_id_exit
                                  )
                                },
                                Type: this.getType(item.Type),
                                destination: this.getCardinal(item.destination),
                                origin: this.getCardinal(item.origin)
                                //directionName: "Test"
                              };
                            })
                          : []
                      }
                    />
                  </Box>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Button
                    color="primary"
                    onClick={() => this.props.BackStep()}
                    variant="outlined"
                    startIcon={<KeyboardBackspaceIcon />}
                    style={{ outline: 'none' }}
                  >
                    Volver
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => this.setNextStep()}
                    variant="contained"
                    endIcon={<ArrowForwardIcon />}
                    style={{
                      outline: 'none',
                      backgroundColor: '#0099ff',
                      marginLeft: '30px'
                    }}
                  >
                    Siguiente
                  </Button>
                </Box>
              </form>
            </Box>
          </Container>
        </Box>

        <SweetAlert
          show={this.state.showConfirmEnableDisable}
          warning
          confirmBtnText="Aceptar"
          confirmBtnBsStyle="light"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="light"
          cancelBtnStyle={{ fontSize: 14 }}
          style={{ color: '#000', fontSize: 14 }}
          confirmBtnStyle={{ fontSize: 14 }}
          title={'Eliminar maniobra'}
          onConfirm={() => this.handleEnableDisable()}
          onCancel={() =>
            this.setState({ showConfirmEnableDisable: false, idToDelete: '' })
          }
          showCancel={true}
          showConfirm={true}
        >
          <span>{'¿Estás seguro que desea eliminar esta maniobra?'}</span>
        </SweetAlert>

        <SweetAlert
          show={this.state.showErrorCreateManeuvers}
          warning
          confirmBtnText="Aceptar"
          confirmBtnBsStyle="light"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="light"
          cancelBtnStyle={{ fontSize: 14 }}
          style={{ color: '#000', fontSize: 14 }}
          confirmBtnStyle={{ fontSize: 14 }}
          title={'Error'}
          onConfirm={() =>
            this.setState({
              showErrorCreateManeuvers: false,
              errorGeometries: false,
              errorOriDest: false
            })
          }
          onCancel={() =>
            this.setState({
              showErrorCreateManeuvers: false,
              errorGeometries: false,
              errorOriDest: false
            })
          }
          showCancel={false}
          showConfirm={true}
        >
          {this.state.errorGeometries === true ? (
            <span>
              {
                'Los campos Compuerta entrada y Compuerta salida deben de ser diferentes '
              }
            </span>
          ) : null}
          <br />
          {this.state.errorOriDest === true ? (
            <span>
              {'Los campos Origen y Destino deben de ser diferentes '}
            </span>
          ) : null}
        </SweetAlert>

        {this.state.openDialogCreateGates ? (
          <DialogCreateGatesVideo
            {...this.props}
            url={
              this.props.objStep1.image
                ? `${this.props.objStep1.image}`
                : '/static/images/avatars/null_image.png'
            }
            open={this.state.openDialogCreateGates}
            gatesList={this.state.gatesList}
            SaveGates={(gatesList) => this.saveGates(gatesList)}
            CloseDialog={() => this.closeDialogCreateGates()}
          />
        ) : null}

        {this.state.openDialogCreateFormFactor ? (
          <DialogCreateFormFactor
            {...this.props}
            url={
              this.props.dataCameraImage.image
                ? `${URL_API}/${this.props.dataCameraImage.image}`
                : '/static/images/avatars/null_image.png'
            }
            formFactorList={this.state.formFactorList}
            SaveformFactor={(formFactorObj) =>
              this.saveformFactor(formFactorObj)
            }
            open={this.state.openDialogCreateFormFactor}
            CloseDialog={() => this.closeDialogCreateFormFactor()}
          />
        ) : null}

        {this.state.showManeuvers ? (
          <DialogShowManeuvers
            open={this.state.showManeuvers}
            CloseDialog={() =>
              this.setState({
                showManeuvers: false
              })
            }
          />
        ) : null}
      </>
    );
  }
}

export default Maneuvers;
