/* eslint-disable */
export const COMPANIES_INICIA = 'COMPANIES_INICIA';
export const COMPANIES_LISTA = 'COMPANIES_LISTA';
export const COMPANIES_LISTADO = 'COMPANIES_LISTADO';
export const COMPANIES_ERROR = 'COMPANIES_ERROR';

export const COMPANIES_DETALLE_INICIA = "COMPANIES_DETALLE_INICIA";
export const COMPANIES_DETALLE = "COMPANIES_DETALLE";
export const COMPANIES_DETALLADO = "COMPANIES_DETALLADO";
export const COMPANIES_DETALLE_ERROR = "COMPANIES_DETALLE_ERROR";

export const COMPANIES_CONTACTS_INICIA = "COMPANIES_CONTACTS_INICIA";
export const COMPANIES_CONTACTS_LISTA = "COMPANIES_CONTACTS_LISTA";
export const COMPANIES_CONTACTS_LISTADO = "COMPANIES_CONTACTS_LISTADO";
export const COMPANIES_CONTACTS_ERROR = "COMPANIES_CONTACTS_ERROR";

export const COMPANIES_ACTUALIZAR = "COMPANIES_ACTUALIZAR";
export const COMPANIES_ACTUALIZADA = "COMPANIES_ACTUALIZADA";
export const COMPANIES_CREAR = "COMPANIES_CREAR";
export const COMPANIES_CREADA = "COMPANIES_CREADA";

export const COMPANIES_HABILITAR_DESHABILITAR = "COMPANIES_HABILITAR_DESHABILITAR";
export const COMPANIES_HABILITADA_DESHABILITADA = "COMPANIES_HABILITADA_DESHABILITADA";