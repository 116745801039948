/* eslint-disable */
import { call, put, takeEvery } from "redux-saga/effects";
import {
  actionCompaniesInicia,
  actionCompaniesListado,
  actionCompaniesError,
  actionCompaniesActualizada,
  actionCompaniesCreada,
  actionCompaniesHabilitadaDeshabilitada
} from "src/actions/api/companies";

import {
  apiCompaniesList,
  apiCompaniesActualizar,
  apiCompaniesCrear,
  apiCompaniestHabilitarDeshabilitar
} from "src/api/companies";

import {
  COMPANIES_LISTA,
  COMPANIES_CREAR,
  COMPANIES_ACTUALIZAR,
  COMPANIES_HABILITAR_DESHABILITAR
} from 'src/constants/api/companies';

function* sagaCompaniesLista({ type, params }) {
  try {
    yield put(actionCompaniesInicia());
    const data = yield call(apiCompaniesList, params);
    yield put(actionCompaniesListado(data));
  } catch (e) {
    yield put(actionCompaniesError(e));
  }
}

function* sagaCompaniesActualizar(action) {
  try {
    yield put(actionCompaniesInicia());
    const resp = yield call(apiCompaniesActualizar, action.data);
    yield put(actionCompaniesActualizada(resp));
  } catch (e) {
    yield put(actionCompaniesError(e));
  }
}


function* sagaCompaniesCrear(action) {
  try {
    yield put(actionCompaniesInicia());
    const resp = yield call(apiCompaniesCrear, action.data);
    yield put(actionCompaniesCreada(resp));
  } catch (e) {
    yield put(actionCompaniesError(e));
  }
}

function* sagaCompaniesHabilitarDeshabilitar(action) {
  try {
    yield put(actionCompaniesInicia());
    const resp = yield call(apiCompaniestHabilitarDeshabilitar, action.data);
    yield put(actionCompaniesHabilitadaDeshabilitada(resp));
  } catch (e) {
    //console.log("Saga error:", e)
    yield put(actionCompaniesError(e));
  }
}

export default [
  takeEvery(COMPANIES_LISTA, sagaCompaniesLista),
  takeEvery(COMPANIES_CREAR, sagaCompaniesCrear),
  takeEvery(COMPANIES_ACTUALIZAR, sagaCompaniesActualizar),
  takeEvery(COMPANIES_HABILITAR_DESHABILITAR, sagaCompaniesHabilitarDeshabilitar)
]
