/* eslint-disable */
export const CONTACTS_INICIA = 'CONTACTS_INICIA';
export const CONTACTS_LISTA = 'CONTACTS_LISTA';
export const CONTACTS_LISTADO = 'CONTACTS_LISTADO';
export const CONTACTS_ERROR = 'CONTACTS_ERROR';

export const CONTACTS_DETALLE_INICIA = 'CONTACTS_DETALLE_INICIA';
export const CONTACTS_DETALLE = 'CONTACTS_DETALLE';
export const CONTACTS_DETALLADO = 'CONTACTS_DETALLADO';
export const CONTACTS_DETALLE_ERROR = 'CONTACTS_DETALLE_ERROR';

export const CONTACTS_ACTUALIZAR = "CONTACTS_ACTUALIZAR";
export const CONTACTS_ACTUALIZADA = "CONTACTS_ACTUALIZADA";

export const CONTACTS_CREAR = "CONTACTS_CREAR";
export const CONTACTS_CREADA = "CONTACTS_CREADA";

export const CONTACTS_HABILITAR_DESHABILITAR = "CONTACTS_HABILITAR_DESHABILITAR";
export const CONTACTS_HABILITADA_DESHABILITADA = "CONTACTS_HABILITADA_DESHABILITADA";