/* eslint-disable */
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  actionDetectorsInicia,
  actionDetectorsListado,
  actionDetectorsError,
  actionDetectorsCreada,
  actionDetectorsActualizada,
  actionDetectorsHabilitadaDeshabilitada
} from 'src/actions/api/detectors';

import {
  apiDetectorsList,
  apiDetectorsCrear,
  apiDetectorsActualizar,
  apiDetectorsHabilitarDeshabilitar
} from 'src/api/detectors';

import {
  DETECTORS_LISTA,
  DETECTORS_CREAR,
  DETECTORS_ACTUALIZAR,
  DETECTORS_HABILITAR_DESHABILITAR
} from 'src/constants/api/detectors';

function* sagaDetectorsLista({ type }) {
  try {
    yield put(actionDetectorsInicia());
    const data = yield call(apiDetectorsList);
    yield put(actionDetectorsListado(data));
  } catch (e) {
    yield put(actionDetectorsError(e));
  }
}

function* sagaDetectorsActualizar(action) {
  try {
    yield put(actionDetectorsInicia());
    const resp = yield call(apiDetectorsActualizar, action.data);
    yield put(actionDetectorsActualizada(resp));
  } catch (e) {
    yield put(actionDetectorsError(e));
  }
}

function* sagaDetectorsCrear(action) {
  try {
    yield put(actionDetectorsInicia());
    const resp = yield call(apiDetectorsCrear, action.data);
    yield put(actionDetectorsCreada(resp));
  } catch (e) {
    yield put(actionDetectorsError(e));
  }
}

function* sagaDetectorsHabilitarDeshabilitar(action) {
  try {
    yield put(actionDetectorsInicia());
    const resp = yield call(apiDetectorsHabilitarDeshabilitar, action.data);
    yield put(actionDetectorsHabilitadaDeshabilitada(resp));
  } catch (e) {
    yield put(actionDetectorsError(e));
  }
}

export default [
  takeEvery(DETECTORS_LISTA, sagaDetectorsLista),
  takeEvery(DETECTORS_CREAR, sagaDetectorsCrear),
  takeEvery(DETECTORS_ACTUALIZAR, sagaDetectorsActualizar),
  takeEvery(
    DETECTORS_HABILITAR_DESHABILITAR,
    sagaDetectorsHabilitarDeshabilitar
  )
];
