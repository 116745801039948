/* eslint-disable */
import {
  CONTACTS_INICIA,
  CONTACTS_LISTA,
  CONTACTS_LISTADO,
  CONTACTS_ERROR,
  CONTACTS_DETALLE_INICIA,
  CONTACTS_DETALLE,
  CONTACTS_DETALLADO,
  CONTACTS_DETALLE_ERROR,
  CONTACTS_ACTUALIZAR,
  CONTACTS_ACTUALIZADA,
  CONTACTS_CREAR,
  CONTACTS_CREADA,
  CONTACTS_HABILITAR_DESHABILITAR,
  CONTACTS_HABILITADA_DESHABILITADA
} from 'src/constants/api/contacts';

export const actionContactsInicia = () => ({ type: CONTACTS_INICIA, });
export const actionContactsLista = (params) => ({ type: CONTACTS_LISTA, params });
export const actionContactsListado = (data) => ({ type: CONTACTS_LISTADO, data });
export const actionContactsError = (error) => ({ type: CONTACTS_ERROR, error });

export const actionContactsDetalleInicia = () => ({ type: CONTACTS_DETALLE_INICIA, });
export const actionContactsDetalle = (params) => ({ type: CONTACTS_DETALLE, params });
export const actionContactsDetallado = (data) => ({ type: CONTACTS_DETALLADO, data });
export const actionContactsDetalleError = (error) => ({ type: CONTACTS_DETALLE_ERROR, error });

export const actionContactsActualizar = data => ({ type: CONTACTS_ACTUALIZAR, data });
export const actionContactsActualizada = (params) => ({ type: CONTACTS_ACTUALIZADA, params });

export const actionContactsCrear = data => ({ type: CONTACTS_CREAR, data });
export const actionContactsCreada = (params) => ({ type: CONTACTS_CREADA, params });

export const actionContactsHabilitarDeshabilitar = data => ({ type: CONTACTS_HABILITAR_DESHABILITAR, data });
export const actionContactsHabilitadaDeshabilitada = (params) => ({ type: CONTACTS_HABILITADA_DESHABILITADA, params });