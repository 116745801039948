/* eslint-disable */
import React, { Component } from 'react';
import {
    Box,
} from '@material-ui/core';

//Kanban
import '@lourenci/react-kanban/dist/styles.css'
//opciones
import { Edit, Reorder, ViewWeek } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';

import styles from './styles';

class RequestCanvaCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        const { title, proyects, variable, totalInvestment, } = this.props;
        if (this.props.typeCard === "title") {
            return (
                <Box width={"100%"} margin={1}>
                    <Box width={"100%"} bgcolor={"#B6B6B6"} padding={1}>
                        <div style={styles.canvaText}>{title}</div>
                    </Box>
                    <Box width={"100%"} bgcolor={"#F1F1F1"} padding={1}>
                        <Box width={"100%"} display="flex" flexDirection="row" justifyContent="space-between">
                            <div style={styles.canvaText}>Proyectos</div>
                            <div style={styles.canvaText}>{proyects}</div>
                        </Box>

                        {/* <Box width={"100%"} display="flex" flexDirection="row" justifyContent="space-between">
                            <div style={styles.canvaText}>Variable</div>
                            <div style={styles.canvaText}>$ {parseFloat(variable).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</div>
                        </Box> */}

                        <Box width={"100%"} display="flex" flexDirection="row" justifyContent="space-between">
                            <div style={styles.canvaText}>Total inversión</div>
                            <div style={styles.canvaText}>$ {parseFloat(totalInvestment).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</div>
                        </Box>

                    </Box>
                </Box>
            );
        } else {
            const { lastUpdate, executive, promotor, totalInvestment, radicado, date, projectName, idRegistry } = this.props;
            return (
                <Box width={"100%"} margin={1}>

                    <Box width={"100%"} display="flex" flexDirection="row" justifyContent="space-between">
                        <div style={styles.canvaText}>{projectName}</div>
                        <div style={styles.canvaText}>{date}</div>
                    </Box>

                    <Box border={1} borderColor={"#B6B6B6"} borderRadius={1}>
                        <Box width={"100%"} padding={1}>
                            <Box width={"100%"} display="flex" flexDirection="row" justifyContent="space-between">
                                <div style={styles.canvaText}>Ejecutivo</div>
                                <div style={styles.canvaText}>{executive}</div>
                            </Box>

                            {/* <Box width={"100%"} display="flex" flexDirection="row" justifyContent="space-between">
                                <div style={styles.canvaText}>Promotor</div>
                                <div style={styles.canvaText}>{promotor}</div>
                            </Box> */}

                            <Box width={"100%"} display="flex" flexDirection="row" justifyContent="space-between">
                                <div style={styles.canvaText}>{radicado}</div>
                                <div style={styles.canvaText}>$ {parseFloat(totalInvestment).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</div>
                            </Box>
                        </Box>
                        <Box width={"100%"} display="flex" flexDirection="row" bgcolor={"#B6B6B6"} padding={1} justifyContent="space-between" alignItems="center">
                            <div style={styles.canvaText}>{lastUpdate}</div>

                            <IconButton style={{margin: 0, padding: 0}} onClick={() => this.props.OnEdit(idRegistry)}>
                                <Edit style={{fontSize: 16}} />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            );
        }

    }
}

export default RequestCanvaCard;
