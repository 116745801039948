/* eslint-disable */
import React, { Component } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowsLeftRight,
  faArrowsUpDown
} from '@fortawesome/free-solid-svg-icons';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import FormFactorForm from './FormFactorForm';

import ReactFormInputValidation from 'react-form-input-validation';
import FieldFormTemplate from '../FieldFormTemplate';
import styles from './styles';

//konva

const url =
  'https://thumbs.dreamstime.com/z/highway-medellin-one-highways-main-roads-city-colombia-heavy-traffic-different-cars-south-america-186557710.jpg';

class DialogCreateFormFactor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        title: this.props.dataDetail ? this.props.dataDetail.title : '',
        width: '',
        height: ''
      },
      errors: {},
      selectedId: null,
      roadway: 0,
      gates: [],
      formFactor: [],
      showConfirmEnableDisable: false,
      idToDelete: '',
      showError: false
    };

    this.formFormFactor = new ReactFormInputValidation(this, { locale: 'es' });
    this.formFormFactor.onformsubmit = async (fields) => {
      if (
        this.state.formFactor.length > 0 &&
        this.state.formFactor.length == 2
      ) {
        const objToSend = {
          w_m: fields.width,
          l_m: fields.height,
          formFactor: this.state.formFactor
        };
        //console.log("formFormFactor:", objToSend)
        this.props.SaveformFactor(objToSend);
      } else {
        this.setState({
          showError: true
        });
      }
    };
  }

  componentDidMount() {
    this.formFormFactor.useRules({
      title: 'required',
      width: 'required',
      height: 'required'
    });
    this.setState({
      formFactor: this.props.formFactorList
    });
  }

  setGatesData = (gates) => {
    this.setState({
      gates: gates
    });
    //console.log("set Gate: ", gates);
  };

  openEdit = (id) => {
    this.setState({
      showConfirmEnableDisable: true,
      idToDelete: id
    });
  };

  setGatesFromProps = () => {
    this.setState({
      formFactor: this.props.formFactorList
    });
  };

  handleEnableDisable = async (data) => {
    // let gateListCopy = await [...this.state.gates];
    // let elementToDelete = await gateListCopy.findIndex((e) => e.id === this.state.idToDelete);
    // await gateListCopy.splice(elementToDelete, 1);
    // this.setState({
    //     showConfirmEnableDisable: false,
    //     idToDelete: "",
    //     gates: gateListCopy
    // });
    this.setState({
      showError: false
    });
  };

  setFormFactors = async (formFacts) => {
    this.setState({
      formFactor: formFacts
    });
    //console.log("set formFacts: ", formFacts);
  };

  setRoadways = async (roadWays) => {
    //console.log("roadWays: ", roadWays)
  };

  setCamera = async (camera) => {
    //console.log("camera: ", camera)
  };

  render() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={this.props.open}
        onClose={() => {
          this.setState(
            {
              formFactor: []
            },
            () => {
              this.props.SaveformFactor({});
            }
          );
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {this.props.Update ? 'Actualizar' : 'Crear'} Factor de Forma
        </DialogTitle>
        <DialogContent>
          <form
            onSubmit={this.formFormFactor.handleSubmit}
            style={{ width: '100%' }}
          >
            <Box
              display="flex"
              flexDirection="row"
              width={'100%'}
              overflow={'hidden'}
            >
              <Box
                display="flex"
                flexDirection="row"
                width={'100%'}
                overflow={'hidden'}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  width={'100%'}
                  overflow={'hidden'}
                  style={{
                    padding: 5
                  }}
                >
                  <div
                    className="form-row"
                    style={{ width: '100%', marginTop: 10, marginBottom: 20 }}
                  >
                    <FormFactorForm
                      formFactors={this.state.formFactor}
                      setFormFactors={(formFacts) =>
                        this.setFormFactors(formFacts)
                      }
                      roadways={[]}
                      setRoadways={(roadWays) => this.setRoadways(roadWays)}
                      cameraImg={this.props.url}
                      camera={{}}
                      setCamera={(camera) => this.setCamera(camera)}
                    />

                    {/* <GateForm
                                        gates={this.state.gates}
                                        setGates={(gates) => this.setGatesData(gates)}
                                        roadways={[{
                                            id: "7020",
                                            name: "Testing-Front"
                                        }]}
                                        setRoadways={(set) => //console.log("setRoadways", set)}
                                        cameraImg={"/static/images/avatars/road.jpg"}
                                    /> */}
                  </div>

                  <div className="form-row">
                    <div class="col-3 col-md-3"></div>
                    <FieldFormTemplate
                      label="Largo en metros *"
                      classCol="col-3 col-md-3"
                      field={
                        <div
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            display: 'flex'
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faArrowsLeftRight}
                            style={{ fontSize: '25px', color: '#0099ff' }}
                          />
                          <EditRoadIcon
                            style={{ fontSize: '40px', color: 'black' }}
                          />
                          <input
                            type="number"
                            name="height"
                            onBlur={this.formFormFactor.handleBlurEvent}
                            onChange={this.formFormFactor.handleChangeEvent}
                            value={this.state.fields.height}
                            data-attribute-name="Largo en metros"
                            style={styles.inputStyle}
                          />
                        </div>
                      }
                      error={this.state.errors.height}
                    />

                    <FieldFormTemplate
                      label="Ancho en metros *"
                      classCol="col-3 col-md-3"
                      field={
                        <div
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            display: 'flex'
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faArrowsUpDown}
                            style={{ fontSize: '25px', color: '#0099ff' }}
                          />
                          <EditRoadIcon
                            style={{ fontSize: '40px', color: 'black' }}
                          />
                          <input
                            type="number"
                            name="width"
                            onBlur={this.formFormFactor.handleBlurEvent}
                            onChange={this.formFormFactor.handleChangeEvent}
                            value={this.state.fields.width}
                            data-attribute-name="Ancho en metros"
                            style={styles.inputStyle}
                          />
                        </div>
                      }
                      error={this.state.errors.width}
                    />
                    <div classCol="col-3 col-md-3"></div>
                  </div>

                  <div className="form-row"></div>
                </Box>
              </Box>
            </Box>
            <DialogActions>
              <Button
                color="primary"
                type="submit"
                //onClose={() => this.props.SaveformFactor(this.state.formFactor)}
              >
                Guardar
              </Button>
              <Button
                onClick={() => {
                  this.setState(
                    {
                      formFactor: []
                    },
                    () => {
                      this.props.SaveformFactor({});
                    }
                  );
                }}
                color="primary"
              >
                Cerrar
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
        {/* <DialogActions>
                    <Button color="primary" type="submit">
                        Guardar
                    </Button>
                    <Button onClick={() => this.props.CloseDialog()} color="primary">
                        Cerrar
                    </Button>
                </DialogActions> */}
        <ToastContainer position={'top-right'} delay={5000} />
        <SweetAlert
          show={this.state.showError}
          warning
          confirmBtnText="Aceptar"
          confirmBtnBsStyle="light"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="light"
          cancelBtnStyle={{ fontSize: 14 }}
          style={{ color: '#000', fontSize: 14 }}
          confirmBtnStyle={{ fontSize: 14 }}
          title={'Agregar factor de forma'}
          onConfirm={() => this.handleEnableDisable()}
          onCancel={() => this.setState({ showError: false, idToDelete: '' })}
          showCancel={false}
          showConfirm={true}
        >
          <span>{'Debes añadir dos factores de forma'}</span>
        </SweetAlert>
      </Dialog>
    );
  }
}

export default DialogCreateFormFactor;
