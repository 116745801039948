/* eslint-disable */

import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

/* eslint-disable */
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MsalContext } from '@azure/msal-react';

import { actionUsuarioLogin } from 'src/actions/api/user';

const DashboardLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}));

const DashboardLayoutWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 256
  }
}));

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

class DashboardLayout extends Component {
  static contextType = MsalContext;
  constructor(props) {
    super(props);
    this.state = {
      isMobileNavOpen: false,
      show: false,
      key: Math.random()
    };
  }

  componentDidMount() {
    this.getUserEmail();
  }

  componentDidUpdate(preprops) {
    if (this.props.dataUser !== preprops.dataUser) {
      this.setNewKey();
    }
    // if (this.props.apiRespuesta) {
    //   switch (this.props.estado) {
    //     case "DETALLE":
    //       //console.log("UserData: ", this.props.dataUser)
    //       break;
    //     default:
    //       break;
    //   }
    // }
  }

  getUserEmail = async () => {
    const msalAccounts = this.context.accounts;
    //const getTokenLocalStorage = await localStorage.getItem('fcmToken');
    this.props.actionUsuarioLogin({
      email: msalAccounts[0].username
      //Token: getTokenLocalStorage
    });
  };

  setNewKey = () => {
    this.setState({
      key: Math.random()
    });
  };
  render() {
    return (
      <DashboardLayoutRoot>
        <DashboardNavbar
          onMobileNavOpen={() => this.setState({ isMobileNavOpen: true })}
        />
        {this.props.dataUser ? (
          <DashboardSidebar
            onMobileClose={() => this.setState({ isMobileNavOpen: false })}
            openMobile={this.state.isMobileNavOpen}
            {...this.props}
            dataUser={this.props.dataUser}
            key={this.state.key}
          />
        ) : null}
        <DashboardLayoutWrapper>
          <DashboardLayoutContainer>
            <DashboardLayoutContent>
              <Outlet />
            </DashboardLayoutContent>
          </DashboardLayoutContainer>
        </DashboardLayoutWrapper>
      </DashboardLayoutRoot>
    );
  }
}

const mapStateToProps = ({ user, analyticSettings }) => ({
  dataUser: user.items,
  estado: user.estado,
  apiRespuesta: user.apiRespuesta,

  busy: user.waiting,
  error: user.error
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      actionUsuarioLogin
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);

//export default DashboardLayout;
