/* eslint-disable */
import React, { Component } from 'react';

import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';

import Button from '@material-ui/core/Button';

import styles from './styles'

import ReactFormInputValidation from "react-form-input-validation";
import FieldFormTemplate from '../FieldFormTemplate';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const booleanList = [
    {
        name: "Sí",
        value: true,
    },
    {
        name: "No",
        value: false,
    }
]


class FrameCreateDetector extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: {
                idRegistry: "",
                name: (this.props.Update && this.props.dataDetail) ? this.props.dataDetail.name : "",

                description: (this.props.Update && this.props.dataDetail) ? this.props.dataDetail.description : "",

                ip_address: (this.props.Update && this.props.dataDetail) ? this.props.dataDetail.ip_address : "",

                mac_addr: (this.props.Update && this.props.dataDetail) ? this.props.dataDetail.mac_addr : "",

                username: (this.props.Update && this.props.dataDetail) ? this.props.dataDetail.username : "",

                password: (this.props.Update && this.props.dataDetail) ? this.props.dataDetail.password : "",

                vpn_username: (this.props.Update && this.props.dataDetail) ? this.props.dataDetail.vpn_username : "",

                vpn_password: (this.props.Update && this.props.dataDetail) ? this.props.dataDetail.vpn_password : "",

                gpu_type: (this.props.Update && this.props.dataDetail) ? this.props.dataDetail.gpu_type : "",
            },
            errors: {},
        };
        this.form = new ReactFormInputValidation(this, { locale: 'es' });
        this.form.onformsubmit = async (fields) => {
            const objToSend = {
                name: fields.name,

                description: fields.description,

                ip_address: fields.ip_address,

                mac_addr: fields.mac_addr,

                username: fields.username,

                password: fields.password,

                vpn_username: fields.vpn_username,

                vpn_password: fields.vpn_password,

                gpu_type: fields.gpu_type,
                created_user: this.props.dataUserLog.public_key,

                vpn_active: true,

            }
            //console.log("Obj to send:", objToSend);
            if (this.props.Update === true) {
                this.props.actionDetectorsActualizar({
                    ...objToSend,
                    id: this.props.dataDetail.public_key,
                    //updatedBy: "A40AF516-4209-4C5A-8EF0-D28328AFA10F"
                });
            } else {
                this.props.actionDetectorsCrear({ ...objToSend });
            }
        }
    }

    componentDidUpdate(preprops) {
        if (this.props.dataDetail !== preprops.dataDetail) {
            this.setDataFields();
        }
        if (this.props.apiRespuestaRequest) {
            switch (this.props.estadoRequest) {
                case "CREADO":
                    toast.success("Se ha creado exitosamente", {
                        backgroundColor: "#1ABD24",
                        color: "#ffffff",
                    });
                    this.props.CloseDialog();
                    break;
                default:
                    break;
            }
            this.props.actionLimpiarEstado()
        }
    }

    async componentDidMount() {
        this.form.useRules({
            name: "required",
            description: "required",
            ip_address: "required",
            mac_addr: "required",
            username: "required",
            password: "required",
            vpn_password: "required",
            vpn_username: "required",
            gpu_type: "required"
        });
        //this.setDataFields();
        // if(this.props.dataUserLog.is_admin === false) {
        //     var arrayToFilter = [];
        //     await arrayToFilter.push(this.props.dataUserLog.client_id)
        //     const companies = await this.props.dataCompanies.filter(i => arrayToFilter.includes(i.public_key));
        //     this.selectDropdownSearch({
        //         ...companies[0],
        //         label: companies[0].name,
        //         value: companies[0].public_key,
        //     })
        // }
    }

    setDataFields = () => {
        this.setState({
            fields: {
                name: (this.props.Update && this.props.dataDetail) ? this.props.dataDetail.name : "",

                description: (this.props.Update && this.props.dataDetail) ? this.props.dataDetail.description : "",

                mac_addr: (this.props.Update && this.props.dataDetail) ? this.props.dataDetail.mac_addr : "",

                ip_address: (this.props.Update && this.props.dataDetail) ? this.props.dataDetail.ip_address : "",

                username: (this.props.Update && this.props.dataDetail) ? this.props.dataDetail.username : "",

                password: (this.props.Update && this.props.dataDetail) ? this.props.dataDetail.password : "",

                vpn_username: (this.props.Update && this.props.dataDetail) ? this.props.dataDetail.vpn_username : "",

                vpn_password: (this.props.Update && this.props.dataDetail) ? this.props.dataDetail.vpn_password : "",

                gpu_type: (this.props.Update && this.props.dataDetail) ? this.props.dataDetail.gpu_type : "",

                //holidaysEnd: this.props.dataDetail ? moment(this.props.dataDetail.holidaysEnd).format("YYYY-MM-DD") : "",
            },
        });
    }


    render() {
        return (

            <>
                <Helmet>
                    <title>Detector</title>
                </Helmet>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}
                >
                    <div style={{
                        fontFamily: "sans-serif",
                        fontSize: 18,
                        fontWeight: "bold"
                    }}>{this.props.Update ? "Editar" : "Agregar"} Detector</div>
                </Box>


                <Box
                    sx={{
                        backgroundColor: '#fff',
                        minHeight: '100%',
                        py: 3,
                        marginTop: 9
                    }}
                >
                    <Container maxWidth={false}>

                        <Box display="flex" flexDirection="row" width={"100%"} overflow={"hidden"}>
                            <form onSubmit={this.form.handleSubmit} style={{ width: "100%", }}>
                                <div className="form-row">
                                    <FieldFormTemplate
                                        label="Nombre *"
                                        classCol="col-6 col-md-6"
                                        field={
                                            <input
                                                maxLength={65}
                                                type="text"
                                                name="name"
                                                onBlur={this.form.handleBlurEvent}
                                                onChange={this.form.handleChangeEvent}
                                                value={this.state.fields.name}
                                                data-attribute-name="Nombre "
                                                style={styles.inputStyle}
                                            />
                                        }
                                        error={this.state.errors.name}
                                    />

                                    <FieldFormTemplate
                                        label="Descripción *"
                                        classCol="col-6 col-md-6"
                                        field={
                                            <input
                                                maxLength={65}
                                                type="text"
                                                name="description"
                                                onBlur={this.form.handleBlurEvent}
                                                onChange={this.form.handleChangeEvent}
                                                value={this.state.fields.description}
                                                data-attribute-name="Descripción"
                                                style={styles.inputStyle}
                                            />
                                        }
                                        error={this.state.errors.description}
                                    />
                                </div>


                                <div className="form-row">

                                    <FieldFormTemplate
                                        label="Dirección IP *"
                                        classCol="col-6 col-md-6"
                                        field={
                                            <input
                                                maxLength={65}
                                                type="text"
                                                name="ip_address"
                                                onBlur={this.form.handleBlurEvent}
                                                onChange={this.form.handleChangeEvent}
                                                value={this.state.fields.ip_address}
                                                data-attribute-name="Dirección IP"
                                                style={styles.inputStyle}
                                            />
                                        }
                                        error={this.state.errors.ip_address}
                                    />

                                    <FieldFormTemplate
                                        label="MAC *"
                                        classCol="col-6 col-md-6"
                                        field={
                                            <input
                                                maxLength={65}
                                                type="text"
                                                name="mac_addr"
                                                onBlur={this.form.handleBlurEvent}
                                                onChange={this.form.handleChangeEvent}
                                                value={this.state.fields.mac_addr}
                                                data-attribute-name="MAC"
                                                style={styles.inputStyle}
                                            />
                                        }
                                        error={this.state.errors.mac_addr}
                                    />

                                </div>


                                <div className="form-row">

                                    <FieldFormTemplate
                                        label="Tipo GPU *"
                                        classCol="col-6 col-md-6"
                                        field={
                                            <input
                                                maxLength={65}
                                                type="text"
                                                name="gpu_type"
                                                onBlur={this.form.handleBlurEvent}
                                                onChange={this.form.handleChangeEvent}
                                                value={this.state.fields.gpu_type}
                                                data-attribute-name="Tipo GPU"
                                                style={styles.inputStyle}
                                            />
                                        }
                                        error={this.state.errors.gpu_type}
                                    />


                                </div>
                                <Box
                                    style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                        width: "100%",
                                        justifyContent: "flex-end"
                                    }}>
                                    <Button color="primary" type="submit" style={{ outline: "none" }}>
                                        Guardar
                                    </Button>
                                    {this.props.Update ?
                                        <Button onClick={() => this.props.DeleteItem()} style={{ outline: "none", color: "#D71919", alignSelf: "flex-end" }}>
                                            Eliminar
                                        </Button>
                                        : null}
                                    <Button onClick={() => this.props.CloseDialog()} color="primary" style={{ outline: "none" }}>
                                        Cerrar
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    </Container>
                </Box>
                {/* </DialogContent>

                <SweetAlert
                    show={this.state.showErrorDate}
                    error
                    confirmBtnText="Aceptar"
                    confirmBtnBsStyle="light"
                    style={{ color: "#000", fontSize: 14 }}
                    title={"Error"}
                    onConfirm={() => this.setState({
                        showErrorDate: !this.state.showErrorDate
                    })}
                >
                    <span>{"Fecha Fin debe ser mayor a Fecha inicio"}</span>
                </SweetAlert>

                <ToastContainer position={"top-right"} delay={5000} />
            </Dialog> */}
            </>

        )
    }
}



export default FrameCreateDetector;