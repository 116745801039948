/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import {
  actionCompaniesLista,
  actionCompaniesActualizar,
  actionCompaniesCrear,
  actionCompaniesHabilitarDeshabilitar
} from "src/actions/api/companies";

import {
  actionPermissionsLista,
} from "src/actions/api/permissions";

import {
  actionCompaniesDetalle
} from "src/actions/api/companiesDetail"

import { actionLimpiarEstado } from "src/actions";


import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import CompanyListResults from 'src/components/company/CompanyListResults';
import CompanyListToolbar from 'src/components/company/CompanyListToolbar';
import Loading from 'src/components/Loading';
import { ToastContainer, toast } from 'react-toastify';
import FrameCreateCompany from 'src/components/FrameCreateCompany';
import SweetAlert from 'react-bootstrap-sweetalert';

class CompanyList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      openDialogEdit: false,
      idToEdit: "",
      textFilter: "",
      dataCompaniesCopy: [],
      dataCompanies: [],
      showCreate: false,
      update: false,
      showDelete: false,
    }
  }

  componentDidMount() {
    this.props.actionCompaniesLista({active: true});
    this.props.actionPermissionsLista();
  }

  componentDidUpdate(preprops) {
    if (this.props.apiRespuesta) {
      this.props.actionLimpiarEstado()
    }

    if (this.props.apiRespuesta) {
      switch (this.props.estado) {
        case "LISTADO":
          this.setState({ dataCompaniesCopy: this.props.dataCompanies, dataCompanies: this.props.dataCompanies });
          break;
        case "ACTUALIZADO":
          toast.success("La Empresa se ha actualizado exitosamente", {
            backgroundColor: "#1ABD24",
            color: "#ffffff",
          });
          this.executeClose();
          break;
        case "CREADO":
          toast.success("La Empresa se ha creado exitosamente", {
            backgroundColor: "#1ABD24",
            color: "#ffffff",
          });
          this.executeClose();
          break;
        case "HABILITADO_DESHABILITADO":
          toast.success("Empresa eliminada exitosamente", {
            backgroundColor: "#1ABD24",
            color: "#ffffff",
          });
          this.setState({
            idToEdit: "",
            showCreate: false,
            update: false,
          })
          this.props.actionCompaniesLista({active: true});
          break;
        case "ERROR":
          //console.log("Front error: ", this.props.error);
          toast.error(this.props.error, {
            backgroundColor: "#E80D0D",
            color: "#ffffff",
          });
          this.props.actionCompaniesLista({active: true});
          break;
        default:
          break;
      }
      this.props.actionLimpiarEstado()
    }
  }

  openEdit = (id) => {
    this.setState({
      showCreate: true,
      idToEdit: id,
      update: true,
    });
    this.props.actionCompaniesDetalle({ id: id });
  }

  closeDialog = () => {
    this.setState({
      showCreate: false,
      idToEdit: "",
      update: false,
    });
  }

  executeClose = async () => {
    this.setState({
      showCreate: false,
      idToEdit: "",
      textFilter: "",
      update: false
    }, () => {
      this.props.actionCompaniesLista({active: true});
    });
  }

  handleChange = async e => {
    const textData = e.toString().toUpperCase();
    //console.log("textData: ", textData);
    //console.log("E: ", e)
    const newData = await this.state.dataCompanies.filter(item => {
      var listPermission = [];
      item.permissions.forEach(element => {
        listPermission.push(element.permission.name)
      });

      return item.nit.toUpperCase().indexOf(textData) > -1 || item.name.toUpperCase().indexOf(textData) > -1 || item.email.toUpperCase().indexOf(textData) > -1 || item.phone.toUpperCase().indexOf(textData) > -1 || item.detectors.toString().toUpperCase().indexOf(textData) > -1 || item.limit_user.toString().toUpperCase().indexOf(textData) > -1 || listPermission.toString().toUpperCase().indexOf(textData) > -1
    });
    this.setState({ dataCompaniesCopy: newData, textFilter: e });
  };

  exportToExcel = async () => {
    //console.log("dataCompanies", this.state.dataCompanies)

    const filterData = this.state.dataCompanies.map(item => {
      return {
        nit: item.nit,
        name: item.name,
        email: item.email,
        phone: item.phone,
        limit_user: item.limit_user,
        detectors: item.detectors,
        permisos: item.permissions.map((pItem) => {
          ////console.log("pItem", pItem.permission.name);
          return pItem.permission.name
        })
      }
    })


    const Heading = [["NIT", "Nombre", "Correo electrónico", "Teléfono", "Número de usuarios", "Número detectores", "Permisos"]];
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const ws = XLSX.utils.json_to_sheet(filterData, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, Heading);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Empresa.xlsx");
  }

  // handleEnableDisable = async (data) => {
  //   await this.props.actionCompaniesHabilitarDeshabilitar(data)
  // }

  showCreate = () => {
    this.setState({
      showCreate: !this.state.showCreate,
      update: false,
    });
  }

  deleteItem = () => {
    this.setState({
      showDelete: true
    })
  }

  confirmDelete = async () => {
    //console.log("id to delete: ", this.state.idToEdit);
    await this.props.actionCompaniesHabilitarDeshabilitar({ id: this.state.idToEdit })
    this.setState({
      showDelete: false
    })
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Empresas</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          {this.state.showCreate ?
            <Container maxWidth={false}>
              <FrameCreateCompany
                {...this.props}
                dataDetail={this.props.dataCompaniesDetail}
                CloseDialog={() => this.showCreate()}
                DeleteItem={() => this.deleteItem()}
                Update={this.state.update}
              />
            </Container>
            :
            <Container maxWidth={false}>
              <CompanyListToolbar {...this.props}
                filter={this.state.textFilter}
                actionShowFrameCreate={() => this.showCreate()}
                exportToExcel={() => this.exportToExcel()}
                handleChange={(e) => this.handleChange(e)} />
              <Box sx={{ pt: 3 }}>
                {this.props.dataCompanies ?
                  <CompanyListResults
                    {...this.props}
                    busy={this.props.busy}
                    estado={this.props.estado}
                    OpenEdit={(id) => this.openEdit(id)}
                    onEnableDisable={this.handleEnableDisable}
                    Companys={this.state.dataCompaniesCopy
                      ? this.state.dataCompaniesCopy.map(item => {
                        return {
                          ...item,
                          id: item.public_key
                        }
                      })
                      : []} />
                  : null}
              </Box>
            </Container>
          }
        </Box>
        <SweetAlert
          danger
          show={this.state.showDelete}
          showCancel
          confirmBtnText="Aceptar"
          confirmBtnBsStyle="light"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="light"
          cancelBtnStyle={{ fontSize: 14 }}
          style={{ color: "#000", fontSize: 14 }}
          confirmBtnStyle={{ fontSize: 14 }}

          onConfirm={() => this.confirmDelete()}
          onCancel={() => this.setState({ showDelete: false })}
        >
          <span>{"¿Está seguro de eliminar esta Empresa?"}</span>
        </SweetAlert>

        <Loading busy={this.props.busy} />
        <ToastContainer position={"top-right"} delay={5000} />
      </>
    )
  }
}

const mapStateToProps = ({ companies, companiesDetail, permissions, user }) => ({
  dataCompanies: companies.items,
  estado: companies.estado,
  apiRespuesta: companies.apiRespuesta,
  busy: companies.waiting || companiesDetail.waiting || permissions.waiting,
  error: companies.error || companiesDetail.error || permissions.error,

  dataCompaniesDetail: companiesDetail.items,
  dataPermissionsList: permissions.items,
  dataUserLog: user.items
});

const mapDispatchToProps = dispatch => bindActionCreators({
  actionCompaniesLista,
  actionPermissionsLista,

  actionLimpiarEstado,
  actionCompaniesDetalle,
  actionCompaniesActualizar,
  actionCompaniesCrear,
  actionCompaniesHabilitarDeshabilitar,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyList);
