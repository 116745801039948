/* eslint-disable */
import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Typography, IconButton } from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import { MsalContext } from '@azure/msal-react';

import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from '../authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

const NotPermissions = () => {
  const { instance } = React.useContext(MsalContext);

  const handleLogout = (logoutType) => {
    if (logoutType === 'popup') {
      msalInstance.logoutPopup();
    } else if (logoutType === 'redirect') {
      msalInstance.logoutRedirect();
    }
  };

  return (
    <>
      <Helmet>
        <title>401 | D33P</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="md">
          <Typography align="center" color="textPrimary" variant="h1">
            Su cuenta no tiene permisos para acceder a esta herramienta.
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle2">
            Póngase en contacto con el administrador del sitio.
            <IconButton
              onClick={() => handleLogout('redirect')}
              key="logoutRedirect"
              color="inherit"
            >
              <InputIcon />
              <Typography
                align="center"
                color="textPrimary"
                variant="subtitle2"
              >
                Cerrar sesión
              </Typography>
            </IconButton>
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default NotPermissions;
