/* eslint-disable */
import {
    ANALYTIC_SETTINGS_INICIA,
    ANALYTIC_SETTINGS_LIST,
    ANALYTIC_SETTINGS_LISTADO,
    ANALYTIC_SETTINGS_ERROR,
    ANALYTIC_SETTINGS_ACTUALIZAR,
    ANALYTIC_SETTINGS_ACTUALIZADA,
    ANALYTIC_SETTINGS_CREAR,
    ANALYTIC_SETTINGS_CREADA
} from 'src/constants/api/analyticSettings';

export const actionAnalyticSettingsInicia = () => ({ type: ANALYTIC_SETTINGS_INICIA, });
export const actionAnalyticSettingsError = (error) => ({ type: ANALYTIC_SETTINGS_ERROR, error });

//Lista
export const actionAnalyticSettingsList = (params) => ({ type: ANALYTIC_SETTINGS_LIST, params });
export const actionAnalyticSettingsListado = (data) => ({ type: ANALYTIC_SETTINGS_LISTADO, data });

//Actualizar
export const actionAnalyticSettingsActualizar = data => ({ type: ANALYTIC_SETTINGS_ACTUALIZAR, data });
export const actionAnalyticSettingsActualizada = (params) => ({ type: ANALYTIC_SETTINGS_ACTUALIZADA, params });

//Crear
export const actionAnalyticSettingsCrear = data => ({ type: ANALYTIC_SETTINGS_CREAR, data });
export const actionAnalyticSettingsCreada = (params) => ({ type: ANALYTIC_SETTINGS_CREADA, params });