/* eslint-disable */
import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Card, ThemeProvider, createMuiTheme } from '@material-ui/core';
import { esES } from '@material-ui/data-grid';
import CameraTableResults from './CameraTableResults';

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#1976d2' }
    }
  },
  esES
);

class CameraListResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCompanyIds: [],
      setSelectedCompanyIds: [],
      limit: 10,
      setLimit: 10,
      page: 0,
      setPage: 0
    };
  }

  handleSelectAll = (event) => {
    console.log('me estoy llamando');
    const { Cameras } = this.props;
    let newSelectedCompanyIds;
    if (event.target.checked) {
      newSelectedCompanyIds = Cameras.map((Company) => Company.idRegistry);
    } else {
      newSelectedCompanyIds = [];
    }
    this.setState({
      selectedCompanyIds: newSelectedCompanyIds
    });
    //setSelectedCompanyIds(newSelectedCompanyIds);
  };

  handleSelectOne = (event, idRegistry) => {
    console.log('me estoy llamando');
    const { selectedCompanyIds } = this.state;
    const selectedIndex = selectedCompanyIds.indexOf(idRegistry);
    let newSelectedCompanyIds = [];

    if (selectedIndex === -1) {
      newSelectedCompanyIds = newSelectedCompanyIds.concat(
        selectedCompanyIds,
        idRegistry
      );
    } else if (selectedIndex === 0) {
      newSelectedCompanyIds = newSelectedCompanyIds.concat(
        selectedCompanyIds.slice(1)
      );
    } else if (selectedIndex === selectedCompanyIds.length - 1) {
      newSelectedCompanyIds = newSelectedCompanyIds.concat(
        selectedCompanyIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCompanyIds = newSelectedCompanyIds.concat(
        selectedCompanyIds.slice(0, selectedIndex),
        selectedCompanyIds.slice(selectedIndex + 1)
      );
    }
    this.setState({
      selectedCompanyIds: newSelectedCompanyIds
    });
  };

  handleLimitChange = (event) => {
    this.setState({
      limit: event.target.value
    });
  };

  handlePageChange = (event, newPage) => {
    this.setState({
      page: newPage
    });
  };

  showList = (selectedCompanyIds, limit, page, Cameras) => {
    return (
      <CameraTableResults
        Cameras={Cameras}
        OnEdit={(id) => this.onEdit(id)}
        onEnableDisable={(data) => this.props.onEnableDisable(data)}
        {...this.props}
      />
    );
  };

  onEdit = (id) => {
    console.log('me estoy llamando');
    this.props.OpenEdit(id);
  };

  render() {
    const { Cameras } = this.props;
    const { selectedCompanyIds, limit, page } = this.state;
    return (
      <Card>
        <PerfectScrollbar>
          <Box display="flex" flexDirection="column">
            <ThemeProvider theme={theme}>
              {this.showList(selectedCompanyIds, limit, page, Cameras)}
            </ThemeProvider>
          </Box>
        </PerfectScrollbar>
      </Card>
    );
  }
}

export default CameraListResults;
