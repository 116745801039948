/* eslint-disable */
import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { AppBar, Box, Hidden, IconButton, Toolbar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';

import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '../authConfig';
import { MsalContext } from '@azure/msal-react';
import WelcomeName from './WelcomeName';
import { list } from 'src/constants/demoData';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actionUsuarioLogin } from 'src/actions/api/user';
import { actionLimpiarEstado } from 'src/actions';
import _ from 'lodash';

export const msalInstance = new PublicClientApplication(msalConfig);

//const navigate = useNavigate();
class DashboardNavbar extends Component {
  static contextType = MsalContext;
  constructor(props) {
    super(props);
    this.state = {
      notifications: list.notifications,
      showNotifications: false,
      notificationBadge: [],
      key: Math.random()
    };
  }

  handleLogout = (logoutType) => {
    if (logoutType === 'popup') {
      msalInstance.logoutPopup();
    } else if (logoutType === 'redirect') {
      msalInstance.logoutRedirect();
    }
  };

  componentDidMount() {
    //notifiactions
    // navigator.serviceWorker.addEventListener("message", (payload) => {
    //   const notificationTitle = payload.data.notification.title;
    //   const notificationOptions = {
    //     body: payload.data.notification.body,
    //   };
    //   if (!("Notification" in window)) {
    //     //console.log("This browser does not support system notifications.");
    //   } else if (Notification.permission === "granted") {
    //     // If it's okay let's create a notification
    //     var notification = new Notification(notificationTitle, notificationOptions);
    //     notification.onclick = function (event) {
    //       event.preventDefault();
    //       notification.close();

    //     }
    //     if (this.props.dataUser.public_key) {
    //       this.props.actionNotificationsLista({ id: this.props.dataUser.public_key })
    //     }
    //   }
    // });
    //TODO REPAIR THIS
    //if (!this.props.dataUser.profileIdRegister) {
    this.getUserEmail();
    //}
  }

  getUserEmail = async () => {
    const msalAccounts = this.context.accounts;
    const getTokenLocalStorage = await localStorage.getItem('fcmToken');
    this.props.actionUsuarioLogin({
      email: msalAccounts[0].username,
      Token: getTokenLocalStorage
    });
  };

  componentDidUpdate(preprops) {
    // if (this.props.dataUser !== preprops.dataUser) {
    //   if (this.props.dataUser) {
    //     if (this.props.dataUser.public_key) {
    //       this.props.actionNotificationsLista({ id: this.props.dataUser.public_key })
    //     }
    //   }
    //   // if (this.props.dataUser.public_key) {
    //   //   this.props.actionNotificationsLista({ id: this.props.dataUser.public_key })
    //   // }
    // }

    if (this.props.apiRespuesta) {
      switch (this.props.estado) {
        case 'LEIDA':
          if (this.props.dataUser.public_key) {
            //this.props.actionNotificationsLista({ id: this.props.dataUser.public_key })
          }
          break;
        default:
          break;
      }
      //this.props.actionLimpiarEstado()
    }
    if (this.props.apiRespuestaDataUser) {
      switch (this.props.estadoDataUser) {
        case 'DETALLE':
          localStorage.setItem(
            'setUserDataProp',
            JSON.stringify({
              ...this.props.dataUser,
              user: this.props.dataUser.name,
              profile: 1
            })
          );
          // document.addEventListener("visibilitychange", () => {
          //   if (document.visibilityState === 'visible') {
          //     this.getNotificationById()
          //   }
          // });
          break;
        case 'ERROR':
          if (this.props.error.includes('no se ha encontrado')) {
            window.location.href = '/401';
          }
          break;
        default:
          break;
      }
      this.props.actionLimpiarEstado();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.onFocus());
  }

  onFocus = async () => {
    if (this.props.dataUser.public_key) {
      //this.props.actionNotificationsLista({ id: this.props.dataUser.public_key })
    }
  };

  closeDialog = () => {
    this.setState({
      showNotifications: false
    });
  };

  render() {
    const { notificationBadge } = this.state;
    return (
      <AppBar elevation={0}>
        <Toolbar
          style={{ background: 'linear-gradient(to right, #0099ff, #005c99)' }}
        >
          <RouterLink to="/">{/*  <Logo {...this.props} /> */}</RouterLink>
          <Box sx={{ flexGrow: 1 }} />
          {/* <IconButton color="inherit" style={{
            border: "none",
            outline: "none"
          }}
            onClick={() => {
              this.setState({
                showNotifications: true
              })
            }}
          >
            <Badge
              badgeContent={notificationBadge ? notificationBadge.length : 0}
            >
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <WelcomeName />
          <IconButton
            onClick={() => this.handleLogout('redirect')}
            key="logoutRedirect"
            color="inherit"
          >
            <InputIcon />
          </IconButton>
          <Hidden lgUp>
            <IconButton color="inherit" onClick={this.props.onMobileNavOpen}>
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  dataUser: user.items,
  estadoDataUser: user.estado,
  apiRespuestaDataUser: user.apiRespuesta,

  busy: user.waiting,
  error: user.error
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      actionUsuarioLogin,
      actionLimpiarEstado
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DashboardNavbar);
