/* eslint-disable */
export const MANEUVERS_INICIA = "MANEUVERS_INICIA"
export const MANEUVERS_LISTA = "MANEUVERS_LISTA "
export const MANEUVERS_LISTADO = "MANEUVERS_LISTADO"

export const MANEUVERS_CREAR = "MANEUVERS_CREAR"
export const MANEUVERS_CREADA = "MANEUVERS_CREADA"

export const MANEUVERS_CLEAR = "MANEUVERS_CLEAR"
export const MANEUVERS_CLEARED = "MANEUVERS_CLEARED"

export const MANEUVERS_HABILITAR_DESHABILITAR = "MANEUVERS_HABILITAR_DESHABILITAR"
export const MANEUVERS_HABILITADA_DESHABILITADA = "MANEUVERS_HABILITADA_DESHABILITADA"
export const MANEUVERS_ERROR = "MANEUVERS_ERROR"