/* eslint-disable */
import {
  ROADWAYS_INICIA,
  ROADWAYS_LISTADO,
  ROADWAYS_ERROR,
  ROADWAYS_ACTUALIZADA,
  ROADWAYS_CREADA,
  ROADWAYS_SCALEFACTOR_CREADA,
  ROADWAYS_HABILITADA_DESHABILITADA,
  ROADWAYS_CLEARED,

} from 'src/constants/api/roadWays';

import {
  LIMPIAR_ESTADO
} from 'src/constants/ActionTypes';


const DEFAULT_STATE = {
  waiting: false,
  estado: '',
  items: [],
  actualizar: false,
  apiRespuesta: false,
  dataDetail: {},
  scaleFactor: {}
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ROADWAYS_INICIA:
      return { ...state, waiting: true };
    case ROADWAYS_LISTADO:
      return { ...state, waiting: false, items: action.data, actualizar: false, estado: 'LISTADO', apiRespuesta: true };

    case ROADWAYS_ACTUALIZADA:
      return { ...state, waiting: false, actualizar: true, estado: 'ACTUALIZADO', apiRespuesta: true };

    case ROADWAYS_CREADA:
      return {
        ...state, waiting: false, actualizar: true, estado: 'CREADO',
        apiRespuesta: true, data: action.params
      };

    case ROADWAYS_SCALEFACTOR_CREADA:
      return {
        ...state, waiting: false, actualizar: true, estado: 'SCALEFACTOR_CREADO',
        apiRespuesta: true, scaleFactor: action.params
      };

    case ROADWAYS_HABILITADA_DESHABILITADA:
      return { ...state, waiting: false, actualizar: true, estado: 'HABILITADO_DESHABILITADO', apiRespuesta: true };

    case ROADWAYS_ERROR:
      return {
        ...state,
        waiting: false,
        error: action.error ? (`${action.error.detail || action.error.detail}`) : 'API Error.',
        estado: 'ERROR',
        actualizar: true,
        apiRespuesta: true
      };

    case ROADWAYS_CLEARED:
      return { ...DEFAULT_STATE, error: '', actualizar: false, estado: '', apiRespuesta: false };

    case LIMPIAR_ESTADO:
      return { ...state, error: '', actualizar: false, estado: '', apiRespuesta: false };
    default:
      return state;
  }
};
