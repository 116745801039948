/* eslint-disable */
import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Box,
    Card,
    Button,
    ThemeProvider,
    createMuiTheme,
} from '@material-ui/core';
import {
    DataGrid,
    esES,
    CellParams,
    GridApi
} from '@material-ui/data-grid';
import { localeTextDataGrid } from 'src/constants/ActionTypes'

//Kanban
import Board from '@lourenci/react-kanban'
import '@lourenci/react-kanban/dist/styles.css'

//opciones
import { Edit, Reorder, ViewWeek } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import RequestCanvaCard from '../RequestCanvaCard';
import SweetAlert from 'react-bootstrap-sweetalert';

const theme = createMuiTheme(
    {
        palette: {
            primary: { main: '#1976d2' },
        },
    },
    esES,
);


class UserTableResults extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedCustomerIds: [],
            limit: 10,
            page: 0,
            viewList: true,
            userEnableDisable: [],
            showConfirmEnableDisable: false
        }

        this.columns = [
            { field: 'id', hide: true, filterable: false },
            {
                field: "",
                headerName: "Opciones",
                sortable: false,
                filterable: false,
                width: 120,
                headerAlign: 'center',
                align: 'center',
                disableClickEventBubbling: true,
                renderCell: (params: CellParams) => {
                    const onClick = () => {
                        const api: GridApi = params.api;
                        const fields = api
                            .getAllColumns()
                            .map((c) => c.field)
                            .filter((c) => c !== "__check__" && !!c);
                        const thisRow = {};

                        fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                        });

                        ////console.log("Edit: ", thisRow);
                        this.props.OnEdit(thisRow.id);
                        //return alert(JSON.stringify(thisRow, null, 4));
                    };

                    return <div>
                        <IconButton aria-label="Editar" onClick={onClick}
                            style={{ border: "none", outline: "none" }}>
                            <Edit fontSize="small" style={{ border: "none", outline: "none" }} />
                        </IconButton>
                    </div>;
                }
            },
            { field: 'name', headerName: 'Nombre', width: 200},
            { field: 'email', headerName: 'Correo', width: 250 },
            { field: 'phone', headerName: 'Teléfono', width: 200 },
            { field: 'role', headerName: 'Rol', width: 200 },
            { field: 'client', headerName: 'Empresa', width: 200 },
            //{ field: 'isAdmin', headerName: 'Administrador', width: 200 },
            //{ field: 'isActive', headerName: 'Activo', width: 200, height: 150 },
            //{ field: 'insertDate', headerName: 'Fecha de creación', width: 200 },
            //{ field: 'updatedDate', headerName: 'Fecha de actualización', width: 200 },
        ];
    }

    handleEnableDisable = () => {
        this.props.onEnableDisable({
            idRegistry: this.state.userEnableDisable,
            stateEnableDisable: 0
        })
        this.setState({ showConfirmEnableDisable: false, userEnableDisable: [] })
    }

    render() {
        return (
            <div
                onKeyDownCapture={(e) => {
                    if (e.key === "Backspace" || e.key === "Delete") {
                        e.stopPropagation();
                    }
                }}
            >
                <DataGrid
                    columns={this.columns}
                    rows={this.props.Customers}
                    rowHeight={25}
                    autoHeight={true}
                    //checkboxSelection
                    disableSelectionOnClick={true}
                    disableColumnReorder={true}
                    disableColumnSelector={true}
                    disableColumnSelector={true}
                    localeText={localeTextDataGrid}
                    isCellEditable={false}
                    editMode={false}
                    rowsPerPageOptions={[10, 20, 40]}
                    pageSize={10}
                    onSelectionModelChange={newSelection => this.setState({ userEnableDisable: newSelection.selectionModel })}
                />
                {
                    this.state.userEnableDisable.length > 0 ?
                        <div style={{ width: '100%', textAlign: 'left', margin: "0.5em 0em 0.5em 0em" }}>
                            <Button color="primary" style={{ marginLeft: "1em" }} variant="contained"
                                onClick={() => {
                                    // let enableDisable = true;
                                    // this.state.requestEnableDisable.map(selectedElement => { 
                                    //     if ((this.props.Requests.find(element => element.idRegistry === selectedElement)).stage !== 0) enableDisable = false })
                                    // if (!enableDisable) {
                                    //     toast.error('Sólo se pueden deshabilitar solicitudes en etapa 0.', {
                                    //         backgroundColor: "#E80D0D",
                                    //         color: "#ffffff",
                                    //     });
                                    // }
                                    // else {
                                    //     this.setState({ showConfirmEnableDisable: true })
                                    // }
                                    this.setState({ showConfirmEnableDisable: true })
                                }}>
                                Deshabilitar
                            </Button>
                        </div>
                        : null
                }

                <SweetAlert
                    show={this.state.showConfirmEnableDisable}
                    success
                    confirmBtnText="Aceptar"
                    confirmBtnBsStyle="light"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="light"
                    cancelBtnStyle={{ fontSize: 14 }}
                    style={{ color: "#000", fontSize: 14 }}
                    confirmBtnStyle={{ fontSize: 14 }}
                    title={"Deshabilitar Usuarios"}
                    onConfirm={() => this.handleEnableDisable()}
                    onCancel={() => this.setState({ showConfirmEnableDisable: false })}
                    showCancel={true}
                    showConfirm={true}
                >
                    <span>{"¿Estás seguro que desea deshabilitar estos usuarios?"}</span>
                </SweetAlert>
            </div>
        )
    }
}



export default UserTableResults;