/* eslint-disable */
export const VIDEO_INICIA = 'VIDEO_INICIA';
export const VIDEO_INICIAUP = 'VIDEO_INICIAUP';
export const VIDEO_LISTA = 'VIDEO_LISTA';
export const VIDEO_LISTADO = 'VIDEO_LISTADO';

export const VIDEO_ACTUALIZAR = 'VIDEO_ACTUALIZAR';
export const VIDEO_ACTUALIZADA = 'VIDEO_ACTUALIZADA';
export const VIDEO_CREAR = 'VIDEO_CREAR';
export const VIDEO_CREADA = 'VIDEO_CREADA';

export const VIDEO_CLEAR = 'VIDEO_CLEAR';
export const VIDEO_CLEARED = 'VIDEO_CLEARED';

export const VIDEO_UPLOAD = 'VIDEO_UPLOAD';
export const VIDEO_UPLOADED = 'VIDEO_UPLOADED';

export const VIDEO_DETALLE = 'VIDEO_DETALLE';
export const VIDEO_DETALLADO = 'VIDEO_DETALLADO';
export const VIDEO_DETALLE_INICIA = 'VIDEO_DETALLE_INICIA';
export const VIDEO_HABILITAR_DESHABILITAR = 'VIDEO_HABILITAR_DESHABILITAR';
export const VIDEO_HABILITADA_DESHABILITADA = 'VIDEO_HABILITADA_DESHABILITADA';
export const VIDEO_ERROR = 'VIDEO_ERROR';