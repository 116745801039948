/* eslint-disable */
import {
    ROLES_INICIA,
    ROLES_LISTA,    
    ROLES_LISTADO,    
    ROLES_ERROR,
} from 'src/constants/api/roles';

export const actionRolesInicia = () => ({ type: ROLES_INICIA, });
export const actionRolesLista = params => ({ type: ROLES_LISTA, params });
export const actionRolesListado = data => ({ type: ROLES_LISTADO, data });

export const actionRolesError = error => ({ type: ROLES_ERROR, error });
