/* eslint-disable */
import React, { Component } from 'react';

import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';

import Button from '@material-ui/core/Button';

import 'react-toastify/dist/ReactToastify.css';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import StepperComponentCamera from 'src/components/StepperComponentCamera';
import SweetAlert from 'react-bootstrap-sweetalert';
import { FETCH_ALL_CONTACT_SUCCESS } from 'src/constants/ActionTypes';

class FrameCreateCamera extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showSaveButton: false,
            showError: false,
            showErrorStep1: false,
            showErrorStep2: false,
            selectCompanies: {
                value: "",
                label: ""
            },
        };
    }

    componentDidMount() {
        this.setState({
            selectCompanies: {
                value: this.props.companieId ? this.props.companieId : "",
                label: this.props.companieName ? this.props.companieName : "",
            },
        })
    }

    HiddenSaveButton = () => {
        this.setState({
            showSaveButton: false
        })
    }

    ShowSaveButton = () => {
        this.setState({
            showSaveButton: true
        })
    }

    createCamera = async (objCam) => {
        //console.log("Crear: ", objCam)
        this.setState({
            showErrorStep1: objCam.step1Error,
            showErrorStep2: objCam.step2Error,
        });

        if (Object.keys(objCam.objStep1).length === 0 || Object.keys(objCam.objStep2).length === 0
        ) {
            ////console.log("show error")
            if (!this.props.Update || Object.keys(objCam.objStep1).length === 0) {
                this.setState({
                    showError: true,
                })
            }

        } else {
            this.props.CreateCameraByApi(objCam)
            console.log('primera entrada');
        }
        if (this.props.Update && Object.keys(objCam.objStep1).length !== 0) {
            this.props.CreateCameraByApi(objCam)
        }
        this.props.filterByCompanie(this.state.selectCompanies);
    }
    render() {
        const { dataPermissionsList } = this.props;
        return (
            // <Dialog
            //     fullWidth={true}
            //     maxWidth={"md"}
            //     open={this.props.open}
            //     onClose={() => this.props.CloseDialog()}
            //     aria-labelledby="form-dialog-title">
            //     <DialogTitle id="form-dialog-title">{this.props.Update ? "Actualizar" : "Crear"} Contacto</DialogTitle>
            //     <DialogContent>
            <>
                <Helmet>
                    <title>Cámaras</title>
                </Helmet>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexDirection: "column"
                    }}
                >
                    <div style={{
                        fontFamily: "sans-serif",
                        fontSize: 18,
                        fontWeight: "bold"
                    }}>Agregar cámara fija {this.props.companieName !== "" ? `| ${this.props.companieName}` : ""}</div>
                </Box>

                <Button color="primary"
                    onClick={() => this.props.CloseDialog(this.state.selectCompanies)}
                    variant="outlined"
                    startIcon={<KeyboardBackspaceIcon />}
                    style={{ outline: "none" }}>
                    Volver
                </Button>
                <Box
                    sx={{
                        backgroundColor: '#fff',
                        py: 3,
                        marginTop: 9,
                        padding: 2
                    }}
                >
                    <Box display="flex" flexDirection="row"
                        width={"100%"}
                        overflow={"hidden"}
                        backgroundColor={'#fff'}>
                        <StepperComponentCamera
                            {...this.props}
                            ShowSaveButton={() => this.ShowSaveButton()}
                            HiddenSaveButton={() => this.HiddenSaveButton()}
                            ref={instance => { this.child = instance; }}
                            Finish={() => this.props.Finish(this.state.selectCompanies)}
                            CreateCamera={(objCam) => this.createCamera(objCam)}
                            deleteById={(id) => this.props.deleteById(id)}
                            ConnectCameraApi={(objCam) => this.props.ConnectCameraApiMain(objCam)}
                        />
                    </Box>
                </Box>

                <SweetAlert
                    danger
                    show={this.state.showError}
                    showCancel={false}
                    confirmBtnText="Aceptar"
                    confirmBtnBsStyle="light"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="light"
                    cancelBtnStyle={{ fontSize: 14 }}
                    style={{ color: "#000", fontSize: 14 }}
                    confirmBtnStyle={{ fontSize: 14 }}

                    onConfirm={() => this.setState({ showError: false })}
                //onCancel={() => this.setState({ showError: false })}
                >
                    <span>{"Lo sentimos, faltan algunos campos en los formularios:"}</span><br />
                    {this.state.showErrorStep1 !== false ?
                        <span>{"Localización"}</span>
                        : ""}
                    <br />
                    {this.state.showErrorStep2 !== false ?
                        <span>{"Datos Generales"}</span>
                        : ""}
                    <br />
                </SweetAlert>

            </>

        )
    }
}



export default FrameCreateCamera;