/* eslint-disable */
import {
  ROADWAYS_DETALLE_INICIA,

  ROADWAYS_DETALLE,
  ROADWAYS_DETALLADO,
  ROADWAYS_DETALLE_ERROR,
} from 'src/constants/api/roadWays';

export const actionRoadWaysDetalleInicia = () => ({ type: ROADWAYS_DETALLE_INICIA, });

//Detalle
export const actionRoadWaysDetalle = (params) => ({ type: ROADWAYS_DETALLE, params });
export const actionRoadWaysDetallado = (data) => ({ type: ROADWAYS_DETALLADO, data });

export const actionRoadWaysDetalleError = (error) => ({ type: ROADWAYS_DETALLE_ERROR, error });
