/* eslint-disable */
import {
    ANALYTIC_SETTINGS_INICIA,
    ANALYTIC_SETTINGS_LISTADO,
    ANALYTIC_SETTINGS_ERROR,
    ANALYTIC_SETTINGS_ACTUALIZADA,
    ANALYTIC_SETTINGS_CREADA
} from 'src/constants/api/analyticSettings';

import {
    LIMPIAR_ESTADO
} from 'src/constants/ActionTypes';


const DEFAULT_STATE = {
    waiting: false,
    estado: '',
    items: [],
    actualizar: false,
    apiRespuesta: false
}

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case ANALYTIC_SETTINGS_INICIA:
            return { waiting: true };

        case ANALYTIC_SETTINGS_LISTADO:
            return { waiting: false, items: action.data, actualizar: false, estado: 'LISTADO', apiRespuesta: true };

        case ANALYTIC_SETTINGS_ACTUALIZADA:
            return { ...state, waiting: false, actualizar: true, estado: 'ACTUALIZADO', apiRespuesta: true };

        case ANALYTIC_SETTINGS_CREADA:
            return {
                ...state, waiting: false, actualizar: true, estado: 'CREADO',
                apiRespuesta: true, data: action.data
            };

        case ANALYTIC_SETTINGS_ERROR:
            return {
                ...state,
                waiting: false,
                error: action.error ? (`${action.error.detail || action.error.detail}`) : 'API Error.',
                estado: 'ERROR',
                actualizar: true,
                apiRespuesta: true
            };
        case LIMPIAR_ESTADO:
            return { ...state, error: '', actualizar: false, estado: '', apiRespuesta: false };
        default:
            return state;
    }
};