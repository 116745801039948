/* eslint-disable */
import React, { Component } from 'react';
import { Button, createMuiTheme } from '@material-ui/core';
import { DataGrid, esES, CellParams, GridApi } from '@material-ui/data-grid';
import { localeTextDataGrid } from 'src/constants/ActionTypes';

//Kanban
import '@lourenci/react-kanban/dist/styles.css';

//opciones
import DeleteIcon from '@mui/icons-material/Delete';
import { Edit } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Box } from '@material-ui/core';

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#1976d2' }
    }
  },
  esES
);

class DrawGatesTableResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCustomerIds: [],
      limit: 10,
      page: 0,
      viewList: true,
      userEnableDisable: [],
      showConfirmEnableDisable: false
    };

    this.columns = [
      { field: 'id', hide: true, filterable: false },
      {
        field: '',
        headerName: 'Opciones',
        sortable: false,
        filterable: false,
        width: 120,
        headerAlign: 'center',
        align: 'center',
        disableClickEventBubbling: true,
        renderCell: (params: CellParams) => {
          const onClick = () => {
            const api: GridApi = params.api;
            const fields = api
              .getAllColumns()
              .map((c) => c.field)
              .filter((c) => c !== '__check__' && !!c);
            const thisRow = {};

            fields.forEach((f) => {
              thisRow[f] = params.getValue(f);
            });

            ////console.log("Edit: ", thisRow);
            this.props.OnEdit(thisRow.id);
            //return alert(JSON.stringify(thisRow, null, 4));
          };

          return (
            <div>
              <IconButton
                aria-label="Editar"
                onClick={onClick}
                style={{ border: 'none', outline: 'none' }}
              >
                <DeleteIcon
                  fontSize="small"
                  style={{ border: 'none', outline: 'none' }}
                />
              </IconButton>
            </div>
          );
        }
      },
      {
        field: 'pos_init',
        headerName: 'Pos. Inicio',
        width: 180,
        renderCell: (params: CellParams) => {
          const getRowInfo = () => {
            const api: GridApi = params.api;
            const fields = api
              .getAllColumns()
              .map((c) => c.field)
              .filter((c) => c !== '__check__' && !!c);
            const thisRow = {};

            fields.forEach((f) => {
              thisRow[f] = params.getValue(f);
            });
            return thisRow;
            // ////console.log("Edit: ", thisRow);
            // this.props.OnEdit(thisRow.id);
            //return alert(JSON.stringify(thisRow, null, 4));
          };

          return (
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <Box display={'flex'} flexDirection={'row'} height={15}>
                <div style={{ fontWeight: 'bold' }}>X:</div>
                {getRowInfo().x.toFixed(0)}
              </Box>
              <Box display={'flex'} flexDirection={'row'}>
                <div style={{ fontWeight: 'bold' }}>Y:</div>
                {getRowInfo().y.toFixed(0)}
              </Box>
            </Box>
          );
        }
      },
      {
        field: 'pos_end',
        headerName: 'Pos. Fin',
        width: 180,
        renderCell: (params: CellParams) => {
          const getRowInfo = () => {
            const api: GridApi = params.api;
            const fields = api
              .getAllColumns()
              .map((c) => c.field)
              .filter((c) => c !== '__check__' && !!c);
            const thisRow = {};

            fields.forEach((f) => {
              thisRow[f] = params.getValue(f);
            });
            return thisRow;
            // ////console.log("Edit: ", thisRow);
            // this.props.OnEdit(thisRow.id);
            //return alert(JSON.stringify(thisRow, null, 4));
          };

          return (
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <Box display={'flex'} flexDirection={'row'} height={15}>
                <div style={{ fontWeight: 'bold' }}>X:</div>
                {getRowInfo().x1.toFixed(0)}
              </Box>
              <Box display={'flex'} flexDirection={'row'}>
                <div style={{ fontWeight: 'bold' }}>Y:</div>
                {getRowInfo().y1.toFixed(0)}
              </Box>
            </Box>
          );
        }
      },
      { field: 'x', headerName: 'x', hide: true, filterable: false },
      { field: 'y', headerName: 'y', hide: true, filterable: false },
      { field: 'x1', headerName: 'x1', hide: true, filterable: false },
      { field: 'y1', headerName: 'y1', hide: true, filterable: false }
      //{ field: 'isAdmin', headerName: 'Administrador', width: 200 },
      //{ field: 'isActive', headerName: 'Activo', width: 200, height: 150 },
      //{ field: 'insertDate', headerName: 'Fecha de creación', width: 200 },
      //{ field: 'updatedDate', headerName: 'Fecha de actualización', width: 200 },
    ];
  }

  handleEnableDisable = () => {
    this.props.onEnableDisable({
      idRegistry: this.state.userEnableDisable,
      stateEnableDisable: 0
    });
    this.setState({ showConfirmEnableDisable: false, userEnableDisable: [] });
  };

  render() {
    return (
      <div
        onKeyDownCapture={(e) => {
          if (e.key === 'Backspace' || e.key === 'Delete') {
            e.stopPropagation();
          }
        }}
      >
        <DataGrid
          columns={this.columns}
          rows={this.props.Gates}
          rowHeight={50}
          autoHeight={true}
          //checkboxSelection
          disableSelectionOnClick={true}
          disableColumnReorder={true}
          disableColumnSelector={true}
          localeText={localeTextDataGrid}
          isCellEditable={false}
          editMode={false}
          rowsPerPageOptions={[10, 20, 40]}
          pageSize={10}
          onSelectionModelChange={(newSelection) =>
            this.setState({ userEnableDisable: newSelection.selectionModel })
          }
        />
        {this.state.userEnableDisable.length > 0 ? (
          <div style={{ width: '100%', textAlign: 'left', margin: '0' }}>
            <Button
              color="primary"
              style={{ marginLeft: '1em' }}
              variant="contained"
              onClick={() => {
                // let enableDisable = true;
                // this.state.requestEnableDisable.map(selectedElement => {
                //     if ((this.props.Requests.find(element => element.idRegistry === selectedElement)).stage !== 0) enableDisable = false })
                // if (!enableDisable) {
                //     toast.error('Sólo se pueden deshabilitar solicitudes en etapa 0.', {
                //         backgroundColor: "#E80D0D",
                //         color: "#ffffff",
                //     });
                // }
                // else {
                //     this.setState({ showConfirmEnableDisable: true })
                // }
                this.setState({ showConfirmEnableDisable: true });
              }}
            >
              Deshabilitar
            </Button>
          </div>
        ) : null}

        <SweetAlert
          show={this.state.showConfirmEnableDisable}
          success
          confirmBtnText="Aceptar"
          confirmBtnBsStyle="light"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="light"
          cancelBtnStyle={{ fontSize: 14 }}
          style={{ color: '#000', fontSize: 14 }}
          confirmBtnStyle={{ fontSize: 14 }}
          title={'Deshabilitar Usuarios'}
          onConfirm={() => this.handleEnableDisable()}
          onCancel={() => this.setState({ showConfirmEnableDisable: false })}
          showCancel={true}
          showConfirm={true}
        >
          <span>{'¿Estás seguro que desea deshabilitar estos usuarios?'}</span>
        </SweetAlert>
      </div>
    );
  }
}

export default DrawGatesTableResults;
