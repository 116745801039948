/* eslint-disable */
import {
    SCALEFACTOR_INICIA,
    SCALEFACTOR_LISTA,    
    SCALEFACTOR_LISTADO, 

    SCALEFACTOR_ACTUALIZAR,
    SCALEFACTOR_ACTUALIZADA,
    SCALEFACTOR_CREAR,
    SCALEFACTOR_CREADA,
    SCALEFACTOR_CLEAR,
    SCALEFACTOR_CLEARED,
    SCALEFACTOR_DETALLE,
    SCALEFACTOR_DETALLADO,
    SCALEFACTOR_DETALLE_INICIA,
    SCALEFACTOR_HABILITAR_DESHABILITAR,
    SCALEFACTOR_HABILITADA_DESHABILITADA,
    SCALEFACTOR_ERROR,
} from 'src/constants/api/scaleFactor';

export const actionScaleFactorInicia = () => ({ type: SCALEFACTOR_INICIA, });
export const actionScaleFactorLista = params => ({ type: SCALEFACTOR_LISTA, params });
export const actionScaleFactorListado = data => ({ type: SCALEFACTOR_LISTADO, data });

export const actionScaleFactorActualizar = data => ({ type: SCALEFACTOR_ACTUALIZAR, data });
export const actionScaleFactorActualizada = (params) => ({ type: SCALEFACTOR_ACTUALIZADA, params });

export const actionScaleFactorCrear = data => ({ type: SCALEFACTOR_CREAR, data });
export const actionScaleFactorCreada = (params) => ({ type: SCALEFACTOR_CREADA, params });

export const actionScaleFactorClear = data => ({ type: SCALEFACTOR_CLEAR, data });
export const actionScaleFactorCleared = data => ({ type: SCALEFACTOR_CLEARED, data });

export const actionScaleFactorDetalle = params => ({ type: SCALEFACTOR_DETALLE, params });
export const actionScaleFactorDetallado = (data) => ({ type: SCALEFACTOR_DETALLADO, data });
export const actionScaleFactorDetalleInicia = () => ({ type: SCALEFACTOR_DETALLE_INICIA, });

export const actionScaleFactorHabilitarDeshabilitar = data => ({ type: SCALEFACTOR_HABILITAR_DESHABILITAR, data });
export const actionScaleFactorHabilitadaDeshabilitada = (params) => ({ type: SCALEFACTOR_HABILITADA_DESHABILITADA, params });

export const actionScaleFactorError = error => ({ type: SCALEFACTOR_ERROR, error });
