/* eslint-disable */
export const ANALYTIC_SETTINGS_INICIA = "ANALYTIC_SETTINGS_INICIA";
export const ANALYTIC_SETTINGS_LIST = "ANALYTIC_SETTINGS_LIST";
export const ANALYTIC_SETTINGS_LISTADO = "ANALYTIC_SETTINGS_LISTADO";
export const ANALYTIC_SETTINGS_ERROR = "ANALYTIC_SETTINGS_ERROR";

//Actualizar
export const ANALYTIC_SETTINGS_ACTUALIZAR = "ANALYTIC_SETTINGS_ACTUALIZAR";
export const ANALYTIC_SETTINGS_ACTUALIZADA = "ANALYTIC_SETTINGS_ACTUALIZADA";

//Crear
export const ANALYTIC_SETTINGS_CREAR = "ANALYTIC_SETTINGS_CREAR";
export const ANALYTIC_SETTINGS_CREADA = "ANALYTIC_SETTINGS_CREADA";
