/* eslint-disable */
import { call, put, takeEvery } from "redux-saga/effects";
import {
  actionContactsInicia,
  actionContactsListado,
  actionContactsError,
  actionContactsActualizada,
  actionContactsCreada,
  actionContactsHabilitadaDeshabilitada
} from "src/actions/api/contacts";

import {
  apiContactsList,
  apiContactsActualizar,
  apiContactsCrear,
  apiContactHabilitarDeshabilitar
} from "src/api/contacts";

import {
  CONTACTS_LISTA,
  CONTACTS_CREAR,
  CONTACTS_ACTUALIZAR,
  CONTACTS_HABILITAR_DESHABILITAR
} from 'src/constants/api/contacts';

function* sagaContactsLista({ type, params }) {
  try {
    yield put(actionContactsInicia());
    const data = yield call(apiContactsList, params);
    yield put(actionContactsListado(data));
  } catch (e) {
    yield put(actionContactsError(e));
  }
}

function* sagaContactsActualizar(action) {
  try {
    yield put(actionContactsInicia());
    const resp = yield call(apiContactsActualizar, action.data);
    yield put(actionContactsActualizada(resp));
  } catch (e) {
    yield put(actionContactsError(e));
  }
}


function* sagaContactsCrear(action) {
  try {
    yield put(actionContactsInicia());
    const resp = yield call(apiContactsCrear, action.data);
    yield put(actionContactsCreada(resp));
  } catch (e) {
    yield put(actionContactsError(e));
  }
}

function* sagaContactsHabilitarDeshabilitar(action) {
  try {
    yield put(actionContactsInicia());
    const resp = yield call(apiContactHabilitarDeshabilitar, action.data);
    yield put(actionContactsHabilitadaDeshabilitada(resp));
  } catch (e) {
    yield put(actionContactsError(e));
  }
}

export default [
  takeEvery(CONTACTS_LISTA, sagaContactsLista),
  takeEvery(CONTACTS_CREAR, sagaContactsCrear),
  takeEvery(CONTACTS_ACTUALIZAR, sagaContactsActualizar),
  takeEvery(CONTACTS_HABILITAR_DESHABILITAR, sagaContactsHabilitarDeshabilitar)
]
