/* eslint-disable */
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import Typography from "@material-ui/core/Typography";

const Username = (dataUser) => {
    const { accounts } = useMsal();
    const [name, setName] = useState(dataUser.name);

    // useEffect(async() => {
    //     // if (accounts.length > 0) {
    //     //     // await setName(accounts[0].name.split(" ")[0] + " " + accounts[0].name.split(" ")[1]);
    //     //     // const nameSync = await accounts[0].name.split(" ")[0] + " " + accounts[0].name.split(" ")[1];
    //     //     // const fullName = await nameSync.split(' ');
    //     //     // const initials = await fullName.shift().charAt(0) + fullName.pop().charAt(0);
    //     //     const initials = "JA";
    //     //     // initials.toUpperCase();
    //     //     localStorage.setItem("initialsName", initials.toUpperCase())
    //     // }
    //     const userData = await localStorage.getItem("setUserDataProp");
    //     const userDataParse = await JSON.parse(userData)
    //     await setName("Juan");
    // }, [accounts]);

    if (name) {
        return name;
    } else {
        return null;
    }
};

export default Username;