/* eslint-disable */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
    actionManeuversInicia,
    actionManeuversListado,
    actionManeuversError,

    actionManeuversCreada,
    actionManeuversHabilitadaDeshabilitada,
    actionManeuversCleared
} from "src/actions/api/maneuvers";

import { 
    apiManeuversList,    
    apiManeuversCrear,
    apiManeuversHabilitarDeshabilitar
} from "src/api/maneuvers";

import { 
    MANEUVERS_LISTA,
    MANEUVERS_CREAR,
    MANEUVERS_HABILITAR_DESHABILITAR,
    MANEUVERS_CLEAR
} from 'src/constants/api/maneuvers';

function* sagaGatesLista({ type, params }) {
  try {        
    yield put(actionManeuversInicia());
    const data = yield call(apiManeuversList, params);
    yield put(actionManeuversListado(data));
  } catch (e) {
    yield put(actionManeuversError(e));    
  }
}


function* sagaGatesCrear(action) {
  try {       
    yield put(actionManeuversInicia());    
    const resp = yield call(apiManeuversCrear, action.data);
    yield put(actionManeuversCreada(resp));
  } catch (e) {
    yield put(actionManeuversError(e));    
  }
}

function* sagaGatesHabilitarDeshabilitar(action) {
  try {
    yield put(actionManeuversInicia());
    const resp = yield call(apiManeuversHabilitarDeshabilitar, action.data);
    yield put(actionManeuversHabilitadaDeshabilitada(resp));
  } catch (e) {
    yield put(actionManeuversError(e));
  }
}

function* sagaGatesClear(action) {
  try {
    yield put(actionManeuversInicia());
    //const resp = yield call(null, {});
    yield put(actionManeuversCleared({}));
  } catch (e) {
    yield put(actionManeuversError(e));
  }
}

export default  [
  takeEvery(MANEUVERS_LISTA, sagaGatesLista), 
  takeEvery(MANEUVERS_CREAR, sagaGatesCrear),
  takeEvery(MANEUVERS_HABILITAR_DESHABILITAR, sagaGatesHabilitarDeshabilitar),
  takeEvery(MANEUVERS_CLEAR, sagaGatesClear),
]
