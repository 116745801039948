/* eslint-disable */
import {
  USUARIO_INICIA,
  USUARIO_LOGEADO,
  USUARIO_ERROR,
} from 'src/constants/api/user';

import {
  LIMPIAR_ESTADO
} from 'src/constants/ActionTypes';


const DEFAULT_STATE = {
  waiting: false,
  estado: '',
  items: {},
  actualizar: false,
  apiRespuesta: false,
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case USUARIO_INICIA:
      return { ...state, waiting: true, };

    case USUARIO_LOGEADO:
      return {
        waiting: false,
        items: {
          ...action.data,
          dominio: action.data ? (action.data.email.substring(action.data.email.lastIndexOf("@") + 1).split(".")[0]).toLowerCase() : ""
        }, 
        actualizar: false, 
        estado: 'DETALLE', 
        apiRespuesta: true
      };
    case USUARIO_ERROR:
      return {
        ...state,
        waiting: false,
        error: action.error ? (`${action.error.detail || action.error.detail}`) : 'API Error.',
        estado: 'ERROR',
        actualizar: false,
        apiRespuesta: true
      };
    default:
      return state;
  }
};
