/* eslint-disable */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
    actionCamerasDetalleInicia,
    actionCamerasDetallado,
    actionCamerasError,
} from "src/actions/api/cameras";

import { 
    apiCameraDetail,    
} from "src/api/cameras";

import {
    CAMERAS_DETALLE
} from 'src/constants/api/cameras';

function* sagaCamerasDetail({ type, params }) {
  try {        
    yield put(actionCamerasDetalleInicia());
    const data = yield call(apiCameraDetail, params);
    yield put(actionCamerasDetallado(data));
  } catch (e) {
    yield put(actionCamerasError(e));    
  }
}

export default  [
  takeEvery(CAMERAS_DETALLE, sagaCamerasDetail), 
]
