/* eslint-disable */
import {
    GATES_VIDEO_INICIA,
    GATES_VIDEO_LISTA,    
    GATES_VIDEO_LISTADO, 

    GATES_VIDEO_CREAR,
    GATES_VIDEO_CREADA,
    
    GATES_VIDEO_CLEAR,
    GATES_VIDEO_CLEARED,

    GATES_VIDEO_HABILITAR_DESHABILITAR,
    GATES_VIDEO_HABILITADA_DESHABILITADA,
    GATES_VIDEO_ERROR,
} from 'src/constants/api/gates';

export const actionGatesVideoInicia = () => ({ type: GATES_VIDEO_INICIA, });
export const actionGatesVideoLista = params => ({ type: GATES_VIDEO_LISTA, params });
export const actionGatesVideoListado = data => ({ type: GATES_VIDEO_LISTADO, data });

export const actionGatesVideoCrear = data => ({ type: GATES_VIDEO_CREAR, data });
export const actionGatesVideoCreada = (params) => ({ type: GATES_VIDEO_CREADA, params });

export const actionGatesVideoClear = data => ({ type: GATES_VIDEO_CLEAR, data });
export const actionGatesVideoCleared = data => ({ type: GATES_VIDEO_CLEARED, data });

export const actionGatesVideoHabilitarDeshabilitar = data => ({ type: GATES_VIDEO_HABILITAR_DESHABILITAR, data });
export const actionGatesVideoHabilitadaDeshabilitada = (params) => ({ type: GATES_VIDEO_HABILITADA_DESHABILITADA, params });

export const actionGatesVideoError = error => ({ type: GATES_VIDEO_ERROR, error });
