/* eslint-disable */
import React from 'react';
import {
  NavLink as RouterLink,
  matchPath,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ListItem, List, ListItemIcon } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListSubheader from '@material-ui/core/ListSubheader';

import InboxIcon from '@material-ui/icons/MoveToInbox';
import StarBorder from '@material-ui/icons/StarBorder';

const NavItem = ({
  href,
  icon: Icon,
  title,
  sub,
  typeMenu,
  ...rest
}) => {

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }));

  const classes = useStyles();
  // const [open, setOpen] = useState([]);
  // const handleClick = () => {
  //   setOpen(!open);
  // };

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };


  const location = useLocation();

  const active = href ? !!matchPath({
    path: href,
    end: false
  }, location.pathname) : false;

  if (typeMenu !== "subMenu") {
    return (

      <ListItem
        disableGutters
        sx={{
          display: 'flex',
          py: 0
        }}
        {...rest}
        key={title}
      >
        <Button
          component={RouterLink}
          sx={{
            color: 'text.secondary',
            fontWeight: 'medium',
            justifyContent: 'flex-start',
            letterSpacing: 0,
            py: 1.25,
            textTransform: 'none',
            width: '100%',
            ...(active && {
              color: 'primary.main'
            }),
            '& svg': {
              mr: 1
            }
          }}
          to={href}
        >
          {Icon && (
            <Icon size="20" />
          )}
          <span style={{fontSize: 15}}>
            {title}
          </span>
        </Button>

        {/* <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <ListItemText primary="Starred" />
          </ListItem>
        </List>
      </Collapse>
      
      {open ? <ExpandLess /> : <ExpandMore />} */}
      </ListItem>
    );
  } else {
    return (
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
      >
        <ListItem
          disableGutters
          sx={{
            display: 'flex',
            py: 0
          }}
          {...rest}
          button onClick={handleClick}>
          <Button
            component={RouterLink}
            sx={{
              color: 'text.secondary',
              fontWeight: 'medium',
              justifyContent: 'flex-start',
              letterSpacing: 0,
              py: 1.25,
              textTransform: 'none',
              width: '100%',
              ...(active && {
                color: 'primary.main'
              }),
              '& svg': {
                mr: 1
              }
            }}
            to={href}
          >
            {Icon && (
              <Icon size="20" />
            )}
            <span>
              {title}
            </span>
          </Button>
          <ListItemText />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {sub.map((item) => (
              <ListItem button className={classes.nested}>
                <Button
                  component={RouterLink}
                  sx={{
                    color: 'text.secondary',
                    fontWeight: 'medium',
                    justifyContent: 'flex-start',
                    letterSpacing: 0,
                    py: 0,
                    textTransform: 'none',
                    width: '100%',
                    ...(active && {
                      color: 'primary.main'
                    }),
                    '& svg': {
                      mr: 1
                    }
                  }}
                  to={item.href}
                >
                  {item.icon && (
                    <Icon size="20" />
                  )}
                  <span>
                    {item.title}
                  </span>
                </Button>
              </ListItem>
            ))}
          </List>
        </Collapse>
      </List>
    )

  }

};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;
