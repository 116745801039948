/* eslint-disable */
import {
  USUARIO_INICIA,
  USUARIO_LOGIN,
  USUARIO_LOGEADO,
  USUARIO_ERROR,
} from 'src/constants/api/user';

export const actionUsuarioInicia = () => ({ type: USUARIO_INICIA, });

//
export const actionUsuarioLogin = (params) => ({ type: USUARIO_LOGIN, params });
export const actionUsuarioLogeado = (data) => ({ type: USUARIO_LOGEADO, data });

export const actionUsuarioError = (error) => ({ type: USUARIO_ERROR, error });
