/* eslint-disable */
import React, { Component } from 'react';

import { Box, Container } from '@material-ui/core';

import Button from '@material-ui/core/Button';

import styles from './styles'
import './styles.css';
import cuid from "cuid";
import SweetAlert from 'react-bootstrap-sweetalert'
import Loading from 'src/components/Loading';
import ReactFormInputValidation from "react-form-input-validation";
import FieldFormTemplate from '../FieldFormTemplate';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CachedIcon from '@mui/icons-material/Cached';

import { acceptedFilesValidation } from "src/api/commons";
import { percentChangue } from "src/api/videos";

import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import DropzoneAreaComponent from 'src/components/DropzoneAreaComponent'
import ImageGrid from 'src/components/ImageGrid'
import InfoIcon from '@mui/icons-material/Info';

import 'react-toastify/dist/ReactToastify.css';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import VideoSnapshot from 'video-snapshot';
import VideocamIcon from '@mui/icons-material/Videocam';

class UploadVideo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            busy: false,
            images: [],
            fields: {
                idRegistry: "",

                name: (this.props.Update && this.props.dataDetail) ? this.props.dataDetail.name : this.props.objStep1.name ? this.props.objStep1.name : "",
                fileType: "",
            },
            errors: {},
            files: [{
                size: 1
            }],
            showErrorFiles: false,
            showErrorVideo: null,
            key: Math.random(),
            image: "",
            uploadVideo: true,
            objToSend: {},
            percent: 0,
            intervalId: null,
            intervalIdTrajectory: null,
            errorUploadVideo: ""

        };
        this.form = new ReactFormInputValidation(this, { locale: 'es' });
        this.form.onformsubmit = async (fields) => {


            if (!this.state.files[0]) {
                this.setState({
                    showErrorVideo: "No ha subido el archivo"
                })
            } else if (this.state.image === "") {
                this.setState({
                    showErrorVideo: "El archivo debe superar los 20 segundos"
                })
            } else {
                const objToSend = await {
                    created_user: this.props.dataUserLog.public_key,
                    initials: "",
                    name: fields.name,
                    client_id: this.props.dataUserLog.is_admin === true ? this.props.companieId : this.props.dataUserLog.client_id,
                    image: this.state.image,
                    file: this.state.files,
                }

                this.props.actionVideosUpload({
                    formFile: this.state.files[0],
                    IDCliente: objToSend.client_id,
                });
                // IDCliente
                // formFile
                this.setState({
                    objToSend: objToSend,
                    uploadVideo: false,
                    percent: 0,
                }, () => {
                    if (this.state.files.length > 0 && this.state.uploadVideo !== true) {
                        var intervalId = setInterval(() => this.updateUnread(), 2000);
                        this.setState({ intervalId: intervalId });
                    }
                })


            }

            if (this.props.Update === true) {
                // this.props.actionExecutivesActualizar({
                //     ...objToSend,
                //     id: this.props.dataDetail.public_key,
                //     //updatedBy: "A40AF516-4209-4C5A-8EF0-D28328AFA10F"
                // });
                //console.log("Update Obj to send:", objToSend);
            } else {
                //console.log("Create Obj to send:", objToSend);
                //this.props.actionExecutivesCrear({ ...objToSend });
            }
        }
    }

    componentDidUpdate(preprops) {
        if (this.props.dataDetail !== preprops.dataDetail) {
            this.setDataFields();
        }

        if (this.props.dataVideoUploaded) {
            switch (this.props.estadoVideo) {
                case "SUBIDO":
                    this.videoUpload();
                    break;
                case "DETALLADO":
                    if (this.props.dataVideoDetailed.load_trajectory >= 100) {
                        clearInterval(this.state.intervalIdTrajectory);
                    }
                    break;
            }
        }

        if (this.props.apiRespuestaVideo) {
            switch (this.props.estadoVideo) {
                case "CREADO":
                    this.getDetailVideo()
                    break;
                default:
                    break;
            }
            this.props.actionLimpiarEstado()
        }
    }

    async componentDidMount() {
        this.form.useRules({
            name: "required",
        });

        // const intervalId = setInterval(() => this.updateUnread(), 2000);
        // this.setState({ intervalId })
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
        clearInterval(this.state.intervalIdTrajectory);
    }

    updateUnread() {
        console.log("Upload: ", percentChangue)
        this.setState({
            percent: percentChangue
        }, () => {
            if (this.state.percent >= 100) {
                clearInterval(this.state.intervalId);
            }
        });
    }


    setDataFields = () => {
        this.setState({
            fields: {
                name: (this.props.Update && this.props.dataDetail) ? this.props.dataDetail.name : this.props.objStep1.name ? this.props.objStep1.name : "",
            },
            files: this.props.objStep1.files ? this.props.objStep1.files : []
        });
    }

    takeSnapshotFunc = async (files) => {
        this.setState({ files: files }, async () => {
            if (this.state.files.length > 0) {
                const snapshoter = new VideoSnapshot(this.state.files[0]);
                const previewSrc = await snapshoter.takeSnapshot(20);
                this.setState({
                    image: previewSrc,
                    images: [{ id: cuid(), src: previewSrc }],
                    busy: false
                })
            }
        })

    }

    // videoChargue = async(e) => {
    //     console.log("e: ", e.target.files[0]);
    //     const snapshoter = new VideoSnapshot(e.target.files[0]);
    //     const previewSrc = await snapshoter.takeSnapshot();
    //     console.log("previewSrc", previewSrc)
    // }

    nextStep = () => {
        this.props.NextStep({
            step: 1,
            completed: true,
            fields: this.state.objToSend,
        });
    }

    videoUpload = async () => {
        this.props.actionVideosCrear({
            ...this.state.objToSend,
            client_id: this.props.dataUserLog.is_admin === true ? this.props.companieId : this.props.dataUserLog.client_id,
            image: "",
            path: this.props.dataVideoUploaded,
            //               "name": "string",
            //   "path": "string",
            //   "created_user": "string"
        });
    }

    getDetailVideo = () => {
        var intervalIdTrajectory = setInterval(() => this.updateTrajectory(), 2000);
        this.setState({ intervalIdTrajectory: intervalIdTrajectory });
    }

    updateTrajectory = () => {
        this.props.actionVideosDetalle({
            id: this.props.dataVideoCreated.public_key
        });
    }

    resetProcess = () => {
        this.setState({
            key: Math.random(),
            image: "",
            uploadVideo: true,
            objToSend: {},
            percent: 0,
            fields: {
                name: ""
            }
        }, () => {
            clearInterval(this.state.intervalId);
            clearInterval(this.state.intervalIdTrajectory);
            this.props.ClearError()
        })
    }


    onDrop = async (acceptedFiles) => {
        console.log("acceptedFiles", acceptedFiles);

        this.setState({
            busy: true
        }, () => {
            this.takeSnapshotFunc(acceptedFiles);
        })
        // acceptedFiles.map(async(file) => {
        //     const reader = await new FileReader();
        //     reader.onload = async(e) => {
        //        await console.log("e.target.result", e.target.result)
        //         await this.setImages(e)
        //     };
        //     reader.readAsDataURL(file);
        //     return file;
        // });

    };

    // setImages = (e) => {
    //     console.log("llamado imagen: ", this.state.image)
    //     this.setState(prevState => {
    //         return {
    //             ...prevState,
    //             images: [{ id: cuid(), src: this.state.image }],
    //         }
    //     })
    // }


    render() {
        return (
            // <Dialog
            //     fullWidth={true}
            //     maxWidth={"md"}
            //     open={this.props.open}
            //     onClose={() => this.props.CloseDialog()}
            //     aria-labelledby="form-dialog-title">
            //     <DialogTitle id="form-dialog-title">{this.props.Update ? "Actualizar" : "Crear"} Contacto</DialogTitle>
            //     <DialogContent>
            <>

                <Box
                    sx={{
                        backgroundColor: '#fff',
                        minHeight: '100%',
                        py: 3,
                        marginTop: 0
                    }}
                >
                    <Container maxWidth={false}>
                        {this.state.uploadVideo === true
                            ?
                            <div class="d-flex justify-content-center" style={{ flexDirection: "column" }}>
                                <div class="d-flex justify-content-center" style={{
                                    backgroundColor: "#f7f7f6",
                                    width: "80%",
                                    alignSelf: "center",
                                    alignItems: "center",
                                    paddingBottom: 20, paddingTop: 20
                                }}>
                                    <InfoIcon style={{
                                        marginRight: 10,
                                        color: "#0099ff",
                                        fontSize: 30
                                    }} />
                                    Puedes analizar un archivo a la vez.
                                </div>
                                <div class="d-flex justify-content-center" style={{ marginTop: 10 }}>
                                    <div style={{
                                        backgroundColor: "transparent",
                                        width: "80%",
                                        alignSelf: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        display: "flex"
                                    }}>
                                        Los formatos de video permitidos son mp4.
                                    </div>
                                </div>
                            </div>
                            : null}

                        <Box display="flex" flexDirection="row" width={"100%"} overflow={"hidden"}>
                            <form onSubmit={this.form.handleSubmit} style={{ width: "100%", }}>
                                {this.state.uploadVideo === true
                                    ?
                                    <Box display="flex"
                                        flexDirection="column"
                                        width={"100%"}
                                        overflow={"hidden"}>
                                        <div class="d-flex justify-content-center">
                                            <FieldFormTemplate
                                                label="Nombre del video *"
                                                classCol="col-10 col-md-10"
                                                field={
                                                    <input
                                                        maxLength={65}
                                                        type="text"
                                                        name="name"
                                                        onBlur={this.form.handleBlurEvent}
                                                        onChange={this.form.handleChangeEvent}
                                                        value={this.state.fields.name}
                                                        data-attribute-name="Nombre del video "
                                                        style={styles.inputStyle}
                                                        placeholder={"Dale un nombre al video"}
                                                    />
                                                }
                                                error={this.state.errors.name}
                                            />
                                        </div>

                                        <div class="d-flex justify-content-center" style={{ flexDirection: "column",                                                 width: "100%",
                                                display: "flex",
                                                flex: 1, }}>
                                            <div class="d-flex justify-content-center" style={{
                                                width: "80%",
                                                display: "flex",
                                                flex: 1,
                                                alignSelf: "center",
                                                flexDirection: "column",
                                            }}>
                                                <DropzoneAreaComponent
                                                    onDrop={(e) => {
                                                        this.setState({
                                                            busy: true,
                                                            images: []
                                                        }, () =>
                                                            this.onDrop(e)
                                                        )
                                                    }
                                                    }
                                                    accept={acceptedFilesValidation}
                                                />
                                                <ImageGrid images={this.state.images} />
                                                {/* <DropzoneArea
                                                    showPreviews={true}
                                                    showPreviewsInDropzone={false}
                                                    useChipsForPreview
                                                    previewGridProps={{ container: { spacing: 1, direction: 'row', height: 5, marginBottom: 5 } }}
                                                    previewChipProps={{ classes: { root: styles.previewChip } }}
                                                    previewText={"Archivo Seleccionado"}
                                                    dropzoneText={"Arrastra el video o haz click para cargar el archivo."}
                                                    onChange={(e) => this.handleChange(e)}
                                                    filesLimit={1}
                                                    showFileNames={true}
                                                    showAlerts={false}
                                                    onDropRejected={() => { this.setState({ showErrorFiles: true }) }}
                                                    acceptedFiles={acceptedFilesValidation}
                                                    dropzoneClass={{ width: "85%" }}
                                                    Icon={() => <CloudUploadIcon style={{
                                                        fontSize: 100,
                                                        color: "#0099ff"
                                                    }} />}
                                                    key={this.state.key}
                                                    maxFileSize={1099511628}
                                                /> */}
                                            </div>

                                        </div>
                                    </Box>
                                    :
                                    <Box display="flex"
                                        flexDirection="column"
                                        width={"100%"}
                                        marginTop={2}
                                        overflow={"hidden"}>
                                        <div class="d-flex justify-content-center"
                                            style={{ fontSize: 30, color: "#4c4c4c" }}>
                                            {this.state.fields.name}
                                        </div>

                                        <div className="form-row" style={{ width: "100%", marginTop: 10, marginBottom: 20, alignContent: "center", alignItems: "center", justifyContent: "center" }}>
                                            {this.props.errorUploadVideo === "" ?
                                                (this.state.percent < 100)
                                                    ? <img src={"/static/images/avatars/trafficlightY.png"} width={"15%"} height={undefined} />
                                                    : <img src={"/static/images/avatars/trafficlightG.png"} width={"15%"} height={undefined} />
                                                : <img src={"/static/images/avatars/trafficlightR.png"} width={"15%"} height={undefined} />

                                            }

                                        </div>

                                        <div class="d-flex justify-content-center"
                                            style={{ fontSize: 14, color: "#4c4c4c", textAlign: "center" }}>
                                            {this.props.errorUploadVideo === ""
                                                ? (this.state.percent < 100)
                                                    ? "Estamos cargando el archivo"
                                                    : "El video ha sido cargado"
                                                : <div class="d-flex justify-content-center">
                                                    Ha ocurrido un error en la carga.<br></br>
                                                    Carga de nuevo el archivo para reiniciar el proceso.
                                                </div>
                                            }

                                        </div>

                                        {this.props.errorUploadVideo === ""
                                            ?

                                            <div class="d-flex justify-content-center" style={{ flexDirection: "column", marginTop: 20 }}>
                                                <div class="d-flex justify-content-center" style={{
                                                    backgroundColor: "#fff",
                                                    width: "80%",
                                                    alignSelf: "center",
                                                    alignItems: "center",
                                                    flexDirection: "row"
                                                }}>
                                                    <VideocamIcon
                                                        style={{
                                                            backgroundColor: "#0099ff",
                                                            fontSize: 40,
                                                            color: "#fff",
                                                            borderRadius: 30,
                                                            padding: 10,
                                                            marginRight: 20
                                                        }} />
                                                    <Box display="flex"
                                                        flexDirection="column"
                                                        width={"90%"}>
                                                        <div>
                                                            {this.state.files[0].name
                                                                ? this.state.files[0].name
                                                                : ""
                                                            }
                                                        </div>
                                                        <ProgressBar
                                                            filledBackground="#0099ff"
                                                            unfilledBackground={"#e1ded8"}
                                                            percent={this.state.percent}
                                                            width={"100%"}
                                                            height={15}
                                                            text={`${this.state.percent}%`}
                                                        />
                                                    </Box>
                                                </div>
                                            </div>
                                            : null}

                                        {this.props.errorUploadVideo === ""
                                            ?
                                            <div class="d-flex justify-content-center" style={{ flexDirection: "column", marginTop: 30 }}>
                                                <div class="d-flex justify-content-center" style={{
                                                    backgroundColor: "#f7f7f6",
                                                    width: "80%",
                                                    alignSelf: "center",
                                                    alignItems: "center",
                                                    paddingBottom: 20, paddingTop: 20,
                                                    flexDirection: "column"
                                                }}>
                                                    {this.props.dataVideoDetailed.load_trajectory >= 100
                                                        ? "Ahora puedes continuar a crear las geometrías que realizarán las mediciones para este video."
                                                        : "Ahora estamos generando las trayectorias derivadas del video."
                                                    }


                                                    <div
                                                        class="d-flex justify-content-center"
                                                        style={{
                                                            width: 80,
                                                            height: 80,
                                                            alignSelf: "center",
                                                            alignItems: "center",
                                                        }}>
                                                        <CircularProgressbar
                                                            text={
                                                                this.props.dataVideoDetailed.load_trajectory !== undefined ?
                                                                    `${this.props.dataVideoDetailed.load_trajectory}%`
                                                                    : "0%"
                                                            }
                                                            value={
                                                                this.props.dataVideoDetailed.load_trajectory !== undefined
                                                                    ?

                                                                    this.props.dataVideoDetailed.load_trajectory
                                                                    : 0
                                                            }
                                                            styles={buildStyles({
                                                                // Rotation of path and trail, in number of turns (0-1)
                                                                rotation: 0.25,

                                                                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                                strokeLinecap: 'butt',

                                                                // Text size
                                                                textSize: '16px',

                                                                // How long animation takes to go from one percentage to another, in seconds
                                                                pathTransitionDuration: 0.5,

                                                                // Can specify path transition in more detail, or remove it entirely
                                                                // pathTransition: 'none',

                                                                // Colors
                                                                pathColor: "#0099ff",
                                                                textColor: '#0099ff',
                                                                trailColor: '#d6d6d6',
                                                                backgroundColor: '#0099ff',
                                                            })}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            : null}
                                    </Box>
                                }
                                <Box
                                    style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                        width: "100%",
                                        marginTop: 30,
                                        justifyContent: "center"
                                    }}>
                                    {this.props.errorUploadVideo === "" ?
                                        this.state.uploadVideo === true ?
                                            <Button
                                                variant='contained'
                                                endIcon={<ArrowForwardIcon />}
                                                color="primary"
                                                type="submit"
                                                style={{ outline: "none" }}>
                                                Cargar Video
                                            </Button>
                                            :
                                            <Button
                                                variant='contained'
                                                endIcon={<ArrowForwardIcon />}
                                                color="primary"
                                                disabled={this.props.dataVideoDetailed !== undefined
                                                    ? this.props.dataVideoDetailed.load_trajectory >= 100
                                                        ? false
                                                        : true
                                                    : true}
                                                //type="submit"
                                                onClick={() => this.nextStep()}
                                                style={{ outline: "none" }}>
                                                Crear Compuertas
                                            </Button>
                                        : null
                                    }
                                    {this.props.errorUploadVideo !== ""
                                        ? <Button
                                            variant='contained'
                                            endIcon={<CachedIcon />}
                                            color="primary"
                                            onClick={() => this.resetProcess()}
                                            style={{ outline: "none" }}>
                                            Reiniciar Proceso
                                        </Button>
                                        : null}
                                </Box>
                            </form>
                        </Box>
                    </Container>
                </Box>
                {/* </DialogContent>

                <SweetAlert
                    show={this.state.showErrorDate}
                    error
                    confirmBtnText="Aceptar"
                    confirmBtnBsStyle="light"
                    style={{ color: "#000", fontSize: 14 }}
                    title={"Error"}
                    onConfirm={() => this.setState({
                        showErrorDate: !this.state.showErrorDate
                    })}
                >
                    <span>{"Fecha Fin debe ser mayor a Fecha inicio"}</span>
                </SweetAlert>

                <ToastContainer position={"top-right"} delay={5000} />
            </Dialog> */}

                <SweetAlert
                    show={this.state.showErrorFiles}
                    error
                    confirmBtnText="Aceptar"
                    confirmBtnBsStyle="light"
                    style={{ color: "#000", fontSize: 14 }}
                    title={"Error"}
                    onConfirm={() => {
                        this.setState({
                            showErrorFiles: !this.state.showErrorFiles,
                            files: [],
                            key: Math.random()
                        })
                    }}
                >
                    <span>{"Archivo no admitido"}</span>
                </SweetAlert>


                <SweetAlert
                    show={this.state.showErrorVideo !== null}
                    error
                    confirmBtnText="Aceptar"
                    confirmBtnBsStyle="light"
                    style={{ color: "#000", fontSize: 14 }}
                    title={"Error"}
                    onConfirm={() => {
                        this.setState({
                            showErrorVideo: null,
                            image: "",
                            //key: Math.random()
                        })
                    }}
                >
                    <span>{this.state.showErrorVideo}</span>
                </SweetAlert>
                <Loading busy={this.state.busy} />
            </>

        )
    }
}



export default UploadVideo;