/* eslint-disable */
import { RGBA } from 'konva/lib/filters/RGBA';
import React, { Component } from 'react';
import './CameraListMosaico.css';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DangerousIcon from '@mui/icons-material/Dangerous';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
//import CarCrashIcon from '@mui/icons-material/CarCrash';
import PetsIcon from '@mui/icons-material/Pets';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { URL_API } from 'src/api/endpoints';
import SweetAlert from 'react-bootstrap-sweetalert';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import StopIcon from '@mui/icons-material/Stop';

class Camaras extends Component {
  constructor() {
    super();
    this.state = {
      listCamaras: [],
      showMessageNotCreateUser: false
    };
  }

  render() {
    const listCamaras = this.props.Cameras.map((element) => {
      return (
        <CamaraElement
          key={element.public_key}
          camara={element}
          showDeleteCamera={this.props.showDeleteCamera}
          actionCamerasDelete={this.props.actionCamerasDelete}
          actionCamerasHabilitarDeshabilitar={
            this.props.actionCamerasHabilitarDeshabilitar
          }
          actionCamerasDeshabilitar={this.props.actionCamerasDeshabilitar}
          actionCamerasSetCapacityPosition={
            this.props.actionCamerasSetCapacityPosition
          }
          OpenEdit={this.props.OpenEdit}
          errorPosAforo={() => {
            this.setState({
              showMessageNotCreateUser: !this.state.showMessageNotCreateUser
            });
          }}
        />
      );
    });

    return (
      <div>
        {listCamaras}
        <SweetAlert
          danger
          show={this.state.showMessageNotCreateUser}
          confirmBtnText="Aceptar"
          confirmBtnBsStyle="light"
          style={{ color: '#000', fontSize: 14 }}
          confirmBtnStyle={{ fontSize: 14 }}
          onConfirm={() => this.setState({ showMessageNotCreateUser: false })}
        >
          <span>{'La camara no está online'}</span>
        </SweetAlert>
      </div>
    );
  }
}

class CamaraElement extends Component {
  constructor() {
    super();
    this.state = {
      optVisibility: 'invisible',
      eventos: [] // Agregar este estado para almacenar los eventos
    };
    this.setVisibility = this.setVisibility.bind(this);
  }

  componentDidMount() {
    const eventos = this.props.camara.roadAlertLog.slice(-4); // Filtrar los últimos 4 eventos
    this.setState({ eventos });
  }

  setVisibility = async () => {
    let estado = (await (this.state.optVisibility == 'visible'))
      ? 'invisible'
      : 'visible';
    console.log('options: ', estado);
    this.setState({ optVisibility: estado });
  };

  render() {
    const element = this.props.camara;
    const style = {
      height: '114px',
      width: '100%',
      backgroundImage: `URL('${URL_API}/${element.path}')`,
      backgroundSize: 'cover',
      backgroundColor:
        element.is_active && element.is_online
          ? 'rgba(0, 92, 153, 0.6)'
          : 'rgba(0, 0, 0, 0.4)'
    };
    const headerContentStyle = {
      backgroundColor:
        element.is_active && element.is_online ? 'rgba(0, 92, 153, 0.6)' : ''
    };

    const mosaicoColor = {
      backgroundColor:
        element.is_active && element.is_online ? 'white' : '#e1ded8'
    };

    const eventos = this.state.eventos.map((e) => (
      <Evento key={e.road_alert_id} id={e.road_alert_id} eventName={e.name} />
    ));

    let kindState;
    if (!element.is_online) {
      kindState = 'Desconectada';
    } else if (element.analytics_id > 0) {
      kindState = 'Aforando';
    } else {
      kindState = 'No aforando';
    }

    return (
      <div className="camaraComponent" style={mosaicoColor}>
        <div className="header" style={style}>
          <div className="header-container" style={headerContentStyle}>
            <a className="display-options" onClick={this.setVisibility}>
              <MoreVertIcon className="moreVertIcon" />
            </a>
            <Opciones
              id={element.public_key}
              isOnline={element.is_online}
              showDeleteCamera={this.props.showDeleteCamera}
              actionCamerasDelete={this.props.actionCamerasDelete}
              actionCamerasSetCapacityPosition={
                this.props.actionCamerasSetCapacityPosition
              }
              visibility={this.state.optVisibility}
              setVisibility={() => this.setVisibility()}
              OpenEdit={this.props.OpenEdit}
              errorPosAforo={this.props.errorPosAforo}
            />
            <p className="titulo">{element.name + '-' + element.kilometer}</p>
          </div>
        </div>
        <div className="body">
          <div className="body-superior">
            <div className="contenedor-estado">
              <p className="title-description">Estado</p>
              <p className="kind-state">{kindState}</p>
            </div>
            <button
              onClick={() => {
                this.setVisibility();
                !element.is_active
                  ? this.props.actionCamerasHabilitarDeshabilitar({
                      id: element.public_key
                    })
                  : this.props.actionCamerasDeshabilitar({
                      id: element.public_key
                    });
              }}
              className={
                element.analytics_id > 0 && element.is_online
                  ? 'stopState'
                  : 'startState'
              }
              title={
                element.analytics_id > 0 && element.is_online
                  ? 'Detener aforo'
                  : 'Iniciar aforo'
              }
              disabled={!element.is_online}
            >
              <div
                className={
                  element.analytics_id > 0 && element.is_online
                    ? 'cuadrado'
                    : 'triangulo'
                }
              ></div>
            </button>
          </div>
          <div className="body-inferior">
            <p className="title-description">Eventos a detectar</p>
            {eventos}
          </div>
        </div>
      </div>
    );
  }
}

class Evento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false // Agregar el estado para controlar si el cursor está sobre el icono
    };
  }

  render() {
    const { id, name } = this.props;
    let icon;
    let color;
    let eventName;

    switch (id) {
      case 1:
        color = '#f9e212';
        icon = <ElectricCarIcon style={{ color: 'black', fontSize: '15px' }} />;
        eventName = 'Tránsito lento';
        break;
      case 2:
        color = '#f9e212';
        icon = (
          <AccessTimeFilledIcon style={{ color: 'black', fontSize: '15px' }} />
        );
        eventName = 'Congestión moderada';
        break;
      case 3:
        color = '#f9e212';
        icon = <StopIcon style={{ color: 'black', fontSize: '15px' }} />;
        eventName = 'Tránsito detenido';
        break;
      case 4:
        color = '#f9e212';
        icon = (
          <CompareArrowsIcon style={{ color: 'black', fontSize: '15px' }} />
        );
        eventName = 'Variación contrasentido';
        break;
      case 5:
        color = '#f9e212';
        icon = (
          <DirectionsCarIcon style={{ color: 'black', fontSize: '15px' }} />
        );
        eventName = 'Vehículo detenido';
        break;
      case 6:
        color = '#6604e3';
        icon = (
          <DirectionsWalkIcon style={{ color: 'black', fontSize: '15px' }} />
        );
        eventName = 'Persona en vía';
        break;
      case 7:
        color = '#00b231';
        icon = <PetsIcon style={{ color: 'black', fontSize: '15px' }} />;
        eventName = 'Animal en vía';
        break;
      case 8:
        color = '#f9e212';
        icon = (
          <DirectionsBikeIcon style={{ color: 'black', fontSize: '15px' }} />
        );
        eventName = 'Bicicletas en vía';
        break;
      case 9:
        color = '#f9e212';
        icon = <DoubleArrowIcon style={{ color: 'black', fontSize: '15px' }} />;
        eventName = 'Variación de flujo';
        break;
    }

    return (
      <>
        <div
          className="event"
          style={{ backgroundColor: color }}
          onMouseEnter={() => this.setState({ isHovered: true })}
          onMouseLeave={() => this.setState({ isHovered: false })}
        >
          {icon}
          <div className="eventName">
            {this.state.isHovered && (
              <p
                style={{
                  textAlign: 'center',
                  fontSize: '12px',
                  marginTop: '8px',
                  width: '50px'
                }}
              >
                {eventName}
              </p>
            )}
          </div>
        </div>
      </>
    );
  }
}

class Opciones extends Component {
  render() {
    return (
      <div className={this.props.visibility}>
        <div
          className={'extern-modal '}
          onClick={this.props.setVisibility}
        ></div>
        <div
          className="modal-camera"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <ul>
            <li>
              <EditIcon />{' '}
              <a
                onClick={() => {
                  this.props.setVisibility();
                  this.props.OpenEdit(this.props.id);
                }}
              >
                Editar
              </a>
            </li>
            <li>
              <ControlCameraIcon />{' '}
              <a
                onClick={
                  !this.props.isOnline
                    ? this.props.errorPosAforo
                    : () => {
                        this.props.setVisibility();
                        this.props.actionCamerasSetCapacityPosition({
                          id: this.props.id
                        });
                      }
                }
              >
                Mover a pos. de aforo
              </a>
            </li>
            <li className="eliminar">
              <DeleteIcon />{' '}
              <a
                onClick={() => {
                  this.props.setVisibility();
                  this.props.showDeleteCamera(this.props.id);
                }}
              >
                Eliminar
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Camaras;
