/* eslint-disable */
export const EXECUTIVES_INICIA = 'EXECUTIVES_INICIA';
export const EXECUTIVES_LISTA = 'EXECUTIVES_LISTA';
export const EXECUTIVES_LISTADO = 'EXECUTIVES_LISTADO';
export const EXECUTIVES_ERROR = 'EXECUTIVES_ERROR';

export const EXECUTIVES_ACTUALIZAR = "EXECUTIVES_ACTUALIZAR";
export const EXECUTIVES_ACTUALIZADA = "EXECUTIVES_ACTUALIZADA";
export const EXECUTIVES_CREAR = "EXECUTIVES_CREAR";
export const EXECUTIVES_CREADA =  "EXECUTIVES_CREADA";

export const EXECUTIVE_DETALLE_INICIA = "EXECUTIVE_DETALLE_INICIA";
export const EXECUTIVE_DETALLE = "EXECUTIVE_DETALLE";
export const EXECUTIVE_DETALLADO = "EXECUTIVE_DETALLADO";
export const EXECUTIVE_DETALLE_ERROR = "EXECUTIVE_DETALLE_ERROR";

export const EXECUTIVES_HABILITAR_DESHABILITAR = "EXECUTIVES_HABILITAR_DESHABILITAR";
export const EXECUTIVES_HABILITADA_DESHABILITADA = "EXECUTIVES_HABILITADA_DESHABILITADA";