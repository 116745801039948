/* eslint-disable */
import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import settings from './settings';
import Api from './Api';

import contacts from './contacts';
import companies from './companies';
import companiesDetail from './companiesDetail';

import permissions from './permissions';
import roles from './roles';

import cameras from './cameras';
import camerasDetail from './camerasDetail';

import roadWays from './roadWays';
import roadWaysDetail from './roadWaysDetail';

import gates from './gates';
import gatesVideo from './gatesVideo';

import scaleFactor from './scaleFactor';

import videos from './videos';
import maneuvers from './maneuvers';

import detectors from './detectors';
import detectorDetail from './detectorDetail';

import user from './user';
import executives from './executives';
import analyticSettings from './analyticSettings'
import contactsDetail from './contactsDetail';

import executiveDetail from './executiveDetail';

const reducers = {
  routing: routerReducer,
  settings,
  Api,
  contacts,
  contactsDetail,
  user,
  permissions,
  roles,

  companies,
  companiesDetail,

  cameras,
  camerasDetail,

  roadWays,
  roadWaysDetail,
  
  gates,
  scaleFactor,

  videos,
  gatesVideo,
  
  maneuvers,
  detectors,
  detectorDetail,

  executives,

  executiveDetail,

  analyticSettings,
};

export default combineReducers(reducers);
