/* eslint-disable */
import { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { Image, Layer, Line, Stage } from 'react-konva';
import Img from 'use-image';
import { Box } from '@material-ui/core';

import Add from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Gate from './Gate';
import DropDownFormTemplate from 'src/components/DropDownFormTemplate';
import CircleIcon from '@mui/icons-material/Circle';

import { colorsList } from "src/api/commons";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function GateForm({ gates, setGates, roadways, setRoadways, setAspectRatio, cameraImg, showFromParent, showFromManeuvers }) {
  const roadImg = cameraImg;
  const layerRef = useRef(null);
  const [selectedId, selectShape] = useState(null);
  const [layoutWidth, setLayoutWidth] = useState(0);
  const [layoutHeight, setLayoutHeight] = useState(0);

  const [roadway, setRoadway] = useState(0);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [colorSelect, setColorSelect] = useState(colorsList[0].value);
  const [heightWD, setheightWD] = useState(windowDimensions.height * (showFromParent === true ? 0.4 : 0.8));
  const [widthWD, setwidthWD] = useState(windowDimensions.width * (showFromParent === true ? 0.4 : 0.8));

  useLayoutEffect(() => {
    fnBlob();
  }, []);

  const fnBlob = async()=> {
    const imageBlob = await (await fetch(cameraImg)).blob();
    const fileReader = new FileReader();
    fileReader.onload = async (e) => {
      let image = new window.Image();
      image.src = e.target.result;
      await image.decode();
      const width = image.width;
      const height = image.height;
      const layoutWidth = layerRef.current.offsetWidth * 0.90;
      const layoutHeight = (layerRef.current.offsetWidth / (width / height)) * 0.90 
      setAspectRatio({width: (width/layoutWidth) , height: (height/layoutHeight)});

      setLayoutWidth(layoutWidth);
      setLayoutHeight(layoutHeight);
    }
    fileReader.readAsDataURL(imageBlob);
  }
  
  const RoadImg = () => {
    const [image] = Img(roadImg);
    return <Image image={image} width={layoutWidth} height={layoutHeight}/>;
  };

  const handleAddGates_ = () => {
    let newGate = new Object();
    const width = 100;
    const height = 30;
    const [x, y] = [100, 100];
    const [x1, y1] = [x + width, y + height];
    const gateNow = Date.now();

    newGate = {
      id: gateNow.toString(),
      name: `gate-${gateNow}`,
      roadway_id: roadway,
      x,
      y,
      x1,
      y1,
      rotation: 0,
      width,
      height,
      fill: "transparent",
      stroke: colorSelect
    };

    setGates([...gates, newGate]);
    const random = Math.floor(Math.random() * colorsList.length);
    setColorSelect(colorsList[random].value)
  };

  const renderGate = () => {
    return (
      <Layer>
        <RoadImg />
        {gates.map((gate, i) => {
          return (
            <Gate
              showFromManeuvers={showFromManeuvers}
              key={i}
              shapeProps={gate}
              isSelected={gate.id === selectedId}
              onSelect={() => {
                selectShape(gate.id);
              }}
              onChange={(newAttrs) => {
                const newGates = gates.slice();
                newGates[i] = newAttrs;
                setGates(newGates);
              }}
            />
          );
        })}
      </Layer>
    );
  };

  const checkDeselect = e => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target.attrs.image !== undefined;
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };
  const { height, width } = windowDimensions;
  return (
    <Box display="flex"  flex={1} flexDirection="column" width={"100%"} overflow={"hidden"} margin={0} padding={0} alignSelf={"center"} >
      <div className='form-row' style={{ width: "100%", marginTop: 10, marginBottom: 20 }}>
        <Box
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            width: "100%",
            marginLeft: 5,
            justifyContent: "center",
            alignItems: "center"
          }}>
          {showFromParent !== true ?
            <div className="form-row" style={{ marginLeft: 10, width: 200 }}>
              <DropDownFormTemplate
                label="Color *"
                field={
                  <select
                    id="state"
                    name="state"
                    data-attribute-name="Color"
                    value={colorSelect}
                    onChange={(e) => {
                      setColorSelect(e.target.value)
                    }}
                    style={{
                      width: "100%",
                      borderWidth: "1px",
                      borderRadius: "5px",
                      padding: 2,
                      borderColor: "#fffb",
                      minHeight: "22px",
                      height: "33px",
                      color: colorSelect
                      //backgroundColor: colorSelect
                    }}
                  >
                    {colorsList.map((element) => {
                      return (
                        <option
                          value={element.value} style={{
                            color: "#FFF",
                            backgroundColor: element.value
                          }}>
                          {element.label}
                        </option>
                      )
                    }
                    )
                    }
                  </select>
                }
              />
            </div>
            : null}
          {showFromParent !== true ?
            <Button
              endIcon={<Add />}
              variant="contained"
              color="primary"
              type="submit"
              onClick={() => handleAddGates_()}
              style={{ outline: "none", width: "20%", textTransform: "none", alignSelf: "center" }}>
              Agregar Compuerta
            </Button>
            : null}


        </Box>
      </div>
      <div ref={layerRef} class="d-flex justify-content-center" style={{ width: "100%" }}>
        <Stage
          class="d-flex justify-content-center"
          width={layoutWidth}
          height={layoutHeight}
          onMouseDown={checkDeselect}
          onTouchStart={checkDeselect}
        >
          <Layer >
            <RoadImg />
          </Layer>
          {renderGate()}
        </Stage>
      </div>
    </Box>

  );
}

