/* eslint-disable */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
    actionGatesInicia,
    actionGatesListado,
    actionGatesError,

    actionGatesCreada,
    actionGatesActualizada,
    actionGatesHabilitadaDeshabilitada,
} from "src/actions/api/gates";

import { 
    apiGatesList,    
    apiGatesCrear,
    apiGatesActualizar,
    apiGatesHabilitarDeshabilitar
} from "src/api/gates";

import { 
    GATES_LISTA,
    GATES_CREAR,
    GATES_ACTUALIZAR,
    GATES_HABILITAR_DESHABILITAR
} from 'src/constants/api/gates';

function* sagaGatesLista({ type, params }) {
  try {        
    yield put(actionGatesInicia());
    const data = yield call(apiGatesList, params);
    yield put(actionGatesListado(data));
  } catch (e) {
    yield put(actionGatesError(e));    
  }
}

function* sagaGatesActualizar(action) {
  try {       
    yield put(actionGatesInicia());    
    const resp = yield call(apiGatesActualizar, action.data);
    yield put(actionGatesActualizada(resp));
  } catch (e) {
    yield put(actionGatesError(e));    
  }
}


function* sagaGatesCrear(action) {
  try {       
    yield put(actionGatesInicia());    
    const resp = yield call(apiGatesCrear, action.data);
    yield put(actionGatesCreada(resp));
  } catch (e) {
    yield put(actionGatesError(e));    
  }
}

function* sagaGatesHabilitarDeshabilitar(action) {
  try {
    yield put(actionGatesInicia());
    const resp = yield call(apiGatesHabilitarDeshabilitar, action.data);
    yield put(actionGatesHabilitadaDeshabilitada(resp));
  } catch (e) {
    yield put(actionGatesError(e));
  }
}

export default  [
  takeEvery(GATES_LISTA, sagaGatesLista), 
  takeEvery(GATES_CREAR, sagaGatesCrear), 
  takeEvery(GATES_ACTUALIZAR, sagaGatesActualizar), 
  takeEvery(GATES_HABILITAR_DESHABILITAR, sagaGatesHabilitarDeshabilitar)
]
