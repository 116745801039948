/* eslint-disable */
import { URL_API_FILES, URL_API_VIDEOS } from './endpoints'

import {
  DEFAULT_HEADERS,
  DEFAULT_HEADERS_UPLOAD,
  apiPeticionExitosa,
  apiPeticionExitosaText,
  apiErrorHandler,
  userAuthToken,
  
} from './commons';

const parseJson = async response => {
  const text = await response.text()
  const json = { name: text }
  return json
}

export let percentChangue = 0;
const config = {
  onUploadProgress: progressEvent => {
    let percentComplete = progressEvent.loaded / progressEvent.total
    percentComplete = parseInt(percentComplete * 100);
    percentChangue = percentComplete
  }
}

const axios = require('axios');

export const apiVideosList = async params => {

  var query2 = params.id !== "" ? `/client?client=${params.id}` : `/`;
  return await fetch(
    `${URL_API_VIDEOS}${query2}`,
    Object.assign({ method: "GET" },
      {
        headers: new Headers({
          ...DEFAULT_HEADERS,
          Authorization: userAuthToken(),
        })
      }
    )
  ).then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}

export const apiVideosCrear = async datos => {
  return await fetch(
    URL_API_VIDEOS,
    //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
    {
      method: "POST", body: JSON.stringify(datos), headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken(),
      })
    }
  ).then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}

export const apiVideosDetalle = async datos => {
  var query = datos.id ? `/${datos.id}` : '';
  return await fetch(
    `${URL_API_VIDEOS}${query}`,
    //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
    {
      method: "GET", headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken(),
      })
    }
  ).then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}




function consume(stream, total = 0) {
  while (stream.state === "readable") {
    var data = stream.read()
    total += data.byteLength;
  }
  if (stream.state === "waiting") {
    stream.ready.then(() => consume(stream, total))
  }
  return stream.closed
}


export const apiVideosUpload = async (datos) => {
  var data = await new FormData()
  await data.append('IDCliente', datos.IDCliente)
  await data.append('formFile', datos.formFile)
  // return await fetch(
  //   `${URL_API_FILES}/upload`,
  //   //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
  //   {
  //     method: "POST", body: data, headers: new Headers({
  //       ...DEFAULT_HEADERS_UPLOAD,
  //       Authorization: userAuthToken(),
  //     })
  //   }
  // ) .then(res => consume(res.body))
  // .then(() => console.log("consumed the entire body without keeping the whole thing in memory!"))
  // .then(apiPeticionExitosaText)
  //   .catch(apiErrorHandler);

  return await axios.post(`${URL_API_FILES}/upload`, data, config)
    .then(apiPeticionExitosaText)
    .catch(apiErrorHandler);
}



export const apiVideosActualizar = async datos => {
  var query = datos.id ? `/${datos.id}` : '';
  return await fetch(
    `${URL_API_VIDEOS}${query}`,
    //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
    {
      method: "PUT", body: JSON.stringify(datos), headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken(),
      })
    }
  ).then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}

export const apiCameraDetail = async params => {
  var query = params.id ? `/${params.id}` : '';
  return await fetch(
    `${URL_API_VIDEOS}${query}`,
    Object.assign({ method: "GET" },
      {
        headers: new Headers({
          ...DEFAULT_HEADERS,
          Authorization: userAuthToken(),
        })
      }
    )
  ).then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}

export const apiVideosHabilitarDeshabilitar = async datos => {
  var query = `/delete/${datos.id}`;
  return await fetch(
    `${URL_API_VIDEOS}${query}`,
    {
      method: "DELETE", body: "", headers: new Headers({
        ...DEFAULT_HEADERS,
        "Content-Type": "text/plain",
        Authorization: userAuthToken(),
      })
    }
  )
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}


export const apiVideoscaleFactor = async datos => {
  var query = await `/scale-factor?l_m=${datos.l_m}&w_m=${datos.w_m}`
  return await fetch(
    `${URL_API_VIDEOS}${query}`,
    //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
    {
      method: "POST", body: JSON.stringify(datos), headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken(),
      })
    }
  ).then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}


//------> Connect camera

export const apiVideosCheckConnection = async datos => {
  return await fetch(
    `${URL_API_VIDEOS}/check-connection/${datos.ip_address}`,
    //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
    {
      method: "GET", headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken(),
      })
    }
  ).then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}

export const apiVideosPtz = async datos => {
  return await fetch(
    `${URL_API_VIDEOS}/ptz`,
    //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
    {
      method: "POST", body: JSON.stringify(datos), headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken(),
      })
    }
  ).then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}

export const apiVideosImage = async datos => {
  return await fetch(
    `${URL_API_VIDEOS}/image`,
    //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
    {
      method: "POST", body: JSON.stringify(datos), headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken(),
      })
    }
  ).then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}