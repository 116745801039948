/* eslint-disable */
import React, { Component } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GateForm from './GateForm';
import DrawGatesListResults from './DrawGatesListResults';
import SweetAlert from 'react-bootstrap-sweetalert';
//konva

const url = '/static/images/avatars/road.jpg';

class DialogCreateGates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aspectRatioDimensions: { height: 0, width: 0 },
      fields: {
        title: this.props.dataDetail ? this.props.dataDetail.title : ''
      },
      errors: {},
      selectedId: null,
      roadway: 0,
      gates: this.props.gateList,
      showConfirmEnableDisable: false,
      idToDelete: ''
    };
  }

  componentDidMount() {
    //console.log(this.props.gatesList);
    this.setState({
      gates: this.props.gatesList
    });
  }

  componentDidUpdate(preprops) {
    if (this.props.gateList !== preprops.gateList) {
      //console.log(this.props.gateList )
    }
    //this.setGatesFromProps();
  }

  setGatesFromProps = () => {
    this.setState({
      gates: this.props.gatesList
    });
  };

  setGatesData = (gates) => {
    this.setState({
      gates: gates
    });
    //console.log("set Gate: ", gates);
  };

  openEdit = (id) => {
    this.setState({
      showConfirmEnableDisable: true,
      idToDelete: id
    });
  };

  handleEnableDisable = async (data) => {
    let gateListCopy = await [...this.state.gates];
    let elementToDelete = await gateListCopy.findIndex(
      (e) => e.id === this.state.idToDelete
    );
    await gateListCopy.splice(elementToDelete, 1);
    this.setState({
      showConfirmEnableDisable: false,
      idToDelete: '',
      gates: gateListCopy
    });
  };

  render() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={this.props.open}
        onClose={() => this.props.SaveGates(this.state.gates)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {this.props.Update ? 'Actualizar' : 'Crear'} Compuertas
        </DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="row"
            width={'100%'}
            overflow={'hidden'}
          >
            <Box
              display="flex"
              flexDirection="row"
              width={'100%'}
              overflow={'hidden'}
            >
              <Box
                display="flex"
                flexDirection="column"
                width={'60%'}
                overflow={'hidden'}
                style={{
                  padding: 0,
                  margin: 0
                }}
              >
                <div
                  className="form-row"
                  style={{ width: '95%', marginTop: 10, marginBottom: 20 }}
                >
                  <GateForm
                    rotation={this.state.rotation}
                    setAspectRatio={(e) =>
                      this.setState({
                        aspectRatioDimensions: {
                          width: e.width,
                          height: e.height
                        }
                      })
                    }
                    gates={this.state.gates}
                    setGates={(gates) => this.setGatesData(gates)}
                    roadways={[
                      {
                        id: '7020',
                        name: 'Testing-Front'
                      }
                    ]}
                    setRoadways={(set) => console.log('setRoadways', set)}
                    cameraImg={this.props.url}
                  />
                </div>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                width={'40%'}
                overflow={'hidden'}
                style={{ padding: 0, marginLeft: 0, marginTop: 80 }}
              >
                <DrawGatesListResults
                  busy={this.props.busy}
                  estado={this.props.estado}
                  OpenEdit={(id) => this.openEdit(id)}
                  onEnableDisable={this.handleEnableDisable}
                  Gates={
                    this.state.gates
                      ? this.state.gates.map((item) => {
                          return {
                            ...item
                          };
                        })
                      : []
                  }
                />
              </Box>
            </Box>

            {/* <DialogActions>
                                <Button color="primary" type="submit">
                                    Guardar
                                </Button>
                                <Button onClick={() => this.props.CloseDialog()} color="primary">
                                    Cerrar
                                </Button>
                            </DialogActions> */}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            type="submit"
            onClick={() => this.props.SaveGates(this.state.gates)}
          >
            Guardar
          </Button>
          <Button
            onClick={() => this.props.SaveGates(this.state.gates)}
            color="primary"
          >
            Cerrar
          </Button>
        </DialogActions>
        <ToastContainer position={'top-right'} delay={5000} />
        <SweetAlert
          show={this.state.showConfirmEnableDisable}
          warning
          confirmBtnText="Aceptar"
          confirmBtnBsStyle="light"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="light"
          cancelBtnStyle={{ fontSize: 14 }}
          style={{ color: '#000', fontSize: 14 }}
          confirmBtnStyle={{ fontSize: 14 }}
          title={'Eliminar compuerta'}
          onConfirm={() => this.handleEnableDisable()}
          onCancel={() =>
            this.setState({ showConfirmEnableDisable: false, idToDelete: '' })
          }
          showCancel={true}
          showConfirm={true}
        >
          <span>{'¿Estás seguro que desea eliminar esta compuerta?'}</span>
        </SweetAlert>
      </Dialog>
    );
  }
}

export default DialogCreateGates;
