/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import {
  actionContactsLista,
} from "src/actions/api/contacts";


import {
  actionCompaniesLista,
} from "src/actions/api/companies";

import { actionLimpiarEstado } from "src/actions";

import {
  actionContactsDetalle,
  actionContactsActualizar,
  actionContactsCrear,
  actionContactsHabilitarDeshabilitar
} from "src/actions/api/contacts";

import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import ContactListResults from 'src/components/contact/ContactListResults';
import ContactListToolbar from 'src/components/contact/ContactListToolbar';
import Loading from 'src/components/Loading';

import { ToastContainer, toast } from 'react-toastify';


class ContactList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      openDialogEdit: false,
      idToEdit: "",
      textFilter: "",
      dataContactsCopy: [],
      dataContacts: [],
    }
  }

  componentDidMount() {
    this.props.actionContactsLista({ estado: "All" });
    this.props.actionCompaniesLista({ id: "All" });
  }

  componentDidUpdate(preprops) {
    if (this.props.apiRespuesta) {
      this.props.actionLimpiarEstado()
    }

    if (this.props.apiRespuesta) {
      switch (this.props.estado) {
        case "LISTADO":
          this.setState({ dataContactsCopy: this.props.dataContacts, dataContacts: this.props.dataContacts });
          break;
        case "ACTUALIZADO":
          toast.success("El contacto se ha actualizado exitosamente", {
            backgroundColor: "#1ABD24",
            color: "#ffffff",
          });
          this.props.actionContactsLista({ estado: "All" });
          break;
        case "CREADO":
          toast.success("El contacto se ha creado exitosamente", {
            backgroundColor: "#1ABD24",
            color: "#ffffff",
          });
          this.props.actionContactsLista({ estado: "All" });
          this.closeDialog();
          break;
        case "ERROR":
          toast.error(this.props.error, {
            backgroundColor: "#E80D0D",
            color: "#ffffff",
          });
          this.props.actionContactsLista({ estado: "All" });
          break;
        case "HABILITADO_DESHABILITADO":
          toast.success("Contactos habilitados/deshabilitados exitosamente", {
            backgroundColor: "#1ABD24",
            color: "#ffffff",
          });
          this.props.actionContactsLista({ estado: "All" });
          break;
        default:
          break;
      }
      this.props.actionLimpiarEstado()
    }
  }

  openEdit = (id) => {
    this.setState({
      openDialogEdit: true,
      idToEdit: id
    });
    this.props.actionContactsDetalle({ id: id });
  }

  closeDialog = () => {
    this.setState({
      openDialogEdit: false
    });
  }

  handleChange = async e => {
    const textData = e.toUpperCase();
    const newData = await this.state.dataContacts.filter(item => {
      return item.name.toUpperCase().indexOf(textData) > -1 || item.administrativeCharge.toUpperCase().indexOf(textData) > -1 || item.email.toUpperCase().indexOf(textData) > -1 || item.phone.toUpperCase().indexOf(textData) > -1 || item.nit.toUpperCase().indexOf(textData) > -1 || item.company.toUpperCase().indexOf(textData) > -1 || item.businessGroup.toUpperCase().indexOf(textData) > -1 || item.insertDate.toUpperCase().indexOf(textData) > -1;
    });
    this.setState({ dataContactsCopy: newData, textFilter: e });
  };

  exportToExcel = async () => {
    const filterData = this.state.dataContacts.map(({ name, administrativeCharge, email, phone, nit, company, businessGroup, insertDate }) => ({ name, administrativeCharge, email, phone, nit, company, businessGroup, insertDate }));
    const Heading = [["Nombre", "Cargo", "Correo", "Teléfono", "NIT", "Razón Social", "Grupo", "Fecha Creación"],];
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const ws = XLSX.utils.json_to_sheet(filterData, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, Heading);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Contactos.xlsx");
  }

  handleEnableDisable = async (data) => {
    await this.props.actionContactsHabilitarDeshabilitar(data)
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Contactos</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          <Container maxWidth={false}>
            <ContactListToolbar {...this.props}
              filter={this.state.textFilter}
              exportToExcel={() => this.exportToExcel()}
              handleChange={(e) => this.handleChange(e)} />
            <Box sx={{ pt: 3 }}>
              {this.props.dataContacts ?
                <ContactListResults
                  busy={this.props.busy}
                  estado={this.props.estado}
                  OpenEdit={(id) => this.openEdit(id)}
                  onEnableDisable={this.handleEnableDisable}
                  Customers={this.state.dataContactsCopy
                    ? this.state.dataContactsCopy.map(item => {
                      return {
                        ...item,
                        id: item.idRegistry
                      }
                    })
                    : []} />
                : null}
            </Box>
          </Container>
        </Box>

        <Loading busy={this.props.busy} />
        <ToastContainer position={"top-right"} delay={5000} />
      </>
    )
  }
}

const mapStateToProps = ({ contacts, contactsDetail, companies }) => ({
  dataContacts: contacts.items,
  estado: contacts.estado,
  apiRespuesta: contacts.apiRespuesta,

  busy: contacts.waiting || contactsDetail.waiting,
  error: contacts.error || contactsDetail.error,

  dataContactsDetail: contactsDetail.items,
  estadoContactsDetail: contactsDetail.estado,
  apiRespuestaContactsDetail: contactsDetail.apiRespuesta,

  dataCompanies: companies.items,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  actionContactsLista,
  actionLimpiarEstado,
  actionContactsDetalle,
  actionCompaniesLista,
  actionContactsCrear,
  actionContactsActualizar,
  actionContactsHabilitarDeshabilitar
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContactList);
