/* eslint-disable */
import { URL_API_CAMERAS, URL_API_DETECTORS } from './endpoints';

import {
  DEFAULT_HEADERS,
  apiPeticionExitosa,
  apiErrorHandler,
  userAuthToken
} from './commons';

export const apiCamerasList = async (params) => {
  var query = (await params.public_key) ? `/?client=${params.public_key}` : '/';
  return await fetch(
    `${URL_API_CAMERAS}${query}`,
    Object.assign(
      { method: 'GET' },
      {
        headers: new Headers({
          ...DEFAULT_HEADERS,
          Authorization: userAuthToken()
        })
      }
    )
  )
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
};

export const apiCamerasCrear = async (datos) => {
  var query = '/';
  return await fetch(
    `${URL_API_CAMERAS}${query}`,
    //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
    {
      method: 'POST',
      body: JSON.stringify(datos),
      headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken()
      })
    }
  )
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
};

export const apiCamerasActualizar = async (datos) => {
  var query = datos.public_key ? `/${datos.public_key}` : '';
  return await fetch(
    `${URL_API_CAMERAS}${query}`,
    //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
    {
      method: 'PUT',
      body: JSON.stringify(datos),
      headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken()
      })
    }
  )
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
};

export const apiCameraDetail = async (params) => {
  var query = params.id ? `/${params.id}` : '';
  return await fetch(
    `${URL_API_CAMERAS}${query}`,
    Object.assign(
      { method: 'GET' },
      {
        headers: new Headers({
          ...DEFAULT_HEADERS,
          Authorization: userAuthToken()
        })
      }
    )
  )
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
};

export const apiCameraScaleFactor = async (datos) => {
  var query = await `/scale-factor?l_m=${datos.l_m}&w_m=${datos.w_m}`;
  return await fetch(
    `${URL_API_CAMERAS}${query}`,
    //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
    {
      method: 'POST',
      body: JSON.stringify(datos),
      headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken()
      })
    }
  )
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
};

export const apiCamerasHabilitarDeshabilitar = async (datos) => {
  var query = `/stop/cameras/${datos.id}`;
  return await fetch(
    `${URL_API_DETECTORS}${query}`,
    Object.assign(
      { method: 'GET' },
      {
        headers: new Headers({
          ...DEFAULT_HEADERS,
          Authorization: userAuthToken()
        })
      }
    )
  )
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
};

export const apiCamerasDeshabilitar = async (datos) => {
  var query = `/start/cameras/${datos.id}`;
  return await fetch(
    `${URL_API_DETECTORS}${query}`,
    Object.assign(
      { method: 'GET' },
      {
        headers: new Headers({
          ...DEFAULT_HEADERS,
          Authorization: userAuthToken()
        })
      }
    )
  )
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
};

export const apiCamerasDelete = async (datos) => {
  var query = `/${datos.id}`;
  return await fetch(`${URL_API_CAMERAS}${query}`, {
    method: 'DELETE',
    body: '',
    headers: new Headers({
      ...DEFAULT_HEADERS,
      'Content-Type': 'text/plain',
      Authorization: userAuthToken()
    })
  })
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
};

export const apiCamerasSetCapacityPosition = async (datos) => {
  var query = `/set-capacity-position/${datos.id}`;
  return await fetch(
    `${URL_API_CAMERAS}${query}`,
    Object.assign(
      { method: 'GET' },
      {
        headers: new Headers({
          ...DEFAULT_HEADERS,
          Authorization: userAuthToken()
        })
      }
    )
  )
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
};

//------> Connect camera

export const apiCamerasCheckConnection = async (datos) => {
  return await fetch(
    `${URL_API_CAMERAS}/check-connection/${datos.ip_address}`,
    //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
    {
      method: 'GET',
      headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken()
      })
    }
  )
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
};

export const apiCamerasPtz = async (datos) => {
  return await fetch(
    `${URL_API_CAMERAS}/ptz`,
    //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
    {
      method: 'POST',
      body: JSON.stringify(datos),
      headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken()
      })
    }
  )
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
};

export const apiCamerasImage = async (datos) => {
  return await fetch(
    `${URL_API_CAMERAS}/image`,
    //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
    {
      method: 'POST',
      body: JSON.stringify(datos),
      headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken()
      })
    }
  )
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
};
