/* eslint-disable */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
    actionContactsDetalleInicia,
    actionContactsDetallado,
    actionContactsDetalleError,
} from "src/actions/api/contacts";

import { 
    apiContactsDetalle,    
} from "src/api/contacts";

import {
    CONTACTS_DETALLE
} from 'src/constants/api/contacts';

function* sagaContactsDetail({ type, params }) {
  try {        
    yield put(actionContactsDetalleInicia());
    const data = yield call(apiContactsDetalle, params);
    yield put(actionContactsDetallado(data));
  } catch (e) {
    yield put(actionContactsDetalleError(e));    
  }
}

export default  [
  takeEvery(CONTACTS_DETALLE, sagaContactsDetail), 
]
