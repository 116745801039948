/* eslint-disable */
import React, { Component } from 'react';

import { Box, Container } from '@material-ui/core';

import Button from '@material-ui/core/Button';

import styles from './styles';

import ReactFormInputValidation from 'react-form-input-validation';
import FieldFormTemplate from '../FieldFormTemplate';
import DropDownFormTemplate from 'src/components/DropDownFormTemplate';
import Add from '@material-ui/icons/Add';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import SweetAlert from 'react-bootstrap-sweetalert';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import 'react-toastify/dist/ReactToastify.css';
import GatesListResults from './GatesListResults';
import DialogCreateGates from 'src/components/DialogCreateGates';
import DialogCreateFormFactor from '../DialogCreateFormFactor';
import { fontSize } from '@mui/system';
import { URL_API } from 'src/api/endpoints';
import { object } from 'prop-types';

const directionsList = [
  {
    name: 'Seleccione un sentido',
    value: ''
  },
  {
    name: 'Sentido norte',
    value: 'N'
  },
  {
    name: 'Sentido sur',
    value: 'S'
  },
  {
    name: 'Sentido oriente',
    value: 'E'
  },
  {
    name: 'Sentido occidente',
    value: 'W'
  }
];

const gatesExample = [
  { name: 'C_Norte', direction: 'Norte' },
  { name: 'C_Sur', direction: 'Sur' }
];

class Driveways extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        idRegistry: '',
        direction: '',
        name: ''
      },
      roadWay: [],
      gatesList: [],
      formFactorList: [],
      formFactorObj: {},
      showConfirmEnableDisable: false,
      errors: {},
      idToDelete: '',
      openDialogCreateGates: false,

      openDialogCreateFormFactor: false,
      idToDeleteFormFactor: '',
      showConfirmEnableDisableFormFactor: false
    };
    this.formGateList = new ReactFormInputValidation(this, { locale: 'es' });
    this.formGateList.onformsubmit = async (fields) => {
      if (fields.name && fields.direction) {
        const newArrayGates = [];
        this.state.gatesList.forEach((element) => {
          newArrayGates.push({
            ...element,
            x: element.x * 3,
            y: element.y * 3,
            x1: element.x1 * 3,
            y1: element.y1 * 3,
            rotation: element.rotation * 3,
            width: element.width * 3,
            height: element.height * 3
          });
        });

        const newArrayFormFactor = [];
        this.state.formFactorList.forEach((element) => {
          newArrayFormFactor.push({
            ...element,
            x: element.x * 2,
            y: element.y * 2,
            x1: element.x1 * 2,
            y1: element.y1 * 2,
            rotation: element.rotation * 2,
            width: element.width * 2,
            height: element.height * 2
          });
        });

        const objToSend = await {
          name: fields.name,
          direction: fields.direction,
          gatesList: newArrayGates,
          formFactorList: newArrayFormFactor,
          formFactorObj: this.state.formFactorObj,
          created_user: this.props.dataUserLog.public_key
          //id: Math.random().toString().slice(2, 11),
        };
        let arrayCopy = await [...this.state.roadWay];
        objToSend.name !== undefined ? await arrayCopy.push(objToSend) : null;

        await this.setState(
          {
            roadWay: await arrayCopy
          },
          () => {
            this.props.CreateCamera({
              step: 3,
              completed: true,
              fields: objToSend
            });
            setTimeout(() => {
              this.setState((prevState) => ({
                fields: {
                  direction: '',
                  name: ''
                },
                roadWay: [],
                gatesList: [],
                formFactorList: [],
                formFactorObj: {}
              }));
            }, 50);
          }
        );
      }

      // if (this.props.Update === true) {
      //     // this.props.actionExecutivesActualizar({
      //     //     ...objToSend,
      //     //     id: this.props.dataDetail.public_key,
      //     //     //updatedBy: "A40AF516-4209-4C5A-8EF0-D28328AFA10F"
      //     // });
      // } else {
      //     //this.props.actionExecutivesCrear({ ...objToSend });
      // }
    };
  }

  componentDidUpdate(preprops) {
    if (this.props.dataDetail !== preprops.dataDetail) {
      this.setDataFields();
    }
  }

  async componentDidMount() {
    this.formGateList.useRules({
      name: 'required',
      direction: 'required'
    });
  }

  setDataFields = () => {
    this.setState({
      fields: {
        name: '',
        direction: ''
      }
    });
  };

  drawFormFactor = () => {
    this.setState({
      openDialogCreateFormFactor: true
    });
  };

  drawGates = () => {
    this.setState({
      openDialogCreateGates: true
    });
  };

  openEdit = (id) => {
    this.setState({
      showConfirmEnableDisable: true,
      idToDelete: id
    });
  };

  openEditFormFactor = (id) => {
    this.setState({
      openDialogCreateFormFactor: true,
      idToDeleteFormFactor: id
    });
  };

  handleEnableDisable = async (data) => {
    // let roadWayCopy = await [...this.state.roadWay];
    // let elementToDelete = await roadWayCopy.findIndex((e) => e.id === this.state.idToDelete);
    // await roadWayCopy.splice(elementToDelete, 1);
    // this.setState({
    //     showConfirmEnableDisable: false,
    //     idToDelete: "",
    //     roadWay: roadWayCopy
    // });
    this.props.deleteById(this.state.idToDelete);
    //this.props.actionRoadWaysHabilitarDeshabilitar({ id: this.state.idToDelete });
    this.setState({
      showConfirmEnableDisable: false,
      idToDelete: ''
    });
  };

  handleEnableDisableFormFactor = async (data) => {
    this.setState({
      showConfirmEnableDisable: false,
      idToDelete: ''
    });
  };

  closeDialogCreateGates = () => {
    this.setState({
      openDialogCreateGates: false
    });
  };

  closeDialogCreateFormFactor = () => {
    this.setState({
      openDialogCreateFormFactor: false
    });
  };

  saveGates = (gatesList) => {
    this.setState(
      {
        gatesList: gatesList
      },
      () => {
        this.closeDialogCreateGates();
      }
    );
  };

  saveformFactor = (formFactorObj) => {
    if (Object.keys(formFactorObj).length !== 0) {
      this.setState(
        {
          formFactorList: formFactorObj.formFactor,
          formFactorObj: formFactorObj
        },
        () => {
          this.closeDialogCreateFormFactor();
        }
      );
    } else {
      this.setState(
        {
          formFactorList: [],
          formFactorObj: {}
        },
        () => {
          this.closeDialogCreateFormFactor();
        }
      );
    }
  };

  render() {
    const { fields } = this.state;
    return (
      // <Dialog
      //     fullWidth={true}
      //     maxWidth={"md"}
      //     open={this.props.open}
      //     onClose={() => this.props.CloseDialog()}
      //     aria-labelledby="form-dialog-title">
      //     <DialogTitle id="form-dialog-title">{this.props.Update ? "Actualizar" : "Crear"} Contacto</DialogTitle>
      //     <DialogContent>
      <>
        <Box
          sx={{
            minHeight: '100%',
            py: 3,
            marginTop: 5,
            padding: 0
          }}
        >
          <Container style={{ width: '100%', padding: 0 }}>
            <Box
              display="flex"
              flexDirection="row"
              width={'100%'}
              overflow={'hidden'}
            >
              <form
                onSubmit={this.formGateList.handleSubmit}
                style={{ width: '100%' }}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  width={'100%'}
                  overflow={'hidden'}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    width={'50%'}
                    overflow={'hidden'}
                  >
                    <div
                      style={{
                        fontFamily: 'sans-serif',
                        fontSize: 20,
                        fontWeight: 'bold',
                        color: '#045c9c',
                        marginTop: 30
                      }}
                    >
                      {'Nueva calzada'}
                    </div>

                    <div
                      className="form-row"
                      style={{ width: '95%', marginTop: 10 }}
                    >
                      <FieldFormTemplate
                        label="Nombre *"
                        classCol="col-12 col-md-12"
                        field={
                          <input
                            maxLength={65}
                            type="text"
                            name="name"
                            onBlur={this.formGateList.handleBlurEvent}
                            onChange={this.formGateList.handleChangeEvent}
                            value={this.state.fields.name}
                            data-attribute-name="Nombre "
                            style={styles.inputStyle}
                            //placeholder={"Nombre de la ruta"}
                          />
                        }
                        error={this.state.errors.name}
                      />
                    </div>

                    <div
                      className="form-row"
                      style={{ width: '95%', marginTop: 10 }}
                    >
                      <DropDownFormTemplate
                        label="Sentido *"
                        classCol="col-12 col-md-12"
                        field={
                          <select
                            //disabled={this.state.fields.is_admin ? true : false}
                            id="direction"
                            name="direction"
                            data-attribute-name="Sentido"
                            value={this.state.fields.direction}
                            onChange={this.formGateList.handleChangeEvent}
                            style={{
                              width: '100%',
                              borderWidth: '1px',
                              borderRadius: '5px',
                              borderColor: '#0000006b',
                              minHeight: '22px',
                              height: '33px'
                            }}
                          >
                            {directionsList.map((element) => (
                              <option value={element.value}>
                                {element.name}
                              </option>
                            ))}
                          </select>
                        }
                        error={this.state.errors.direction}
                      />
                    </div>

                    <div
                      className="form-row"
                      style={{ width: '95%', marginTop: 10 }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          flex: 1,
                          flexDirection: 'row',
                          width: '100%',
                          marginLeft: 5
                        }}
                      >
                        <Button
                          endIcon={<EditRoadIcon />}
                          variant={'outlined'}
                          color="primary"
                          onClick={() => this.drawGates()}
                          disabled={
                            fields.name === '' || fields.direction === ''
                              ? true
                              : false
                          }
                          style={{
                            outline: 'none',
                            width: '100%',
                            textTransform: 'none'
                          }}
                        >
                          Dibujar compuertas ({this.state.gatesList.length})
                        </Button>
                      </Box>
                    </div>

                    <div
                      className="form-row"
                      style={{ width: '95%', marginTop: 10 }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          flex: 1,
                          flexDirection: 'row',
                          width: '100%',
                          marginLeft: 5
                        }}
                      >
                        <Button
                          endIcon={<EditRoadIcon />}
                          variant={'outlined'}
                          color="primary"
                          onClick={() => this.drawFormFactor()}
                          disabled={
                            fields.name === '' || fields.direction === ''
                              ? true
                              : false
                          }
                          style={{
                            outline: 'none',
                            width: '100%',
                            textTransform: 'none'
                          }}
                        >
                          Dibujar factor de forma
                          <CheckCircleIcon
                            style={{
                              margin: 2,
                              fontSize: 15,
                              color:
                                this.state.formFactorList.length > 0
                                  ? '#72E14B'
                                  : '#e8e8e8'
                            }}
                          />
                        </Button>
                      </Box>
                    </div>

                    <div
                      className="form-row"
                      style={{ width: '95%', marginTop: 10 }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          flex: 1,
                          flexDirection: 'row',
                          width: '100%',
                          marginLeft: 5
                        }}
                      >
                        <Button
                          endIcon={<Add />}
                          variant="contained"
                          color="primary"
                          type="submit"
                          onClick={(fields) =>
                            this.formGateList.onformsubmit(fields)
                          }
                          // disabled={
                          //     (fields.user === ""
                          //         || fields.password === ""
                          //         || fields.direction_ip === "")
                          //         ? true
                          //         : false
                          // }
                          style={{
                            outline: 'none',
                            width: '100%',
                            textTransform: 'none',
                            backgroundColor: '#0099ff'
                          }}
                        >
                          Agregar Calzada
                        </Button>

                        {/* <Box
                                    style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                        width: "100%",
                                        justifyContent: "flex-end"
                                    }}>
                                    <Button color="primary" type="submit" style={{ outline: "none" }}>
                                        Siguiente
                                    </Button>
                                </Box> */}
                      </Box>
                    </div>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="row"
                    width={'50%'}
                    height={'20%'}
                    padding={2}
                    marginTop={3}
                  >
                    <GatesListResults
                      busy={this.props.busy}
                      estado={this.props.estado}
                      OpenEdit={(id) => this.openEdit(id)}
                      //onEnableDisable={this.handleEnableDisable}
                      Gates={
                        this.props.dataRoadWaysList
                          ? this.props.dataRoadWaysList.map((item) => {
                              return {
                                ...item,
                                id: item.public_key,
                                directionName: 'Test'
                              };
                            })
                          : []
                      }
                    />
                  </Box>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Button
                    color="primary"
                    onClick={() => this.props.BackStep()}
                    variant="outlined"
                    startIcon={<KeyboardBackspaceIcon />}
                    style={{ outline: 'none' }}
                  >
                    Volver
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => this.props.Finish()}
                    variant="contained"
                    style={{
                      outline: 'none',
                      backgroundColor: '#0099ff',
                      marginLeft: '30px'
                    }}
                  >
                    Finalizar
                  </Button>
                </Box>
              </form>
            </Box>
          </Container>
        </Box>

        <SweetAlert
          show={this.state.showConfirmEnableDisable}
          warning
          confirmBtnText="Aceptar"
          confirmBtnBsStyle="light"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="light"
          cancelBtnStyle={{ fontSize: 14 }}
          style={{ color: '#000', fontSize: 14 }}
          confirmBtnStyle={{ fontSize: 14 }}
          title={'Eliminar Calzada'}
          onConfirm={() => this.handleEnableDisable()}
          onCancel={() =>
            this.setState({ showConfirmEnableDisable: false, idToDelete: '' })
          }
          showCancel={true}
          showConfirm={true}
        >
          <span>{'¿Estás seguro que desea eliminar esta calzada?'}</span>
        </SweetAlert>

        {this.state.openDialogCreateGates ? (
          <DialogCreateGates
            {...this.props}
            url={
              this.props.dataDetail.path
                ? `${URL_API}/${this.props.dataDetail.path}`
                : this.props.dataCameraImage.image
                ? `${URL_API}/${this.props.dataCameraImage.image}`
                : '/static/images/avatars/null_image.png'
            }
            open={this.state.openDialogCreateGates}
            gatesList={this.state.gatesList}
            SaveGates={(gatesList) => this.saveGates(gatesList)}
            CloseDialog={() => this.closeDialogCreateGates()}
          />
        ) : null}
        {this.state.openDialogCreateFormFactor ? (
          <DialogCreateFormFactor
            {...this.props}
            url={
              this.props.dataDetail.path
                ? `${URL_API}/${this.props.dataDetail.path}`
                : this.props.dataCameraImage.image
                ? `${URL_API}/${this.props.dataCameraImage.image}`
                : '/static/images/avatars/null_image.png'
            }
            formFactorList={this.state.formFactorList}
            SaveformFactor={(formFactorObj) =>
              this.saveformFactor(formFactorObj)
            }
            open={this.state.openDialogCreateFormFactor}
            CloseDialog={() => this.closeDialogCreateFormFactor()}
          />
        ) : null}
        {console.log('jjj', this.props.dataDetail)}
      </>
    );
  }
}

export default Driveways;
