/* eslint-disable */
import {
    PERMISSIONS_INICIA,
    PERMISSIONS_LISTA,    
    PERMISSIONS_LISTADO,    
    PERMISSIONS_ERROR,
} from 'src/constants/api/permissions';

export const actionPermissionsInicia = () => ({ type: PERMISSIONS_INICIA, });
export const actionPermissionsLista = params => ({ type: PERMISSIONS_LISTA, params });
export const actionPermissionsListado = data => ({ type: PERMISSIONS_LISTADO, data });

export const actionPermissionsError = error => ({ type: PERMISSIONS_ERROR, error });
