/* eslint-disable */
import React from 'react';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class Loading extends React.Component {

    constructor(props) {
        super(props)
        this.state = { ...props }
    }

    render() {
        return (
            <ReactCSSTransitionGroup
                transitionName="example"
                transitionEnterTimeout={500}
                transitionLeaveTimeout={300}>

                <Dialog open={this.props.busy} onClose={this.handleRequestClose}>
                    <DialogContent>
                        <div>
                            <CircularProgress />
                        </div>
                    </DialogContent>
                </Dialog>
            </ReactCSSTransitionGroup>
        );
    }
}
