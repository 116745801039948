/* eslint-disable */
import { call, put, takeEvery } from "redux-saga/effects";
import {
  actionVideosInicia,
  actionVideosIniciaUp,
  actionVideosListado,
  actionVideosError,
  actionVideosCleared,
  actionVideosCreada,
  actionVideosActualizada,
  actionVideosUploaded,
  actionVideosDetallado,
  actionVideosHabilitadaDeshabilitada,
  //---connect camera

} from "src/actions/api/videos";

import {
  apiVideosList,
  apiVideosCrear,
  apiVideosActualizar,
  apiVideosHabilitarDeshabilitar,
  apiVideosUpload,
  apiVideosDetalle,

} from "src/api/videos";

import {
  VIDEO_LISTA,
  VIDEO_CREAR,
  VIDEO_ACTUALIZAR,
  VIDEO_HABILITAR_DESHABILITAR,
  VIDEO_UPLOAD,
  VIDEO_CLEAR,
  VIDEO_DETALLE,
  
} from 'src/constants/api/videos';

function* sagaVideosLista({ type, params }) {
  try {
    yield put(actionVideosInicia());
    const data = yield call(apiVideosList, params);
    yield put(actionVideosListado(data));
  } catch (e) {
    yield put(actionVideosError(e));
  }
}

function* sagaVideosActualizar(action) {
  try {
    yield put(actionVideosInicia());
    const resp = yield call(apiVideosActualizar, action.data);
    yield put(actionVideosActualizada(resp));
  } catch (e) {
    yield put(actionVideosError(e));
  }
}


function* sagaVideosCrear(action) {
  try {
    yield put(actionVideosInicia());
    const resp = yield call(apiVideosCrear, action.data);
    yield put(actionVideosCreada(resp));
  } catch (e) {
    yield put(actionVideosError(e));
  }
}

function* sagaVideosDetalle(action) {
  try {
    yield put(actionVideosIniciaUp());
    const resp = yield call(apiVideosDetalle, action.data);
    yield put(actionVideosDetallado(resp));
  } catch (e) {
    yield put(actionVideosError(e));
  }
}

function* sagaVideosHabilitarDeshabilitar(action) {
  try {
    yield put(actionVideosInicia());
    const resp = yield call(apiVideosHabilitarDeshabilitar, action.data);
    yield put(actionVideosHabilitadaDeshabilitada(resp));
  } catch (e) {
    yield put(actionVideosError(e));
  }
}

function* sagaVideosUpload(action) {
  try {
    yield put(actionVideosIniciaUp());
    const resp = yield call(apiVideosUpload, action.data);
    yield put(actionVideosUploaded(resp));
  } catch (e) {
    yield put(actionVideosError(e));
  }
}


function* sagaVideosClear(action) {
  try {
    yield put(actionVideosInicia());
    //const resp = yield call(null, {});
    yield put(actionVideosCleared({}));
  } catch (e) {
    yield put(actionVideosError(e));
  }
}



export default [
  takeEvery(VIDEO_LISTA, sagaVideosLista),
  takeEvery(VIDEO_CREAR, sagaVideosCrear),
  takeEvery(VIDEO_ACTUALIZAR, sagaVideosActualizar),
  takeEvery(VIDEO_HABILITAR_DESHABILITAR, sagaVideosHabilitarDeshabilitar),
  takeEvery(VIDEO_UPLOAD, sagaVideosUpload),
  takeEvery(VIDEO_DETALLE, sagaVideosDetalle),
  
  takeEvery(VIDEO_CLEAR, sagaVideosClear),

]
