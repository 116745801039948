/* eslint-disable */
import { call, put, takeEvery } from "redux-saga/effects";
import {
    actionAnalyticSettingsInicia,
    actionAnalyticSettingsListado,
    actionAnalyticSettingsError,
    actionAnalyticSettingsActualizada,
    actionAnalyticSettingsCreada,
} from "src/actions/api/analyticSettings";

import {
    apiAnalyticSettingsList,
    apiAnalyticSettingsActualizar,
    apiAnalyticSettingsCrear
} from "src/api/analyticSettings";

import {
    ANALYTIC_SETTINGS_LIST,
    ANALYTIC_SETTINGS_CREAR,
    ANALYTIC_SETTINGS_ACTUALIZAR,
} from 'src/constants/api/analyticSettings';

function* sagaAnalyticSettingsLista({ type, params }) {
    try {
        yield put(actionAnalyticSettingsInicia());
        const data = yield call(apiAnalyticSettingsList, params);
        yield put(actionAnalyticSettingsListado(data));
    } catch (e) {
        yield put(actionAnalyticSettingsError(e));
    }
}

function* sagaAnalyticSettingsActualizar(action) {
    try {
        yield put(actionAnalyticSettingsInicia());
        const resp = yield call(apiAnalyticSettingsActualizar, action.data);
        yield put(actionAnalyticSettingsActualizada(resp));
    } catch (e) {
        yield put(actionAnalyticSettingsError(e));
    }
}


function* sagaAnalyticSettingsCrear(action) {
    try {
        yield put(actionAnalyticSettingsInicia());
        const resp = yield call(apiAnalyticSettingsCrear, action.data);
        yield put(actionAnalyticSettingsCreada(resp));
    } catch (e) {
        yield put(actionAnalyticSettingsError(e));
    }
}

export default [
    takeEvery(ANALYTIC_SETTINGS_LIST, sagaAnalyticSettingsLista),
    takeEvery(ANALYTIC_SETTINGS_CREAR, sagaAnalyticSettingsCrear),
    takeEvery(ANALYTIC_SETTINGS_ACTUALIZAR, sagaAnalyticSettingsActualizar),
]