/* eslint-disable */
import {
  GATES_INICIA,
  GATES_LISTADO,
  GATES_ERROR,
  GATES_ACTUALIZADA,
  GATES_CREADA,
  GATES_HABILITADA_DESHABILITADA
} from 'src/constants/api/gates';

import {
  LIMPIAR_ESTADO
} from 'src/constants/ActionTypes';


const DEFAULT_STATE = {
  waiting: false,
  estado: '',
  items: [],
  actualizar: false,
  apiRespuesta: false,
  dataDetail: {}
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case GATES_INICIA:
      return { ...state, waiting: true };
    case GATES_LISTADO:
      return { ...state, waiting: false, items: action.data, actualizar: false, estado: 'LISTADO', apiRespuesta: true };

    case GATES_ACTUALIZADA:
      return { ...state, waiting: false, actualizar: true, estado: 'ACTUALIZADO', apiRespuesta: true };

    case GATES_CREADA:
      return {
        ...state, waiting: false, actualizar: true, estado: 'CREADO',
        apiRespuesta: true, data: action.params
      };

    case GATES_HABILITADA_DESHABILITADA:
      return { ...state, waiting: false, actualizar: true, estado: 'HABILITADO_DESHABILITADO', apiRespuesta: true };

    case GATES_ERROR:
      return {
        ...state,
        waiting: false,
        error: action.error ? (`${action.error.detail || action.error.detail}`) : 'API Error.',
        estado: 'ERROR',
        actualizar: true,
        apiRespuesta: true
      };
    case LIMPIAR_ESTADO:
      return { ...state, error: '', actualizar: false, estado: '', apiRespuesta: false };
    default:
      return state;
  }
};
