/* eslint-disable */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
    actionRolesInicia,
    actionRolesListado,
    actionRolesError,
} from "src/actions/api/roles";

import { 
    apiRolesList,    
} from "src/api/roles";

import { 
    ROLES_LISTA,
} from 'src/constants/api/roles';

function* sagaRolesLista({ type, params }) {
  try {        
    yield put(actionRolesInicia());
    const data = yield call(apiRolesList, params);
    yield put(actionRolesListado(data));
  } catch (e) {
    yield put(actionRolesError(e));    
  }
}

export default  [
  takeEvery(ROLES_LISTA, sagaRolesLista), 
]
