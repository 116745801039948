/* eslint-disable */
import {
  COMPANIES_INICIA,
  COMPANIES_LISTADO,
  COMPANIES_ERROR,
  COMPANIES_ACTUALIZADA,
  COMPANIES_CREADA,
  COMPANIES_HABILITADA_DESHABILITADA
} from 'src/constants/api/companies';

import {
  LIMPIAR_ESTADO
} from 'src/constants/ActionTypes';


const DEFAULT_STATE = {
  waiting: false,
  estado: '',
  items: [],
  actualizar: false,
  apiRespuesta: false
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case COMPANIES_INICIA:
      return { waiting: true };
    case COMPANIES_LISTADO:
      return { waiting: false, items: action.data, actualizar: false, estado: 'LISTADO', apiRespuesta: true };

    case COMPANIES_ACTUALIZADA:
      return { ...state, waiting: false, actualizar: true, estado: 'ACTUALIZADO', apiRespuesta: true };

    case COMPANIES_CREADA:
      return {
        ...state, waiting: false, actualizar: true, estado: 'CREADO',
        apiRespuesta: true, data: action.data
      };

    case COMPANIES_HABILITADA_DESHABILITADA:
      return { ...state, waiting: false, actualizar: true, estado: 'HABILITADO_DESHABILITADO', apiRespuesta: true };

    case COMPANIES_ERROR:
      return {
        ...state,
        waiting: false,
        error: action.error ? (`${action.error.detail || action.error.detail}`) : 'API Error.',
        estado: 'ERROR',
        actualizar: true,
        apiRespuesta: true
      };
    case LIMPIAR_ESTADO:
      return { ...state, error: '', actualizar: false, estado: '', apiRespuesta: false };
    default:
      return state;
  }
};
