/* eslint-disable */

const styles = {
    canvaText: {
        fontFamily: "sans-serif",
        fontSize: 10,
    },
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: "#FFF",
    },
    secondTab: {
        fontFamily: "sans-serif",
        fontSize: 10,
        minHeight: "auto",
        padding: 2
    },
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    },
    inputStyle: { borderWidth: 1, borderRadius: 5, width: "100%", height: "33px", paddingLeft: 5 }
};


export default styles;