/* eslint-disable */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
    actionPermissionsInicia,
    actionPermissionsListado,
    actionPermissionsError,
} from "src/actions/api/permissions";

import { 
    apiPermissionsList,    
} from "src/api/permissions";

import { 
    PERMISSIONS_LISTA,
} from 'src/constants/api/permissions';

function* sagaPermissionsLista({ type, params }) {
  try {        
    yield put(actionPermissionsInicia());
    const data = yield call(apiPermissionsList, params);
    yield put(actionPermissionsListado(data));
  } catch (e) {
    yield put(actionPermissionsError(e));    
  }
}

export default  [
  takeEvery(PERMISSIONS_LISTA, sagaPermissionsLista), 
]
