/* eslint-disable */
import React, { Component } from 'react';

import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';

import Button from '@material-ui/core/Button';

import styles from './styles';

import ReactFormInputValidation from 'react-form-input-validation';
import FieldFormTemplate from '../FieldFormTemplate';
import DropDownWithSearchFormTemplate from 'src/components/DropDownWithSearchFormTemplate';
import DropDownFormTemplate from 'src/components/DropDownFormTemplate';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const profilesList = [
  {
    name: 'Ejecutivo',
    value: 'E897FC34-DCFE-4852-A8EB-57B9FC4B689A'
  },
  {
    name: 'Supervisor',
    value: 'AF7578EA-1F5E-4B2E-ACD6-5BB7EE0989C5'
  },
  {
    name: 'Administrador',
    value: '539FDC2F-9C8B-4BC1-AAD6-101FC10A1176'
  }
];

const booleanList = [
  {
    name: 'Sí',
    value: true
  },
  {
    name: 'No',
    value: false
  }
];

class FrameCreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        idRegistry: '',
        name:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.name
            : '',

        email:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.email
            : '',

        role_id:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.role_id
            : '',

        is_admin:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.is_admin
            : false,

        client_id:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.client_id
            : '',

        phone:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.phone
            : ''
      },
      is_admin:
        this.props.Update && this.props.dataDetail
          ? this.props.dataDetail.is_admin
          : false,
      errors: {},
      selectRol: {},
      selectCompanies: {},
      errorCompanies: '',
      errorRol: '',
      showErrorDate: false
    };
    this.form = new ReactFormInputValidation(this, { locale: 'es' });
    this.form.onformsubmit = async (fields) => {
      if (
        Object.keys(this.state.selectCompanies).length !== 0 &&
        Object.keys(this.state.selectRol).length !== 0
      ) {
        const objToSend = {
          name: fields.name,
          email: this.props.Update ? this.props.dataDetail.email : fields.email,
          role_id: this.state.selectRol.public_key,
          client_id: this.state.selectCompanies.public_key,
          created_user: this.props.dataUserLog.public_key,
          phone: fields.phone,
          is_active: true,
          password: '1234',
          is_admin: fields.is_admin
        };
        //console.log("Obj to send:", objToSend);
        if (this.props.Update === true) {
          this.props.actionExecutivesActualizar({
            ...objToSend,
            id: this.props.dataDetail.public_key
            //updatedBy: "A40AF516-4209-4C5A-8EF0-D28328AFA10F"
          });
        } else {
          this.props.actionExecutivesCrear({ ...objToSend });
        }
      } else {
        this.setState({
          errorRol:
            Object.keys(this.state.selectRol).length !== 0
              ? ''
              : 'Seleccione un Rol',
          errorCompanies:
            Object.keys(this.state.selectCompanies).length !== 0
              ? ''
              : 'Seleccione una Empresa'
        });
      }
    };
  }

  componentDidUpdate(preprops) {
    if (this.props.dataDetail !== preprops.dataDetail) {
      this.setDataFields();
    }
    if (this.props.apiRespuestaRequest) {
      switch (this.props.estadoRequest) {
        case 'CREADO':
          toast.success('Se ha creado exitosamente', {
            backgroundColor: '#1ABD24',
            color: '#ffffff'
          });
          this.props.CloseDialog();
          break;
        default:
          break;
      }
      this.props.actionLimpiarEstado();
    }
  }

  async componentDidMount() {
    this.form.useRules({
      name: 'required',
      email: 'required|email',
      phone: 'required',
      is_admin: 'required'
    });
    //this.setDataFields();
    if (this.props.dataUserLog.is_admin === false) {
      var arrayToFilter = [];
      await arrayToFilter.push(this.props.dataUserLog.client_id);
      const companies = await this.props.dataCompanies.filter((i) =>
        arrayToFilter.includes(i.public_key)
      );
      this.selectDropdownSearch({
        ...companies[0],
        label: companies[0].name,
        value: companies[0].public_key
      });
    }
  }

  setDataFields = () => {
    this.setState(
      {
        fields: {
          name:
            this.props.Update && this.props.dataDetail
              ? this.props.dataDetail.name
              : '',

          email:
            this.props.Update && this.props.dataDetail
              ? this.props.dataDetail.email
              : '',

          role_id:
            this.props.Update && this.props.dataDetail
              ? this.props.dataDetail.role_id
              : '',

          is_admin:
            this.props.Update && this.props.dataDetail
              ? this.props.dataDetail.is_admin
              : '',

          client_id:
            this.props.Update && this.props.dataDetail
              ? this.props.dataDetail.client_id
              : '',

          phone:
            this.props.Update && this.props.dataDetail
              ? this.props.dataDetail.phone
              : ''
          //holidaysEnd: this.props.dataDetail ? moment(this.props.dataDetail.holidaysEnd).format("YYYY-MM-DD") : "",
        },
        is_admin:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.is_admin
            : false
      },
      () => {
        this.selectDropdownsData();
      }
    );
  };

  selectDropdownsData = async () => {
    const newArray = await this.props.dataCompanies;
    const arrayToFilter = await [];
    if (this.props.dataDetail.client_id && this.props.dataUserLog.is_admin) {
      await arrayToFilter.push(this.props.dataDetail.client_id);
      const companies = await newArray.filter((i) =>
        arrayToFilter.includes(i.public_key)
      );
      this.selectDropdownSearch({
        ...companies[0],
        label: companies[0].name,
        value: companies[0].public_key
      });
    } else {
      await arrayToFilter.push(this.props.dataUserLog.client_id);
      const companies = await newArray.filter((i) =>
        arrayToFilter.includes(i.public_key)
      );

      //console.log("no soy full admin: ", companies)
      this.selectDropdownSearch({
        ...companies[0],
        label: companies[0].name,
        value: companies[0].public_key
      });
    }

    const newArrayRol = await this.props.dataRoles;
    const arrayToFilterRol = await [];
    if (this.props.dataDetail.rol_id) {
      await arrayToFilterRol.push(this.props.dataDetail.rol_id);
      const role = await newArrayRol.filter((i) =>
        arrayToFilterRol.includes(i.public_key)
      );
      this.selectDropdownSearchRol({
        ...role[0],
        label: role[0].name,
        value: role[0].public_key
      });
    }
  };

  selectDropdownSearch = (data) => {
    this.setState({
      selectCompanies: data,
      errorCompanies: ''
    });
  };

  selectDropdownSearchRol = (data) => {
    this.setState({
      selectRol: data,
      errorRol: ''
    });
  };

  render() {
    const { dataCompanies, dataRoles } = this.props;
    return (
      // <Dialog
      //     fullWidth={true}
      //     maxWidth={"md"}
      //     open={this.props.open}
      //     onClose={() => this.props.CloseDialog()}
      //     aria-labelledby="form-dialog-title">
      //     <DialogTitle id="form-dialog-title">{this.props.Update ? "Actualizar" : "Crear"} Contacto</DialogTitle>
      //     <DialogContent>
      <>
        <Helmet>
          <title>Usuarios</title>
        </Helmet>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start'
          }}
        >
          <div
            style={{
              fontFamily: 'sans-serif',
              fontSize: 18,
              fontWeight: 'bold'
            }}
          >
            {this.props.Update ? 'Editar' : 'Agregar'} Usuarios
          </div>
        </Box>

        <Box
          sx={{
            backgroundColor: '#fff',
            minHeight: '100%',
            py: 3,
            marginTop: 9
          }}
        >
          <Container maxWidth={false}>
            <Box
              display="flex"
              flexDirection="row"
              width={'100%'}
              overflow={'hidden'}
            >
              <form onSubmit={this.form.handleSubmit} style={{ width: '100%' }}>
                <div className="form-row">
                  <FieldFormTemplate
                    label="Nombre *"
                    classCol="col-12 col-md-6"
                    field={
                      <input
                        maxLength={65}
                        type="text"
                        name="name"
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        value={this.state.fields.name}
                        data-attribute-name="Nombre "
                        style={styles.inputStyle}
                      />
                    }
                    error={this.state.errors.name}
                  />

                  <FieldFormTemplate
                    label="Correo *"
                    classCol="col-12 col-md-6"
                    field={
                      <input
                        maxLength={65}
                        disabled={this.props.Update}
                        type="text"
                        name="email"
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        value={this.state.fields.email}
                        data-attribute-name="Correo"
                        style={styles.inputStyle}
                      />
                    }
                    error={this.state.errors.email}
                  />
                </div>

                <div className="form-row">
                  {/* <DropDownFormTemplate
                                label="Administrador *"
                                classCol="col-6 col-md-6"
                                field={
                                    <select
                                        //disabled={this.state.fields.is_admin ? true : false}
                                        id="is_admin"
                                        name="is_admin"
                                        data-attribute-name="Administrador"
                                        value={this.state.fields.is_admin}
                                        onChange={this.form.handleChangeEvent}
                                        style={{
                                            width: "100%",
                                            borderWidth: "1px",
                                            borderRadius: "5px",
                                            borderColor: "#0000006b",
                                            minHeight: "22px",
                                            height: "33px"
                                        }}
                                    >
                                       
                                        {booleanList.map((element) =>
                                            <option value={element.value}>{element.name}</option>
                                        )
                                        }
                                    </select>
                                }
                                error={this.state.errors.is_admin}
                            /> */}

                  <FieldFormTemplate
                    label="Teléfono *"
                    classCol="col-12 col-md-6"
                    field={
                      <input
                        maxLength={65}
                        type="text"
                        name="phone"
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        value={this.state.fields.phone}
                        data-attribute-name="Teléfono"
                        style={styles.inputStyle}
                      />
                    }
                    error={this.state.errors.phone}
                  />

                  <DropDownWithSearchFormTemplate
                    label="Rol *"
                    className="col-6 col-md-6"
                    value={this.state.selectRol}
                    options={dataRoles.map((item) => {
                      return {
                        ...item,
                        label: item.name,
                        value: item.public_key
                      };
                    })}
                    name="rol"
                    error={this.state.errorRol}
                    select={(args) => this.selectDropdownSearchRol(args)}
                  />
                </div>
                <div className="form-row">
                  <DropDownWithSearchFormTemplate
                    label="Empresa *"
                    className="col-12 col-md-12"
                    value={this.state.selectCompanies}
                    disabled={
                      this.props.dataUserLog.is_admin === false ? true : false
                    }
                    options={dataCompanies.map((item) => {
                      return {
                        ...item,
                        label: item.name,
                        value: item.public_key
                      };
                    })}
                    name="companies"
                    error={this.state.errorCompanies}
                    select={(args) => this.selectDropdownSearch(args)}
                  />
                </div>

                <Box
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Button
                    color="primary"
                    type="submit"
                    style={{ outline: 'none' }}
                  >
                    Guardar
                  </Button>
                  {this.props.Update ? (
                    <Button
                      onClick={() => this.props.DeleteItem()}
                      style={{
                        outline: 'none',
                        color: '#D71919',
                        alignSelf: 'flex-end'
                      }}
                    >
                      Eliminar
                    </Button>
                  ) : null}
                  <Button
                    onClick={() => this.props.CloseDialog()}
                    color="primary"
                    style={{ outline: 'none' }}
                  >
                    Cerrar
                  </Button>
                </Box>
              </form>
            </Box>
          </Container>
        </Box>
        {/* </DialogContent>

                <SweetAlert
                    show={this.state.showErrorDate}
                    error
                    confirmBtnText="Aceptar"
                    confirmBtnBsStyle="light"
                    style={{ color: "#000", fontSize: 14 }}
                    title={"Error"}
                    onConfirm={() => this.setState({
                        showErrorDate: !this.state.showErrorDate
                    })}
                >
                    <span>{"Fecha Fin debe ser mayor a Fecha inicio"}</span>
                </SweetAlert>

                <ToastContainer position={"top-right"} delay={5000} />
            </Dialog> */}
      </>
    );
  }
}

export default FrameCreateUser;
