/* eslint-disable */
import {
  COMPANIES_INICIA,
  COMPANIES_LISTA,
  COMPANIES_LISTADO,
  COMPANIES_ERROR,
  COMPANIES_ACTUALIZAR,
  COMPANIES_ACTUALIZADA,
  COMPANIES_CREAR,
  COMPANIES_CREADA,
  COMPANIES_HABILITADA_DESHABILITADA,
  COMPANIES_HABILITAR_DESHABILITAR
} from 'src/constants/api/companies';

export const actionCompaniesInicia = () => ({ type: COMPANIES_INICIA, });
export const actionCompaniesLista = (params) => ({ type: COMPANIES_LISTA, params });
export const actionCompaniesListado = (data) => ({ type: COMPANIES_LISTADO, data });
export const actionCompaniesError = (error) => ({ type: COMPANIES_ERROR, error });

export const actionCompaniesActualizar = data => ({ type: COMPANIES_ACTUALIZAR, data });
export const actionCompaniesActualizada = (params) => ({ type: COMPANIES_ACTUALIZADA, params });

export const actionCompaniesCrear = data => ({ type: COMPANIES_CREAR, data });
export const actionCompaniesCreada = (params) => ({ type: COMPANIES_CREADA, params });

export const actionCompaniesHabilitarDeshabilitar = data => ({ type: COMPANIES_HABILITAR_DESHABILITAR, data });
export const actionCompaniesHabilitadaDeshabilitada = (params) => ({ type: COMPANIES_HABILITADA_DESHABILITADA, params });