/* eslint-disable */
export const ROADWAYS_INICIA = 'ROADWAYS_INICIA';
export const ROADWAYS_LISTA = 'ROADWAYS_LISTA';
export const ROADWAYS_LISTADO = 'ROADWAYS_LISTADO';
export const ROADWAYS_ERROR = 'ROADWAYS_ERROR';

export const ROADWAYS_ACTUALIZAR = "ROADWAYS_ACTUALIZAR";
export const ROADWAYS_ACTUALIZADA = "ROADWAYS_ACTUALIZADA";
export const ROADWAYS_CREAR = "ROADWAYS_CREAR";
export const ROADWAYS_CREADA =  "ROADWAYS_CREADA";

export const ROADWAYS_SCALEFACTOR_CREAR = "ROADWAYS_SCALEFACTOR_CREAR";
export const ROADWAYS_SCALEFACTOR_CREADA =  "ROADWAYS_SCALEFACTOR_CREADA";

export const ROADWAYS_CLEAR = "ROADWAYS_CLEAR";
export const ROADWAYS_CLEARED =  "ROADWAYS_CLEARED";

export const ROADWAYS_DETALLE_INICIA = "ROADWAYS_DETALLE_INICIA";
export const ROADWAYS_DETALLE = "ROADWAYS_DETALLE";
export const ROADWAYS_DETALLADO = "ROADWAYS_DETALLADO";
export const ROADWAYS_DETALLE_ERROR = "ROADWAYS_DETALLE_ERROR";

export const ROADWAYS_HABILITAR_DESHABILITAR = "ROADWAYS_HABILITAR_DESHABILITAR";
export const ROADWAYS_HABILITADA_DESHABILITADA = "ROADWAYS_HABILITADA_DESHABILITADA";