/* eslint-disable */
import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import reducers from '../reducers/index';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas/index';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

const thunkMiddleware = [thunk];

if (process.env.NODE_ENV !== 'production') {
  thunkMiddleware.push(createLogger());
}

const sagaMiddleware = createSagaMiddleware();

// const middlewares = [sagaMiddleware, routeMiddleware];
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// export default function configureStore(initialState) {
//   const store = createStore(reducers(history), initialState,
//     composeEnhancers(applyMiddleware(...middlewares)));

//   sagaMiddleware.run(rootSaga);

//   if (module.hot) {
//     // Enable Webpack hot module replacement for reducers
//     module.hot.accept('../reducers/index', () => {
//       const nextRootReducer = require('../reducers/index');
//       store.replaceReducer(nextRootReducer);
//     });
//   }
//   return store;
// }
// export {history , configureStore};

const store = createStore(
  reducers,
  applyMiddleware(...thunkMiddleware, sagaMiddleware)
);

sagaMiddleware.run(rootSaga);

export { store };
