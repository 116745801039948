/* eslint-disable */
import React, { Component } from 'react';

import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';

import Button from '@material-ui/core/Button';

import styles from './styles';
import { URL_API } from 'src/api/endpoints';
import ReactFormInputValidation from 'react-form-input-validation';
import FieldFormTemplate from '../FieldFormTemplate';
import DropDownWithSearchFormTemplate from 'src/components/DropDownWithSearchFormTemplate';
import DropDownFormTemplate from 'src/components/DropDownFormTemplate';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConnectCamera from './ConnectCamera';
import { usePreviousProps } from '@material-ui/utils';

class GeneralData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        idRegistry: '',
        description:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.description
            : this.props.objStep2.description
            ? this.props.objStep2.description
            : '',

        tilt:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.tilt
            : this.props.objStep2.tilt
            ? this.props.objStep2.tilt
            : '',

        pan:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.pan
            : this.props.objStep2.pan
            ? this.props.objStep2.pan
            : '',

        zoom:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.zoom
            : this.props.objStep2.zoom
            ? this.props.objStep2.zoom
            : ''
      },
      objCam: this.props.objStep2 ? this.props.objStep2 : {},
      errors: {}
    };
    this.form = new ReactFormInputValidation(this, { locale: 'es' });
    this.form.onformsubmit = async (fields) => {
      const objToSend = {
        description: fields.description,
        tilt: fields.tilt,
        pan: fields.pan,
        zoom: fields.zoom,
        created_user: this.props.dataUserLog.public_key,
        objCam: this.state.objCam,
        path: this.props.dataCameraImage.image
      };
      console.log(objToSend);
      this.props.NextStep({
        step: 2,
        completed: true,
        fields: objToSend
      });
      if (this.props.Update === true) {
        // this.props.actionExecutivesActualizar({
        //     ...objToSend,
        //     id: this.props.dataDetail.public_key,
        //     //updatedBy: "A40AF516-4209-4C5A-8EF0-D28328AFA10F"
        // });
      } else {
        //this.props.actionExecutivesCrear({ ...objToSend });
      }
    };
  }

  componentDidUpdate(preprops) {
    if (this.props.objStep2 !== preprops.objStep2) {
      this.setDataFields();
    }

    if (this.props.dataCameraPtz !== preprops.dataCameraPtz) {
      this.setDataFields();
    }
  }

  async componentDidMount() {
    this.form.useRules({
      description: 'required',
      tilt: 'required',
      zoom: 'required',
      pan: 'required'
    });
  }

  setDataFields = () => {
    this.setState({
      fields: {
        //description: this.props.objStep2.description ? this.props.objStep2.description : "",
        ...this.state.fields,
        tilt:
          this.props.Update && this.props.dataCameraPtz
            ? this.props.dataCameraPtz.tilt
            : this.props.dataCameraPtz.tilt
            ? this.props.dataCameraPtz.tilt
            : '',

        pan:
          this.props.Update && this.props.dataCameraPtz
            ? this.props.dataCameraPtz.pan
            : this.props.dataCameraPtz.pan
            ? this.props.dataCameraPtz.pan
            : '',

        zoom:
          this.props.Update && this.props.dataCameraPtz
            ? this.props.dataCameraPtz.zoom
            : this.props.dataCameraPtz.zoom
            ? this.props.dataCameraPtz.zoom
            : ''
      }
      //objCam: this.props.objStep2 ? this.props.objStep2 : {},
    });
  };

  connectCameraApi = (objCam) => {
    //connectCameraApi
    // this.setState({
    //     // fields: {
    //     //     ...this.state.fields,

    //     //     tilt: 19.35,

    //     //     pan: -54.35,

    //     //     zoom: 75.36,
    //     // },
    //     objCam: objCam
    // });

    this.setState(
      {
        objCam: objCam,
        errors: {}
      },
      () => {
        this.props.ConnectCameraApi(objCam);
      }
    );
  };

  render() {
    return (
      // <Dialog
      //     fullWidth={true}
      //     maxWidth={"md"}
      //     open={this.props.open}
      //     onClose={() => this.props.CloseDialog()}
      //     aria-labelledby="form-dialog-title">
      //     <DialogTitle id="form-dialog-title">{this.props.Update ? "Actualizar" : "Crear"} Contacto</DialogTitle>
      //     <DialogContent>
      <>
        <Box
          sx={{
            minHeight: '100%',
            py: 3,
            marginTop: 5,
            padding: 0
          }}
        >
          <Container style={{ width: '100%', padding: 0 }}>
            <Box
              display="flex"
              flexDirection="row"
              width={'100%'}
              overflow={'hidden'}
            >
              <form onSubmit={this.form.handleSubmit} style={{ width: '100%' }}>
                <Box
                  display="flex"
                  flexDirection="row"
                  width={'100%'}
                  overflow={'hidden'}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    width={'50%'}
                    overflow={'hidden'}
                  >
                    <div className="form-row" style={{ width: '95%' }}>
                      <FieldFormTemplate
                        label="Descripción *"
                        classCol="col-12 col-md-12"
                        field={
                          <input
                            maxLength={65}
                            type="text"
                            name="description"
                            onBlur={this.form.handleBlurEvent}
                            onChange={this.form.handleChangeEvent}
                            value={this.state.fields.description}
                            data-attribute-name="Descripción "
                            style={styles.inputStyle}
                            //placeholder={"Nombre de la ruta"}
                          />
                        }
                        error={this.state.errors.description}
                      />
                    </div>

                    <ConnectCamera
                      {...this.props}
                      ConnectCameraApi={(objCam) =>
                        this.connectCameraApi(objCam)
                      }
                      ObjCam={this.state.objCam}
                    />

                    <div
                      style={{
                        fontFamily: 'sans-serif',
                        fontSize: 20,
                        fontWeight: 'bold',
                        color: '#045c9c',
                        marginTop: 30
                      }}
                    >
                      Posición de aforo
                    </div>

                    <div
                      className="form-row"
                      style={{ width: '95%', marginTop: 10 }}
                    >
                      <FieldFormTemplate
                        label="Inclinación *"
                        classCol="col-12 col-md-12"
                        field={
                          <input
                            maxLength={65}
                            disabled
                            type="text"
                            name="tilt"
                            onBlur={this.form.handleBlurEvent}
                            onChange={this.form.handleChangeEvent}
                            value={this.state.fields.tilt}
                            data-attribute-name="Inclinación "
                            style={styles.inputStyle}
                            //placeholder={"Nombre de la ruta"}
                          />
                        }
                        error={this.state.errors.tilt}
                      />

                      <FieldFormTemplate
                        label="Posición horizontal *"
                        classCol="col-12 col-md-12"
                        field={
                          <input
                            maxLength={65}
                            disabled
                            type="text"
                            name="zoom"
                            onBlur={this.form.handleBlurEvent}
                            onChange={this.form.handleChangeEvent}
                            value={this.state.fields.pan}
                            data-attribute-name="Posición horizontal "
                            style={styles.inputStyle}
                            //placeholder={"Nombre de la ruta"}
                          />
                        }
                        error={this.state.errors.zoom}
                      />

                      <FieldFormTemplate
                        label="Aumento *"
                        classCol="col-12 col-md-12"
                        field={
                          <input
                            maxLength={65}
                            disabled
                            type="text"
                            name="pan"
                            onBlur={this.form.handleBlurEvent}
                            onChange={this.form.handleChangeEvent}
                            value={this.state.fields.zoom}
                            data-attribute-name="Aumento "
                            style={styles.inputStyle}
                            //placeholder={"Nombre de la ruta"}
                          />
                        }
                        error={this.state.errors.pan}
                      />
                    </div>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="row"
                    width={'50%'}
                    height={'20%'}
                    padding={2}
                  >
                    <img
                      src={
                        this.props.dataCameraImage.image &&
                        this.state.objCam.created_user
                          ? `${URL_API}/${this.props.dataCameraImage.image}`
                          : '/static/images/avatars/null_image.png'
                      }
                      style={{
                        width: '80vw',
                        height: '50vh'
                      }}
                    />
                    {/* {console.log('qqqqqqq', this.state.objCam)} */}
                  </Box>
                </Box>

                <Box
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Button
                    color="primary"
                    onClick={() => this.props.BackStep()}
                    variant="outlined"
                    startIcon={<KeyboardBackspaceIcon />}
                    style={{ outline: 'none' }}
                  >
                    Volver
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    endIcon={<ArrowForwardIcon />}
                    style={{
                      outline: 'none',
                      backgroundColor: '#0099ff',
                      marginLeft: '30px'
                    }}
                  >
                    Siguiente
                  </Button>
                </Box>
              </form>
            </Box>
          </Container>
        </Box>
        {/* </DialogContent>

                <SweetAlert
                    show={this.state.showErrorDate}
                    error
                    confirmBtnText="Aceptar"
                    confirmBtnBsStyle="light"
                    style={{ color: "#000", fontSize: 14 }}
                    title={"Error"}
                    onConfirm={() => this.setState({
                        showErrorDate: !this.state.showErrorDate
                    })}
                >
                    <span>{"Fecha Fin debe ser mayor a Fecha inicio"}</span>
                </SweetAlert>

                <ToastContainer position={"top-right"} delay={5000} />
            </Dialog> */}
      </>
    );
  }
}

export default GeneralData;
