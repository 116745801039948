/* eslint-disable */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
    actionExecutivesDetalleInicia,
    actionExecutivesDetallado,
    actionExecutivesError,
} from "src/actions/api/executives";

import { 
    apiExecutiveDetail,    
} from "src/api/executives";

import {
    EXECUTIVE_DETALLE
} from 'src/constants/api/executives';

function* sagaExecutiveDetail({ type, params }) {
  try {        
    yield put(actionExecutivesDetalleInicia());
    const data = yield call(apiExecutiveDetail, params);
    yield put(actionExecutivesDetallado(data));
  } catch (e) {
    yield put(actionExecutivesError(e));    
  }
}

export default  [
  takeEvery(EXECUTIVE_DETALLE, sagaExecutiveDetail), 
]
