/* eslint-disable */
import {
    ROADWAYS_INICIA,
    ROADWAYS_LISTA,    
    ROADWAYS_LISTADO, 

    ROADWAYS_ACTUALIZAR,
    ROADWAYS_ACTUALIZADA,
    ROADWAYS_CREAR,
    ROADWAYS_CREADA,
    
    ROADWAYS_SCALEFACTOR_CREAR,
    ROADWAYS_SCALEFACTOR_CREADA,

    ROADWAYS_CLEAR,
    ROADWAYS_CLEARED,
    
    ROADWAYS_DETALLE,
    ROADWAYS_DETALLADO,
    ROADWAYS_DETALLE_INICIA,
    ROADWAYS_HABILITAR_DESHABILITAR,
    ROADWAYS_HABILITADA_DESHABILITADA,
    ROADWAYS_ERROR,
} from 'src/constants/api/roadWays';

export const actionRoadWaysInicia = () => ({ type: ROADWAYS_INICIA, });
export const actionRoadWaysLista = params => ({ type: ROADWAYS_LISTA, params });
export const actionRoadWaysListado = data => ({ type: ROADWAYS_LISTADO, data });

export const actionRoadWaysActualizar = data => ({ type: ROADWAYS_ACTUALIZAR, data });
export const actionRoadWaysActualizada = (params) => ({ type: ROADWAYS_ACTUALIZADA, params });

export const actionRoadWaysCrear = data => ({ type: ROADWAYS_CREAR, data });
export const actionRoadWaysCreada = (params) => ({ type: ROADWAYS_CREADA, params });

export const actionRoadWaysScaleFactorCrear = data => ({ type: ROADWAYS_SCALEFACTOR_CREAR, data });
export const actionRoadWaysScaleFactorCreada = (params) => ({ type: ROADWAYS_SCALEFACTOR_CREADA, params });

export const actionRoadWaysClear = data => ({ type: ROADWAYS_CLEAR, data });
export const actionRoadWaysCleared = data => ({ type: ROADWAYS_CLEARED, data });

export const actionRoadWaysDetalle = params => ({ type: ROADWAYS_DETALLE, params });
export const actionRoadWaysDetallado = (data) => ({ type: ROADWAYS_DETALLADO, data });
export const actionRoadWaysDetalleInicia = () => ({ type: ROADWAYS_DETALLE_INICIA, });

export const actionRoadWaysHabilitarDeshabilitar = data => ({ type: ROADWAYS_HABILITAR_DESHABILITAR, data });
export const actionRoadWaysHabilitadaDeshabilitada = (params) => ({ type: ROADWAYS_HABILITADA_DESHABILITADA, params });

export const actionRoadWaysError = error => ({ type: ROADWAYS_ERROR, error });
