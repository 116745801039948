/* eslint-disable */
import React, { Component } from 'react';

import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';

import Button from '@material-ui/core/Button';

import styles from './styles';

import ReactFormInputValidation from 'react-form-input-validation';
import FieldFormTemplate from '../FieldFormTemplate';
import DropDownWithSearchFormTemplateMulti from 'src/components/DropDownWithSearchFormTemplateMulti';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const profilesList = [
  {
    name: 'Ejecutivo',
    value: 'E897FC34-DCFE-4852-A8EB-57B9FC4B689A'
  },
  {
    name: 'Supervisor',
    value: 'AF7578EA-1F5E-4B2E-ACD6-5BB7EE0989C5'
  },
  {
    name: 'Administrador',
    value: '539FDC2F-9C8B-4BC1-AAD6-101FC10A1176'
  }
];

class FrameCreateCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        idRegistry: '',
        name:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.name
            : '',

        email:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.email
            : '',

        nit:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.nit
            : '',

        phone:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.phone
            : '',

        limitUser:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.limit_user
            : '',

        detectors:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.detectors
            : ''
      },
      permissions:
        this.props.Update && this.props.dataDetail
          ? this.props.dataDetail.permissions
          : [],
      selectPermissions: [],
      errorPermissions: '',
      errors: {},
      showErrorDate: false
    };
    this.form = new ReactFormInputValidation(this, { locale: 'es' });
    this.form.onformsubmit = async (fields) => {
      if (this.state.selectPermissions.length !== 0) {
        var newArray = [];
        this.state.selectPermissions.forEach((element) => {
          newArray.push(element.value);
        });

        const objToSend = {
          name: fields.name,
          email: fields.email,
          nit: this.props.Update ? this.props.dataDetail.nit : fields.nit,
          limit_user: fields.limitUser,
          detectors: fields.detectors,
          phone: fields.phone,
          permissions: newArray,
          created_user: this.props.dataUserLog.public_key
        };
        if (this.props.Update === true) {
          this.props.actionCompaniesActualizar({
            ...objToSend,
            id: this.props.dataDetail.public_key
            //updatedBy: "A40AF516-4209-4C5A-8EF0-D28328AFA10F"
          });
        } else {
          this.props.actionCompaniesCrear({ ...objToSend });
        }
      } else {
        this.setState({
          errorPermissions: 'Seleccione un permiso'
        });
      }
    };
  }

  componentDidUpdate(preprops) {
    if (this.props.dataDetail !== preprops.dataDetail) {
      this.setDataFields();
    }
    if (this.props.apiRespuestaRequest) {
      switch (this.props.estadoRequest) {
        case 'CREADO':
          toast.success('Se ha creado exitosamente', {
            backgroundColor: '#1ABD24',
            color: '#ffffff'
          });
          this.props.CloseDialog();
          break;
        default:
          break;
      }
      this.props.actionLimpiarEstado();
    }
  }

  componentDidMount() {
    this.form.useRules({
      name: 'required',
      email: 'required|email',
      nit: 'required|regex:/^[0-9]*$/',
      phone: 'required',
      limitUser: 'required|numeric|integer|regex:/^[0-9]*$/',
      detectors: 'required|numeric|integer|regex:/^[0-9]*$/'
    });
  }

  setDataFields = () => {
    this.setState(
      {
        fields: {
          name:
            this.props.Update && this.props.dataDetail
              ? this.props.dataDetail.name
              : '',

          email:
            this.props.Update && this.props.dataDetail
              ? this.props.dataDetail.email
              : '',

          nit:
            this.props.Update && this.props.dataDetail
              ? this.props.dataDetail.nit
              : '',

          phone:
            this.props.Update && this.props.dataDetail
              ? this.props.dataDetail.phone
              : '',

          limitUser:
            this.props.Update && this.props.dataDetail
              ? this.props.dataDetail.limit_user
              : '',

          detectors:
            this.props.Update && this.props.dataDetail
              ? this.props.dataDetail.detectors
              : ''
        },
        selectPermissions:
          this.props.Update && this.props.dataDetail
            ? this.props.dataDetail.permissions
            : []
      },
      () => {
        this.setPermissions();
      }
    );
  };

  selectDropdownSearch = (data) => {
    this.setState({
      selectPermissions: data,
      errorPermissions: ''
    });
  };

  setPermissions = async () => {
    if (this.props.dataDetail) {
      if (this.props.dataDetail.permissions) {
        let Permissionarray = [];
        this.props.dataDetail.permissions.forEach((element) => {
          Permissionarray.push({
            label: element.permission.name,
            value: element.permission.public_key
          });
        });
        this.setState({
          selectPermissions: Permissionarray
        });
      }
    }
  };

  render() {
    const { dataPermissionsList } = this.props;
    return (
      // <Dialog
      //     fullWidth={true}
      //     maxWidth={"md"}
      //     open={this.props.open}
      //     onClose={() => this.props.CloseDialog()}
      //     aria-labelledby="form-dialog-title">
      //     <DialogTitle id="form-dialog-title">{this.props.Update ? "Actualizar" : "Crear"} Contacto</DialogTitle>
      //     <DialogContent>
      <>
        <Helmet>
          <title>Empresas</title>
        </Helmet>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start'
          }}
        >
          <div
            style={{
              fontFamily: 'sans-serif',
              fontSize: 18,
              fontWeight: 'bold'
            }}
          >
            {this.props.Update ? 'Editar' : 'Agregar'} Empresas
          </div>
        </Box>

        <Box
          sx={{
            backgroundColor: '#fff',
            minHeight: '100%',
            py: 3,
            marginTop: 9
          }}
        >
          <Container maxWidth={false}>
            <Box
              display="flex"
              flexDirection="row"
              width={'100%'}
              overflow={'hidden'}
            >
              <form onSubmit={this.form.handleSubmit} style={{ width: '100%' }}>
                <div className="form-row">
                  <FieldFormTemplate
                    label="Nombre *"
                    classCol="col-12 col-md-6"
                    field={
                      <input
                        maxLength={65}
                        type="text"
                        name="name"
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        value={this.state.fields.name}
                        data-attribute-name="Nombre "
                        style={styles.inputStyle}
                      />
                    }
                    error={this.state.errors.name}
                  />

                  <FieldFormTemplate
                    label="Nit *"
                    classCol="col-12 col-md-6"
                    field={
                      <input
                        maxLength={65}
                        type={'text'}
                        name="nit"
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        value={this.state.fields.nit}
                        data-attribute-name="Nit"
                        style={styles.inputStyle}
                        disabled={this.props.Update}
                      />
                    }
                    error={this.state.errors.nit}
                  />
                </div>

                <div className="form-row">
                  <FieldFormTemplate
                    label="Teléfono *"
                    classCol="col-12 col-md-6"
                    field={
                      <input
                        maxLength={65}
                        type="text"
                        name="phone"
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        value={this.state.fields.phone}
                        data-attribute-name="Teléfono "
                        style={styles.inputStyle}
                      />
                    }
                    error={this.state.errors.phone}
                  />

                  <FieldFormTemplate
                    label="Correo electrónico *"
                    classCol="col-12 col-md-6"
                    field={
                      <input
                        maxLength={65}
                        type="text"
                        name="email"
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        value={this.state.fields.email}
                        data-attribute-name="Correo electrónico"
                        style={styles.inputStyle}
                      />
                    }
                    error={this.state.errors.email}
                  />
                </div>

                <div className="form-row">
                  <FieldFormTemplate
                    label="Número de usuarios *"
                    classCol="col-12 col-md-6"
                    field={
                      <input
                        maxLength={65}
                        type="number"
                        name="limitUser"
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        value={this.state.fields.limitUser}
                        data-attribute-name="Número de usuarios "
                        style={styles.inputStyle}
                      />
                    }
                    error={this.state.errors.limitUser}
                  />

                  <FieldFormTemplate
                    label="Número detectores *"
                    classCol="col-12 col-md-6"
                    field={
                      <input
                        maxLength={65}
                        type="number"
                        name="detectors"
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        value={this.state.fields.detectors}
                        data-attribute-name="Número detectores"
                        style={styles.inputStyle}
                      />
                    }
                    error={this.state.errors.detectors}
                  />
                </div>

                <div className="form-row" style={{ marginTop: 0 }}>
                  <DropDownWithSearchFormTemplateMulti
                    label="Permisos *"
                    className="col-12 col-md-12"
                    value={this.state.selectPermissions}
                    options={dataPermissionsList.map((item) => {
                      return {
                        ...item,
                        label: item.name,
                        value: item.public_key
                      };
                    })}
                    name="department"
                    error={this.state.errorPermissions}
                    select={(args) => this.selectDropdownSearch(args)}
                  />
                </div>

                <Box
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Button
                    color="primary"
                    type="submit"
                    style={{ outline: 'none' }}
                  >
                    Guardar
                  </Button>
                  {this.props.Update ? (
                    <Button
                      onClick={() => this.props.DeleteItem()}
                      style={{
                        outline: 'none',
                        color: '#D71919',
                        alignSelf: 'flex-end'
                      }}
                    >
                      Eliminar
                    </Button>
                  ) : null}
                  <Button
                    onClick={() => this.props.CloseDialog()}
                    color="primary"
                    style={{ outline: 'none' }}
                  >
                    Cerrar
                  </Button>
                </Box>
              </form>
            </Box>
          </Container>
        </Box>
      </>
    );
  }
}

export default FrameCreateCompany;
