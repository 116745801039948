/* eslint-disable */
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import { Bold, Search as SearchIcon } from 'react-feather';

import React, { Component } from 'react';

import DialogCreateUser from '../DialogCreateUser';

const title = 'Usuarios';

class UserListToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialogCreate: false,
      title: this.props.title
    };
  }

  openCreate = () => {
    // this.setState({
    //   openDialogCreate: true
    // });
    this.props.actionShowFrameCreate();
    //this.props.actionRequestsDetalle({ id: id });
  };

  closeDialogCreate = () => {
    this.setState({
      openDialogCreate: false
    });
  };
  render() {
    return (
      <Box {...this.props}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start'
            }}
          >
            <div
              style={{
                fontFamily: 'sans-serif',
                fontSize: 18,
                fontWeight: 'bold'
              }}
            >
              {title}
            </div>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            {/* <Button sx={{ mx: 1 }} onClick={() => this.props.exportToExcel()}>
              Exportar
            </Button> */}
            <Button
              style={{ backgroundColor: '#0099ff' }}
              color="primary"
              variant="contained"
              onClick={this.openCreate}
            >
              Agregar {title}
            </Button>
          </Box>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Card>
            <CardContent>
              <Box sx={{ maxWidth: 500 }}>
                <TextField
                  fullWidth
                  onChange={(e) => this.props.handleChange(e.target.value)}
                  value={this.props.filter}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  placeholder={`Buscar ${title}`}
                  variant="outlined"
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    );
  }
}

export default UserListToolbar;
