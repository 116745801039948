/* eslint-disable */
import { USER_LOGIN } from './endpoints'

import {
  DEFAULT_HEADERS,
  apiPeticionExitosa,
  apiErrorHandler,
  userAuthToken,
  
} from './commons';


export const apiUserLogin = async params => {
  var query = params.email ? `/?email=${params.email}` : '';
  return await fetch(
    `${USER_LOGIN}${query}`,
    //Object.assign({ method: "POST", body: JSON.stringify(params)}, DEFAULT_HEADERS)
    {
      method: "GET", headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken(),
      })
    }
  ).then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}
