/* eslint-disable */
import React from 'react';
import Select, { components } from 'react-select'
import CircleIcon from '@mui/icons-material/Circle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export default class DropDownWithSearchFormTemplate extends React.Component {

    render() {
        const { field, label, classCol, error, className, value, options, disabled, showIcon, name, showFontAwesome } = this.props;
        const colourStyles = {
            control: base => ({
                ...base,
                border: '1px solid black',
                height: "32px",
                maxHeight: "32px",
                minHeight: "32px",
                marginTop: "-4px",
                position: 'relative'

            }),
            menuPortal: base => ({
                ...base,
                zIndex: 9999999999999999

            }),

            container: provided => ({ ...provided, width: "100%", }),

            placeholder: styles => ({ ...styles }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '30px',
            }),
        };


        const { Option } = components
        const CustomSelectOption = props => (

            <Option {...props} style={{
                alignItems: "center",
                maxWidth: "100%",
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
            }}>
                {(showIcon === true && showFontAwesome === true && props.data.label !== undefined)
                    ?
                    <FontAwesomeIcon icon={props.data.icon} rotation={props.data.rotate} />
                    : null}

                {(showIcon === true && showFontAwesome !== true && props.data.label !== undefined) ?
                    <CircleIcon style={{
                        color: props.data.stroke,
                        fontSize: 18,
                        marginLeft: 2,
                        marginTop: -4
                    }} />
                    : null}
                {" " + (props.data.label.toString() ? props.data.label.toString() : "Selecciona una opción")}


            </Option>
        )

        const CustomSelectValue = props => (
            <div style={{
                maxWidth: "100%",
                textOverflow: '',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            }}>

                {(showIcon === true
                    && showFontAwesome === true
                    && props.data.label !== undefined)
                    ?
                    <FontAwesomeIcon icon={props.data.icon} 
                    rotation={props.data.rotate} 
                    style={{
                        marginLeft: 2
                    }} 
                    />
                    : null}

                {(showIcon === true
                    && showFontAwesome !== true
                    && props.data.label !== undefined)
                    ?
                    <CircleIcon style={{
                        color: props.data.stroke,
                        fontSize: 18,
                        marginLeft: 2,
                        marginTop: -4
                    }} />
                    : null}
                {" " + (props.data.label ? props.data.label : "Selecciona una opción")}

            </div>
        )

        return (
            <div className={className}>
                <label className="e-label-top" style={{
                    fontSize: "13px",
                    marginTop: "-200px",
                    color: "#0000008a",
                    fontWeight: "normal"
                }}>{label}</label>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={value}
                    isSearchable={true}
                    name={name}
                    options={options}
                    styles={colourStyles}
                    onChange={(args) => this.props.select({ ...args, name: name })}
                    menuPortalTarget={document.body}
                    menuPlacement={"bottom"}
                    menuShouldScrollIntoView={true}
                    menuPosition={'fixed'}
                    isDisabled={disabled}
                    key={value}
                    placeholder={'Seleccionar...'}
                    components={{ Option: CustomSelectOption, SingleValue: CustomSelectValue }}
                />
                {error
                    ?
                    <label className="error" style={{ color: "red", fontSize: 12, fontWeight: "normal" }}>
                        {error ? error : ""}
                    </label>
                    : null}
            </div>

        )
    }

}
