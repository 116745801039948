/* eslint-disable */
import {  
  DETECTOR_DETALLE_INICIA,
  DETECTOR_DETALLADO,    
  DETECTOR_DETALLE_ERROR,
} from 'src/constants/api/detectors';

import {    
LIMPIAR_ESTADO
} from 'src/constants/ActionTypes';


const DEFAULT_STATE = { 
waiting: false, 
estado:'', 
items:{},  
actualizar: false,
apiRespuesta: false
}

export default (state = DEFAULT_STATE, action) => {  
switch (action.type) {
  case DETECTOR_DETALLE_INICIA:
    return { waiting: true };
  case DETECTOR_DETALLADO:
    return { waiting: false, items: action.data, actualizar: false, estado:'DETALLE', apiRespuesta: true};
  case DETECTOR_DETALLE_ERROR:
    return {...state, 
      waiting: false, 
      error: action.error ? (`${action.error.detail || action.error.detail}`) : 'API Error.' ,         
      estado:'ERROR', 
      actualizar: true, 
      apiRespuesta: true};   
  case LIMPIAR_ESTADO:
    return { ...state, waiting: false, error: '', actualizar: false, estado: '', apiRespuesta: false}; 
  default:
    return state;
}
};
