/* eslint-disable */
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  actionCamerasInicia,
  actionCamerasListado,
  actionCamerasError,
  actionCamerasCleared,
  actionCamerasCreada,
  actionCamerasDeleted,
  actionCamerasActualizada,
  actionCamerasHabilitadaDeshabilitada,
  actionCamerasSetedCapacityPosition,
  actionCamerasCheckConnectionObtenida,
  actionCamerasPtzObtenida,
  actionCamerasImageObtenida,
  actionCamerasDeshabilitada
} from 'src/actions/api/cameras';

import {
  apiCamerasList,
  apiCamerasCrear,
  apiCamerasActualizar,
  apiCamerasDelete,
  apiCamerasHabilitarDeshabilitar,
  apiCamerasSetCapacityPosition,
  apiCamerasDeshabilitar,
  apiCamerasCheckConnection,
  apiCamerasPtz,
  apiCamerasImage
} from 'src/api/cameras';

import {
  CAMERAS_LISTA,
  CAMERAS_CREAR,
  CAMERAS_ACTUALIZAR,
  CAMERAS_HABILITAR_DESHABILITAR,
  CAMERAS_DELETE,
  CAMERAS_DESHABILITAR,
  CAMERAS_SET_CAPACITY_POSITION,
  CAMERAS_CLEAR,
  CAMERAS_CHECKCONNECTION_OBTENER,
  CAMERAS_PTZ_OBTENER,
  CAMERAS_IMAGE_OBTENER
} from 'src/constants/api/cameras';

function* sagaCamerasLista({ type, params }) {
  try {
    yield put(actionCamerasInicia());
    const data = yield call(apiCamerasList, params);
    yield put(actionCamerasListado(data));
  } catch (e) {
    yield put(actionCamerasError(e));
  }
}

function* sagaCamerasActualizar(action) {
  try {
    yield put(actionCamerasInicia());
    const resp = yield call(apiCamerasActualizar, action.data);
    yield put(actionCamerasActualizada(resp));
  } catch (e) {
    yield put(actionCamerasError(e));
  }
}

function* sagaCamerasCrear(action) {
  try {
    yield put(actionCamerasInicia());
    const resp = yield call(apiCamerasCrear, action.data);
    yield put(actionCamerasCreada(resp));
  } catch (e) {
    yield put(actionCamerasError(e));
  }
}

function* sagaCamerasClear(action) {
  try {
    yield put(actionCamerasInicia());
    //const resp = yield call(null, {});
    yield put(actionCamerasCleared({}));
  } catch (e) {
    yield put(actionCamerasError(e));
  }
}

function* sagaCamerasHabilitarDeshabilitar(action) {
  try {
    yield put(actionCamerasInicia());
    const resp = yield call(apiCamerasHabilitarDeshabilitar, action.data);
    yield put(actionCamerasHabilitadaDeshabilitada(resp));
  } catch (e) {
    yield put(actionCamerasError(e));
  }
}

function* sagaCamerasDeshabilitar(action) {
  try {
    yield put(actionCamerasInicia());
    const resp = yield call(apiCamerasDeshabilitar, action.data);
    yield put(actionCamerasDeshabilitada(resp));
  } catch (e) {
    yield put(actionCamerasError(e));
  }
}

function* sagaCamerasDelete(action) {
  try {
    yield put(actionCamerasInicia());
    const resp = yield call(apiCamerasDelete, action.data);
    yield put(actionCamerasDeleted(resp));
  } catch (e) {
    yield put(actionCamerasError(e));
  }
}

function* sagaCamerasSetPosition(action) {
  try {
    yield put(actionCamerasInicia());
    const resp = yield call(apiCamerasSetCapacityPosition, action.data);
    yield put(actionCamerasSetedCapacityPosition(resp));
  } catch (e) {
    yield put(actionCamerasError(e));
  }
}

/////----------> CONNECT CAMERA

function* sagaCamerasCheckConnection(action) {
  try {
    yield put(actionCamerasInicia());
    const resp = yield call(apiCamerasCheckConnection, action.data);
    yield put(actionCamerasCheckConnectionObtenida(resp));
  } catch (e) {
    yield put(actionCamerasError(e));
  }
}

function* sagaCamerasPtz(action) {
  try {
    yield put(actionCamerasInicia());
    const resp = yield call(apiCamerasPtz, action.data);
    yield put(actionCamerasPtzObtenida(resp));
  } catch (e) {
    yield put(actionCamerasError(e));
  }
}

function* sagaCamerasImage(action) {
  try {
    yield put(actionCamerasInicia());
    const resp = yield call(apiCamerasImage, action.data);
    yield put(actionCamerasImageObtenida(resp));
  } catch (e) {
    yield put(actionCamerasError(e));
  }
}

export default [
  takeEvery(CAMERAS_LISTA, sagaCamerasLista),
  takeEvery(CAMERAS_CREAR, sagaCamerasCrear),
  takeEvery(CAMERAS_ACTUALIZAR, sagaCamerasActualizar),
  takeEvery(CAMERAS_HABILITAR_DESHABILITAR, sagaCamerasHabilitarDeshabilitar),
  takeEvery(CAMERAS_DESHABILITAR, sagaCamerasDeshabilitar),
  takeEvery(CAMERAS_CLEAR, sagaCamerasClear),
  takeEvery(CAMERAS_DELETE, sagaCamerasDelete),
  takeEvery(CAMERAS_SET_CAPACITY_POSITION, sagaCamerasSetPosition),
  takeEvery(CAMERAS_CHECKCONNECTION_OBTENER, sagaCamerasCheckConnection),
  takeEvery(CAMERAS_PTZ_OBTENER, sagaCamerasPtz),
  takeEvery(CAMERAS_IMAGE_OBTENER, sagaCamerasImage)
];
