/* eslint-disable */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
    actionExecutivesInicia,
    actionExecutivesListado,
    actionExecutivesError,

    actionExecutivesCreada,
    actionExecutivesActualizada,
    actionExecutivesHabilitadaDeshabilitada,
} from "src/actions/api/executives";

import { 
    apiExecutivesList,    
    apiUserCrear,
    apiUserActualizar,
    apiExecutivesHabilitarDeshabilitar
} from "src/api/executives";

import { 
    EXECUTIVES_LISTA,
    EXECUTIVES_CREAR,
    EXECUTIVES_ACTUALIZAR,
    EXECUTIVES_HABILITAR_DESHABILITAR
} from 'src/constants/api/executives';

function* sagaExecutivesLista({ type, params }) {
  try {        
    yield put(actionExecutivesInicia());
    const data = yield call(apiExecutivesList, params);
    yield put(actionExecutivesListado(data));
  } catch (e) {
    yield put(actionExecutivesError(e));    
  }
}

function* sagaExecutivesActualizar(action) {
  try {       
    yield put(actionExecutivesInicia());    
    const resp = yield call(apiUserActualizar, action.data);
    yield put(actionExecutivesActualizada(resp));
  } catch (e) {
    yield put(actionExecutivesError(e));    
  }
}


function* sagaExecutivesCrear(action) {
  try {       
    yield put(actionExecutivesInicia());    
    const resp = yield call(apiUserCrear, action.data);
    yield put(actionExecutivesCreada(resp));
  } catch (e) {
    yield put(actionExecutivesError(e));    
  }
}

function* sagaExecutivesHabilitarDeshabilitar(action) {
  try {
    yield put(actionExecutivesInicia());
    const resp = yield call(apiExecutivesHabilitarDeshabilitar, action.data);
    yield put(actionExecutivesHabilitadaDeshabilitada(resp));
  } catch (e) {
    yield put(actionExecutivesError(e));
  }
}

export default  [
  takeEvery(EXECUTIVES_LISTA, sagaExecutivesLista), 
  takeEvery(EXECUTIVES_CREAR, sagaExecutivesCrear), 
  takeEvery(EXECUTIVES_ACTUALIZAR, sagaExecutivesActualizar), 
  takeEvery(EXECUTIVES_HABILITAR_DESHABILITAR, sagaExecutivesHabilitarDeshabilitar)
]
