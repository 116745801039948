/* eslint-disable */
import { URL_API_GATES } from './endpoints'

import {
  DEFAULT_HEADERS,
  apiPeticionExitosa,
  apiErrorHandler,
  userAuthToken,
  
} from './commons';

export const apiGatesList = async params => {
  var query = await `/list?active=${params.active}`
  var query2 = params.is_admin === false ? await `&client=${params.client}` : '';
  return await fetch(
    `${URL_API_GATES}${query}${query2}`,
    Object.assign({ method: "GET" },
      {
        headers: new Headers({
          ...DEFAULT_HEADERS,
          Authorization: userAuthToken(),
        })
      }
    )
  ).then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}


export const apiGatesCrear = async datos => {
  var query = '/';
  return await fetch(
    `${URL_API_GATES}${query}`,
    //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
    {
      method: "POST", body: JSON.stringify(datos), headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken(),
      })
    }
  ).then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}



export const apiGatesActualizar = async datos => {
  var query = datos.id ? `/${datos.id}` : '';
  return await fetch(
    `${URL_API_GATES}${query}`,
    //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
    {
      method: "PUT", body: JSON.stringify(datos), headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken(),
      })
    }
  ).then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}

export const apiGatesDetail = async params => {
  var query = params.id ? `/${params.id}` : '';
  return await fetch(
    `${URL_API_GATES}${query}`,
    Object.assign({ method: "GET" },
      {
        headers: new Headers({
          ...DEFAULT_HEADERS,
          Authorization: userAuthToken(),
        })
      }
    )
  ).then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}

export const apiGatesHabilitarDeshabilitar = async datos => {
  var query = `/${datos.id}`;
  return await fetch(
    `${URL_API_GATES}${query}`,
    {
      method: "DELETE", body: "", headers: new Headers({
        ...DEFAULT_HEADERS,
        "Content-Type": "text/plain",
        Authorization: userAuthToken(),
      })
    }
  )
  .then(apiPeticionExitosa)
  .catch(apiErrorHandler);
}

