/* eslint-disable */
import { URL_API_CONTACTS, URL_API_CONTACT } from './endpoints'

import {
  DEFAULT_HEADERS,
  apiPeticionExitosa,
  apiErrorHandler,
  userAuthToken,
  
} from './commons';

export const apiContactsList = async params => {
  var query = params.estado ? `/${params.estado}` : '';
  return await fetch(
    `${URL_API_CONTACTS}${query}`,
    Object.assign({ method: "GET" },
      {
        headers: new Headers({
          ...DEFAULT_HEADERS,
          Authorization: userAuthToken(),
        })
      }
    )
  ).then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}


export const apiContactsDetalle = async params => {
  var query = params.id ? `/${params.id}` : '';
  return await fetch(
    `${URL_API_CONTACT}${query}`,
    Object.assign({ method: "GET" },
      {
        headers: new Headers({
          ...DEFAULT_HEADERS,
          Authorization: userAuthToken(),
        })
      }
    )
  ).then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}

export const apiContactsActualizar = async datos => {
  var query = '/';
  return await fetch(
    `${URL_API_CONTACTS}${query}`,
    //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
    {
      method: "PUT", body: JSON.stringify(datos), headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken(),
      })
    }
  ).then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}


export const apiContactsCrear = async datos => {
  var query = '/';
  return await fetch(
    `${URL_API_CONTACTS}${query}`,
    //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
    {
      method: "POST", body: JSON.stringify(datos), headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken(),
      })
    }
  ).then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}

export const apiContactHabilitarDeshabilitar = async datos => {
  return await fetch(
    `${URL_API_CONTACTS}/${datos.stateEnableDisable}`,
    {
      method: "POST", body: JSON.stringify(datos.idRegistry), headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken(),
      })
    }
  )
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}
