/* eslint-disable */
import React, { Component } from 'react';

import { Box } from '@material-ui/core';

import Button from '@material-ui/core/Button';

import styles from './styles';

import ReactFormInputValidation from 'react-form-input-validation';
import FieldFormTemplate from '../FieldFormTemplate';
import DropDownWithSearchFormTemplate from 'src/components/DropDownWithSearchFormTemplate';
import DropDownFormTemplate from 'src/components/DropDownFormTemplate';

import LinkedCameraIcon from '@material-ui/icons/LinkedCamera';

import VisibilityIcon from '@mui/icons-material/Visibility';

class ConnectCamera extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statePassword: 'password',
      fields: {
        idRegistry: '',
        username: '',

        password: '',

        ip_address: ''
      },
      errors: {}
    };
    this.formConnectCamera = new ReactFormInputValidation(this, {
      locale: 'es'
    });
    this.formConnectCamera.onformsubmit = async (fields) => {
      const objToSend = await {
        username: this.state.fields.username.trim(),
        password: this.state.fields.password.trim(),
        ip_address: this.state.fields.ip_address.trim(),
        created_user: this.props.dataUserLog.public_key
      };
      this.props.ConnectCameraApi(objToSend);

      if (this.props.Update === true) {
        // this.props.actionExecutivesActualizar({
        //     ...objToSend,
        //     id: this.props.dataDetail.public_key,
        //     //updatedBy: "A40AF516-4209-4C5A-8EF0-D28328AFA10F"
        // });
      } else {
        //this.props.actionExecutivesCrear({ ...objToSend });
      }
    };
  }

  componentDidUpdate(preprops) {
    if (this.props.ObjCam.objCam !== preprops.ObjCam.objCam) {
      this.setDataFields();
    }
  }

  async componentDidMount() {
    this.formConnectCamera.useRules({
      username: 'required',
      password: 'sometimes',
      ip_address: 'required'
    });
    this.setDataFields();
  }

  setDataFields = () => {
    this.setState({
      fields: {
        username: this.props.ObjCam.objCam
          ? this.props.ObjCam.objCam.username
          : '',

        password: this.props.ObjCam.objCam
          ? this.props.ObjCam.objCam.password
          : '',

        ip_address: this.props.ObjCam.objCam
          ? this.props.ObjCam.objCam.ip_address
          : ''
      }
    });
  };

  changeStatePassword = () => {
    this.setState({
      statePassword:
        this.state.statePassword == 'password' ? 'text' : 'password'
    });
  };

  render() {
    const { fields } = this.state;
    const passwordStyle = {
      ...styles.inputStyle,
      position: 'relative',
      width: '98%',
      zIndex: '0'
    };

    const visibilityStyle = {
      position: 'absolute',
      float: 'right',
      marginTop: '5px',
      marginRight: '10px',
      right: 10
    };
    return (
      <Box
        sx={{
          backgroundColor: '#fff',
          marginTop: 0
        }}
      >
        <form
          onSubmit={this.formConnectCamera.handleSubmit}
          style={{ width: '100%' }}
        >
          <div
            classCol="col-12 col-md-12"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <Box
              display="flex"
              flexDirection="row"
              width={'100%'}
              overflow={'hidden'}
              style={{
                alignItems: 'center'
              }}
            >
              <img
                src={'/static/images/avatars/camera.png'}
                alt="Logo"
                style={{
                  width: 70,
                  height: undefined
                }}
              />
              <div
                style={{ fontSize: 12, width: '70%', margin: 5, padding: 10 }}
              >
                Completa los datos de conexión a la cámara para poder acceder a
                ella y obtener los datos de posición de aforo
              </div>
            </Box>
          </div>

          <div className="form-row" style={{ width: '95%', marginTop: 10 }}>
            <FieldFormTemplate
              label="Usuario *"
              classCol="col-12 col-md-12"
              field={
                <input
                  maxLength={65}
                  type="text"
                  name="username"
                  onBlur={this.formConnectCamera.handleBlurEvent}
                  onChange={this.formConnectCamera.handleChangeEvent}
                  value={this.state.fields.username}
                  data-attribute-name="Usuario "
                  style={styles.inputStyle}
                  autoComplete="username"
                  //placeholder={"Nombre de la ruta"}
                />
              }
              error={this.state.errors.username}
              // error="Error en el campo username, intente de nuevo o contacte a los administradores."
            />

            <FieldFormTemplate
              label="Contraseña (En caso de contar con una)."
              classCol="col-12 col-md-12"
              field={
                <div>
                  <input
                    maxLength={65}
                    type={this.state.statePassword}
                    name="password"
                    onBlur={this.formConnectCamera.handleBlurEvent}
                    onChange={this.formConnectCamera.handleChangeEvent}
                    value={this.state.fields.password}
                    data-attribute-name="Contraseña "
                    style={passwordStyle}
                    autoComplete="current-password"
                  />
                  <VisibilityIcon
                    style={visibilityStyle}
                    onClick={this.changeStatePassword}
                  />
                </div>
              }
              error={this.state.errors.password}
              // error="Error en el campo password, intente de nuevo o contacte a los administradores."
            />

            <FieldFormTemplate
              label="Dirección ip *"
              classCol="col-12 col-md-12"
              field={
                <input
                  maxLength={65}
                  type="text"
                  name="ip_address"
                  onBlur={this.formConnectCamera.handleBlurEvent}
                  onChange={this.formConnectCamera.handleChangeEvent}
                  value={this.state.fields.ip_address}
                  data-attribute-name="Dirección ip "
                  style={styles.inputStyle}
                  //placeholder={"Nombre de la ruta"}
                />
              }
              error={this.state.errors.ip_address}
              // error="Error en el campo ip_address, intente de nuevo o contacte a los administradores."
            />
            <div className="form-row" style={{ width: '100%', marginTop: 10 }}>
              <Box
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                  width: '100%',
                  marginLeft: 10
                }}
              >
                <Button
                  endIcon={<LinkedCameraIcon />}
                  key={'formConnectCamera'}
                  id={'formConnectCamera'}
                  variant="contained"
                  color="primary"
                  //type="submit"
                  onClick={(fields) =>
                    this.formConnectCamera.onformsubmit(fields)
                  }
                  disabled={
                    fields.user === '' ||
                    // fields.password === '' ||
                    fields.ip_address === ''
                      ? true
                      : false
                  }
                  style={{
                    outline: 'none',
                    width: '100%',
                    textTransform: 'none'
                  }}
                >
                  Conectar cámara
                </Button>
              </Box>
            </div>
          </div>
        </form>
      </Box>
    );
  }
}

export default ConnectCamera;
