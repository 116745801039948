/* eslint-disable */
import {
  CAMERAS_DETALLE_INICIA,

  CAMERAS_DETALLE,
  CAMERAS_DETALLADO,
  CAMERAS_DETALLE_ERROR,
} from 'src/constants/api/cameras';

export const actionCamerasDetalleInicia = () => ({ type: CAMERAS_DETALLE_INICIA, });

//Detalle
export const actionCamerasDetalle = (params) => ({ type: CAMERAS_DETALLE, params });
export const actionCamerasDetallado = (data) => ({ type: CAMERAS_DETALLADO, data });

export const actionCamerasDetalleError = (error) => ({ type: CAMERAS_DETALLE_ERROR, error });
