/* eslint-disable */
import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import styles from './styles';

import DropDownFormTemplate from '../DropDownFormTemplate';
import DropDownWithSearchFormTemplateMulti from 'src/components/DropDownWithSearchFormTemplateMulti';
import DropDownWithSearchFormTemplate from 'src/components/DropDownWithSearchFormTemplate';
import { Box } from '@material-ui/core';
import ReactFormInputValidation from 'react-form-input-validation';
import FieldFormTemplate from '../FieldFormTemplate';

import moment from 'moment';

import { Checkbox, FormControlLabel } from '@material-ui/core';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SweetAlert from 'react-bootstrap-sweetalert';

import { adminList } from 'src/api/commons';

const profilesList = [
  {
    name: 'Ejecutivo',
    value: 'E897FC34-DCFE-4852-A8EB-57B9FC4B689A'
  },
  {
    name: 'Supervisor',
    value: 'AF7578EA-1F5E-4B2E-ACD6-5BB7EE0989C5'
  },
  {
    name: 'Administrador',
    value: '539FDC2F-9C8B-4BC1-AAD6-101FC10A1176'
  }
];

class FrameCreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        idRegistry: '',
        name: this.props.dataDetail ? this.props.dataDetail.name : '',
        email: this.props.dataDetail ? this.props.dataDetail.email : '',
        rol: this.props.dataDetail ? this.props.dataDetail.idProfile : '',
        holidays: this.props.dataDetail
          ? this.props.dataDetail.holidays
          : false,
        idRegistryHolidays: this.props.dataDetail
          ? this.props.dataDetail.idRegistryHolidays
          : '',
        holidaysStart: this.props.dataDetail
          ? moment(this.props.dataDetail.holidaysStart).format('YYYY-MM-DD')
          : '',
        holidaysEnd: this.props.dataDetail
          ? moment(this.props.dataDetail.holidaysEnd).format('YYYY-MM-DD')
          : '',
        user: 'A40AF516-4209-4C5A-8EF0-D28328AFA10F'
      },
      selectHolidays: {},
      errors: {},
      selectDepartment: this.props.dataDetail
        ? this.props.dataDetail.departments
        : [],
      errorDepartment: '',
      showErrorDate: false
    };
    this.form = new ReactFormInputValidation(this, { locale: 'es' });
    this.form.onformsubmit = async (fields) => {
      if (
        this.state.fields.holidays === true &&
        this.state.fields.holidaysStart > this.state.fields.holidaysEnd
      ) {
        this.setState({
          showErrorDate: true
        });
        return;
      }
      if (
        this.state.fields.holidays === true &&
        (this.state.selectHolidays.value === '' ||
          this.state.selectHolidays.value === null)
      ) {
        this.setState({
          errorHolidays: 'Selecciona un ejecutivo de remplazo'
        });
      } else {
        if (!adminList.includes(this.state.fields.rol.toUpperCase())) {
          if (this.state.selectDepartment.length !== 0) {
            var newArray = [];
            this.state.selectDepartment.forEach((element) => {
              newArray.push(element.value);
            });

            const objToSend = {
              name: fields.name,
              email: fields.email,
              idProfile: fields.rol,
              insertBy: fields.user,
              departments: adminList.includes(
                this.state.fields.rol.toUpperCase()
              )
                ? []
                : newArray,
              holidays: fields.holidays,
              holidaysStart:
                fields.holidays === true ? fields.holidaysStart : null,
              holidaysEnd: fields.holidays === true ? fields.holidaysEnd : null,
              idRegistryHolidays:
                fields.holidays === true
                  ? this.state.selectHolidays.value !== ''
                    ? this.state.selectHolidays.value
                    : null
                  : null,
              user: 'A40AF516-4209-4C5A-8EF0-D28328AFA10F'
            };
            if (this.props.Update === true) {
              this.props.actionExecutivesActualizar({
                ...objToSend,
                idRegistry: this.props.dataDetail.idRegistry,
                updatedBy: 'A40AF516-4209-4C5A-8EF0-D28328AFA10F'
              });
            } else {
              this.props.actionExecutivesCrear({ ...objToSend });
            }
            //this.props.actionRequestCrear(objToSend);
          } else {
            this.setState({
              errorDepartment: 'Seleccione un departamento'
            });
          }
        } else {
          const objToSend = {
            name: fields.name,
            email: fields.email,
            idProfile: fields.rol,
            insertBy: fields.user,
            departments: [],
            holidays: fields.holidays,
            holidaysStart:
              fields.holidays === true ? fields.holidaysStart : null,
            holidaysEnd: fields.holidays === true ? fields.holidaysEnd : null,
            idRegistryHolidays:
              fields.holidays === true
                ? this.state.selectHolidays.value !== ''
                  ? this.state.selectHolidays.value
                  : null
                : null,
            user: 'A40AF516-4209-4C5A-8EF0-D28328AFA10F'
          };
          if (this.props.Update === true) {
            this.props.actionExecutivesActualizar({
              ...objToSend,
              idRegistry: this.props.dataDetail.idRegistry,
              updatedBy: 'A40AF516-4209-4C5A-8EF0-D28328AFA10F'
            });
          } else {
            this.props.actionExecutivesCrear({ ...objToSend });
          }
        }
      }
    };
  }

  componentDidUpdate(preprops) {
    if (this.props.dataDetail !== preprops.dataDetail) {
      this.setDataFields();
    }
    if (this.props.apiRespuestaRequest) {
      switch (this.props.estadoRequest) {
        case 'CREADO':
          toast.success('Se ha creado exitosamente', {
            backgroundColor: '#1ABD24',
            color: '#ffffff'
          });
          this.props.CloseDialog();
          break;
        default:
          break;
      }
      this.props.actionLimpiarEstado();
    }
  }

  componentDidMount() {
    this.form.useRules({
      name: 'required',
      email: 'required|email',
      rol: 'required',
      holidaysStart: 'required',
      holidaysEnd: 'required'
    });
  }

  setDataFields = () => {
    this.setState(
      {
        fields: {
          name: this.props.dataDetail ? this.props.dataDetail.name : '',
          email: this.props.dataDetail ? this.props.dataDetail.email : '',
          rol: this.props.dataDetail
            ? this.props.dataDetail.idProfile.toUpperCase()
            : '',
          user: 'A40AF516-4209-4C5A-8EF0-D28328AFA10F',
          holidays: this.props.dataDetail
            ? this.props.dataDetail.holidays
            : false,
          idRegistryHolidays: this.props.dataDetail
            ? this.props.dataDetail.idRegistryHolidays
            : '',
          holidaysStart: this.props.dataDetail
            ? moment(this.props.dataDetail.holidaysStart).format('YYYY-MM-DD')
            : '',
          holidaysEnd: this.props.dataDetail
            ? moment(this.props.dataDetail.holidaysEnd).format('YYYY-MM-DD')
            : '',
          idRegistry: this.props.dataDetail
            ? this.props.dataDetail.idRegistry
            : ''
        }
      },
      () => {
        this.selectHolidaysById();
        this.setDepartments();
        //this.setCompanyData(this.props.dataDetail ? this.props.dataDetail.idCompany : "")
      }
    );
  };

  setDepartments = async () => {
    // //console.log("Set Departments: ", this.props.dataDetail.departments);
    if (this.props.dataDetail) {
      if (this.props.dataDetail.departments) {
        let Departmentarray = [];
        this.props.dataDetail.departments.forEach((element) => {
          Departmentarray.push({
            label: element.name,
            value: element.idRegistry
          });
        });
        this.setState({
          selectDepartment: Departmentarray
        });
      }
    }
  };

  changueRol = (e) => {
    ////console.log("args: ", e.target.value);
    // if (e.target.value !== "") {
    //     this.setCompanyData(e.target.value);
    // }
    this.form.handleChangeEvent(e);
  };

  setCompanyData = async (id) => {
    if (this.props.dataCompanies && id !== '') {
      const data = [...this.props.dataCompanies];
      var empIds = await [id];
      var filteredArray = await data.filter(function (itm) {
        return empIds.indexOf(itm.idRegistry) > -1;
      });
      var companyInfo = await filteredArray[0];
      if (companyInfo) {
        this.setState({
          fields: {
            ...this.state.fields,
            nit: companyInfo.nit,
            phoneNumber: companyInfo.phone,
            businessPhone: companyInfo.phone,
            businessEmail: companyInfo.email
          }
        });
      }
    }
  };

  selectSubStationById = async () => {
    const { dataSubstation, dataRequestDetail } = this.props;
    const newArray = await [...dataSubstation];
    const arrayToFilter = await [];
    await arrayToFilter.push(dataRequestDetail.idSubstation);
    const FilterSubstation = await newArray.filter((i) =>
      arrayToFilter.includes(i.idRegistry)
    );
    this.selectDropdownSearch({
      ...FilterSubstation[0],
      label: FilterSubstation[0].name,
      value: FilterSubstation[0].idRegistry
    });
  };

  selectDropdownSearch = (data) => {
    this.setState({
      selectDepartment: data,
      errorDepartment: ''
    });
  };

  setHolidays = (e) => {
    this.setState({
      fields: {
        ...this.state.fields,
        holidays: e.target.checked
      }
    });
  };

  selectHolidaysById = async () => {
    const { dataExecutives, dataDetail } = this.props;
    if (dataDetail) {
      if (dataDetail.idRegistryHolidays) {
        const newArray = await [...dataExecutives];
        const arrayToFilter = await [];
        await arrayToFilter.push(dataDetail.idRegistryHolidays);
        const FilterRequest = await newArray.filter((i) =>
          arrayToFilter.includes(i.idRegistry)
        );
        this.selectDropdownSearchHolidays({
          ...FilterRequest[0],
          label: FilterRequest[0].name,
          value: FilterRequest[0].idRegistry
        });
      } else {
        this.setState({
          selectHolidays: {
            label: 'Ejecutivo Remplazo',
            value: ''
          }
        });
      }
    }
  };

  selectDropdownSearchHolidays = (data) => {
    this.setState({
      selectHolidays: data,
      errorHolidays: ''
    });
  };

  render() {
    const { dataDepartmentsList, dataDetail, dataExecutives } = this.props;
    return (
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={this.props.open}
        onClose={() => this.props.CloseDialog()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {this.props.Update ? 'Actualizar' : 'Crear'} Contacto
        </DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="row"
            width={'100%'}
            overflow={'hidden'}
          >
            <form onSubmit={this.form.handleSubmit} style={{ width: '100%' }}>
              <div className="form-row">
                <FieldFormTemplate
                  label="Nombre *"
                  classCol="col-12 col-md-6"
                  field={
                    <input
                      maxLength={65}
                      type="text"
                      name="name"
                      onBlur={this.form.handleBlurEvent}
                      onChange={this.form.handleChangeEvent}
                      value={this.state.fields.name}
                      data-attribute-name="Nombre "
                      style={styles.inputStyle}
                    />
                  }
                  error={this.state.errors.name}
                />

                <FieldFormTemplate
                  label="Correo *"
                  classCol="col-12 col-md-6"
                  field={
                    <input
                      maxLength={65}
                      disabled={this.props.Update === true ? true : false}
                      type="text"
                      name="email"
                      onBlur={this.form.handleBlurEvent}
                      onChange={this.form.handleChangeEvent}
                      value={this.state.fields.email}
                      data-attribute-name="Correo"
                      style={styles.inputStyle}
                    />
                  }
                  error={this.state.errors.email}
                />
              </div>

              <div className="form-row" style={{ marginTop: 0 }}>
                <DropDownFormTemplate
                  label="Perfil *"
                  classCol="col-12 col-md-6"
                  field={
                    <select
                      id="rol"
                      name="rol"
                      data-attribute-name="Perfil"
                      value={this.state.fields.rol}
                      onChange={(e) => this.changueRol(e)}
                      style={{
                        width: '100%',
                        borderWidth: '1px',
                        borderRadius: '5px',
                        borderColor: '#0000006b',
                        minHeight: '22px',
                        height: '33px'
                      }}
                    >
                      <option value={''}>{'Seleccione una opción'}</option>
                      {profilesList.map((element) => (
                        <option value={element.value}>{element.name}</option>
                      ))}
                    </select>
                  }
                  error={this.state.errors.rol}
                />
              </div>

              <div className={'form-row'}>
                <FieldFormTemplate
                  label={'Vacaciones'}
                  classCol="col-12 col-md-6"
                  field={
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="holidays"
                          name="holidays"
                          onBlur={this.form.handleBlurEvent}
                          onChange={(e) => this.setHolidays(e)}
                          color="primary"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                          checked={this.state.fields.holidays}
                          value={this.state.fields.holidays}
                        />
                      }
                      label="Vacaciones"
                    />
                  }
                  error={this.state.errors.holidays}
                />

                {this.state.fields.holidays === true ? (
                  <DropDownWithSearchFormTemplate
                    label="Ejecutivo Remplazo"
                    className="col-12 col-md-6"
                    value={this.state.selectHolidays}
                    defaultValue={{
                      label: 'Ejecutivo Remplazo',
                      value: ''
                    }}
                    options={dataExecutives.map((item) => {
                      if (dataDetail) {
                        if (item.idRegistry !== dataDetail.idRegistry) {
                          return {
                            ...item,
                            label: item.name,
                            value: item.idRegistry
                          };
                        } else {
                          return {
                            label: 'Selecciona un Ejecutivo',
                            value: ''
                          };
                        }
                      }
                    })}
                    name="subestation"
                    error={this.state.errorHolidays}
                    select={(args) => this.selectDropdownSearchHolidays(args)}
                  />
                ) : null}
              </div>

              {this.state.fields.holidays === true ? (
                <div className="form-row" style={{ marginTop: 0 }}>
                  <FieldFormTemplate
                    label="Fecha Inicio *"
                    classCol="col-12 col-md-6"
                    field={
                      <input
                        maxLength={65}
                        type="date"
                        data-date-format="DD-MMMM-YYYY HH:MM"
                        name="holidaysStart"
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        value={this.state.fields.holidaysStart}
                        data-attribute-name="Fecha Inicio"
                        style={styles.inputStyle}
                      />
                    }
                    error={this.state.errors.holidaysStart}
                  />

                  <FieldFormTemplate
                    label="Fecha Fin *"
                    classCol="col-12 col-md-6"
                    field={
                      <input
                        maxLength={65}
                        type="date"
                        data-date-format="DD-MMMM-YYYY HH:MM"
                        name="holidaysEnd"
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        value={this.state.fields.holidaysEnd}
                        data-attribute-name="Fecha Fin"
                        style={styles.inputStyle}
                      />
                    }
                    error={this.state.errors.holidaysEnd}
                  />
                </div>
              ) : null}

              {adminList.includes(
                this.state.fields.rol.toUpperCase()
              ) ? null : (
                <div className="form-row" style={{ marginTop: 0 }}>
                  <DropDownWithSearchFormTemplateMulti
                    label="Departamentos *"
                    className="col-12 col-md-12"
                    value={this.state.selectDepartment}
                    options={dataDepartmentsList.map((item) => {
                      return {
                        ...item,
                        label: item.name,
                        value: item.idRegistry
                      };
                    })}
                    name="department"
                    error={this.state.errorDepartment}
                    select={(args) => this.selectDropdownSearch(args)}
                  />
                </div>
              )}
              <DialogActions>
                <Button color="primary" type="submit">
                  Guardar
                </Button>

                <Button
                  onClick={() => this.props.CloseDialog()}
                  color="primary"
                >
                  Cerrar
                </Button>
              </DialogActions>
            </form>
          </Box>
        </DialogContent>

        <SweetAlert
          show={this.state.showErrorDate}
          error
          confirmBtnText="Aceptar"
          confirmBtnBsStyle="light"
          style={{ color: '#000', fontSize: 14 }}
          title={'Error'}
          onConfirm={() =>
            this.setState({
              showErrorDate: !this.state.showErrorDate
            })
          }
        >
          <span>{'Fecha Fin debe ser mayor a Fecha inicio'}</span>
        </SweetAlert>

        <ToastContainer position={'top-right'} delay={5000} />
      </Dialog>
    );
  }
}

export default FrameCreateUser;
