/* eslint-disable */
import { all } from "redux-saga/effects";


import sagasContacts from './sagasContacts';
import sagasContactsDetail from './sagasContactsDetail';

import sagasCompanies from './sagasCompanies';
import sagasCompaniesDetail from './sagasCompaniesDetail';

import sagasAnalyticSettings from "./sagasAnalyticSettings";

import sagaPermissions from './sagasPermissions';
import sagaRoles from './sagasRoles';


import sagaExecutives from "./sagasExecutives";
import sagasExecutiveDetail from "./sagasExecutiveDetail";

import sagasCameras from "./sagasCameras";
import sagasCamerasDetail from "./sagasCamerasDetail";

import sagasRoadWays from "./sagasRoadWays";
import sagasRoadWaysDetail from "./sagasRoadWaysDetail";

import sagasGates from "./sagasGates";
import sagasGatesVideos from "./sagasGatesVideos";
import sagasManeuvers from "./sagasManeuvers";

import sagasScaleFactor from "./sagasScaleFactor";

import sagasVideos from "./sagasVideos";

import sagasDetectors from "./sagasDetectors";
import sagasDetectorDetail from "./sagasDetectorDetail";

import sagasUser from "./sagasUser";



export default function* rootSaga(getState) {
  yield all([
    ...sagasAnalyticSettings,
    ...sagasContacts,
    ...sagasContactsDetail,

    ...sagaPermissions,
    ...sagaRoles,

    ...sagasCompanies,
    ...sagasCompaniesDetail,

    ...sagasCameras,
    ...sagasCamerasDetail,

    ...sagasRoadWays,
    ...sagasRoadWaysDetail,

    ...sagasGates,
    ...sagasScaleFactor,

    ...sagasVideos,
    ...sagasGatesVideos,
    ...sagasManeuvers,

    ...sagasDetectors,
    ...sagasDetectorDetail,

    ...sagasUser,

    ...sagaExecutives,

    ...sagasExecutiveDetail,

  ])
}
