/* eslint-disable */
import React, { Component } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import 'react-toastify/dist/ReactToastify.css';

//konva "/static/images/avatars/maneuvers.png"



class DialogShowManeuvers extends Component {
    constructor(props) {
        super(props)
        this.state = {
        };
    }


    render() {
        return (
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={this.props.open}
                onClose={() => this.props.CloseDialog()}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Maniobras</DialogTitle>
                <DialogContent>
                    <form style={{ width: "100%", }}>
                        <Box display="flex" flexDirection="row" width={"100%"} overflow={"hidden"}>

                            <Box display="flex" flexDirection="row" width={"100%"} overflow={"hidden"}>

                                <Box display="flex" flexDirection="column" width={"100%"} overflow={"hidden"} style={{
                                    padding: 5,
                                }}>
                                    <div className="form-row" style={{ width: "100%", marginTop: 10, marginBottom: 20, alignContent: "center", alignItems: "center", justifyContent: "center"}}>
                                        <img src={"/static/images/avatars/manuevers.png"} width={"70%"} height={undefined}  />
                                    </div>

                                </Box>
                            </Box>


                        </Box>
                        <DialogActions>

                            <Button onClick={() => this.props.CloseDialog()}
                                color="primary">
                                Cerrar
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent >

            </Dialog >
        )
    }
}



export default DialogShowManeuvers;