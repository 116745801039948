/* eslint-disable */
import {
  CAMERAS_INICIA,
  CAMERAS_LISTADO,
  CAMERAS_ERROR,
  CAMERAS_ACTUALIZADA,
  CAMERAS_CREADA,
  CAMERAS_HABILITADA_DESHABILITADA,
  CAMERAS_DESHABILITADA,
  CAMERAS_CLEARED,
  CAMERAS_DELETED,
  CAMERAS_SETED_CAPACITY_POSITION,

  //--> Camera connection
  CAMERAS_CHECKCONNECTION_OBTENIDA,
  CAMERAS_PTZ_OBTENIDA,
  CAMERAS_IMAGE_OBTENIDA,
  //CAMERAS_SCALEFACTOR,
  CAMERAS_SCALEFACTORED
} from 'src/constants/api/cameras';

import { LIMPIAR_ESTADO } from 'src/constants/ActionTypes';

const DEFAULT_STATE = {
  waiting: false,
  estado: '',
  items: [],
  actualizar: false,
  apiRespuesta: false,
  dataDetail: {},
  cameraChkConnection: {},
  image: {},
  ptz: {}
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case CAMERAS_INICIA:
      return { ...state, waiting: true };
    case CAMERAS_LISTADO:
      return {
        ...state,
        waiting: false,
        items: action.data,
        actualizar: false,
        estado: 'LISTADO',
        apiRespuesta: true
      };

    case CAMERAS_ACTUALIZADA:
      return {
        ...state,
        waiting: false,
        actualizar: true,
        estado: 'ACTUALIZADO',
        apiRespuesta: true
      };

    case CAMERAS_CREADA:
      return {
        ...state,
        waiting: false,
        actualizar: true,
        estado: 'CREADO',
        apiRespuesta: true,
        data: action.params
      };

    case CAMERAS_DELETED:
      return {
        state: state,
        waiting: false,
        actualizar: true,
        estado: 'ELIMINADO',
        apiRespuesta: true //, data: action.params
      };

    case CAMERAS_HABILITADA_DESHABILITADA:
      return {
        ...state,
        waiting: false,
        actualizar: true,
        estado: 'HABILITADO',
        apiRespuesta: true
      };

    case CAMERAS_DESHABILITADA:
      return {
        ...state,
        waiting: true,
        actualizar: true,
        estado: 'DESHABILITADO',
        apiRespuesta: true
      };

    case CAMERAS_SETED_CAPACITY_POSITION:
      return {
        ...state,
        waiting: true,
        actualizar: true,
        estado: 'SETED',
        apiRespuesta: true
      };

    case CAMERAS_ERROR:
      return {
        ...state,
        waiting: false,
        error: action.error
          ? action.error.detail
            ? action.error.detail
            : action.error.codigo === 401
            ? 'Usuario y/o contraseña incorrectos'
            : 'Error al solicitar el Api'
          : 'API Error.',
        estado: 'ERROR',
        actualizar: true,
        apiRespuesta: true
      };

    //--->Connect camera

    case CAMERAS_CHECKCONNECTION_OBTENIDA:
      return {
        ...state,
        waiting: false,
        actualizar: true,
        estado: 'CAMERA_CHECK_CONNECTION',
        apiRespuesta: true,
        cameraChkConnection: action.params
      };
    case CAMERAS_PTZ_OBTENIDA:
      return {
        ...state,
        waiting: false,
        actualizar: true,
        estado: 'CAMERA_PTZ',
        apiRespuesta: true,
        ptz: action.params
      };

    case CAMERAS_IMAGE_OBTENIDA:
      return {
        ...state,
        waiting: false,
        actualizar: true,
        estado: 'CAMERA_IMAGE',
        apiRespuesta: true,
        image: action.params
      };

    case CAMERAS_CLEARED:
      return {
        DEFAULT_STATE,
        error: '',
        actualizar: false,
        estado: '',
        apiRespuesta: false
      };
    case LIMPIAR_ESTADO:
      return {
        ...state,
        error: '',
        actualizar: false,
        estado: '',
        apiRespuesta: false
      };
    default:
      return state;
  }
};
