/* eslint-disable */
export const CAMERAS_INICIA = 'CAMERAS_INICIA';
export const CAMERAS_LISTA = 'CAMERAS_LISTA';
export const CAMERAS_LISTADO = 'CAMERAS_LISTADO';
export const CAMERAS_ERROR = 'CAMERAS_ERROR';

export const CAMERAS_ACTUALIZAR = 'CAMERAS_ACTUALIZAR';
export const CAMERAS_ACTUALIZADA = 'CAMERAS_ACTUALIZADA';
export const CAMERAS_CREAR = 'CAMERAS_CREAR';
export const CAMERAS_CREADA = 'CAMERAS_CREADA';

export const CAMERAS_DELETE = 'CAMERAS_DELETE';
export const CAMERAS_DELETED = 'CAMERAS_DELETED';

export const CAMERAS_CLEAR = 'CAMERAS_CLEAR';
export const CAMERAS_CLEARED = 'CAMERAS_CLEARED';

export const CAMERAS_SCALEFACTOR = 'CAMERAS_SCALEFACTOR';
export const CAMERAS_SCALEFACTORED = 'CAMERAS_SCALEFACTORED';

export const CAMERAS_DETALLE_INICIA = 'CAMERAS_DETALLE_INICIA';
export const CAMERAS_DETALLE = 'CAMERAS_DETALLE';
export const CAMERAS_DETALLADO = 'CAMERAS_DETALLADO';
export const CAMERAS_DETALLE_ERROR = 'CAMERAS_DETALLE_ERROR';

export const CAMERAS_HABILITAR_DESHABILITAR = 'CAMERAS_HABILITAR_DESHABILITAR';
export const CAMERAS_HABILITADA_DESHABILITADA =
  'CAMERAS_HABILITADA_DESHABILITADA';

export const CAMERAS_SET_CAPACITY_POSITION = 'CAMERAS_SET_CAPACITY_POSITION';
export const CAMERAS_SETED_CAPACITY_POSITION =
  'CAMERAS_SETED_CAPACITY_POSITION';

export const CAMERAS_DESHABILITAR = 'CAMERAS_DESHABILITAR';
export const CAMERAS_DESHABILITADA = 'CAMERAS_DESHABILITADA';
export const CAMERAS_CHECKCONNECTION_OBTENER =
  'CAMERAS_CHECKCONNECTION_OBTENER';
export const CAMERAS_CHECKCONNECTION_OBTENIDA =
  'CAMERAS_CHECKCONNECTION_OBTENIDA';

export const CAMERAS_PTZ_OBTENER = 'CAMERAS_PTZ_OBTENER';
export const CAMERAS_PTZ_OBTENIDA = 'CAMERAS_PTZ_OBTENIDA';

export const CAMERAS_IMAGE_OBTENER = 'CAMERAS_IMAGE_OBTENER';
export const CAMERAS_IMAGE_OBTENIDA = 'CAMERAS_IMAGE_OBTENIDA';
