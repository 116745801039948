/* eslint-disable */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
    actionRoadWaysInicia,
    actionRoadWaysListado,
    actionRoadWaysError,

    actionRoadWaysCreada,
    actionRoadWaysScaleFactorCreada,
    actionRoadWaysActualizada,
    actionRoadWaysHabilitadaDeshabilitada,
    actionRoadWaysCleared
} from "src/actions/api/roadWays";

import { 
    apiRoadWaysList,    
    apiRoadWaysCrear,
    apiRoadWaysActualizar,
    apiRoadWaysHabilitarDeshabilitar,
    apiRoadWaysScaleFactorCrear
} from "src/api/roadWays";

import { 
    ROADWAYS_LISTA,
    ROADWAYS_CREAR,
    ROADWAYS_ACTUALIZAR,
    ROADWAYS_HABILITAR_DESHABILITAR,
    ROADWAYS_CLEAR,
    ROADWAYS_SCALEFACTOR_CREAR
} from 'src/constants/api/roadWays';

function* sagaRoadWaysLista({ type, params }) {
  try {        
    yield put(actionRoadWaysInicia());
    const data = yield call(apiRoadWaysList, params);
    yield put(actionRoadWaysListado(data));
  } catch (e) {
    yield put(actionRoadWaysError(e));    
  }
}

function* sagaRoadWaysActualizar(action) {
  try {       
    yield put(actionRoadWaysInicia());    
    const resp = yield call(apiRoadWaysActualizar, action.data);
    yield put(actionRoadWaysActualizada(resp));
  } catch (e) {
    yield put(actionRoadWaysError(e));    
  }
}


function* sagaRoadWaysCrear(action) {
  try {       
    yield put(actionRoadWaysInicia());    
    const resp = yield call(apiRoadWaysCrear, action.data);
    yield put(actionRoadWaysCreada(resp));
  } catch (e) {
    yield put(actionRoadWaysError(e));    
  }
}

function* sagaRoadWaysScaleFactorCrear(action) {
  try {       
    yield put(actionRoadWaysInicia());    
    const resp = yield call(apiRoadWaysScaleFactorCrear, action.data);
    yield put(actionRoadWaysScaleFactorCreada(resp));
  } catch (e) {
    yield put(actionRoadWaysError(e));    
  }
}

function* sagaRoadWaysHabilitarDeshabilitar(action) {
  try {
    yield put(actionRoadWaysInicia());
    const resp = yield call(apiRoadWaysHabilitarDeshabilitar, action.data);
    yield put(actionRoadWaysHabilitadaDeshabilitada(resp));
  } catch (e) {
    yield put(actionRoadWaysError(e));
  }
}

function* sagaRoadWaysClear(action) {
  try {
    yield put(actionRoadWaysInicia());
    //const resp = yield call(null, {});
    yield put(actionRoadWaysCleared({}));
  } catch (e) {
    yield put(actionRoadWaysError(e));
  }
}


export default  [
  takeEvery(ROADWAYS_LISTA, sagaRoadWaysLista), 
  takeEvery(ROADWAYS_CREAR, sagaRoadWaysCrear), 
  takeEvery(ROADWAYS_SCALEFACTOR_CREAR, sagaRoadWaysScaleFactorCrear),
  takeEvery(ROADWAYS_ACTUALIZAR, sagaRoadWaysActualizar), 
  takeEvery(ROADWAYS_HABILITAR_DESHABILITAR, sagaRoadWaysHabilitarDeshabilitar),
  takeEvery(ROADWAYS_CLEAR, sagaRoadWaysClear),
]
