/* eslint-disable */

export const URL_API_FILES_LOCATION = 'https://demo.d33ptracker.co/'; // debe ir con el "/ " localhost
// export const URL_API_FILES_LOCATION = 'http://localhost:5001/'; // debe ir con el "/ " localhost
// export const URL_API_FILES_LOCATION = 'http://35.231.156.57:80/'; // debe ir con el "/ " apunta  a la maquina de google por ahora
export const URL_API_FILES = `${URL_API_FILES_LOCATION}FileUpload?`; //

// export const URL_API = 'http://127.0.0.1:8000'; // localhost
export const URL_API = 'https://d33p-api.azurewebsites.net'; // Dockerized API PROD
// export const URL_API = 'https://d33p-api-qa.azurewebsites.net'; // Dockerized API QA

// export const URL_APP = 'http://localhost:3000'; //--> Localhost
// export const URL_APP = 'https://deepappstaeus2dv.z13.web.core.windows.net'; //_--> DEV
// export const URL_APP = 'https://deepappstaeus2qa.z13.web.core.windows.net'; //_--> QA
export const URL_APP = 'https://www.admin.deeptracker.co'; //_--> Prod

export const URL_API_USUARIOS = `${URL_APP}/users`;

export const URL_API_USERS = `${URL_API}/users`;

export const URL_API_EXECUTIVES = `${URL_API}/users`;

//companies
export const URL_API_COMPANIES = `${URL_API}/clients`;

//Permisos
export const URL_API_PERMISSIONS = `${URL_API}/permissions/`;

//Roles
export const URL_API_ROLES = `${URL_API}/roles/`;

//Login
export const USER_LOGIN = `${URL_API}/users/permission`;

//Cameras
export const URL_API_CAMERAS = `${URL_API}/cameras`;

//Gates
export const URL_API_GATES = `${URL_API}/gates`;
export const URL_API_GATES_VIDEO = `${URL_API}/geometries`;

//Roadways
export const URL_API_ROADWAYS = `${URL_API}/roadways`;

//Detectors
export const URL_API_DETECTORS = `${URL_API}/detectors`;
//Videos
export const URL_API_VIDEOS = `${URL_API}/videos`;
export const URL_API_MANEUVERS = `${URL_API}/maneuvers`;

//</--Lists
export const URL_API_CONTACTS = `${URL_API}/Contacts`;
export const URL_API_CONTACT = `${URL_API}/Contact`;

//Ajustes de Analítica
export const URL_API_ANALYTICS_SETTINGS = `${URL_API}/Analyticss`;
export const URL_API_ANALYTIC_SETTINGS = `${URL_API}/Analytics`;

//Create && edit User
export const URL_API_CREATE_USER = `${URL_API}/users`;

//Power BI URL
export const POWERBI_URL = `${URL_API_COMPANIES}/dashboard/`;
