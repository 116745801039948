/* eslint-disable */
import { call, put, takeEvery } from "redux-saga/effects";
import { 
    actionScaleFactorInicia,
    actionScaleFactorListado,
    actionScaleFactorError,

    actionScaleFactorCreada,
    actionScaleFactorActualizada,
    actionScaleFactorHabilitadaDeshabilitada,
} from "src/actions/api/scaleFactor";

import { 
  apiCameraScaleFactor
} from "src/api/cameras";

import { 
    SCALEFACTOR_LISTA,
    SCALEFACTOR_CREAR,
    SCALEFACTOR_ACTUALIZAR,
    SCALEFACTOR_HABILITAR_DESHABILITAR
} from 'src/constants/api/scaleFactor';


function* sagaScaleFactorCrear(action) {
  try {       
    yield put(actionScaleFactorInicia());    
    const resp = yield call(apiCameraScaleFactor, action.data);
    yield put(actionScaleFactorCreada(resp));
  } catch (e) {
    yield put(actionScaleFactorError(e));    
  }
}

export default  [
  takeEvery(SCALEFACTOR_CREAR, sagaScaleFactorCrear),
]
