/* eslint-disable */
import { URL_API_COMPANIES } from './endpoints';

import {
  DEFAULT_HEADERS,
  apiPeticionExitosa,
  apiErrorHandler,
  userAuthToken
} from './commons';

export const apiCompaniesList = async (params) => {
  var query = params.active ? `/?active=${params.active}` : '';
  return await fetch(
    `${URL_API_COMPANIES}${query}`,
    Object.assign(
      { method: 'GET' },
      {
        headers: new Headers({
          ...DEFAULT_HEADERS,
          Authorization: userAuthToken()
        })
      }
    )
  )
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
};

export const apiCompaniesActualizar = async (datos) => {
  var query = datos.id ? `/${datos.id}` : '';
  return await fetch(
    `${URL_API_COMPANIES}${query}`,
    //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
    {
      method: 'PUT',
      body: JSON.stringify(datos),
      headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken()
      })
    }
  )
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
};

export const apiCompaniesCrear = async (datos) => {
  var query = '/';
  return await fetch(
    `${URL_API_COMPANIES}${query}`,
    //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
    {
      method: 'POST',
      body: JSON.stringify(datos),
      headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken()
      })
    }
  )
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
};

export const apiCompaniestHabilitarDeshabilitar = async (datos) => {
  var query = `/${datos.id}`;
  return await fetch(`${URL_API_COMPANIES}${query}`, {
    method: 'DELETE',
    body: '',
    headers: new Headers({
      ...DEFAULT_HEADERS,
      'Content-Type': 'text/plain',
      Authorization: userAuthToken()
    })
  })
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
};
