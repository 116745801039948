/* eslint-disable */
import { useState } from 'react';
import { Image, Layer, Line, Stage } from 'react-konva';
import Img from 'use-image';
import Gate from './Gate';

import { Box } from '@material-ui/core';

import Add from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

//import axios from '../axios';

export default function FormFactorForm({ formFactors,
  setFormFactors,
  roadways,
  setRoadways,
  cameraImg,
  camera,
  setCamera }) {
  const roadImg = cameraImg;
  const [selectedId, selectShape] = useState(null);
  const [roadway, setRoadway] = useState(0);
  const [factorX1, setFactorX1] = useState(0);
  const [factorX2, setFactorX2] = useState(0);

  const RoadImg = () => {
    const [image] = Img(roadImg);
    return <Image image={image} width={960} height={540} />;
  };

  const handleAddGateFormFactor = () => {
    let newFormFactor = new Object();
    const width = 100;
    const [x, y] = [50, 50];
    const [x1, y1] = [x + width, y + width];
    const currentTime = Date.now();

    newFormFactor = {
      id: currentTime,
      name: `ff-${currentTime}`,
      roadway_id: roadway,
      x,
      y,
      x1,
      y1,
      rotation: 0,
      width,
      height: 7,
      fill: '#00f042',
    };

    setFormFactors([...formFactors, newFormFactor]);
  };

  const renderGate = () => {
    return (
      <Layer>
        <RoadImg />
        {formFactors.map((formFactor, i) => {
          return (
            <Gate
              key={i}
              shapeProps={formFactor}
              isSelected={formFactor.id === selectedId}
              onSelect={() => {
                selectShape(formFactor.id);
              }}
              onChange={(newAttrs) => {
                const newFormFactors = formFactors.slice();
                newFormFactors[i] = newAttrs;
                setFormFactors(newFormFactors);
              }}
            />
          );
        })}
      </Layer>
    );
  };

  const checkDeselect = e => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target.attrs.image !== undefined;
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  const handleCalculeFormFactor = e => {
    e.preventDefault();
    const formFactor = {
      line1: {
        x1: formFactors[0].x,
        y1: formFactors[0].y,
        x2: formFactors[0].x1,
        y2: formFactors[0].y1
      },
      line2: {
        x3: formFactors[1].x,
        y3: formFactors[1].y,
        x4: formFactors[1].x1,
        y4: formFactors[1].y1
      },
    };

    //console.log("handleCalculeFormFactor: ", formFactor);
    // axios.post(`cameras/scale-factor?l_m=${factorX1}&w_m=${factorX2}`, formFactor)
    //   .then(res => {
    //     setCamera(camera => ({
    //       ...camera,
    //       scale_x: res.data.scale_x,
    //       scale_y: res.data.scale_y
    //     }));
    //   })
    //   .catch(err => {
    //     console.error(err);
    //   });
  }

  return (

    <Box display="flex" flex={1} flexDirection="column" width={"100%"} overflow={"hidden"} margin={0} padding={0} alignSelf={"center"}>
      <div className='form-row' style={{ width: "100%", marginTop: 10, marginBottom: 20 }}>

        <Box
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            width: "100%",
            marginLeft: 5,
            justifyContent: "center"
          }}>
          <Button
            endIcon={<Add />}
            variant="contained"
            color="primary"
            disabled={formFactors.length >= 2}
            //type="submit"
            onClick={() => handleAddGateFormFactor()}
            style={{ outline: "none", width: "45%", textTransform: "none", alignSelf: "center", backgroundColor: formFactors.length >= 2 ? '#cecece' :'#0099ff' }}>
            Agregar factor de forma
          </Button>



        </Box>
      </div >

      <Stage
        className='mx-auto mt-6'
        width={960}
        height={540}
        onMouseDown={checkDeselect}
        onTouchStart={checkDeselect}
      >
        <Layer>
          <RoadImg />
        </Layer>
        {renderGate()}
      </Stage>
    </Box>

    // {formFactors.length > 1 &&
    //   <div className='mt-6 flex gap-4'>
    //     <div className='mt-5 relative'>
    //       <input
//maxLength={65}
    //         id='factorX1'
    //         name='factorX1'
    //         type='text'
    //         className='peer input-text'
    //         placeholder='Largo en metros'
    //         onChange={e => setFactorX1(e.target.value)}
    //       />
    //       <label
    //         htmlFor='street'
    //         className='label-text peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm'
    //       >
    //         Largo en metros
    //       </label>
    //     </div>
    //     <div className='mt-5 relative'>
    //       <input
//maxLength={65}
    //         id='factorX2'
    //         name='factorX2'
    //         type='text'
    //         className='peer input-text'
    //         placeholder='Ancho en metros'
    //         onChange={e => setFactorX2(e.target.value)}
    //       />
    //       <label
    //         htmlFor='street'
    //         className='label-text peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm'
    //       >
    //         Ancho en metros
    //       </label>
    //     </div>
    //     <div className='mt-5 relative'>
    //       <button
    //         className='mt-5 mr-2 px-4 py-2 rounded bg-yellow-500 hover:bg-yellow-400 text-white font-semibold text-center block w-full focus:outline-none focus:ring focus:ring-offset-2 focus:ring-yellow-500 focus:ring-opacity-80 cursor-pointer'
    //         onClick={handleCalculeFormFactor}
    //       >
    //         Calcular factor de forma
    //       </button>
    //     </div>
    //   </div>
    // }
  );
}

