/* eslint-disable */
export const SCALEFACTOR_INICIA = 'SCALEFACTOR_INICIA';
export const SCALEFACTOR_LISTA = 'SCALEFACTOR_LISTA';
export const SCALEFACTOR_LISTADO = 'SCALEFACTOR_LISTADO';
export const SCALEFACTOR_ERROR = 'SCALEFACTOR_ERROR';

export const SCALEFACTOR_ACTUALIZAR = "SCALEFACTOR_ACTUALIZAR";
export const SCALEFACTOR_ACTUALIZADA = "SCALEFACTOR_ACTUALIZADA";
export const SCALEFACTOR_CREAR = "SCALEFACTOR_CREAR";
export const SCALEFACTOR_CREADA =  "SCALEFACTOR_CREADA";

export const SCALEFACTOR_CLEAR = "SCALEFACTOR_CLEAR";
export const SCALEFACTOR_CLEARED =  "SCALEFACTOR_CLEARED";

export const SCALEFACTOR_DETALLE_INICIA = "SCALEFACTOR_DETALLE_INICIA";
export const SCALEFACTOR_DETALLE = "SCALEFACTOR_DETALLE";
export const SCALEFACTOR_DETALLADO = "SCALEFACTOR_DETALLADO";
export const SCALEFACTOR_DETALLE_ERROR = "SCALEFACTOR_DETALLE_ERROR";

export const SCALEFACTOR_HABILITAR_DESHABILITAR = "SCALEFACTOR_HABILITAR_DESHABILITAR";
export const SCALEFACTOR_HABILITADA_DESHABILITADA = "SCALEFACTOR_HABILITADA_DESHABILITADA";