/* eslint-disable */
import { URL_API_ANALYTICS_SETTINGS, URL_API_ANALYTIC_SETTINGS } from './endpoints'

import {
    DEFAULT_HEADERS,
    apiPeticionExitosa,
    apiErrorHandler,
    userAuthToken,
    
} from './commons';

export const apiAnalyticSettingsList = async params => {
    var query = params.estado ? `/${params.estado}` : '';
    return await fetch(
        `${URL_API_ANALYTICS_SETTINGS}${query}`,
        Object.assign({ method: "GET" },
            {
                headers: new Headers({
                    ...DEFAULT_HEADERS,
                    Authorization: userAuthToken(),
                })
            }
        )
    ).then(apiPeticionExitosa)
        .catch(apiErrorHandler);
}

export const apiAnalyticSettingsDetalle = async params => {
    var query = params.id ? `/${params.id}` : '';
    return await fetch(
        `${URL_API_ANALYTIC_SETTINGS}${query}`,
        Object.assign({ method: "GET" },
            {
                headers: new Headers({
                    ...DEFAULT_HEADERS,
                    Authorization: userAuthToken(),
                })
            }
        )
    ).then(apiPeticionExitosa)
        .catch(apiErrorHandler);
}

export const apiAnalyticSettingsActualizar = async datos => {
  var query = '/';
    return await fetch(
      `${URL_API_ANALYTIC_SETTINGS}${query}`,
        {
            method: "PUT", body: JSON.stringify(datos), headers: new Headers({
                ...DEFAULT_HEADERS,
                Authorization: userAuthToken(),
            })
        }
    ).then(apiPeticionExitosa)
        .catch(apiErrorHandler);
}


export const apiAnalyticSettingsCrear = async datos => {
    var query = '/';
    return await fetch(
    `${URL_API_ANALYTIC_SETTINGS}${query}`,
        {
            method: "POST", body: JSON.stringify(datos), headers: new Headers({
                ...DEFAULT_HEADERS,
                Authorization: userAuthToken(),
            })
        }
    ).then(apiPeticionExitosa)
        .catch(apiErrorHandler);
}
