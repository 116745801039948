/* eslint-disable */
import {
    EXECUTIVES_INICIA,
    EXECUTIVES_LISTA,    
    EXECUTIVES_LISTADO, 

    EXECUTIVES_ACTUALIZAR,
    EXECUTIVES_ACTUALIZADA,
    EXECUTIVES_CREAR,
    EXECUTIVES_CREADA,

    EXECUTIVE_DETALLE,
    EXECUTIVE_DETALLADO,
    EXECUTIVE_DETALLE_INICIA,
    EXECUTIVES_HABILITAR_DESHABILITAR,
    EXECUTIVES_HABILITADA_DESHABILITADA,
    EXECUTIVES_ERROR,
} from 'src/constants/api/executives';

export const actionExecutivesInicia = () => ({ type: EXECUTIVES_INICIA, });
export const actionExecutivesLista = params => ({ type: EXECUTIVES_LISTA, params });
export const actionExecutivesListado = data => ({ type: EXECUTIVES_LISTADO, data });

export const actionExecutivesActualizar = data => ({ type: EXECUTIVES_ACTUALIZAR, data });
export const actionExecutivesActualizada = (params) => ({ type: EXECUTIVES_ACTUALIZADA, params });

export const actionExecutivesCrear = data => ({ type: EXECUTIVES_CREAR, data });
export const actionExecutivesCreada = (params) => ({ type: EXECUTIVES_CREADA, params });

export const actionExecutivesDetalle = params => ({ type: EXECUTIVE_DETALLE, params });
export const actionExecutivesDetallado = (data) => ({ type: EXECUTIVE_DETALLADO, data });
export const actionExecutivesDetalleInicia = () => ({ type: EXECUTIVE_DETALLE_INICIA, });

export const actionExecutivesHabilitarDeshabilitar = data => ({ type: EXECUTIVES_HABILITAR_DESHABILITAR, data });
export const actionExecutivesHabilitadaDeshabilitada = (params) => ({ type: EXECUTIVES_HABILITADA_DESHABILITADA, params });

export const actionExecutivesError = error => ({ type: EXECUTIVES_ERROR, error });
