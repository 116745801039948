/* eslint-disable */
import { URL_API_ROADWAYS } from './endpoints'

import {
  DEFAULT_HEADERS,
  apiPeticionExitosa,
  apiErrorHandler,
  userAuthToken,
  
} from './commons';

export const apiRoadWaysList = async params => {
  var query = await `/xcamare/${params.id}`
  //var query2 = params.is_admin === false ? await `&client=${params.client}` : '';
  return await fetch(
    `${URL_API_ROADWAYS}${query}`,
    Object.assign({ method: "GET" },
      {
        headers: new Headers({
          ...DEFAULT_HEADERS,
          Authorization: userAuthToken(),
        })
      }
    )
  ).then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}


export const apiRoadWaysCrear = async datos => {
  var query = '/';
  return await fetch(
    `${URL_API_ROADWAYS}${query}`,
    //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
    {
      method: "POST", body: JSON.stringify(datos), headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken(),
      })
    }
  ).then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}

export const apiRoadWaysScaleFactorCrear = async datos => {
  return await fetch(
    `${URL_API_ROADWAYS}/scale-factor?id=${datos.id}&l_m=${datos.l_m}&w_m=${datos.w_m}`,
    //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
    {
      method: "POST", body: JSON.stringify(datos.lines), headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken(),
      })
    }
  ).then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}

export const apiRoadWaysActualizar = async datos => {
  var query = datos.id ? `/${datos.id}` : '';
  return await fetch(
    `${URL_API_ROADWAYS}${query}`,
    //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
    {
      method: "PUT", body: JSON.stringify(datos), headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken(),
      })
    }
  ).then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}

export const apiRoadWaysDetail = async params => {
  var query = params.id ? `/${params.id}` : '';
  return await fetch(
    `${URL_API_ROADWAYS}${query}`,
    Object.assign({ method: "GET" },
      {
        headers: new Headers({
          ...DEFAULT_HEADERS,
          Authorization: userAuthToken(),
        })
      }
    )
  ).then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}

export const apiRoadWaysHabilitarDeshabilitar = async datos => {
  var query = `/${datos.id}/delete`;
  return await fetch(
    `${URL_API_ROADWAYS}${query}`,
    {
      method: "DELETE", body: "", headers: new Headers({
        ...DEFAULT_HEADERS,
        "Content-Type": "text/plain",
        Authorization: userAuthToken(),
      })
    }
  )
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
}

