/* eslint-disable */
// import { URL_API_DETECTORS } from './endpoints';

import {
  DEFAULT_HEADERS,
  apiPeticionExitosa,
  apiErrorHandler,
  userAuthToken
} from './commons';

export const apiDetectorsList = async () => {
  return await fetch(
    'https://d33p-api.azurewebsites.net/detectors/',
    Object.assign(
      { method: 'GET' },
      {
        headers: new Headers({
          ...DEFAULT_HEADERS,
          Authorization: userAuthToken()
        })
      }
    )
  )
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
};

export const apiDetectorsCrear = async (datos) => {
  return await fetch(
    'https://d33p-api.azurewebsites.net/detectors/',
    //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
    {
      method: 'POST',
      body: JSON.stringify(datos),
      headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken()
      })
    }
  )
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
};

export const apiDetectorsActualizar = async (datos) => {
  var query = datos.id ? `/${datos.id}` : '';
  return await fetch(
    `https://d33p-api.azurewebsites.net/detectors/${query}`,
    //Object.assign({ method: "POST", body: JSON.stringify(datos)}, DEFAULT_HEADERS)
    {
      method: 'PUT',
      body: JSON.stringify(datos),
      headers: new Headers({
        ...DEFAULT_HEADERS,
        Authorization: userAuthToken()
      })
    }
  )
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
};

export const apiDetectorDetail = async (params) => {
  var query = params.id ? `/${params.id}` : '';
  return await fetch(
    `https://d33p-api.azurewebsites.net/detectors/${query}`,
    Object.assign(
      { method: 'GET' },
      {
        headers: new Headers({
          ...DEFAULT_HEADERS,
          Authorization: userAuthToken()
        })
      }
    )
  )
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
};

export const apiDetectorsHabilitarDeshabilitar = async (datos) => {
  var query = `/${datos.id}`;
  return await fetch(`https://d33p-api.azurewebsites.net/detectors/${query}`, {
    method: 'DELETE',
    body: '',
    headers: new Headers({
      ...DEFAULT_HEADERS,
      'Content-Type': 'text/plain',
      Authorization: userAuthToken()
    })
  })
    .then(apiPeticionExitosa)
    .catch(apiErrorHandler);
};
